import React from 'react';
import TendersList from '@components/setup/TendersList';

const Tenders = () => {
  return (
    <div id="tenders" className="megaContent">
      <div className="container-fluid">
         <TendersList />
      </div>
    </div>
  );
};

export default Tenders;