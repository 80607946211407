import React, { useState, useContext, useCallback} from "react";
import { login, getTranslations } from "lib/api";
import { Eye, EyeSlash } from "@icons";
import useDebounce from "@components/customHooks/useDebounce";
import { LoginHero } from "components/atomic/molecules";
import { useNavigate } from "react-router-dom-v5-compat";
import { LevelContext } from "components/contexts/LevelContext";
import { validatePassword, validdateEmail } from "utils/validation";
import { Row, Col, Form, InputGroup, Button, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { OtpInput } from "components/atomic/atoms";
import { OETrans } from "components/translation/OETrans";
import { translate } from "components/translation/translation";

// Component
const LoginForm = ({ setShowLogin, setApiError }) => {
  // Context
  const { setUserInfo, setIsOe } = useContext(LevelContext);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [error, setError] = useState({ show: false, message: "", title: "" });
  const userLanguage = localStorage.getItem('userLanguage') ?? 'IT';
  const navigate = useNavigate();

  const alwaysRequiredFields = ["email", "password", "otp"];

   useDebounce(() => {
      fetchTranslations();
   });

   const fetchTranslations = useCallback( async (e) => {
      getTranslations().then((retrievedTranslations) => {
         const filteredTranslations = retrievedTranslations.data.filter((trans) => trans.langCode === userLanguage);

         localStorage.setItem('translations', JSON.stringify(filteredTranslations));
      }).catch((error) => {
         setApiError(true);
         console.error(translate('common.error.translations', 'Errore nel caricamento delle traduzioni'));
      });
   }, [setApiError, userLanguage]);

  // Event handlers
  const handleClick = async (e) => {
    setError({ show: false, message: "", title: "" });
    // Check if loginForm is empty
    if (
      validdateEmail(loginForm.email) &&
      validatePassword(loginForm.password)
    ) {
      setIsLoading(true);
      await login(loginForm.email, loginForm.password, loginForm.otp).then(
        (resp) => {
          if (resp.code === 200) {
            if (resp.data && resp.data.token) {
              setUserInfo(resp.data);
              setIsOe(resp.data.isOe);
              navigate("/projects");
            } else {
              setError({
                show: true,
                message: resp.message,
                title: "Successo",
              });
              setShowOtp(true);
              setIsLoading(false);
            }
          } else {
            if (
              resp.code === 429 &&
              resp.message !== "Unauthorized" &&
              resp.message !== "Too many requests"
            ) {
              setError({
                show: true,
                message: resp.message,
                title: "Errore",
              });
              setShowOtp(true);
              setIsLoading(false);
            } else if (resp.code === 422) {
              Object.keys(resp.data).map((key) => {
                // Se key è un required field, set requiredFields
                if (alwaysRequiredFields.includes(key)) {
                  setError({
                    show: true,
                    message: resp.data[key].join(" "),
                    title: "Errore",
                  });
                }
                return null;
              });
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setError({
                show: true,
                message: translate('common.error.loginvalidation', 'Email o password non validi'),
                title: translate('common.error', 'Errore'),
              });
            }
          }
        }
      );
    } else {
      setError({
        show: true,
        message: translate('common.error.loginvalidation', 'Email o password non validi'),
        title: translate('common.error', 'Errore'),
      });
    }
  };

  return (
    <Row className="w-100 d-flex justify-content-center m-0">
      <Col
        lg={8}
        className="p-4 d-none d-lg-flex flex-column justify-content-center"
      >
        <LoginHero />
      </Col>
      <Col
        xs={12}
        md={12}
        lg={4}
        className="p-4 d-flex flex-column justify-content-center text-center"
      >
        <Row>
          <Col>
            <Alert
              className="text-start"
              variant={error.title === "Errore" ? "danger" : "success"}
              onClose={() => setError({ show: false })}
              dismissible
              show={error.show}
            >
              <Alert.Heading
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "15.6px",
                  fontFamily: "Barlow",
                }}
              >
                {error.title}
              </Alert.Heading>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "18px",
                  fontFamily: "Barlow",
                }}
              >
                {error.message}
              </p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5
              style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "28.8px",
                fontFamily: "Barlow",
                color: "rgba(8, 162, 170, 1)",
              }}
              className="pb-3"
            >
              {showOtp ? (
                <>
                  <FontAwesomeIcon
                    color="#2C3D68"
                    icon={faCircleArrowLeft}
                    onClick={() => {
                      setShowOtp(false);
                    }}
                    className="px-2"
                    style={{ cursor: "pointer" }}
                  />
                  Inserisci il codice OTP
                </>
              ) : (
                  <OETrans code='common.hero.signin' fallback={'Accesso'} />
              )}
            </h5>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form>
              {showOtp ? (
                <>
                  <Row>
                    <Col className="text-center">
                      {/** Testo informativo per inserimento del codice otp */}
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          fontFamily: "Barlow",
                        }}
                      >
                        Inserisci il codice OTP inviato alla tua email
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-3">
                      <OtpInput
                        onInputChange={(otp) => {
                          setLoginForm({
                            ...loginForm,
                            otp: otp,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="text-center"
                      type="email"
                      name="email"
                      value={loginForm.email}
                      placeholder={translate('common.email', 'Email')}
                      onChange={({ target: { value } }) =>
                        setLoginForm({ ...loginForm, email: value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <InputGroup>
                      <Form.Control
                        isInvalid={false}
                        className="text-center"
                        style={{ borderRadius: "8px" }}
                        {...(showPass
                          ? { type: "text" }
                          : { type: "password" })}
                        name="password"
                        value={loginForm.password}
                        placeholder={translate('common.password', 'Password')}
                        onChange={({ target: { value } }) =>
                          setLoginForm({ ...loginForm, password: value })
                        }
                      />
                      <InputGroup.Text
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: 0,
                          height: "100%",
                          borderColor: "#b3b3b3",
                        }}
                      >
                        {showPass ? (
                          <Eye
                            className="eye"
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <EyeSlash
                            className="eye"
                            onClick={() => setShowPass(!showPass)}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>{" "}
                </>
              )}

              <Button
                disabled={isLoading}
                onClick={(e) => {
                  // remove otp code 
                  setLoginForm({ ...loginForm, otp: "" });
                  handleClick(e);
                }}
                variant="info"
                type="button"
              >
                {isLoading ? <><OETrans code='common.loading' fallback={'Caricamento'}/>...</> : <OETrans code='common.login' fallback={'Accedi'}/>}
              </Button>
            </Form>
          </Col>
        </Row>
        {showOtp ? (
          <Row>
            <Col>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontFamily: "Barlow",
                }}
                className="pt-3"
              >
                Non hai ricevuto il codice?{" "}
                <Button
                  variant="link"
                  disabled={isLoading}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                  style={{
                    color: "rgb(8, 162, 170)",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    fontFamily: "Barlow",
                  }}
                >
                  Richiedi un nuovo codice
                </Button>
              </p>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontFamily: "Barlow",
                }}
                className="pt-3"
              >
                Hai dimenticato la password?{" "}
                <Button
                  variant="link"
                  onClick={() => setShowLogin(false)}
                  style={{
                    color: "rgb(8, 162, 170)",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontFamily: "Barlow",
                  }}
                  disabled={isLoading}
                >
                  Recupera qui
                </Button>
              </p>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default LoginForm;
