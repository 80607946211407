import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Dropdown,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { IcoFilter } from "@icons";
import { getProjectResumeTimesheets, getOEChecklistQuestions } from "@lib/api";
import { optionsForMonths } from "@lib/helpers/calendar";
import Select from "react-select";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import { LevelContext } from "components/contexts/LevelContext";

const CustomMenu = ({
  selectedColumn,
  columns,
  onChange,
  setSelectedColumn,
  searchValue,
  selectedColor,
  optionsColors,
  setSelectedColor,
  optionsQuestions,
  selectedQuestion,
  setSelectedQuestion,
  optionsAnswers,
  selectedAnswer,
  setSelectedAnswer,
  optionsSearchType,
  selectedSearchType,
  setSelectedSearchType,
  optionsMonths,
  selectedMonth,
  setSelectedMonth,
  optionsYesNo,
  isPendingReset,
  isPendingFilter,
  handleReset,
  handleFilterSearch,
}) => {
  let options = [];

  switch (selectedColumn.value) {
    case "number":
      options = optionsSearchType;
      break;
    case "reported":
    case "reportable":
      options = optionsYesNo;
      break;
   case "pubblicita":
      options = optionsSearchType.filter((opt) => opt.value === 2);
      break;
    default:
      options = optionsSearchType.filter((opt) => opt.value === 1);
      break;
  }

  return (
    <>
      <div ref={useRef()} style={{ minWidth: "50rem" }}>
        <Form>
          <Row className="mt-2">
            <Col
              className={
                selectedSearchType.value === 1 || selectedSearchType.value === 2
                  ? "col-4"
                  : "col-7"
              }
            >
              <Form.Group as={Col} className="px-2">
                <Form.Label className="mb-0"><OETrans code='common.column' fallback={'Colonna'}/></Form.Label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="column"
                  placeholder={translate('common.placeholder.select', 'Seleziona...')}
                  value={selectedColumn}
                  options={columns}
                  onChange={(e) => onChange(e, "column", setSelectedColumn)}
                />
              </Form.Group>
            </Col>
            <Col
              className={
                selectedSearchType.value === 1 || selectedSearchType.value === 2
                  ? "col-4"
                  : "col-5"
              }
            >
              <Form.Group as={Col} className="px-2">
                <Form.Label className="mb-0">Tipo di ricerca</Form.Label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="searchType"
                  placeholder={translate('common.placeholder.select', 'Seleziona...')}
                  value={selectedSearchType}
                  options={options}
                  onChange={(e) =>
                    onChange(e, "searchType", setSelectedSearchType)
                  }
                  isDisabled={!selectedColumn.value}
                />
              </Form.Group>
            </Col>

            {selectedSearchType.value === 1 &&
              selectedColumn.value !== "month" && (
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Valore</Form.Label>
                    <FormControl
                      name="value"
                      type={
                        selectedColumn.typeOf === "number" ? "number" : "text"
                      }
                      className="filter-text-box"
                      placeholder={translate('common.search', 'Cerca') + '...'}
                      value={searchValue}
                      onChange={(e) => onChange(e, "value")}
                      style={{ height: "2.375rem" }}
                      disabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              )}

            {selectedSearchType.value === 1 &&
              selectedColumn.value === "month" && (
                <Col className="col-4">
                  <Form.Group as={Col} className="px-2">
                    <Form.Label className="mb-0">Valore</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="month"
                      placeholder={translate('common.placeholder.select', 'Seleziona...')}
                      value={selectedMonth}
                      options={optionsMonths}
                      onChange={(e) => onChange(e, "month", setSelectedMonth)}
                      isDisabled={!selectedColumn.value}
                    />
                  </Form.Group>
                </Col>
              )}

            {selectedSearchType.value === 2 && (
              <Col className="col-4">
                <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Semaforo</Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="color"
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedColor}
                    options={optionsColors}
                    onChange={(e) => onChange(e, "color", setSelectedColor)}
                    isDisabled={!selectedColumn.value}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          {selectedSearchType.value === 3 && (
            <Row className="mt-3">
              <Col className="col-7">
                <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Domanda</Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="question"
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedQuestion}
                    options={optionsQuestions}
                    onChange={(e) =>
                      onChange(e, "question", setSelectedQuestion)
                    }
                    isDisabled={!selectedColumn.value}
                  />
                </Form.Group>
              </Col>
              <Col className="col-1 mt-4 align-self-center text-center mb-2">
                con
              </Col>
              <Col className="col-4">
                <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Risposta</Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="answer"
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedAnswer}
                    options={optionsAnswers}
                    onChange={(e) => onChange(e, "answer", setSelectedAnswer)}
                    isDisabled={!selectedColumn.value}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="justify-content-end px-2 mt-4 mb-2">
            <Col className="col-auto">
              <Button variant="secondary" onClick={() => handleReset()}>
                Azzera{" "}
                {isPendingReset && (
                  <Spinner animation="border" role="status" size="sm"></Spinner>
                )}
              </Button>
            </Col>
            <Col className="col-auto">
              <Button
                variant="primary"
                className="w-100"
                onClick={(e) => handleFilterSearch(e)}
              >
                Applica{" "}
                {isPendingFilter && (
                  <Spinner animation="border" role="status" size="sm"></Spinner>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const entityObj = {
  number: {
    entityParam: "number",
    colorParam: "timesheets_color",
    entityQuestParam: "timesheets",
    questionParam: "timesheets_question_code",
    queryParam: "entity_type",
  },
  pubblicita: {
   queryParam: "advcolor",
 },
};

const OEFilterStaff = forwardRef(
  (
    {
      children,
      columnDefs,
      projectId,
      setTable,
      setTableMeta,
      isFiltered,
      setIsFiltered,
      setFilteredTotal,
      handleClick,
      setFilterQueryParamsString,
      onFilterChanged,
      tableRef,
    },
    ref
  ) => {
    const { userRole} = useContext(LevelContext);
    const [columns, setColumns] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedColumn, setSelectedColumn] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [optionsColors, setOptionsColors] = useState([]);
    const [optionsQuestions, setOptionsQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState([]);
    const [optionsAnswers, setOptionsAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [optionsSearchType, setOptionsSearchType] = useState([]);
    const [selectedSearchType, setSelectedSearchType] = useState([]);
    const [optionsMonths, setOptionsMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [optionsYesNo, setOptionsYesNo] = useState([]);
    const [, /* isOpen */ setIsOpen] = useState(false);
    const [isPendingReset, setIsPendingReset] = useState(false);
    const [isPendingFilter, setIsPendingFilter] = useState(false);

    const renderChildren = useCallback((columns) => {
      if (Array.isArray(columns) && columns.length > 0) {
        columns.forEach((col) => {
          Array.isArray(col.children)
            ? renderChildren(col.children)
            : setColumns((prevState) => [
                ...prevState,
                ...[
                  {
                    value: col.cellRendererParams?.queryParam,
                    label:
                      col.field === "reported"
                        ? "Rendicontato"
                        : col.field === "reportable"
                        ? "Eleggibile"
                        : col.headerName,
                    typeOf: col.cellRendererParams?.typeOf,
                    colId: col.field,
                  },
                ],
              ]);
        });
      }
    }, []);

    useEffect(() => {
      setOptionsColors([
        {
          value: "green",
          label: "Verde",
        },
        {
          value: "green_white",
          label: "Verde Rec.",
        },
        {
          value: "yellow",
          label: "Giallo",
        },
        {
          value: "red",
          label: "Rosso",
        },
        {
          value: "gray",
          label: "Grigio",
        },
        {
          value: "white",
          label: "Bianco",
        },
      ]);

      setOptionsAnswers([
        {
          value: "yes",
          label: translate('common.yes', 'Sì'),
        },
        {
          value: "no",
          label: translate('common.no', 'No'),
        },
        {
          value: "na",
          label: "N.A.",
        },
        {
          value: "nd",
          label: "N.D.",
        },
        {
          value: "rec",
          label: "Rec.",
        },
      ]);

      setOptionsSearchType([
        { value: 1, label: "testo" },
        ...(userRole < 70 ? [{ value: 2, label: "per semaforo" }] : []),
        ...(userRole < 70 ? [{ value: 3, label: "per controllo" }] : []),
      ]);

      setOptionsYesNo([
        { value: true, label: translate('common.yes', 'Sì') },
        { value: false, label: translate('common.no', 'No') },
      ]);

      setOptionsMonths(optionsForMonths());
    }, [userRole]);

    /* useEffect(() => {
      if (!isOpen) {
         setSearchValue('');
         setSelectedColor([]);
         setSelectedQuestion([]);
         setSelectedAnswer([]);
      }
   }, [isOpen]); */

    useEffect(() => {
      renderChildren(columnDefs);
    }, [columnDefs, renderChildren]);

    const changeOptionsQuestions = useCallback(() => {
      if (
        selectedSearchType.value === 3 &&
        selectedColumn.value &&
        selectedColumn.value === "number"
      ) {
        getOEChecklistQuestions(
          entityObj[selectedColumn.value].entityQuestParam
        ).then((retrievedQuestions) => {
          if (retrievedQuestions.success) {
            setOptionsQuestions(
              retrievedQuestions.data
                .sort(
                  (valueA, valueB) =>
                    valueA.sectionNumber - valueB.sectionNumber ||
                    valueA.number - valueB.number
                )
                .map((quest) => ({
                  value: quest.code,
                  label: `${quest.code} - ${quest.questions}`,
                }))
            );
          }
        });
      }
    }, [selectedColumn.value, selectedSearchType.value]);

    useEffect(() => {
      changeOptionsQuestions();
    }, [changeOptionsQuestions, selectedColumn]);

    const onChange = (e, fieldName, setSelect) => {
      //console.log('e.target.value', e.target.value);
      let value;

      switch (fieldName) {
        case "column":
          value = e.value ?? [];

          setSelect(e);
          setSelectedSearchType([]);
          setSearchValue("");
          break;
        case "searchType":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue(e.value === true || e.value === false ? e.value : "");
          break;
        case "value":
          value = e.target.value.trim();

          setSearchValue(value);
          if (selectedColor.value) setSelectedColor([]);
          if (selectedQuestion.value) setSelectedQuestion([]);
          if (selectedAnswer.value) setSelectedAnswer([]);
          break;
        case "month":
          setSelect(e ?? []);
          setSearchValue(e.value);
          setSelectedColor([]);
          setSelectedQuestion([]);
          setSelectedAnswer([]);
          break;
        case "color":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue("");
          setSelectedQuestion([]);
          setSelectedAnswer([]);
          break;
        case "question":
        case "answer":
          value = e.value ?? [];

          setSelect(e);
          setSearchValue("");
          setSelectedColor([]);
          break;
        default:
          break;
      }
    };

    const handleFilterSearch = async (e) => {
      e?.preventDefault();
      setIsPendingFilter(true);

      let queryParamsString;

      if (
        selectedSearchType.value === 1 ||
        selectedSearchType.value === true ||
        selectedSearchType.value === false
      ) {
        //testo
        queryParamsString = `?${selectedColumn.value}=${searchValue}`;
      } else if (selectedColumn.value === "pubblicita") {
         //pubblicita
         queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${selectedColor.value}`;
       } else if (selectedSearchType.value === 2) {
        //semaforo
        queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${
          entityObj[selectedColumn.value].entityParam
        }&${entityObj[selectedColumn.value].colorParam}=${selectedColor.value}`;
      } else if (selectedSearchType.value === 3) {
        //domanda/risposta
        queryParamsString = `?${entityObj[selectedColumn.value].queryParam}=${
          entityObj[selectedColumn.value].entityParam
        }&${entityObj[selectedColumn.value].questionParam}=${
          selectedQuestion.value
        }&${entityObj[selectedColumn.value].questionParam}_${
          selectedAnswer.value
        }=1`;
      }

      const result = await getProjectResumeTimesheets(
        projectId,
        queryParamsString
      );

      if (result.data) {
        setTable(result.data);
        const { data, success, ...restResp } = result;
        setTableMeta(restResp);
        setFilteredTotal(result.totals);
        setIsFiltered(true);
        if (setFilterQueryParamsString)
          setFilterQueryParamsString(queryParamsString);
        if (onFilterChanged)
          onFilterChanged(tableRef ? tableRef.current : ref, true);
      }

      setIsPendingFilter(false);

      if (Object.keys(selectedColumn).length > 0) {
        const allHeaders = document.getElementsByClassName("ag-header-cell");

        Array.from(allHeaders).forEach((el) => {
          el.classList.remove("ag-header-cell-filtered");
        });

        const elementSelected = document.querySelector(
          `[col-id=${selectedColumn.colId}]`
        );
        elementSelected.classList.add("ag-header-cell-filtered");
      }
    };

    useImperativeHandle(ref, () => ({
      async handleReset(e) {
        e?.preventDefault();
        setIsPendingReset(true);

        setSelectedColumn([]);
        setSelectedSearchType([]);
        setSearchValue("");
        setSelectedColor([]);
        setSelectedQuestion([]);
        setSelectedAnswer([]);

        const result = await getProjectResumeTimesheets(projectId);

        if (result.data) {
          setTable(result.data);
          const { data, success, ...restResp } = result;
          setTableMeta(restResp);
          setIsFiltered(false);
          if (setFilterQueryParamsString) setFilterQueryParamsString("");
        }

        setIsPendingReset(false);

        const allHeaders = document.getElementsByClassName("ag-header-cell");

        Array.from(allHeaders).forEach((el) => {
          el.classList.remove("ag-header-cell-filtered");
        });
      },
    }));

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <>
        <OverlayTrigger placement="top" overlay={<Tooltip>Filtri</Tooltip>}>
          <Button
            style={{ padding: "0.2rem" }}
            variant={isFiltered ? "info" : "primary"}
            onClick={(e) => {
              onClick(e);
            }}
          >
            <IcoFilter />
          </Button>
        </OverlayTrigger>

        {/* <a href="/" ref={ref} onClick={(e) => {e.preventDefault(); onClick(e);}}>
            {children}
         </a> */}
      </>
    ));

    return (
      <Dropdown
        className="me-2"
        align="end"
        autoClose="outside"
        onToggle={(e) => setIsOpen(e)}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {/* children */}
        </Dropdown.Toggle>

        <Dropdown.Menu
          /* as={CustomMenu} */ className="filter-table to-left p-3"
        >
          <CustomMenu
            columns={columns}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            searchValue={searchValue}
            optionsColors={optionsColors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            optionsQuestions={optionsQuestions}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            optionsAnswers={optionsAnswers}
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            optionsSearchType={optionsSearchType}
            selectedSearchType={selectedSearchType}
            setSelectedSearchType={setSelectedSearchType}
            optionsMonths={optionsMonths}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            optionsYesNo={optionsYesNo}
            isPendingReset={isPendingReset}
            isPendingFilter={isPendingFilter}
            onChange={onChange}
            handleReset={handleClick}
            handleFilterSearch={handleFilterSearch}
            tableRef={tableRef}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default OEFilterStaff;
