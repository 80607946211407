import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { OETrans } from 'components/translation/OETrans';

const ClientsNavHorizontal = () => {
   const urlSearch = window.location.search;

   return (
      <>
         <Navbar className='sub-navbar'>
            <Nav activeKey={urlSearch} className="justify-content-evenly">
               <Nav.Item>
                  <Nav.Link as={Link} to='/clients?groups' eventKey='?groups'>
                     <OETrans code='common.clients.groups' fallback={'Gruppi'}/>
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/clients?companies' eventKey='?companies'>
                     <OETrans code='common.clients.companies' fallback={'Società'}/>
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/clients?users' eventKey='?users'>
                     <OETrans code='common.clients.users' fallback={'Utenti'}/>
                  </Nav.Link>
               </Nav.Item>
            </Nav>
         </Navbar>
      </>
   );
};
export default ClientsNavHorizontal;
