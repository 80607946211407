import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Row, Col, Button, Tab, Tabs, Form, Card, ButtonToolbar, InputGroup, FormControl, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
//import Select from 'react-select';
import { Link } from 'react-router-dom';
import {
   getProject,
   //getAllProjectTarget,
   editProjectExpenseItems,
   getProjectFrameworks,
   updateFrameworkValues,
   createProjectFrameworks,
   getProjectCompanyTenders,
   getAllStreams,
   getProjectTenders,
   editProjectTenders,
   addMonthEndToFramework,
   addMonthStartToFramework,
   deleteMonthStartToFramework,
   deleteMonthEndToFramework,
   editProject,
} from '@lib/api';
import UploadDocument from '@components/form/upload/uploadDocuments';
import StatusBar from '@components/StatusBar';
import { InputNumber, Message } from 'primereact';
import { formatToDecimals } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { months } from '@lib/helpers/calendar';
import CustomizedCheckbox from '@components/materialComponents/Checkbox';
import { Lens, IcoPlus, IcoMinusCircled } from '@icons';
import DataHeader from 'components/DataHeader';
import { OETrans } from 'components/translation/OETrans';
import { translate } from 'components/translation/translation';

export const SwitchShowMonths = ({
   filteredValues,
   months,
   handleTargetAmountChange,
   frameworkIndex,
   localLang,
   totalPerMonths,
   isActive,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [showMonths, setShowMonths] = useState(false);

   return (
      <div className='py-3 ps-3'>
         <Row>
            <Col className=''>
               <Row className='my-3'>
                  <Col className='col-auto me-4 fw-bold'>Totale</Col>
                  <Col className='text-end'>{formatToDecimals(totalPerMonths) + ' €'}</Col>
               </Row>
            </Col>
            <Col id='col-space-planning'>&nbsp;</Col>
            <Col className='col-auto ms-auto'>
               <Form.Check
                  className='my-3'
                  inline
                  label={'Visualizza mesi'}
                  name='group1'
                  type='switch'
                  value='showMonths'
                  defaultChecked={showMonths}
                  onClick={(e) => setShowMonths(e.target.checked)}
               />
            </Col>
         </Row>

         {showMonths === true ? (
            <Row className='overflow-auto'>
               {filteredValues
                  .sort((valueA, valueB) => valueA.month - valueB.month)
                  .map((econValue, valueIndex) => {
                     //console.log('econValue', econValue);
                     return (
                        <Col key={'econValue' + econValue.id} className='pt-3 pb-4 col-month'>
                           <Row className='flex-row'>
                              <Form.Label className={`fw-normal ${(!isOe && userRole !== 5) && 'text-end'}`}>
                                 {months[econValue.month - 1]}
                              </Form.Label>
                              {(isOe || userRole === 5) ? (
                                 <InputNumber
                                    name='value'
                                    onChange={(e) =>
                                       handleTargetAmountChange(frameworkIndex, econValue.id, 'value', e.value)
                                    }
                                    min={0}
                                    //minFractionDigits={0}
                                    //maxFractionDigits={2}
                                    //suffix={'€'}
                                    mode='currency'
                                    currency='EUR'
                                    locale={localLang}
                                    className='md'
                                    value={econValue.value}
                                    disabled={!isActive}
                                 />
                              ) : (
                                 <Form.Text as='p' className='text-end'>{`${formatToDecimals(
                                    econValue.value
                                 )} €`}</Form.Text>
                              )}
                           </Row>
                           {/* //only for check */}
                           {/* <Row style={{fontSize: '13px'}}>
                     <span>id:{econValue.id}</span>
                     <span>economicFrameworkId:{econValue.projectEconomicFrameworkId}</span><br/>
                     <span>expenseItemId:{econValue.projectExpenseItemId}</span><br/>
                     <span>streamId:{econValue.projectStreamId}</span><br/>
                     <span>year:{econValue.year}</span>
                  </Row> */}
                        </Col>
                     );
                  })}
            </Row>
         ) : null}
      </div>
   );
};

const Planning = ({ project }) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [thisProject, setThisProject] = useState({})
   //const [targets, setTargets] = useState([]);
   const [/* projectAmounts */, setProjectAmounts] = useState({ totalAdmissibleAmount: 0, totalApprovedAmount: 0 })
   const [projectFrameworks, setProjectFrameworks] = useState([])
   const [optionsCompanyTenders, setOptionsCompanyTenders] = useState([])
   const [sbShow, setSbShow] = useState(<></>);
   const [projectNoticeExpenseItems, setProjectNoticeExpenseItems] = useState([])
   const [projectExpenseItems, setProjectExpenseItems] = useState([])
   const [projectExpenseItemsForAmounts, setProjectExpenseItemsForAmounts] = useState([])
   const [projectFrameworksAmount, setProjectFrameworksAmount] = useState([])
   const [streams, setStreams] = useState([])
   const [activeKey, setActiveKey] = useState((isOe || userRole === 5) ? 'economics' : 'target');
   const [activeKeyFramework, setActiveKeyFramework] = useState('');
   const localLang = `${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`;
   const [left, setLeft] = useState([]);
   const [right, setRight] = useState([]);
   const [checked, setChecked] = useState([]);
   const [originLeft, setOriginLeft] = useState([]);
   const [originRight, setOriginRight] = useState([]);
   const [isThereStreams, setIsThereStreams] = useState(false);
   const [indirectCosts, setIndirectCosts] = useState(false);
   const [indirectCostsPerc, setIndirectCostsPerc] = useState(0);
   const [isLoading, setIsLoading] = useState(false);
   //console.log('left', left);
   //console.log('right:', right);
   //console.log('checked:', checked);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   useEffect(() => {
      if (project) {
         setThisProject(project);
         setIndirectCosts(project.indirectCostsPerc > 0)
         setIndirectCostsPerc(project.indirectCostsPerc)
      }
   }, [project]);

   useEffect(() => {
      if (thisProject.id) {
         setIsLoading(true);

         /* getAllProjectTarget(thisProject.id).then((targets) => {
            setTargets(
               targets.data.map((target) => ({
                  id: target.id,
                  name: target.name,
                  description: target.description,
                  uom: target.uom,
                  projectId: target.projectId,
                  noticeTargetId: target.noticeTargetId,
                  targetValue: target.targetValue,
                  targetDate: target.targetDate,
               }))
            );
         }); */

         getProjectCompanyTenders(thisProject.companyId, true).then((companyTenders) => {  
            if (companyTenders.success) {
               setOptionsCompanyTenders(
                  companyTenders.data.map((companyTender) => ({
                     value: companyTender.id,
                     label: companyTender.tenderNumber,
                  }))
               );
            }
         });

         getProjectFrameworks(thisProject.id).then(frameworks => {
            setProjectFrameworks(frameworks.data.map(framework => ({
               id: framework.id,
               active: framework.active,
               economicFrameworkValues: framework.economicFrameworkValues,
               number: framework.number,
            })))

            if (frameworks.data.length > 0) {
               //setFrameworkId(frameworks.data.sort((a, b) => b.id - a.id)[0].id)
               setActiveKeyFramework(`framework-${frameworks.data.sort((a, b) => b.id - a.id)[0]?.id}`);
            }
         })

         getAllStreams(thisProject.id).then((streams) => {
            if (streams.success) {
               setStreams(
                  streams.data.map((stream) => ({
                     id: stream.id,
                     streamId: stream.streamId,
                     name: stream.name,
                  }))
               );
            }
         });

         setProjectExpenseItems(thisProject.expenseItems);
         setProjectExpenseItemsForAmounts(thisProject.expenseItems);
         setProjectAmounts({
            totalAdmissibleAmount: thisProject.totalAdmissibleAmount,
            totalApprovedAmount: thisProject.totalApprovedAmount,
         });
         setProjectNoticeExpenseItems(thisProject.noticeExpenseItems);

         //setOriginProjectExpenseItems(thisProject.expenseItems);

         setIsThereStreams(thisProject.streams?.length > 0);
      }
   }, [thisProject]);

   useEffect(() => {
      if (thisProject.id) {
         getProjectTenders(thisProject.id).then((associatedTenders) => {
            setIsLoading(true);

            if (associatedTenders.success) {
               let tempSelectedTenders = optionsCompanyTenders.filter(optionCompanyTender => {
                  return associatedTenders.data.some(associatedTender => {
                     return Number(optionCompanyTender.value) === Number(associatedTender.id);
                  });
               }).map(filteredOpt => ({
                  ...filteredOpt,
                  unlinkable: associatedTenders.data.filter(assTender => assTender.id === filteredOpt.value).map(el => el.unlinkable).pop(),
               }));

               let tempLeft = optionsCompanyTenders.filter(opt => {
                  return !tempSelectedTenders.some((f) => {
                     return f.value === opt.value;
                  });
               }).map(filteredOpt => ({
                  ...filteredOpt,
                  unlinkable: true,
               }));

               setLeft(tempLeft);
               setOriginLeft(tempLeft);

               setRight(tempSelectedTenders);
               setOriginRight(tempSelectedTenders);
            }
         }).then(() => {
            setIsLoading(false);
         });
      }
   }, [optionsCompanyTenders, thisProject.id]);

   /* const updateProjectExpenseStreamConfig = () => {
      getProjectExpenseItemsStreamConfig(thisProject.id).then((expenseItemsStreamConfig) => {
         setAllProjectExpenseItemsStreamConfig(
            expenseItemsStreamConfig.data.map((expenseItemsStreamConfig) => ({
               ...expenseItemsStreamConfig,
            }))
         );
      });
   }; */

   const selectTender = async () => {
      setSbShow(<></>);
      //console.log('e:', e);

      const arrTenderIds = right.map(el => el.value);
      //console.log('arrTenderIds', arrTenderIds);

      const data = {
         tenderIds: arrTenderIds,
         //_method: 'PUT',
      };

      const result = await editProjectTenders(thisProject.id, data);

      if (result.success) {
         handleStatusBar('success', `Gare associate con successo`, true);

         setRight(
            optionsCompanyTenders.filter(optionCompanyTender => {
               return result.data.some(associatedTender => {
                  return Number(optionCompanyTender.value) === Number(associatedTender.id);
               });
            }).map(filteredOpt => ({
               ...filteredOpt,
               unlinkable: result.data.filter(assTender => assTender.id === filteredOpt.value).map(el => el.unlinkable).pop(),
            }))
         );
      } else {
         handleStatusBar('error', `Non è stato possibile associare la gara alla voce di spesa`, false);
      }
   };

   const handleSave = async () => {
      setIsLoading(true);
      setSbShow(<></>);
      
      // If indireactCosts if true and IndirectCostsPerc <= 0 display error
      if (indirectCosts && (indirectCostsPerc <= 0)) {
         handleStatusBar('error', 'Il valore della percentuale addizionale deve essere maggiore dello 0%', true);
         setIsLoading(false);
         return;
      }

      if (projectExpenseItems.some(el => el.generalExpenses && el.geShare === 0)) {
         handleStatusBar('error', 'Se la voce "Gestione spese generali" è abilitata, il valore della percentuale associata deve essere maggiore dello 0%', true);
            setIsLoading(false);
            return;
      }

      let resultOfActions = [true];
      let resultOfMessages = [];

      let [projectExpenseItemsResult] = await Promise.all([
         handleItemList(
            'projectExpenseItems',
        /* originProjectExpenseItems, */ projectExpenseItems,
        /* deleteProjectExpenseItems, */ editProjectExpenseItems /* createProjectExpenseItems */
         ),
      ]);

      const resultOfAll = resultOfActions.concat(
         projectExpenseItemsResult ? projectExpenseItemsResult.resultOfActionsTemp : []
      );
      const resultOfAllMessages = resultOfMessages.concat(
         projectExpenseItemsResult ? projectExpenseItemsResult.messageResult : []
      );
      //console.log('resultOfAll', resultOfAll);
      //console.log('resultOfAllMessages', resultOfAllMessages);

      if (
         resultOfAll.length === 0 ||
         (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))
      ) {
         // Save Project 
         const projectResult = await editProject(thisProject.id, { 'indirect_costs_perc': indirectCostsPerc })
         if (projectResult.success) {
            handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true);
         } else {
            handleStatusBar('error', translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
         }
         setIsLoading(false);
      } else {
         handleStatusBar(
            'error',
            resultOfAllMessages.length > 0
               ? Object.values(resultOfAllMessages[0].data).join() || resultOfAllMessages[0].message
               : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
         setIsLoading(false);
      }

      setTimeout(() => {
         getProject(thisProject.id).then((retrievedProject) => {
            setThisProject(retrievedProject.data);
            setProjectExpenseItemsForAmounts(retrievedProject.data.expenseItems);
            setProjectExpenseItems(retrievedProject.data.expenseItems);
            //setProjectExpenseItemsForAmounts(retrievedProject.data.expenseItems)
            setProjectAmounts({
               totalAdmissibleAmount: retrievedProject.data.totalAdmissibleAmount,
               totalApprovedAmount: retrievedProject.data.totalApprovedAmount,
            });
            setProjectNoticeExpenseItems(retrievedProject.data.noticeExpenseItems);
            //setOriginProjectExpenseItems(retrievedProject.data.expenseItems)
         });
      }, 500);

      /* await Promise.all(
           projectExpenseItems.map(async (expenseItem) => {
              if (expenseItem.isNew) {
                 const createResult = await createProjectExpenseItems(thisProject.id, expenseItem)
                 delete expenseItem.isNew
                 if (createResult.success) {
                    handleStatusBar('success', `Voci di spesa aggiunte con successo`, true);
                 } else {
                    handleStatusBar('error', `Non è stato possibile aggiungere le voce di spesa`, false);
                 }
              } else {
                 expenseItem._method = 'PUT'
                 delete expenseItem.createdAt
                 delete expenseItem.updatedAt
                 delete expenseItem.createdBy
                 delete expenseItem.updatedBy
                 if (expenseItem.isChanged === undefined) {
                    delete expenseItem.name
                 }
                 const editResult = await editProjectExpenseItems(thisProject.id, expenseItem)
                 delete expenseItem._method
                 if (editResult.success) {
                    handleStatusBar('success', `Voci di spesa modificata con successo`, true);
                 } else {
                    handleStatusBar('error', `Non è stato possibile modificare le voci di spesa`, false);
                 }
              }
           })
        ); */

      /* const updatedProject = await getProject(thisProject.id)
        if (updatedProject.success) {
           setProjectExpenseItemsForAmounts(updatedProject.data.expenseItems)
           setProjectExpenseItems(updatedProject.data.expenseItems)
           //setProjectExpenseItemsForAmounts(updatedProject.data.expenseItems)
           setProjectAmounts({ totalAdmissibleAmount: updatedProject.data.totalAdmissibleAmount, totalApprovedAmount: updatedProject.data.totalApprovedAmount })
           setProjectNoticeExpenseItems(updatedProject.data.noticeExpenseItems)
        } */
   };

   const updateValues = async (projectEconomicFrameworkId) => {
      setSbShow(<></>);

      /* await Promise.all(
           projectFrameworks.forEach(framework => {
              framework.economicFrameworkValues.map(async economicFrameworkValue => {
                 const result = await updateFrameworkValues(thisProject.id, economicFrameworkValue.projectEconomicFrameworkId, economicFrameworkValue.id, economicFrameworkValue.value)
                 if (result.success) {
                    handleStatusBar('success', `Valori aggiornati con successo`, true);
                 } else {
                    handleStatusBar('error', `Non è stato possibile aggiornare i valori`, false);
                 }
              })
           })
        ) */

      const arrayForUpdate = projectFrameworksAmount.map((projectFrameworkAmount) => ({
         id: projectFrameworkAmount.id,
         value: projectFrameworkAmount.value,
      }));
      //console.log('arrayForUpdate', arrayForUpdate);

      const result = await updateFrameworkValues(
         thisProject.id,
         projectEconomicFrameworkId,
         arrayForUpdate
      );
      //console.log('result', result);

      if (result.success) {
         handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true);

         setTimeout(() => {
            /* setProjectFrameworks(result.data.economicFrameworkValues.map(framework => ({  //NOT WORK
                   id: framework.id,
                   active: framework.active,
                   economicFrameworkValues: framework.economicFrameworkValues,
                   number: framework.number,
                }))); */

            getProjectFrameworks(thisProject.id).then(frameworks => {
               setProjectFrameworks(frameworks.data.map(framework => ({
                  id: framework.id,
                  active: framework.active,
                  economicFrameworkValues: framework.economicFrameworkValues,
                  number: framework.number,
               })))

               //setFrameworkId(frameworks.data.length > 0 ? frameworks.data.sort((a, b) => b.id - a.id)[0].id : '')
            })
         }, 2000);
      } else {
         handleStatusBar('error', result.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
      }
   }

   const handleItemList = async (
      entityType,
    /* originItemList, */ itemList,
    /* deleteAPI, */ editAPI /* createAPI */
   ) => {
      let resultOfActionsTemp = [];

      /* const oldItemsDeleted = originItemList.filter(object1 => {
           return !itemList.some(object2 => {
              return Number(object1.id) === Number(object2.id);
           });
        });
  
        if (oldItemsDeleted.length > 0) {
           oldItemsDeleted.forEach(async item => {
              const deleteResult = await deleteAPI(thisProject.id, item);
              resultOfActionsTemp.push(deleteResult.success);
           });
        } */

      let validationFields = [];

      switch (entityType) {
         case 'projectExpenseItems':
            validationFields = ['name'];
            break;
         /* case 'clusters':
               validationFields = ['name', 'regions']
               break; */
         default:
            validationFields = [];
      }

      let result;
      let messageResult = [];

      await Promise.all(
         itemList.map(async (item) => {
            const resultOfEvery = validationFields.every(
               (field) => item[field] || (Array.isArray(item[field]) && item[field].every((el) => el))
            );
            //console.log('item', item);

            if (resultOfEvery) {
               if (item.id) {
                  const { name, createdAt, createdBy, updatedAt, updatedBy, ...restItem } = item;
                  result = await editAPI(thisProject.id, restItem);
                  //console.log('result edit:', result);
                  if (result.success === false) messageResult = result;
                  resultOfActionsTemp.push(result.success);
               } /* else {
               result = await createAPI(thisProject.id, item);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            } */
            } else {
               //console.log('non riempiti');
               messageResult = { success: false, data: { validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`] } };
               resultOfActionsTemp.push(false);
               return;
            }
         })
      );

      //console.log('messageResult', messageResult);
      return { resultOfActionsTemp, messageResult };
   };

   const handleSubRowChange = (parentId, parentIndex, subId, event) => {
      let updatedExpenses = [...projectExpenseItems];
      let tempUpdatedExpenses = updatedExpenses.filter(
         (el) => el.projectNoticeExpenseItemId === parentId
      );

      const name = event.originalEvent ? event.originalEvent.target.name : event.target.name;

      const subIndex = tempUpdatedExpenses.findIndex(el => el.id === subId);

      switch (name) {
         case 'humanResource':
         case 'generalExpenses':
            tempUpdatedExpenses[subIndex][name] = event.target.checked === true ? 1 : 0;

            if (name === 'generalExpenses' && event.target.checked === false) {
               tempUpdatedExpenses[subIndex]['geShare'] = 0;
            }
            break;
         case 'geShare':
            tempUpdatedExpenses[subIndex]['geShare'] = event.value;
            break;
         case 'name':
            tempUpdatedExpenses[subIndex]['isChanged'] = true;
            tempUpdatedExpenses[subIndex]['name'] = event.target.value;
            break;
         default:
            tempUpdatedExpenses[subIndex][name] = event.target.value;
      }

      updatedExpenses = updatedExpenses.filter((el) => el.projectNoticeExpenseItemId !== parentId);
      updatedExpenses.push(tempUpdatedExpenses);

      setProjectExpenseItems(updatedExpenses.flat());
   };

   const handleTargetAmountChange = (frameworkIndex, index, type, value) => {
      const updatedTargetsAmount = [...projectFrameworks[frameworkIndex].economicFrameworkValues];

      var array_index = updatedTargetsAmount.findIndex((item) => item.id === index);

      updatedTargetsAmount[array_index][type] = value;

      setProjectFrameworksAmount(updatedTargetsAmount);
   };

   const createFramework = async () => {
      const updatedFrameworks = [...projectFrameworks]
      const result = await createProjectFrameworks({ projectId: thisProject.id, active: 1 })
      if (result.success) {
         handleStatusBar('success', `Quadro economico generato con successo`, true)
         updatedFrameworks.push(result.data)
         /* updateProjectExpenseStreamConfig() */
      } else {
         handleStatusBar('error', `Non è stato possibile generare il quadro economico`, false);
      }
      setProjectFrameworks(updatedFrameworks)

      //setFrameworkId(updatedFrameworks.sort((a, b) => b.id - a.id)[0].id)
      setActiveKeyFramework(`framework-${updatedFrameworks.sort((a, b) => b.id - a.id)[0]?.id}`);
   }

   const handleIndirectCostsToggle = () => {
      setIndirectCosts(!indirectCosts);
      if (indirectCosts) {
         setIndirectCostsPerc(0)
      }

      // If additionalcost if set to true disable and set check to false all general expenses
      if (!indirectCosts) {
         const updatedItems = projectExpenseItems.map(item => ({
            ...item,
            generalExpenses: false
         }));
         setProjectExpenseItems(updatedItems);
      }
   }

   const expenseTab = () => {
      return (
         <Form>
            <div id='planning-table'>
               <Row className='mt-2 mb-4'>
                  <h5>Voci di spesa</h5>
               </Row>
            </div>
            <div>
               <>
                  <Row className="mb-4">
                     <Col className="fw-bold">Costi Indiretti</Col>
                  </Row>
                  <Row className="mb-4">
                     <Col className="ps-4 align-middle col-9">
                        <Form.Check
                           type="checkbox"
                           name="indirectCosts"
                           checked={indirectCosts}
                           label="Abilita"
                           onChange={(e) => handleIndirectCostsToggle(e)}
                        />
                     </Col>

                     <Col className="align-middle col-3">
                        <InputNumber
                           name="indirectCostsPerc"
                           min={0}
                           max={300}
                           step={1}
                           minFractionDigits={0}
                           maxFractionDigits={0}
                           disabled={!indirectCosts}
                           mode="decimal"
                           className={
                              Number(0) > 100
                                 ? "md input-candidacy justify-content-center p-invalid"
                                 : "md input-candidacy justify-content-center"
                           }
                           suffix={" %"}
                           onChange={(e) => setIndirectCostsPerc(e.value)}
                           value={indirectCostsPerc}
                        />
                     </Col>
                  </Row>
               </>
               {projectNoticeExpenseItems?.map((expense, index) => (
                  <React.Fragment key={'expItem' + index}>
                     <Row className='mb-4'>
                        <Col className='fw-bold'>{expense.name}</Col>
                     </Row>
                     {projectExpenseItems
                        .filter((el) => el.projectNoticeExpenseItemId === expense.id)
                        .sort((a, b) => a.position - b.position)
                        .map((subRow, subIndex) => {
                           return (
                              <Row key={'tr' + subIndex} className='mb-4'>
                                 <Col
                                    key={`${subIndex}-${subRow.projectNoticeExpenseItemId}`}
                                    className='ps-4 align-middle'>
                                    <span>
                                       {subRow.name ?? ''}
                                       {/*  {subRow.position} */}
                                    </span>
                                 </Col>
                                 <Col className='ps-4 align-middle'>
                                    <Form.Check
                                       type='checkbox'
                                       name='humanResource'
                                       defaultChecked={subRow.humanResource}
                                       label='Abilita gestione costi interni'
                                       onChange={(e) => handleSubRowChange(expense.id, index, subRow.id, e)}
                                    />
                                 </Col>
                                 <Col className='align-middle'>
                                    <Form.Check
                                       type='checkbox'
                                       name='generalExpenses'
                                       checked={subRow.generalExpenses}
                                       disabled={indirectCosts}
                                       label='Abilita gestione spese generali'
                                       onChange={(e) => handleSubRowChange(expense.id, index, subRow.id, e)}
                                    />
                                 </Col>
                                 <Col className='align-middle'>
                                    <InputNumber
                                       name='geShare'
                                       min={0}
                                       max={300}
                                       step={1}
                                       minFractionDigits={0}
                                       maxFractionDigits={0}
                                       disabled={!subRow.generalExpenses}
                                       mode='decimal'
                                       className={
                                          Number(subRow.geShare) > 100
                                             ? 'md input-candidacy justify-content-center p-invalid'
                                             : 'md input-candidacy justify-content-center'
                                       }
                                       suffix={' %'}
                                       value={subRow.geShare}
                                       onChange={(e) => handleSubRowChange(expense.id, index, subRow.id, e)}
                                    />
                                 </Col>
                              </Row>
                           );
                        })}
                  </React.Fragment>
               ))}
            </div>

            <Row className='mt-5 text-center'>
               <Col>
                  <Button disabled={isLoading} onClick={handleSave}>
                     <OETrans code='common.save' fallback={'Salva'}/>
                  </Button>
               </Col>
            </Row>
         </Form>
      );
   };

   const amountsTab = () => {
      const addMonthEnd = async (index, frameworkId) => {
         const result = await addMonthEndToFramework(thisProject.id, frameworkId);
         if (result.success) {
            const updatedProjectFrameworks = [...projectFrameworks];
            updatedProjectFrameworks[index].economicFrameworkValues =
               result.data.economicFrameworkValues;
            setProjectFrameworks(updatedProjectFrameworks);
         } else {
            handleStatusBar(
               'error',
               `Non è stato possibile aggiungere un mese al quadro economico`,
               false
            );
         }
      };

      const addMonthStart = async (index, frameworkId) => {
         const result = await addMonthStartToFramework(thisProject.id, frameworkId);
         if (result.success) {
            const updatedProjectFrameworks = [...projectFrameworks];
            updatedProjectFrameworks[index].economicFrameworkValues =
               result.data.economicFrameworkValues;
            setProjectFrameworks(updatedProjectFrameworks);
         } else {
            handleStatusBar(
               'error',
               `Non è stato possibile aggiungere un mese al quadro economico`,
               false
            );
         }
      };

      const deleteMonthStart = async (index, frameworkId) => {
         const result = await deleteMonthStartToFramework(thisProject.id, frameworkId);
         if (result.success) {
            const updatedProjectFrameworks = [...projectFrameworks];
            updatedProjectFrameworks[index].economicFrameworkValues =
               result.data.economicFrameworkValues;
            setProjectFrameworks(updatedProjectFrameworks);
         } else {
            handleStatusBar(
               'error',
               `Non è stato possibile eliminare un mese dal quadro economico`,
               false
            );
         }
      };

      const deleteMonthEnd = async (index, frameworkId) => {
         const result = await deleteMonthEndToFramework(thisProject.id, frameworkId);
         if (result.success) {
            const updatedProjectFrameworks = [...projectFrameworks];
            updatedProjectFrameworks[index].economicFrameworkValues =
               result.data.economicFrameworkValues;
            setProjectFrameworks(updatedProjectFrameworks);
         } else {
            handleStatusBar(
               'error',
               `Non è stato possibile eliminare un mese dal quadro economico`,
               false
            );
         }
      };

      const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

      return (
         <Fragment>
            {projectFrameworks?.length === 0 ? (
               <>
                  <Message
                     severity='warn'
                     text='Non hai ancora creato un quadro economico. Dopo aver creato il quadro economico non sarà possibile modificare streams e sotto voci di spesa'
                  />

                  {(isOe || userRole === 5) && (
                     <Row className='mt-5 mb-4'>
                        <Col className='text-center'>
                           <ConditionalWrapper
                              condition={!isThereStreams}
                              wrapper={(children) => (
                                 <OverlayTrigger placement='top' overlay={<Tooltip>Non sono stati ancora creati degli stream sul progetto</Tooltip>}>
                                    {children}
                                 </OverlayTrigger>
                              )}>
                              <span className='d-inline-block' style={{ margin: '4rem auto' }}>
                                 <Button onClick={createFramework} disabled={!isThereStreams}>Crea nuovo quadro economico</Button>
                              </span>
                           </ConditionalWrapper>
                        </Col>
                     </Row>
                  )}
               </>
            ) : <div className='simple-box scenaritab mt-1'>
               <Row>
                  <Tabs
                     id="controlled-tab-framework"
                     activeKey={activeKeyFramework}
                     onSelect={(k) => { k === 'add-target' ? createFramework() : setActiveKeyFramework(k) }} //& setFrameworkId(Number(k.replace('framework-', '')))
                     className="mb-3"
                  >
                     {projectFrameworks.sort((a, b) => b.id - a.id)
                        .map((framework, frameworkIndex) => {
                           //console.log('framework:', framework);
                           const totalPerFramework = framework.economicFrameworkValues.reduce((tot, elem) => { return tot + Number(elem.value) }, 0);

                           return <Tab
                              key={`key-tab-framework-${framework.id}`}
                              eventKey={`framework-${framework.id}`}
                              title={`Variante ${framework.number}`}
                           >
                              {(isOe || userRole === 5) && (<>
                                 <DataHeader
                                    showCol1={false}
                                    data={[{ name: 'Totale per variante', value: (totalPerFramework ? formatToDecimals(totalPerFramework) : 0) + ' €' }]}
                                    childrenButton={
                                       (isOe || userRole === 5) && (
                                          <>
                                             <Row className="flex-column gap-2">
                                                <Col className="pe-0">
                                                   <Row className="align-items-center">
                                                      <Col className="me-auto">
                                                         <OverlayTrigger placement='top' overlay={<Tooltip>Rimuovi/aggiungi mese all'inizio</Tooltip>}>
                                                            <span>Mese Inizio</span>
                                                         </OverlayTrigger>
                                                      </Col>

                                                      <Col className="col-auto px-0">
                                                         <Button variant="link" disabled={!framework.active} onClick={() => deleteMonthStart(frameworkIndex, framework.id)}><IcoMinusCircled className='red-color ico-small plus' /></Button>
                                                      </Col>
                                                      <Col className="col-auto ps-1">
                                                         <Button variant="link" disabled={!framework.active} onClick={() => addMonthStart(frameworkIndex, framework.id)}><IcoPlus className="second-color ico-small plus" /></Button>
                                                      </Col>
                                                   </Row>
                                                </Col>

                                                <Col className="pe-0">
                                                   <Row className="align-items-center">
                                                      <Col className="me-auto">
                                                         <OverlayTrigger placement='top' overlay={<Tooltip>Rimuovi/aggiungi mese alla fine</Tooltip>}>
                                                            <span>Mese Fine</span>
                                                         </OverlayTrigger>
                                                      </Col>
                                                      <Col className="col-auto px-0">
                                                         <Button variant="link" disabled={!framework.active} onClick={() => deleteMonthEnd(frameworkIndex, framework.id)}><IcoMinusCircled className='red-color ico-small plus' /></Button>
                                                      </Col>
                                                      <Col className="col-auto ps-1">
                                                         <Button variant="link" disabled={!framework.active} onClick={() => addMonthEnd(frameworkIndex, framework.id)}><IcoPlus className="second-color ico-small plus" /></Button>
                                                      </Col>
                                                   </Row>
                                                </Col>
                                             </Row>
                                             {/* <ButtonToolbar className='justify-content-end'>
                                                <InputGroup className='align-items-start mt-3 mt-md-0 ms-2'>
                                                   <OverlayTrigger placement='top' overlay={<Tooltip>Rimuovi/aggiungi mese all'inizio</Tooltip>}>
                                                      <InputGroup.Text className="bg-transparent">Mese Inizio</InputGroup.Text>
                                                   </OverlayTrigger>
                                                   
                                                   <Button variant="light" onClick={() => deleteMonthStart(frameworkIndex, framework.id)}><IcoMinusCircled className='red-color ico-small plus'/></Button>
                                                   <Button variant="light" className="pe-0" onClick={() => addMonthStart(frameworkIndex, framework.id)}><IcoPlus className="second-color ico-small plus"/></Button>
                                                </InputGroup>

                                                <InputGroup className='align-items-start mt-3 mt-md-0 ms-2'>
                                                   <OverlayTrigger placement='top' overlay={<Tooltip>Rimuovi/aggiungi mese alla fine</Tooltip>}>
                                                      <InputGroup.Text className="bg-transparent">Mese Fine</InputGroup.Text>
                                                   </OverlayTrigger>

                                                   <Button variant="light" onClick={() => deleteMonthEnd(frameworkIndex, framework.id)}><IcoMinusCircled className='red-color ico-small plus'/></Button>
                                                   <Button variant="light" className="pe-0" onClick={() => addMonthEnd(frameworkIndex, framework.id)}><IcoPlus className="second-color ico-small plus"/></Button>
                                                </InputGroup>
                                             </ButtonToolbar> */}
                                          </>
                                       )
                                    }
                                 />

                                 {/* <Row className='mt-3 mb-4 justify-content-center'>
                                    <Col className='col-auto'><Button variant="success" onClick={() => addMonthStart(frameworkIndex, framework.id)}>Aggiungi mese all'inizio</Button> </Col>
                                    <Col className='col-auto'><Button variant="success" onClick={() => addMonthEnd(frameworkIndex, framework.id)}>Aggiungi mese alla fine</Button> </Col>
                                    <Col className='col-auto'><Button variant="danger" onClick={() => deleteMonthStart(frameworkIndex, framework.id)}>Elimina mese all'inizio</Button> </Col>
                                    <Col className='col-auto'><Button variant="danger" onClick={() => deleteMonthEnd(frameworkIndex, framework.id)}>Elimina mese alla fine</Button></Col>
                                 </Row> */}
                              </>
                              )}

                              <Tabs
                                 id={`sub-tab-framework-${framework.id}`}
                                 defaultActiveKey={`stream-tab-${streams?.[0]?.id}`}
                                 className="mb-3"
                              >
                                 {streams.map(stream =>
                                    <Tab
                                       key={`key-tab-stream-${stream.id}`}
                                       eventKey={`stream-tab-${stream.id}`}
                                       title={`${stream.streamId} - ${stream.name}`}
                                    >
                                       {projectNoticeExpenseItems.map((expense, index) => {
                                          return <Fragment key={index}>
                                             <Row>
                                                <Col className='col-2 fw-bold py-3'>{expense.name}</Col>
                                                <Col className='p-0'>
                                                   {projectExpenseItemsForAmounts.sort((a, b) => a.position - b.position).map((projectExpense, valueIndex) => {
                                                      const arrayOfYears = framework.economicFrameworkValues.filter(econFram => econFram.projectExpenseItemId === projectExpense.id).filter((value, index, array) => array.findIndex(v => v.year === value.year) === index).map(el => el.year);
                                                      const totalPerExpenseItem = framework.economicFrameworkValues.filter(econFram => econFram.projectExpenseItemId === projectExpense.id && econFram.projectStreamId === stream.id).reduce((tot, elem) => { return tot + Number(elem.value) }, 0);
                                                      //console.log('projectExpense', projectExpense);
                                                      //console.log('framework.economicFrameworkValues', framework.economicFrameworkValues);

                                                      return projectExpense.projectNoticeExpenseItemId ===
                                                         expense.id ? (
                                                         <Row
                                                            key={projectExpense.id}
                                                            style={{
                                                               borderTop:
                                                                  valueIndex > 0
                                                                     ? '1px solid rgb(222, 226, 230)'
                                                                     : 'none',
                                                            }}>
                                                            <Col className='col-3 px-3 pt-4 align-top'>
                                                               {projectExpense.name}
                                                            </Col>
                                                            <Col className='px-3 pt-3'>
                                                               <Row>
                                                                  <Col>
                                                                     <Tabs>
                                                                        {arrayOfYears.map((year) => {
                                                                           const filteredValues =
                                                                              framework.economicFrameworkValues.filter(
                                                                                 (econFrameValue) =>
                                                                                    econFrameValue.year === year &&
                                                                                    econFrameValue.projectExpenseItemId ===
                                                                                    projectExpense.id &&
                                                                                    econFrameValue.projectStreamId ===
                                                                                    stream.id &&
                                                                                    econFrameValue.projectEconomicFrameworkId ===
                                                                                    framework.id
                                                                              );
                                                                           const totalPerMonths =
                                                                              filteredValues.reduce((tot, elem) => {
                                                                                 return tot + Number(elem.value);
                                                                              }, 0);
                                                                           //let showMonths = false;
                                                                           //console.log('showMonths', showMonths);

                                                                           return (
                                                                              <Tab
                                                                                 key={projectExpense.id + ' ' + year}
                                                                                 eventKey={
                                                                                    projectExpense.id + ' ' + year
                                                                                 }
                                                                                 title={year}>
                                                                                 <SwitchShowMonths
                                                                                    filteredValues={filteredValues}
                                                                                    months={months()}
                                                                                    handleTargetAmountChange={
                                                                                       handleTargetAmountChange
                                                                                    }
                                                                                    frameworkIndex={frameworkIndex}
                                                                                    localLang={localLang}
                                                                                    totalPerMonths={totalPerMonths}
                                                                                    isActive={framework.active}
                                                                                 />
                                                                              </Tab>
                                                                           );
                                                                        })}

                                                                        <Tab
                                                                           eventKey={projectExpense.id + '-total'}
                                                                           title={'Totale'}
                                                                           className='py-4 px-3'
                                                                           style={{ minWidth: '25rem' }}>
                                                                           <table>
                                                                              <tbody>
                                                                                 {arrayOfYears.map((year) => {
                                                                                    const totalPerYear =
                                                                                       framework.economicFrameworkValues
                                                                                          .filter(
                                                                                             (econFram) =>
                                                                                                econFram.projectExpenseItemId === projectExpense.id &&
                                                                                                econFram.year === year &&
                                                                                                econFram.projectStreamId === stream.id
                                                                                          )
                                                                                          .reduce((tot, elem) => {
                                                                                             return tot + Number(elem.value);
                                                                                          }, 0);

                                                                                    return (
                                                                                       <tr key={year + '-' + projectExpense.id}>
                                                                                          <td className='py-2 fw-bold'>{year}</td>
                                                                                          <td className='py-2 ps-5 text-end'>{formatToDecimals(totalPerYear) + ' €'}</td>
                                                                                       </tr>
                                                                                    );
                                                                                 })}

                                                                                 <tr>
                                                                                    <td className='py-2 fw-bold text-uppercase'>TOTALE</td>
                                                                                    <td className='py-2 ps-5 text-end'>{formatToDecimals(totalPerExpenseItem) + ' €'}</td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </Tab>
                                                                     </Tabs>
                                                                  </Col>
                                                               </Row>
                                                            </Col>
                                                         </Row>)
                                                         : ''
                                                   })}
                                                </Col>
                                             </Row>

                                          </Fragment>
                                       })}
                                       {(isOe || userRole === 5) && (
                                          <Row className='mt-5 text-center'>
                                             <Col>
                                                <Button onClick={() => updateValues(framework.id)} disabled={projectFrameworksAmount.length === 0 ? true : false}>
                                                   <OETrans code='common.save' fallback={'Salva'}/>
                                                </Button>
                                             </Col>
                                          </Row>)}
                                    </Tab>
                                 )}

                                 <Tab
                                    eventKey={`document-tab-${framework.id}`}
                                    title="Documenti"
                                 >
                                    {framework.id && <UploadDocument entity="projectEconomicFrameworks" entityId={framework.id} key={`key-document-tab-${framework.id}`} />}
                                 </Tab>
                              </Tabs>
                           </Tab>
                        })}

                     {(isOe || userRole === 5) && <Tab
                        eventKey={'add-target'}
                        title={
                           <OverlayTrigger placement='top' overlay={<Tooltip>Aggiungi quadro economico</Tooltip>}>
                              <IcoPlus className='second-color ico-small plus' />
                           </OverlayTrigger>
                        }>
                     </Tab>}
                  </Tabs>
               </Row>
            </div >
            }
         </Fragment>
      );
   }

   // const selectTendersTabOld = () => {
   //    return (
   //       <div className='mt-0'>
   //          <Row>
   //             <Tabs
   //                //defaultActiveKey={streams.sort((streamA, streamB) => streamA.streamId - streamB.streamId)[0]?.id}
   //                id='uncontrolled-tab-example'
   //                className='mb-3'>
   //                {streams
   //                   .sort((streamA, streamB) => streamA.streamId - streamB.streamId)
   //                   .map((stream) => (
   //                      <Tab
   //                         key={stream.streamId}
   //                         eventKey={stream.id}
   //                         title={`${stream.streamId} - ${stream.name}`}>
   //                         {allProjectExpenseItemsStreamConfig.length > 0 ? (
   //                            <div id='planning-table-tenders'>
   //                               <Row>
   //                                  <h5 className='mt-2 mb-4'>Voci di spesa</h5>
   //                               </Row>
   //                               <Row>
   //                                  <Col className='col-6'></Col>
   //                                  <Col className='col-4 p-0 m-0 ms-4 ps-2'>
   //                                     <div className='text-center justify-content-center' style={{ width: 'fit-content' }}>
   //                                        Associa gare <br />
   //                                        <Link
   //                                           to='/setup?tenders'
   //                                           target='_blank'
   //                                           rel='noreferrer'
   //                                           className='link-tender'>
   //                                           oppure carica nuova gara
   //                                        </Link>
   //                                     </div>
   //                                  </Col>
   //                               </Row>

   //                               {projectNoticeExpenseItems
   //                                  .sort((a, b) => a.position - b.position)
   //                                  .map((expense, index) => {
   //                                     const projectExpenseItemsStreamConfigFilteredByStreamAndExpenseItem =
   //                                        allProjectExpenseItemsStreamConfig.filter(
   //                                           (streamConfig) =>
   //                                              streamConfig.streamId === stream.id &&
   //                                              streamConfig.projectExpenseItem.projectNoticeExpenseItemId ===
   //                                              expense.id
   //                                        );
   //                                     //console.log('projectExpenseItemsStreamConfigFilteredByStreamAndExpenseItem', projectExpenseItemsStreamConfigFilteredByStreamAndExpenseItem);

   //                                     return projectExpenseItemsStreamConfigFilteredByStreamAndExpenseItem.length >
   //                                        0 ? (
   //                                        <Fragment key={index}>
   //                                           <Row className='fw-bold pt-3 px-3 pb-3'>
   //                                              {expense.name}
   //                                              {/*  {expense.noticeExpenseItemId} */}
   //                                           </Row>
   //                                           {projectExpenseItemsStreamConfigFilteredByStreamAndExpenseItem
   //                                              .sort((a, b) => a.projectExpenseItem.position - b.projectExpenseItem.position)
   //                                              .map((streamConfig, subIndex) => {
   //                                                 console.log('streamConfig', streamConfig);
   //                                                 return <Row key={'conf' + subIndex} className='px-3 py-1'>
   //                                                    <Col className='col-6'>
   //                                                       <Form.Control
   //                                                          type='text'
   //                                                          readOnly
   //                                                          plaintext
   //                                                          defaultValue={streamConfig.projectExpenseItem.name}
   //                                                       />
   //                                                       {/* LOG */}
   //                                                       {/* {streamConfig.projectExpenseItemId} */}
   //                                                    </Col>
   //                                                    <Col className='col-6'>
   //                                                       <Select
   //                                                          id={`id-` + streamConfig.stream.id + '-' + streamConfig.id}
   //                                                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
   //                                                          value={streamConfig.tenders.length > 0 ?
   //                                                             optionsCompanyTenders.filter(opt => {
   //                                                                return streamConfig.tenders.some(tender => {
   //                                                                   return Number(opt.value) === Number(tender.id);
   //                                                                });
   //                                                             }) : []
   //                                                          }
   //                                                          options={optionsCompanyTenders}
   //                                                          onChange={(e) => selectTender(e, streamConfig.stream.id, streamConfig.id)}
   //                                                          isClearable
   //                                                          isMulti
   //                                                          closeMenuOnSelect={false}
   //                                                          styles={{
   //                                                             container: (base) => ({
   //                                                                ...base,
   //                                                                width: "fit-content",
   //                                                                minWidth: "17rem",
   //                                                             }),
   //                                                             /* menu: (base) => ({
   //                                                                ...base,
   //                                                                width: "fit-content",
   //                                                                minWidth: "100%"
   //                                                             }), */
   //                                                          }}
   //                                                       />
   //                                                       {/* LOG */}
   //                                                       {/* {streamConfig.id} */}
   //                                                    </Col>
   //                                                 </Row>
   //                                              })}
   //                                        </Fragment>
   //                                     ) : (
   //                                        ''
   //                                     );
   //                                  })}
   //                            </div>
   //                         ) : (
   //                            'Nessuna voce di spesa configurata'
   //                         )}
   //                      </Tab>
   //                   ))}
   //             </Tabs>
   //          </Row>
   //       </div>
   //    );
   // };

   const selectTendersTab = () => {
      const not = (a, b) => {
         return a.filter((value) => b.indexOf(value) === -1);
      }

      const intersection = (a, b) => {
         return a.filter((value) => b.indexOf(value) !== -1);
      }

      const union = (a, b) => {
         return [...a, ...not(b, a)];
      }

      const leftChecked = intersection(checked, left);
      const rightChecked = intersection(checked, right);

      const handleToggle = (value) => () => {
         const currentIndex = checked.indexOf(value);
         const newChecked = [...checked];

         if (currentIndex === -1) {
            newChecked.push(value);
         } else {
            newChecked.splice(currentIndex, 1);
         }

         setChecked(newChecked);
      };

      const numberOfChecked = (items) => intersection(checked, items).length;

      const handleToggleAll = (items) => () => {
         if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
         } else {
            setChecked(union(checked, items));
         }
      };

      const handleCheckedRight = () => {
         let tempRight = right.concat(leftChecked);
         let tempLeft = not(left, leftChecked);

         setRight(tempRight);
         setOriginRight(tempRight);
         setLeft(tempLeft);
         setOriginLeft(tempLeft);
         setChecked(not(checked, leftChecked));
      };

      const handleCheckedLeft = () => {
         let tempLeft = left.concat(rightChecked);
         let tempRight = not(right, rightChecked);


         setLeft(tempLeft);
         setOriginLeft(tempLeft);
         setRight(tempRight);
         setOriginRight(tempRight);
         setChecked(not(checked, rightChecked));
      };

      const onFilterTextBoxChanged = (e, setList, originList) => {
         let value = e.target.value.toLowerCase();

         if (value && value.trim().length > 0) {
            let tempListFiltered = originList.filter(el => el.label.toLowerCase().includes(value));

            setList(tempListFiltered);
         } else {
            setList(originList);
         }
      };

      const customList = (title, items, listType) => {
         let itemsAvailable = items.filter(el => el.unlinkable);
         //console.log('itemsAvailable', itemsAvailable);

         return (
         <Card className="px-2 py-1">
            <Card.Header>
               <ListItem
                  className="px-0 py-1"
                  role="listitem"
               >
                  <ListItemIcon onClick={handleToggleAll(itemsAvailable)}>
                     <CustomizedCheckbox
                        checked={numberOfChecked(itemsAvailable) === itemsAvailable.length && itemsAvailable.length !== 0}
                        /* indeterminate={
                           numberOfChecked(itemsAvailable) !== itemsAvailable.length && numberOfChecked(itemsAvailable) !== 0
                        } */
                        disabled={itemsAvailable.length === 0}
                     />
                  </ListItemIcon>
                  <ListItemText id={title} primary={title} secondary={`${numberOfChecked(items)}/${items.length} selezionati`} />
                  <ButtonToolbar className='justify-content-end'>
                     <InputGroup className='search-table align-items-center mt-3 mt-md-0 ms-2'>
                        <FormControl
                           type='text'
                           className='filter-text-box'
                           placeholder={translate('common.search', 'Cerca') + '...'}
                           onInput={(e) => onFilterTextBoxChanged(e, listType === 'left' ? setLeft : setRight, listType === 'left' ? originLeft : originRight)}
                        />
                        <Lens />
                     </InputGroup>
                  </ButtonToolbar>
               </ListItem>
            </Card.Header>

            <Card.Body>
               <List
                  sx={{
                     //width: 200,
                     //height: 230,
                     bgcolor: 'background.paper',
                     overflow: 'auto',
                     height: 560,
                  }}
                  dense
                  component="div"
                  role="list"
               >
                  {items.map((item) => {
                     const labelId = `transfer-list-all-item-${item}-label`;
                     return (
                        <ListItem
                           key={item.value}
                           role="listitem"
                           button
                           onClick={handleToggle(item)}
                           disabled={item.unlinkable === false}
                        >
                           <ListItemIcon>
                              <CustomizedCheckbox
                                 checked={checked.indexOf(item) !== -1}
                                 tabIndex={-1}
                                 disableRipple
                                 disabled={item.unlinkable === false}
                              />
                           </ListItemIcon>
                           <ListItemText id={labelId} primary={`${item.label}`} />
                        </ListItem>
                     );
                  })}
               </List>
            </Card.Body>
         </Card>
      )};

      return (
         <div className='mt-0'>
            <Row className="mb-4">
               <h5 className='mt-2 mb-2'>Associa</h5><br />
               <Link
                  to='/tenders'
                  target='_blank'
                  rel='noreferrer'
                  className='link-tender small'>
                  oppure carica nuova gara
               </Link>
            </Row>

            {/* <Row>
               <Select
                  id={`id-select`}
                  placeholder={translate('common.placeholder.select', 'Seleziona...')}
                  options={optionsCompanyTenders}
                  value={right}
                  onChange={(e) => selectTender(e)}
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  styles={{
                     container: (base) => ({
                        ...base,
                        width: "fit-content",
                        minWidth: "17rem",
                     }),
                  }}
               />
            </Row> */}

            <Row className="justify-content-center align-items-center">
               <Col>{customList('Associabili', left, 'left')}</Col>

               <Col className="col-1">
                  <Row className="align-items-center flex-column">
                     {isLoading && (
                        <div className="text-center my-4">
                           <Spinner animation="border" role="status" size='sm'/>
                        </div>
                     )}
                  </Row>
                  <Row className="align-items-center flex-column">
                     <Button
                        className="my-3 transfer-button px-3"
                        variant={leftChecked.length === 0 ? 'outline-info' : 'primary'}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                     >
                        &gt;
                     </Button>
                     <Button
                        className="my-3 transfer-button px-3"
                        variant={rightChecked.length === 0 ? 'outline-info' : 'primary'}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                     >
                        &lt;
                     </Button>
                  </Row>
               </Col>

               <Col>{customList('Gare associate', right, 'right')}</Col>
            </Row>

            <Row className='mt-4 text-center'>
               <Col>
                  <Button onClick={selectTender}><OETrans code='common.save' fallback={'Salva'}/></Button>
               </Col>
            </Row>
         </div>
      );
   };

   return (
      <>
         <div className='candidacy simple-box scenaritab'>
            <Row>
               <Tabs
                  //defaultActiveKey="economics"
                  id='uncontrolled-tab-example'
                  variant='pills'
                  className='mb-3'
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
               >
                  {(isOe || userRole === 5) && (
                     <Tab eventKey='economics' title='Setup'>
                        {activeKey === 'economics' && expenseTab()}
                     </Tab>
                  )}
                  <Tab eventKey='target' title='Budget'>
                     {activeKey === 'target' && projectFrameworks && amountsTab()}
                  </Tab>
                  {(isOe || userRole === 5) && (
                     <Tab eventKey='tenders' title='Associazione gare'>
                        {activeKey === 'tenders' && selectTendersTab()}
                     </Tab>
                  )}
               </Tabs>
            </Row>
         </div>
         {sbShow}
      </>
   );
};
export default Planning;
