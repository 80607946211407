import React, { useState, useEffect, useContext } from "react";
import { LevelContext } from "@components/contexts/LevelContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TooltipInfoIcon } from "@icons";

export const OETrans = ({ code, type, fallback }) => {
   const { translations, devMode } = useContext(LevelContext);
   const [trans, setTrans] = useState({});
   //console.log('code:', code);

   /* const snakeToCamel = useCallback((code, strCode) => {
      const formattedString = code.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1));

      return formattedString;
   }, []); */

   useEffect(() => {
      if (code && translations) {
         //let returnObj = translations.filter((el) => snakeToCamel(el.code) === code).pop(); //il filtro per lang viene applicato in LoginForm.jsx
         let returnObj = translations.filter(el => {
            const formattedString = el.code.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1));
            //console.log('formattedString:', formattedString);

            return formattedString === code;
         }).pop();
         //console.log('returnObj PRIMA:', returnObj);

         //returnObj = returnObj.pop();
         //console.log('returnObj DOPO:', returnObj);

         setTrans(returnObj);
      }
   }, [code, translations]);

   const renderTooltip = (props) => {
      let message = "";

      if (props.popper.state) {
         message = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id="button-tooltip" {...props}>
            {message}
         </Tooltip>
      );
   };

   if (!trans) {
      // Se trans è vuoto, restituisci il fallback
      return devMode ? (
         <OverlayTrigger
            placement="top"
            delay={{ show: 200, hide: 400 }}
            overlay={<Tooltip>code mancante: {code}</Tooltip>}
         >
            <span>{fallback}</span>
         </OverlayTrigger>
      ) : (
         <span>{fallback}</span>
      );
   }

   if (type === "propTrans") {
      return trans?.name;
   } else if (type === "propTransTooltip") {
      return trans?.description;
   } else
      return (
         <span code={code}>
            {devMode ? (
               <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={<Tooltip>code: {code}</Tooltip>}
               >
                  <span dangerouslySetInnerHTML={{ __html: trans?.name }} />
               </OverlayTrigger>
            ) : (
               <span dangerouslySetInnerHTML={{ __html: trans?.name }} />
            )}
            {trans?.description && (
               <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={renderTooltip}
                  popperConfig={{ testObj: trans?.description }}
               >
                  <TooltipInfoIcon
                     className="tooltip-ico"
                     style={{ width: "1rem" }}
                  />
               </OverlayTrigger>
            )}
         </span>
      );
};
