import React, { useState, useEffect, useContext } from 'react';
import { Row, Spinner, Form, Col, Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import OESimpleTable from 'components/oetable/OESimpleTable';
import StatusBar from '@components/StatusBar';
import { IcoPlus } from '@icons';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import { dateFormatter } from '@lib/helpers/formatting';
import {
  getOEProceduralProgresses,
  getProceduralProgresses,
  createProceduralProgress,
} from '@lib/api/index';

import ActionBtnRendererAdvancement from '../actionBtnRenderer/ActionBtnRendererAdvancement';
import { LevelContext } from 'components/contexts/LevelContext';
import { translate } from "components/translation/translation";
import { OETrans } from 'components/translation/OETrans';

const initialState = {
  proceduralProgressCode: null,
  forecastStartDate: null,
  forecastEndDate: null,
  startDate: null,
  endDate: null,
};

const Procedural = ({ projectId }) => {
  const [newProceduralProgress, setNewProceduralProgress] = useState(initialState);
  const [showModalNew, setShowModalNew] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [proceduralList, setProceduralList] = useState(null);
  const [optionsProcedureProgesses, setOptionsProcedureProgesses] = useState([]);
  const [selectedProcedureProgress, setSelectedProcedureProgress] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  const { isOe, userRole } = useContext(LevelContext);

  const handleReset = () => {
    setSbShow(<></>);
    setSelectedProcedureProgress([]);
    setNewProceduralProgress(initialState);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);
  const handleCloseModal = (setShowModal) => {
    handleReset();
    setShowModal(false);
  };
  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (projectId) {
      getProceduralProgresses(projectId).then((proceduralProgresses) => {
        setProceduralList(proceduralProgresses.data);
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (refresh && projectId) {
      getProceduralProgresses(projectId).then((proceduralProgresses) => {
        setProceduralList(proceduralProgresses.data);
      });
      setRefresh(false);
    }
  }, [refresh, projectId]);

  useEffect(() => {
    getOEProceduralProgresses().then((proceduralProgresses) => {
      setOptionsProcedureProgesses(
        proceduralProgresses.data.map((proceduralProgress) => ({
          value: proceduralProgress.code,
          label: proceduralProgress.name,
        }))
      );
    });
  }, []);

  const onChange = (e, fieldName, setSelect) => {
    //console.log('e', e);

    let tempNewProceduralProgress = { ...newProceduralProgress };
    let value;

    switch (fieldName) {
      case 'proceduralProgressCode':
        value = e.value;
        tempNewProceduralProgress[fieldName] = value;
        setSelect(e);
        break;
      case 'forecastStartDate':
      case 'forecastEndDate':
      case 'startDate':
      case 'endDate':
      default:
        value = e.target.value;
        tempNewProceduralProgress[fieldName] = value;
    }
    setNewProceduralProgress(tempNewProceduralProgress);
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'Fase procedurale',
        field: 'proceduralProgressCode',
        valueGetter: (params) => {
          if (optionsProcedureProgesses.length > 0) {
            return optionsProcedureProgesses?.find(
              (el) => el.value === params.data.proceduralProgressCode
            )
              ? optionsProcedureProgesses?.find(
                  (el) => el.value === params.data.proceduralProgressCode
                ).label
              : params.data.proceduralProgressCode;
          }
        },
        filter: 'agTextColumnFilter',
        resizable: true,
        flex: 2,
      },
      {
        headerName: 'Inizio previsto',
        field: 'forecastStartDate',
        //filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          if (!params.data.forecastStartDate) return '';
          return dateFormatter(params.data.forecastStartDate);
        },
        ...columnCentered(),
        minWidth: 160,
      },
      {
        headerName: 'Fine prevista',
        field: 'forecastEndDate',
        //filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          if (!params.data.forecastEndDate) return '';
          return dateFormatter(params.data.forecastEndDate);
        },
        ...columnCentered(),
        minWidth: 160,
      },
      {
        headerName: 'Inizio effettivo',
        field: 'startDate',
        //filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          if (!params.data.startDate) return '';
          return dateFormatter(params.data.startDate);
        },
        ...columnCentered(),
        minWidth: 160,
      },
      {
        headerName: 'Fine effettiva',
        field: 'endDate',
        //filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          if (!params.data.endDate) return '';
          return dateFormatter(params.data.endDate);
        },
        ...columnCentered(),
        minWidth: 150,
      },
      {
        headerName: '',
        minWidth: 100,
        maxWidth: 100,
        cellStyle: { textAlign: 'center', padding: 0 },
        cellRenderer: ActionBtnRendererAdvancement,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsProcedureProgesses: optionsProcedureProgesses,
        },
      },
    ]);
  }, [optionsProcedureProgesses]);

  const handleSave = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      forecastStartDate,
      forecastEndDate,
      startDate,
      endDate,
      ...restProceduralProgress
    } = newProceduralProgress;

    let isForecastDateValid;
    let isStartDateValid;
    if (forecastStartDate !== null && forecastEndDate !== null) {
      isForecastDateValid = forecastStartDate > forecastEndDate;
    }
    if (startDate !== null && endDate !== null) {
      isStartDateValid = startDate > endDate;
    }

    const isProcedureFilled = Object.values(restProceduralProgress).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isProcedureFilled) {
      handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
      return;
    }
    if (isForecastDateValid || isStartDateValid) {
      handleStatusBar(
        'error',
        'La data di inizio non può essere successiva alla data di fine',
        false
      );
      return;
    }

    let result;
    setIsLoading(true);
    setShowSpinner(true);
    result = await createProceduralProgress(projectId, newProceduralProgress);
    //console.log('result', result);

    if (result.success) {
      handleStatusBar('success', translate('common.message.created', 'Elemento creato con successo'), true);
      setTimeout(() => {
        setShowModalNew(false);
        handleReset();
        setRefresh(true);
      }, 2500);
    } else {
      handleStatusBar(
        'error',
        result.success === false
          ? Object.values(result.data).join()
          : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
        false
      );
    }
    setIsLoading(false);
    setShowSpinner(false);
  };

  return (
    <>
      <OESimpleTable
        columnDefs={columnDefs}
        rowData={proceduralList}
        buttonToolbarVisible={true}
        buttonChildren={
          (isOe || userRole === 5) && (
            <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
              <IcoPlus className='light-color ico-small plus new-button' />
              <span className='d-md-inline'>Nuovo Progresso Procedurale</span>
            </Button>
          )
        }
        buttonTitle={'Nuovo Progresso Procedurale'}
        showModal={handleShowModal}
        setShowModal={setShowModalNew}
        pageSizeId={'progressAdv'}
        fullWidth={true}
      />
      <Modal
        show={showModalNew}
        className='modal-with-nav'
        size='lg'
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Nuovo Progresso Procedurale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id='form-new-procedural-progress'>
            <Row className='mb-3' style={{ marginTop: '2rem' }}>
              <Col className='col-6'>
                <Form.Group as={Col} className='pe-2'>
                  <Form.Label className='mb-3'>
                    Progresso Procedurale<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name='proceduralProgress'
                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                    value={selectedProcedureProgress}
                    options={optionsProcedureProgesses}
                    onChange={(e) =>
                      onChange(e, 'proceduralProgressCode', setSelectedProcedureProgress)
                    }
                    isDisabled={isLoading}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3' style={{ marginTop: '2rem' }}>
              <Col className='col-6'>
                <Form.Group as={Col} className='pe-2'>
                  <Form.Label className='mb-3'>Data Prevista Inizio</Form.Label>
                  <Form.Control
                    type='date'
                    name='forecastStartDate'
                    value={
                      newProceduralProgress?.forecastStartDate
                        ? newProceduralProgress.forecastStartDate
                        : ''
                    }
                    onChange={(e) => onChange(e, 'forecastStartDate')}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
              <Col className='col-6'>
                <Form.Group as={Col} className='pe-2'>
                  <Form.Label className='mb-3'>Data Prevista Fine</Form.Label>
                  <Form.Control
                    type='date'
                    name='forecastEndDate'
                    value={
                      newProceduralProgress?.forecastEndDate
                        ? newProceduralProgress.forecastEndDate
                        : ''
                    }
                    onChange={(e) => onChange(e, 'forecastEndDate')}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px' }}>
              <Col className='col-6'>
                <Form.Group as={Col} className='pe-2'>
                  <Form.Label className='mb-3'>Data Effettiva Inizio</Form.Label>
                  <Form.Control
                    type='date'
                    name='startDate'
                    value={newProceduralProgress?.startDate ? newProceduralProgress.startDate : ''}
                    onChange={(e) => onChange(e, 'startDate')}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
              <Col className='col-6'>
                <Form.Group as={Col} className='pe-2'>
                  <Form.Label className='mb-3'>Data Effettiva Fine</Form.Label>
                  <Form.Control
                    type='date'
                    name='endDate'
                    value={newProceduralProgress?.endDate ? newProceduralProgress.endDate : ''}
                    onChange={(e) => onChange(e, 'endDate')}
                    disabled={isLoading}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          {sbShow}
          <Row>
            <Col className="col-12">
              <span className="label">
                <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
              </span>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant='secondary'
            onClick={() => handleCloseModal(setShowModalNew)}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button disabled={isLoading} variant='primary' onClick={(e) => handleSave(e)}>
            {newProceduralProgress.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.create' fallback={'Crea'}/>}

            {showSpinner && (
              <Spinner
                as='span'
                className='ms-2'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Procedural;
