import React, { useState, useEffect, useContext } from "react";
import {
   Col,
   Form,
   Button,
   InputGroup,
   Modal,
   Row,
   OverlayTrigger,
   Tooltip,
   Tabs,
   Tab,
} from "react-bootstrap";
import Select from "react-select";
import { TooltipInfoIcon, Eye, EyeSlash } from "@icons";
import { getRoles, createUser } from "@lib/api";
import StatusBar from "@components/StatusBar";
import { LevelContext } from "@components/contexts/LevelContext"
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const UserCreation = ({
   isOEUserManagement,
   showModalNew,
   setShowModalNew,
   setRefresh,
   optionsCompanies,
   optionsPrivilegeGroups,
   optionsPrivilegeCompanies,
   optionsPrivilegeProjects,
}) => {
   const initialState = {
      firstName: "",
      lastName: "",
      email: "",
      isOe: isOEUserManagement,
      password: "",
      passwordConfirmation: "",
      companyIds: isOEUserManagement ? [1] : [],
      roleIds: [], //non c'è su dbdiagram
      /* privileges: [], //non c'è su dbdiagram */
   };

  const optionsAccountStatus = [
    { value: "ACTIVE", label: "Attivo" },
    { value: "LOCKED", label: "Bloccato" },
    { value: "SUSPENDED", label: "Sospeso" },
  ];

  const optionsOtp = [
    { value: true, label: "Attivo" },
    { value: false, label: "Inattivo" },
  ];
  const { userRole } = useContext(LevelContext);
   const [newUser, setNewUser] = useState(initialState);
   const [optionsRoles, setOptionsRoles] = useState([]);
   const [selectedRoles, setSelectedRoles] = useState([]);
   const [selectedCompanies, setSelectedCompanies] = useState([]);
   const [selectedPrivilegeGroups, setSelectedPrivilegeGroups] = useState([]);
   const [selectedPrivilegeCompanies, setSelectedPrivilegeCompanies] = useState(
      []
   );
   const [selectedPrivilegeProjects, setSelectedPrivilegeProjects] = useState(
      []
   );
  const [selectedAccountStatus, setSelectedAccountStatus] = useState([]);
  const [selectedOtp, setSelectedOtp] = useState([]);
   const [emailInvalid, setEmailInvalid] = useState(false);
   const [passwordInvalid, setPasswordInvalid] = useState(false);
   //const [isSuperAdmin, setIsSuperAdmin] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [showPass, setShowPass] = useState(false);
   const [activeKey, setActiveKey] = useState("registry");
   const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
   //password con almeno una maiuscola, almeno un numero, almeno 8 caratteri, almeno un carattere speciale
   const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line
   //console.log('newUser', newUser);

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setShowModal(false);
      setActiveKey("registry");
      setNewUser(initialState);
      setSelectedRoles([]);
      setSelectedCompanies([]);
      setSelectedPrivilegeGroups([]);
      setSelectedPrivilegeCompanies([]);
      setSelectedPrivilegeProjects([]);
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const clickHandler = () => setShowPass(!showPass);

   useEffect(() => {
      getRoles().then((retrievedRoles) => {
         setOptionsRoles(
            retrievedRoles.data.map((role) => ({
               value: role.id,
               label: role.name,
            }))
         );
      });

      /* const userRoles = localStorage.getItem('userRoles').split(',').map(Number);
          setIsSuperAdmin(userRoles.some(el => el === 1)); */
   }, []);

   const onChange = (e, fieldName, setSelect) => {
      //console.log('event:', e);

      let tempNewUser = { ...newUser };
      let value;

      switch (fieldName) {
         case "firstName":
         case "lastName":
         case "email":
         case "password":
            value = e.target.value;

            tempNewUser[fieldName] = value;
            break;
         case "companyIds":
         case "groupids":
         case "projectIds":
         case "otherCompanyIds":
            value = e ?? [];

            tempNewUser[fieldName] = value;

        setSelect(e);
        break;
      case "roleIds":
      case "otpEnabled":
      case "accountStatus":
        value = [e];

        tempNewUser[fieldName] = value;

        setSelect(e);
        break;
      default:
        value = e.target.value;
        tempNewUser[fieldName] = value;
    }

      setNewUser(tempNewUser);
   };

   const onBlur = (value, regex, setState) => {
      if (value && value.match(regex)) {
         setState(false);
      } else {
         setState(true);
      }
   };

   const handleNewUser = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const { createdAt, createdBy, updatedAt, updatedBy, ...restNewUser } =
         newUser;

      const isUserFilled = Object.values(restNewUser).every((prop) => prop !== "")
         ? true
         : false;

      restNewUser.roleIds = restNewUser.roleIds.map(
         (role) => role.value || role.id
      );
      //console.log("restNewUser", restNewUser);

      if (isOEUserManagement) {
         restNewUser.companyIds = [1];
         restNewUser.companyIds = [1];
         restNewUser.groupids = [];
         restNewUser["otherCompanyIds"] = [];
         restNewUser.projectIds = [];
      } else {
         restNewUser.companyIds = restNewUser.companyIds.map(
            (company) => company.value || company.id
         );
         restNewUser.groupids = restNewUser.groupids
            ? restNewUser.groupids.map((group) => group.value || group.id)
            : [];
         restNewUser["otherCompanyIds"] = restNewUser.otherCompanyIds
            ? restNewUser.otherCompanyIds.map(
               (company) => company.value || company.id
            )
            : [];
         restNewUser.projectIds = restNewUser.projectIds
            ? restNewUser.projectIds.map((project) => project.value || project.id)
            : [];
      }

      if (
         !isUserFilled ||
         restNewUser.roleIds.length === 0 ||
         (!isOEUserManagement && restNewUser.companyIds.length === 0)
      ) {
         handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      if (emailInvalid) {
         handleStatusBar("error", translate('common.error.email', 'Email non conforme'), false);
         return;
      }

      if (passwordInvalid) {
         handleStatusBar("error", translate('common.error.password', 'Password non conforme'), false);
         return;
      }

    restNewUser.accountStatus = selectedAccountStatus.value ?? "ACTIVE";
    restNewUser.settings = {
      otpEnabled: selectedOtp.value ?? false,
    };

      //  console.log('RESTNEW', restNewUser);
      let result = await createUser(restNewUser);

      if (result.success) {
         handleStatusBar("success", translate('common.message.created', 'Elemento creato con successo'), true);

         setTimeout(() => {
            setShowModalNew(false);
            setRefresh(true);
         }, 2000);
      } else {
         let errorMessage = Object.values(result.data)[0].pop();
         //console.log('errorMessage', errorMessage);
         handleStatusBar(
            "error",
            errorMessage ??
            translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }
   };

  return (
    <>
      <Modal
        show={showModalNew}
        size="lg"
        className="modal-with-nav"
        onHide={() => handleCloseModal(setShowModalNew)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="pt-0 px-2"><OETrans code='common.createuser' fallback={'Crea utente'} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              fill
            >
              {/* Anagrafica */}
              <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
                <Row className="mb-2rem" style={{ marginTop: "2rem" }}>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <OETrans code='users.firstName' fallback={'Nome'} />&nbsp;<span className="required-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={newUser.firstName}
                      onChange={(e) => onChange(e, "firstName")}
                    />
                  </Form.Group>

                        <Form.Group as={Col} className="px-4">
                           <Form.Label className="mb-3">
                              <OETrans code='users.lastName' fallback={'Cognome'} />&nbsp;<span className="required-field">*</span>
                           </Form.Label>
                           <Form.Control
                              type="text"
                              name="lastName"
                              value={newUser.lastName}
                              onChange={(e) => onChange(e, "lastName")}
                           />
                        </Form.Group>
                     </Row>

                     <Row className="mb-2rem">
                        <Form.Group as={Col} className="px-4">
                           <Form.Label className="mb-3">
                              <OETrans code='users.roleIds' fallback={'Ruolo'}/>&nbsp;<span className="required-field">*</span>
                           </Form.Label>
                           <Select
                              classNamePrefix="multiselect-noflex"
                              closeMenuOnSelect={false}
                              name="roleIds"
                              placeholder={translate('common.placeholder.select', 'Seleziona...')}
                              value={selectedRoles}
                              options={optionsRoles} //.filter(role => !isSuperAdmin ? optionsRoles.filter(role => opt.value !== 1) : optionsRoles)
                              onChange={(e) => onChange(e, "roleIds", setSelectedRoles)}
                           />
                        </Form.Group>

                        <Form.Group as={Col} className="px-4">
                           <Form.Label className="mb-3">
                              <OETrans code='users.email' fallback={'Email'} />&nbsp;<span className="required-field">*</span>
                           </Form.Label>
                           <Form.Control
                              autoComplete="new-password"
                              type="text"
                              name="email"
                              value={newUser.email}
                              onChange={(e) => onChange(e, "email")}
                              onBlur={(e) =>
                                 onBlur(e.target.value, emailRegex, setEmailInvalid)
                              }
                              isInvalid={emailInvalid}
                           />
                           <Form.Control.Feedback
                              type="invalid"
                              className="ps-1 position-absolute"
                           >
                              {translate('common.error.email', 'email non conforme').toLowerCase()}
                           </Form.Control.Feedback>
                        </Form.Group>
                     </Row>

                     <Row className="mb-2rem">
                        <Form.Group as={Col} className="col-6 px-4">
                           <Form.Label className="mb-3">
                              <OETrans code='users.password' fallback={'Password'} />
                              <OverlayTrigger
                                 placement="top"
                                 delay={{ show: 200, hide: 400 }}
                                 overlay={
                                    <Tooltip id="button-tooltip">
                                       <OETrans code='common.passwordmustcontain' fallback={'La password deve contenere'}/>:
                                       <ul
                                          style={{
                                             textAlign: "left",
                                             paddingLeft: "20px",
                                             margin: "auto",
                                          }}
                                       >
                                          <li><OETrans code='common.capitalletter' fallback={'una lettera maiuscola'}/>,</li>
                                          <li><OETrans code='common.number' fallback={'un numero'}/>,</li>
                                          <li><OETrans code='common.specialcharacter' fallback={'un carattere speciale'}/>,</li>
                                          <li><OETrans code='common.minimumlength' fallback={'una lunghezza minima di 8 caratteri'}/>.</li>
                                       </ul>
                                    </Tooltip>
                                 }
                              >
                                 <TooltipInfoIcon
                                    className="tooltip-ico"
                                    style={{ width: "1rem" }}
                                 />
                              </OverlayTrigger>
                              &nbsp;<span className="required-field">*</span>
                           </Form.Label>
                           <InputGroup>
                              <Form.Control
                                 autoComplete="new-password"
                                 type={showPass ? "text" : "password"}
                                 rows={3}
                                 name="password"
                                 value={newUser.password}
                                 placeholder="Password"
                                 onChange={(e) => onChange(e, "password")}
                                 isInvalid={newUser.password && passwordInvalid}
                                 onBlur={(e) => onBlur(e.target.value, passwordRegex, setPasswordInvalid)}
                              />
                              <InputGroup.Text
                                 className="addon-for-number"
                                 style={{ cursor: "pointer" }}
                              >
                                 {showPass ? (
                                    <Eye className="eye" onClick={clickHandler} />
                                 ) : (
                                    <EyeSlash className="eye" onClick={clickHandler} />
                                 )}
                              </InputGroup.Text>
                              <Form.Control.Feedback type="invalid" className="ps-1">
                                 {translate('common.error.password', 'password non conforme').toLowerCase()}
                              </Form.Control.Feedback>
                           </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} className="col-6 px-4">
                           <Form.Label className="mb-3">
                              <OETrans code='users.passwordConfirmation' fallback={'Conferma password'}/>&nbsp;
                              <span className="required-field">*</span>
                           </Form.Label>
                           <InputGroup>
                              <Form.Control
                                 autoComplete="new-password"
                                 type={showPass ? "text" : "password"}
                                 rows={3}
                                 name="passwordConfirmation"
                                 value={newUser.passwordConfirmation}
                                 placeholder="Password"
                                 onChange={(e) => onChange(e, "passwordConfirmation")}
                                 isInvalid={
                                    newUser.password &&
                                    newUser.passwordConfirmation &&
                                    newUser.password !== newUser.passwordConfirmation
                                 }
                              />
                              <InputGroup.Text
                                 className="addon-for-number"
                                 style={{ cursor: "pointer" }}
                              >
                                 {showPass ? (
                                    <Eye className="eye" onClick={clickHandler} />
                                 ) : (
                                    <EyeSlash className="eye" onClick={clickHandler} />
                                 )}
                              </InputGroup.Text>
                              <Form.Control.Feedback type="invalid" className="ps-1">
                                 {translate('common.passwordmatch', 'le password non coincidono').toLowerCase()}
                              </Form.Control.Feedback>
                           </InputGroup>
                        </Form.Group>
                     </Row>

                     {!isOEUserManagement && (
                        <Row className="mb-2rem">
                           <Form.Group as={Col} className="col-6 px-4">
                              <Form.Label className="mb-3">
                                 <OETrans code='users.companyIds' fallback={'Società di appartenenza'}/>&nbsp;
                                 <span className="required-field">*</span>
                              </Form.Label>
                              <Select
                                 classNamePrefix="multiselect-noflex"
                                 closeMenuOnSelect={false}
                                 name="companyIds"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedCompanies}
                                 options={optionsCompanies.filter(
                                    (opt) => opt.value !== 1
                                 )}
                                 onChange={(e) =>
                                    onChange(e, "companyIds", setSelectedCompanies)
                                 }
                                 isMulti
                                 isClearable
                              />
                           </Form.Group>
                        </Row>
                     )}

                     {sbShow}
                  </Tab>

                    {/* Permessi */}
                    <Tab
                       eventKey="privileges"
                title={translate('common.permissions', 'Permessi')}
                {...(isOEUserManagement && { disabled: true })}
              >
                        <Row className="mb-2rem" style={{ marginTop: "2rem" }}>
                           <Form.Group as={Col}>
                              <Form.Label className="mb-3">
                                 <OETrans code='users.groupids' fallback={'Gruppi'}/>
                              </Form.Label>
                              <Select
                                 closeMenuOnSelect={false}
                                 name="groupids"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedPrivilegeGroups}
                                 options={optionsPrivilegeGroups}
                                 isMulti
                                 isClearable
                                 onChange={(e) =>
                                    onChange(e, "groupids", setSelectedPrivilegeGroups)
                                 }
                              />
                           </Form.Group>
                        </Row>

                        <Row className="mb-2rem">
                           <Form.Group as={Col} className="px-4">
                              <Form.Label className="mb-3">
                                 <OETrans code='users.otherCompanyIds' fallback={'Società'}/>
                              </Form.Label>
                              <Select
                                 classNamePrefix="multiselect-noflex"
                                 closeMenuOnSelect={false}
                                 name="otherCompanyIds"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedPrivilegeCompanies}
                                 options={optionsPrivilegeCompanies}
                                 isMulti
                                 isClearable
                                 onChange={(e) =>
                                    onChange(
                                       e,
                                       "otherCompanyIds",
                                       setSelectedPrivilegeCompanies
                                    )
                                 }
                              />
                           </Form.Group>
                        </Row>

                        <Row  className="mb-5">
                           <Form.Group as={Col} className="px-4">
                              <Form.Label className="mb-3">
                                 <OETrans code='users.projectIds' fallback={'Progetti'}/>
                              </Form.Label>
                              <Select
                                 classNamePrefix="multiselect-noflex"
                                 closeMenuOnSelect={false}
                                 name="projectIds"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedPrivilegeProjects}
                                 options={optionsPrivilegeProjects}
                                 isMulti
                                 isClearable
                                 onChange={(e) =>
                                    onChange(e, "projectIds", setSelectedPrivilegeProjects)
                                 }
                              />
                           </Form.Group>
                        </Row>
                        {sbShow}
                     </Tab>
                    <Tab
                eventKey="Settings"
                title={"Impostazioni"}
                {...(userRole !== 1) ? { disabled: true } : {}}
              >
                <Row style={{ marginBottom: "40px" }}>
                  <Form.Group as={Col}>
                    <Form.Label>Stato Account</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      name="status"
                      placeholder="Seleziona..."
                      value={selectedAccountStatus}
                      options={optionsAccountStatus}
                      onChange={(e) =>
                        onChange(e, "accountStatus", setSelectedAccountStatus)
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Servizio Otp</Form.Label>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      name="otpEnabled"
                      placeholder="Seleziona..."
                      value={selectedOtp}
                      options={optionsOtp}
                      onChange={(e) =>
                        onChange(e, "otpEnabled", setSelectedOtp)
                      }
                    />
                  </Form.Group>
                </Row>
              </Tab>
                 </Tabs>
          </Form>

               <Row className="mt-4 mb-2">
                  <span className="mx-3">
                     <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                  </span>
               </Row>
            </Modal.Body>

            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => handleCloseModal(setShowModalNew)}
               >
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant="primary" onClick={(e) => handleNewUser(e)}>
                  <OETrans code='common.create' fallback={'Crea'}/>
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default UserCreation;
