export const translate = (code, fallback, type = 'name') => {
   let translations = localStorage.getItem('translations');
   translations = translations ? JSON.parse(translations) : [];

   if (!translations || translations.length === 0) {
      return fallback;
   } else {
      let trans = translations.filter(el => {
         const formattedString = el.code.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1));

         return formattedString === code;
      }).pop();

      return trans ? trans[type] : fallback;
   }
}