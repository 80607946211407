import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { formatToDecimals } from '@lib/helpers/formatting';

const Histogram = ({ data }) => {
  const svgRef = useRef();
  const tooltipRef = useRef(null);

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 250 - margin.left - margin.right;
    const height = 208 - margin.top - margin.bottom;

    const xScale = d3.scaleBand().range([0, width]).padding(0.3);
    const yScale = d3.scaleLinear().range([height, 0]);

    const dataPoints = data;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const tooltip = d3
      .select(tooltipRef.current)
      .attr('class', 'svg-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    xScale.domain(dataPoints.map((d) => d.label));

    const yMax = Math.max(...dataPoints.map((d) => +d.value));
    const yDomainMax = yMax > 0 ? yMax : 1;

    const yDomain = yMax > 0 ? [0, yDomainMax] : [-1, 10];

    yScale.domain(yDomain);

    svg.style('font-family', 'Barlow, sans-serif');

    svg
      .append('g')
      .call(
        d3
          .axisLeft(yScale)
          .ticks(dataPoints.some((d) => d.value > 0) ? 5 : 3)
          .tickFormat(d3.format('.2s'))
      )
      .selectAll('text')
      .style('font-family', 'Barlow');

    svg
      .selectAll('rect')
      .data(dataPoints)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.label))
      .attr('y', height)
      .attr('width', xScale.bandwidth())
      .attr('height', 0)
      .attr('fill', (d, i) => ['#C2C923', '#08A2AA', '#666666'][i])
      .on('mouseover', function (event, d) {
        tooltip.html(`${d.label} - ${formatToDecimals(+d.value)} €`).style('visibility', 'visible');
        tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseout', function () {
        tooltip.style('visibility', 'hidden');
      })
      .transition()
      .duration(1000)
      .delay((d, i) => i * 200)
      .attr('y', (d) => yScale(d.value))
      .attr('height', (d) => {  return height - yScale(d.value) < 0 ? 0 : height - yScale(d.value)});

    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .style('display', 'none');
  }, [data]);

  return (
    <div>
      <svg ref={svgRef}></svg>
      <div ref={tooltipRef}></div>
    </div>
  );
};

export default Histogram;
