import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MaxMinimize } from "@icons";
import SetupNav from "./nav/setupNav";
import ProjectNav from "./nav/projectNav";
import ClientsNav from "./nav/clientsNav";
import AdministrationNav from "./nav/administrationNav";

const Menu = () => {
   const location = useLocation();
   const [activeId, setActiveId] = useState('0');

   const toggleActive = (id) => {
      if (activeId === id) {
         setActiveId(null);
      } else {
         setActiveId(id);
      }
   }

   if (location.pathname.indexOf('setup') > 0) {
      return (
         <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
            <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0') }}><MaxMinimize className="max-minimize" /></a></div>
            <SetupNav />
         </div>
      );
   } else if (location.pathname.indexOf('clients') > 0) {
      return (
         <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
            <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0') }}><MaxMinimize className="max-minimize" /></a></div>
            <ClientsNav />
         </div>
      );
   } else if (location.pathname.indexOf('project') > 0) {
      return (
         <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
            <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0') }}><MaxMinimize className="max-minimize" /></a></div>
            <ProjectNav />
         </div>
      );
   } else if (location.pathname.indexOf('administration') > 0) {
      return (
         <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
            <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0') }}><MaxMinimize className="max-minimize" /></a></div>
            <AdministrationNav />
         </div>
      );
   }
};
export default Menu;