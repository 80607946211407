import React from 'react';
import { Link } from 'react-router-dom';
import { IcoTax, IcoBag, IcoInstitution, IcoMegaphone, /* Handshake */ } from '@icons';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const SetupNav = () => {
   const urlSearch = window.location.search;

   return (
      <>
         <ul className='menu-container side-nav'>
            <li className='menu-item'>
               <Link to='/setup?funds' className='text-decoration-none' title={translate('common.setupnav.funds', 'Fondi')}>
                  <span className={urlSearch === '?funds' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoTax />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.funds' fallback={'Fondi'}/>
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/setup?instruments' className='text-decoration-none' title={translate('common.setupnav.instruments', 'Strumenti')}>
                  <span className={urlSearch === '?instruments' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoBag />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.instruments' fallback={'Strumenti'}/> 
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/setup?institutions' className='text-decoration-none' title={translate('common.setupnav.institutions', 'Enti')}>
                  <span className={urlSearch === '?institutions' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoInstitution />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.institutions' fallback={'Enti'}/> 
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/setup?notices' className='text-decoration-none' title={translate('common.setupnav.notices', 'Bandi')}>
                  <span className={urlSearch === '?notices' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoMegaphone />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.notices' fallback={'Bandi'}/> 
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/setup?checklist' className='text-decoration-none' title={translate('common.setupnav.checklists', 'Checklist')}>
                  <span className={urlSearch === '?checklist' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoMegaphone />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.checklists' fallback={'Checklist'} />
                     </div>
                  </span>
               </Link>
            </li>
            {/* <li className='menu-item'>
               <Link to='/setup?tenders' className='text-decoration-none' title={translate('common.setupnav.tenders', 'Gare')}>
                  <span className={urlSearch === '?tenders' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <Handshake />
                     </div>
                     <div className='nav-text'>
                        <OETrans code='common.setupnav.tenders' fallback={'Gare'}/> 
                     </div>
                  </span>
               </Link>
            </li> */}
         </ul>
      </>
   );
};
export default SetupNav;
