import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Row, Col, Button, InputGroup, FormControl, Modal, Form, Spinner, Tabs, Tab } from 'react-bootstrap';
import { getBulletins, getBulletin, createBulletin, editBulletin, deleteBulletin } from '@lib/api';
import { OETrans } from 'components/translation/OETrans';
import { translate } from "components/translation/translation";
import { Lens, IcoCanc } from "@icons";
import MarkdownEditComponent from 'components/form/MarkdownComponents/MarkdownEditComponent';
import OpportunityArchive from 'components/opportunity/OpportunityArchive';
import UploadDocument from 'components/form/upload/uploadDocuments';
import StatusBar from 'components/StatusBar';
import MarkdownReaderComponent from 'components/form/MarkdownComponents/MarkdownReaderComponent';
import { optionsForMonths } from '@lib/helpers/calendar';
import { Calendar } from 'primereact';
import { dateFormatterInput, /* localeCalendar */ } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';

const initialState = {
   title: '',
   content: '',
   publishedAt: '',
};

const OpportunityLayout = () => {
   const { userRole } = useContext(LevelContext);
   const [bulletins, setBulletins] = useState([]);
   const [bulletin, setBulletin] = useState(initialState);
   const [bulletinToShow, setBulletinToShow] = useState({});
   const [lastBulletin, setLastBulletin] = useState({});
   const [noBulletins, setNoBulletins] = useState(false);
   const [selectedTreeItem, setSelectedTreeItem] = useState('');
   const [editState, setEditState] = useState(false);
   const [showModalBulletin, setShowModalBulletin] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   //console.log('bulletin:', bulletin);
   //console.log('bulletins:', bulletins);

   const handleShowModal = (setShowModal, isNew) => {
      setShowModal(true)

      if (isNew) {
         setBulletin(initialState);
         setSelectedTreeItem('');
      }
   };

   const handleCloseModal = (setState) => {
      setSbShow(<></>);
      setShowModalBulletin(false);
      setActiveKey('registry');
      setState(false);
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const fetchData = useCallback(async () => {
      getBulletins().then((retrievedBulletins) => {
         if (retrievedBulletins.success) {
            setBulletins(retrievedBulletins.data);

            let lastBulletin = {};

            if (retrievedBulletins.data.length > 0) {
               lastBulletin = retrievedBulletins.data.reduce((a, b) => {
                  return new Date(a.publishedAt) > new Date(b.publishedAt) ? a : b;
               });
            }

            setLastBulletin(lastBulletin);
            setSelectedTreeItem(lastBulletin.id);
         }
      });
   }, []);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      if (selectedTreeItem) {
         getBulletin(Number(selectedTreeItem)).then((retrievedBulletin) => {
            if (retrievedBulletin.success) {
               setBulletin(retrievedBulletin.data);
            }
         });
      }
   }, [selectedTreeItem]);

   useEffect(() => {
      setBulletinToShow(selectedTreeItem ? bulletin : lastBulletin);
   }, [bulletin, lastBulletin, selectedTreeItem]);

   const onSearch = useCallback((e) => {
      if (e.target.value.length >= 2) {
         const filteredBulletins = bulletins.filter(el => el.title.includes(e.target.value) || el.content.includes(e.target.value));

         if (filteredBulletins.length > 0) {
            setNoBulletins(false);
            setBulletins(filteredBulletins);
         } else {
            setBulletinToShow({});
            setNoBulletins(true);
         }
      } else {
         setNoBulletins(false);
         fetchData();
      }
   }, [bulletins, fetchData]);

   const onChange = (e, section, topic, setState, state) => {
      //console.log('e onChange', e);

      switch (section) {
         case 'description':
            setState(() => ({
               ...state,
               [topic]: topic === 'title' ? e.target.value : (e === '<p></p>' ? '' : e),
            }));

            break;
         case 'files':
            setState(() => ({
               ...state,
               [topic]: e.target ? e.target.value : e.value,
            }));
            break;
         case 'date':
            setState(() => ({
               ...state,
               [topic]: e.value,
            }));
            break;
         case 'images':
            /* setState(() => ({
               ...state,
               [topic]: e.target ? e.target.value : e.value,
            })); */

            /*const reader = new FileReader();
            reader.readAsBinaryString(e.target.files[0]);
            reader.onload = (event) => {
               setState(() => ({
                  ...state,
                  [topic]: e.target ? e.target.value : e.value,
                  file: `data:${e.target.files[0].type};base64,${btoa(event.target.result)}`,
               }));
            }; */

            /* const formData = new FormData();

            formData.append('file', e.target.files[0], e.target.files[0].name);
            console.log('formData', formData);
            setState(() => ({
              ...state,
              [topic]: e.target ? e.target.value : e.value,
              file: formData,
            })); */
            break;
         default:
            break;
      }
   };

   const handleSave = async (e) => {
      setSbShow(<></>);
      setIsLoading(true);

      if (!bulletin.title || !bulletin.content || !bulletin.publishedAt) {
         handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      bulletin.companyId = 1; //Openeconomics (rivedere la logica business)
      if (typeof bulletin.publishedAt !== 'string') bulletin.publishedAt = dateFormatterInput(bulletin.publishedAt);

      const { createdAt, updatedAt, ...restBulletin } = bulletin;

      const result = bulletin.id ? await editBulletin(restBulletin) : await createBulletin(restBulletin);
      //console.log('result', result);

      if (result.success) {
         handleStatusBar('success', translate('common.message.saved', 'Elemento salvato con successo'), true);

         setTimeout(() => {
            setIsLoading(false);
            setBulletin(() => ({
               ...result.data,
               companyId: result.data.company.id,
            }));
            setActiveKey('documents');
            fetchData();
         }, 2000);
      } else {
         setIsLoading(false);
         handleStatusBar('error', result.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
      }
   };

   const handleDelete = async () => {
      setSbShow(<></>);

      const deleteResult = await deleteBulletin(bulletin.id);

      if (deleteResult.success) {
         handleStatusBar("success", translate('common.message.deleted', 'Elemento eliminato correttamente'), true);
         setTimeout(() => {
            setShowModalBulletin(false);
            fetchData();
         }, 2000);
      } else {
         handleStatusBar(
            "error",
            deleteResult.message ??
            translate('common.error.delete', 'Si è verificato un errore durante l’eliminazione delle informazioni'),
            false
         );
      }
   };

   return (
      <>
         <Row className='pb-4' style={{ borderBottom: '1px solid #B3B3B3' }}>
            <Col className=''>
               <Row className="align-items-center">
                  <Col className='px-0'>
                     <span style={{ fontWeight: 700, fontSize: '2.5rem', fontFamily: 'Barlow', lineHeight: 'normal' }}><OETrans code='common.bulletins' fallback={'Bollettini'} /></span>
                  </Col>
                  {userRole <= 30 && <>
                     <Col className='text-end px-0'>
                        <Button variant="outline-info" className={selectedTreeItem ? 'me-2' : 'invisible'} onClick={() => handleShowModal(setShowModalBulletin, false)}>
                           <span className="d-md-inline"><OETrans code='common.opportunity.edit' fallback={'Modifica'} /></span>
                        </Button>
                        <Button variant="primary" onClick={() => handleShowModal(setShowModalBulletin, true)}>
                           <span className="d-md-inline"><OETrans code='common.opportunity.new' fallback={'Nuovo'} /></span>
                        </Button>
                     </Col>
                  </>
                  }
               </Row>
            </Col>
         </Row>

         <Row className='mt-5'>
            <Col className='col-9 px-5'>
               <div className='opportunity simple-box' style={{minHeight: '30rem'}}>
                  {Object.keys(bulletinToShow).length > 0 ? <>
                     <div className='text-center mb-4'>
                        <h1 className='bulletin-title'>{bulletinToShow.title ?? ''}</h1>
                        <span className='bulletin-date'>
                           {bulletinToShow.publishedAt ?
                              <>{optionsForMonths().find((m) => m.value === (new Date(bulletinToShow.publishedAt).getMonth() + 1))?.label} {new Date(bulletinToShow.publishedAt).getFullYear()}</>
                              : null
                           }
                        </span>
                     </div>
                     <MarkdownReaderComponent text={bulletinToShow.content} />
                  </> : null}
               </div>
            </Col>
            <Col className='col-3'>
               <div className='opportunity simple-box' style={{minHeight: '30rem'}}>
                  <Row className='flex-column'>
                     <Col className='mb-3 px-0'>
                        <span style={{ fontWeight: 500, fontSize: '1.2rem', fontFamily: 'Barlow', lineHeight: 'normal' }}>
                           <OETrans code='common.search' fallback={'Cerca'} />
                        </span>
                     </Col>
                     <Col className='px-0'>
                        <InputGroup className="search-table align-items-center m-0">
                           <FormControl
                              type="text"
                              className="filter-text-box"
                              onInput={(e) => onSearch(e)}
                           />
                           <Lens className="lens" />
                        </InputGroup>
                     </Col>
                  </Row>

                  <Row className='flex-column mt-5'>
                     <Col className='pt-4 px-0' style={{ borderTop: '1px solid #B3B3B3' }}>
                        <span style={{ fontWeight: 500, fontSize: '1.2rem', fontFamily: 'Barlow', lineHeight: 'normal' }}>
                           <OETrans code='common.opportunity.archive' fallback={'Archivio'} />
                        </span>
                     </Col>
                     <Col className='pt-3 px-0'>
                        {noBulletins ? 
                        <OETrans code='common.opportunity.noitemsfound' fallback={'Nessun elemento trovato'} /> :
                        <OpportunityArchive
                           bulletins={bulletins}
                           lastBulletinYear={lastBulletin.publishedAt ? new Date(lastBulletin.publishedAt).getFullYear() : null}
                           selectedTreeItem={selectedTreeItem}
                           setSelectedTreeItem={setSelectedTreeItem}
                        />}
                     </Col>
                  </Row>
               </div>
            </Col>
         </Row>


         <Modal
            show={showModalBulletin}
            className="modal-with-nav"
            size="xl"
            dialogClassName="modal-75w"
            onHide={() => handleCloseModal(setShowModalBulletin)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>{bulletin.id ? <OETrans code='common.bulletin.editbulletin' fallback={'Modifica Bollettino'} /> : <OETrans code='common.bulletin.createbulletin' fallback={'Crea Bollettino'} />}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Tabs
                  className="mb-4"
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                  fill
               >
                  <Tab eventKey="registry" title={"Dettagli"}>
                     <Form onSubmit={handleSave} id="form-new-notice">
                        <Row>
                           <Col>
                              <Row className='mb-3'>
                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       <OETrans code='common.opportunity.title' fallback={'Titolo'} />
                                       &nbsp;<span className="required-field">*</span>
                                    </Form.Label>
                                    <Form.Control
                                       type="text"
                                       name="title"
                                       value={bulletin.title}
                                       onChange={(e) => onChange(e, 'description', 'title', setBulletin, bulletin)}
                                    />
                                 </Form.Group>

                                 <Form.Group as={Col} className="col-3">
                                    <Form.Label>
                                       <OETrans code='common.opportunity.date' fallback={'Data'} />
                                       &nbsp;<span className="required-field">*</span>
                                    </Form.Label>
                                    <Calendar
                                       value={bulletin.publishedAt && typeof bulletin.publishedAt === 'string' ? new Date(bulletin.publishedAt) : bulletin.publishedAt}
                                       onChange={(e) => onChange(e, 'date', 'publishedAt', setBulletin, bulletin)}
                                       view='month'
                                       dateFormat='mm/yy'
                                       style={{ height: '38px' }}
                                    />
                                 </Form.Group>
                              </Row>

                              <Row className='mb-3 pt-4'>
                                 <MarkdownEditComponent
                                    titleCode='common.opportunity.content'
                                    fallback='Contenuto'
                                    placeholder={''}
                                    onChange={e => onChange(e, 'description', 'content', setBulletin, bulletin)}
                                    name='content'
                                    type='description'
                                    existingContent={bulletin.content}
                                    editState={editState}
                                    setEditState={setEditState}
                                    toolbarOptions={['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history']}
                                    mandatory={true}
                                 />
                              </Row>
                           </Col>
                        </Row>
                     </Form>

                     <Row>
                        <Col className="col-12">
                           <span className="label">
                              <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'} />
                           </span>
                        </Col>
                     </Row>

                     {sbShow}
                  </Tab>

                  <Tab eventKey="documents" title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!bulletin.id}>
                     <UploadDocument
                        entity="bulletins"
                        entityId={bulletin.id}
                        disabled={bulletin.id}
                     />
                     {sbShow}
                  </Tab>
               </Tabs>
            </Modal.Body>

            <Modal.Footer>
               {bulletin.id &&
                  <Button variant="outline-danger" className='me-auto' onClick={(e) => handleDelete()}>
                     <IcoCanc className='red-color ico-small cancel me-1' style={{ verticalAlign: 'text-top', }} />
                     <OETrans code='common.opportunity.deletebulletin' fallback={'Elimina bollettino'} />
                  </Button>
               }
               <Button
                  variant="secondary"
                  onClick={() => handleCloseModal(setShowModalBulletin)}
               >
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button
                  variant="primary"
                  onClick={(e) => handleSave(e)}
                  disabled={activeKey === 'documents'}
               >
                  {bulletin.id ? <OETrans code='common.save' fallback={'Salva'} /> : <OETrans code='common.create' fallback={'Crea'} />}
                  {isLoading && (
                     <Spinner
                        as="span"
                        className="ms-2"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                     />
                  )}
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};
export default OpportunityLayout;