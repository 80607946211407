import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const StatusBar = ({ status, message, timeout, children }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setDisplay(true);
  }, [status, message, timeout]);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setDisplay(false);
      }, 7000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  if (!display) return null;

  if (display) {
    return (
      <div id='statusbar'>
        {status === 'success' && (
          <div className='simple-box success'>
            <h5 className='d-inline me-2' style={{color: 'white'}}>SUCCESS</h5> {message ? <span>{message}</span> : null}
          </div>
        )}

        {status === 'error' && (
          <div className='simple-box error'>
            <h5 className='d-inline me-2'>ERROR</h5> {message ? <span>{message}</span> : null}
            {/* {children} */}
          </div>
        )}
        {status === 'errors' && <>{children}</>}

        {status === 'elaborating' && (
          <div className='simple-box elaborating'>
            <h5>
              <Spinner animation='border' role='status'></Spinner> ELABORATING
            </h5>
          </div>
        )}
      </div>
    );
  }
};
export default StatusBar;
