import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Alert } from "components/atomic/atoms";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const TranslationForm = ({
  languages,
  translation,
  setTranslation,
  loading,
  isUpdateMode,
  isCodeExists,
  originalValues,
  onRequiredFieldsChange,
}) => {
   const [requiredFields, setRequiredFields] = useState({});
   //console.log('originalValues FORM:', originalValues);
   //console.log('translation FORM:', translation);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setTranslation({
      ...translation,
      [name]: value,
    });

    const alwaysRequiredFields = [
      "code",
      ...languages.map((lang) => `name_${lang}`),
    ];
    if (alwaysRequiredFields.includes(name)) {
      if (value.trim() === "") {
        setRequiredFields({ ...requiredFields, [name]: true });
      } else {
        const newFields = { ...requiredFields };
        delete newFields[name];
        setRequiredFields(newFields);
      }
    }
  };

  useEffect(() => {
    onRequiredFieldsChange(requiredFields);
  }, [requiredFields, onRequiredFieldsChange]);

  return (
    <Form>
      {loading && (
        <Row>
          <Col className="mb-4 col-12">
            <Alert
              show={loading}
              message={translate('common.saving', 'Salvataggio in corso') + '...'}
              variant="info"
              delay={5000}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Form.Group as={Col} className="mb-4">
          <Form.Label>
            Codice <span className="required-field">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="code"
            value={translation.code || ""}
            onChange={handleChanges}
            isInvalid={(!isUpdateMode && isCodeExists) || (isUpdateMode && isCodeExists && (translation.code !== originalValues.code))}
          />
          <Form.Control.Feedback type="invalid" className="ps-1 position-absolute">
            {translate('common.translations.codeexists', 'codice già esistente').toLowerCase()}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {languages.map((lang, i) => {
        // Create 2 input fields for each language [name, description]
        return (
          <Row key={i}>
            <Form.Group as={Col} className="mb-4 col-6">
              <Form.Label>
                {lang} <span className="required-field">*</span>
              </Form.Label>
              <Form.Control
                as="textarea" 
                rows={2}
                name={`name_${lang}`}
                value={translation[`name_${lang}`] || ""}
                {...(requiredFields[`name_${lang}`] && { isInvalid: true })}
                onChange={handleChanges}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-4 col-6">
              <Form.Label><OETrans code='oetranslations.description' fallback={'Descrizione'}/></Form.Label>
              <Form.Control
                as="textarea" 
                rows={2}
                name={`description_${lang}`}
                value={translation[`description_${lang}`] || ""}
                onChange={handleChanges}
              />
            </Form.Group>
          </Row>
        );
      })}
    </Form>
  );
};

export default TranslationForm;
