import React from 'react';
import { Link } from 'react-router-dom';
//import { Trans } from '@lib/i18n';
import { SocialNetworking, IcoEnterprise, IcoUtenteBox } from '@icons';

const ClientsNav = () => {
   const urlSearch = window.location.search;

   return (
      <>
         <ul className='menu-container side-nav'>
            <li className='menu-item'>
               <Link to='/clients?groups' className='text-decoration-none' title="Gruppi">
                  <span className={urlSearch === '?groups' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <SocialNetworking />
                     </div>
                     <div className='nav-text'>
                        {/* <Trans code='A0382' /> */} Gruppi
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/clients?companies' className='text-decoration-none' title="Società">
                  <span className={urlSearch === '?companies' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoEnterprise />
                     </div>
                     <div className='nav-text'>
                        {/* <Trans code='A0543' /> */} Società
                     </div>
                  </span>
               </Link>
            </li>
            <li className='menu-item'>
               <Link to='/clients?users' className='text-decoration-none' title="Utenti">
                  <span className={urlSearch === '?users' ? 'menu-link active' : 'menu-link'}>
                     <div className='nav-ico'>
                        <IcoUtenteBox style={{width: '35px', height: '35px'}} />
                     </div>
                     <div className='nav-text'>
                        {/* <Trans code='A0543' /> */} Utenti
                     </div>
                  </span>
               </Link>
            </li>
         </ul>
      </>
   );
};
export default ClientsNav;
