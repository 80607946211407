import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
   ButtonToolbar,
   Spinner,
} from 'react-bootstrap';
import { getFunds } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererFunds from 'components/actionBtnRenderer/ActionBtnRendererFunds';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const FundsList = () => {
   const [funds, setFunds] = useState(null);
   const [fundsMeta, setFundsMeta] = useState({});
   const [columnDefs, setColumnDefs] = useState([]);
   const [refresh, setRefresh] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   //console.log('funds', funds);

   useEffect(() => {
      getFunds('funds').then((retrievedFunds) => {
         if (retrievedFunds.success) {
            setFunds(retrievedFunds.data);
            const { data, success, ...restResp } = retrievedFunds;
            setFundsMeta(restResp);
         }
      });
   }, []);

   useEffect(() => {
      if (refresh) {
         getFunds('funds').then((retrievedFunds) => {
            if (retrievedFunds.success) {
               setFunds(retrievedFunds.data);
               const { data, success, ...restResp } = retrievedFunds;
               setFundsMeta(restResp);
            }
         });

         setRefresh(false);
      }
   }, [refresh]);

   const onGridReady = useCallback(() => {
      getFunds('funds').then(retrievedFunds => {
         if (retrievedFunds.success) {
            setFunds(retrievedFunds.data);
            const { data, success, ...restResp } = retrievedFunds;
            setFundsMeta(restResp);
         }
      });
   }, []);

   const defaultColDef = {
      resizable: false,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('funds.name', 'Fondo'),
            field: 'name',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('funds.description', 'Descrizione'),
            field: 'description',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: '',
            minWidth: 250,
            maxWidth: 250,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererFunds,
            cellRendererParams: {
               setRefresh: setRefresh,
            },
         },
      ]);
   }, []);

   return (
      <>
         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  {/* <h5 className='title-simple-box'>Lista fondi</h5> */}
               </div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={fundsMeta}
                        setData={setFunds}
                        dataLength={funds?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='funds'
                     />
                  </ButtonToolbar>
               </div>
            </div>
            <div className='row'>
               {Array.isArray(funds) ? (
                  <div className='col-12' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={funds}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           //onFirstDataRendered={onFirstDataRendered}
                           //onModelUpdated={onModelUpdated}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription="ag-grid-table"
                           suppressCellFocus={true}
                        />
                        {Object.keys(fundsMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={fundsMeta} 
                              setData={setFunds} 
                              setDataMeta={setFundsMeta}
                              refTable='funds'
                              setIsFirstResearch={setIsFirstResearch}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                       <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default FundsList;