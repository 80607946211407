import React from "react";
import { Card } from "react-bootstrap";
import { IcoEdit, IcoCanc } from "@icons";
import {
  ButtonWithOverlay,
  ButtonWithPopover,
} from "components/atomic/molecules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { translate } from "components/translation/translation";

const IndustrialAccountCard = ({
  year,
  month,
  amount,
  notes,
  onEdit,
  onDelete,
  ...rest
}) => {
  const props = { ...rest };

  const style = props.isFirst
    ? {
        border: "1px solid #e5e5e5",
        background: "rgba(9, 168, 176, 0.3)",
      }
    : {
        border: "1px solid #DEE2E6",
        background: "#fff",
      };

  return (
    <Card style={style} className="mb-3 p-3 rounded-3 shadow-sm">
      <Card.Body>
        <Card.Title
          className="d-flex justify-content-between align-items-center"
          style={{ minHeight: "30px" }}
        >
          {year ?? "2040"}{" "}
          <div className="d-flex align-items-center">
            <>
              <ButtonWithOverlay
                tooltipText={translate('common.tooltip.edit', 'Modifica')}
                variant="link"
                onClick={onEdit}
              >
                <IcoEdit className="ms-2 second-color ico-small edit" />
              </ButtonWithOverlay>
              <ButtonWithOverlay
                tooltipText={translate('common.tooltip.delete', 'Elimina')}
                variant="link"
                onClick={onDelete}
              >
                <IcoCanc className="ms-2 second-color ico-small edit" />
              </ButtonWithOverlay>
            </>
          </div>
        </Card.Title>

        <h5 className="pb-4">{month ?? "Gennaio"}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <ButtonWithPopover
            disabled={!notes}
            popoverTitle="Note"
            placement="right"
            popoverText={notes}
            variant="link"
            onClick={() => {}}
          >
            <FontAwesomeIcon icon={faBook} color="#1C2743" />
          </ButtonWithPopover>
          <h5 className="text-end m-0" style={{ fontSize: "1.1rem" }}>
            {amount ?? "1000"} €
          </h5>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IndustrialAccountCard;
