import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import Flourish from "@flourish/live-api";
import { formatToDecimals } from "@lib/helpers/formatting";

const SunburstChart = ({ data = [] }) => {
  const [sizeBySum, setSizeBySum] = useState(false);

  useEffect(() => {
    let tempConfig = {
      base_visualisation_id: "16561390",
      api_key:
        "-Djdpl96Ne3iVl38YMO6NL1Kq_loiaBZ0bycoGhajplHwe0SiFK0gDfQmu8nYuW6",
      container: "#sunburst-chart",
      template: "@flourish/hierarchy",
      version: "19.7.3",
      state: {
        aggregation_type: sizeBySum ? "Sum" : "Count",
        ascend_button_visibility: "always",
        auto_set_height: false,
        color: {
          categorical_custom_palette:
            "Valore Progetti:#00346E\nNon Incassato: #CCCCCC\nIncassato: #C2C923\n\nRendicontato: #23C98D\nNon Rendicontato: #CB1B1B\n\nRendicontabile: #80C923\nIn Lavorazione: #FFB317\nNon Elegibile: #CB1B4A\n\nAvanzamento: #08A2AA\nNon Consuntivato: #FFFFFF\n\n_: white",
          categorical_extend: false,
          categorical_palette: [
            "#4328e7",
            "#9654e5",
            "#ff6283",
            "#ff8800",
            "#ffc502",
            "#88e99a",
            "#29dae4",
            "#1aa7ee",
            "#019c00",
            "#007d8e",
            "#c11f1f",
            "#730000",
          ],
        },
        dropdown_style: {
          background: null,
          border_styles_advanced: false,
          font_color: null,
        },
        hierarchy_layout: "sunburst",
        label_case: "topUpperCase",
        label_color: "#ffffff",
        label_weight: "allNormal",
        legend_categorical: {
          order_override: "\n",
        },
        legend_container: {
          text_size: 1,
          text_weight: "normal",
        },
        legend_mode: "off",
        num_of_visible_levels: 5,
        popup: {
          mode: "none",
          popup_is_custom: true,
          show_popup_styles: false,
        },
        show_value_labels: false,
        size_by_control: {
          control_type: "dropdown",
        },
        size_by_control_visibility: "always",
        slider_style: {
          play_button: true,
        },
        sunburst_depth_fade: 0.75,
        sunburst_inner_radius: 16,
        sunburst_labelling: "circular",
        sunburst_show_zoomed_node_in_center: false,
      },
      bindings: {
        data: {
          nest_columns: [0, 1, 2, 3, 4],
          popup_metadata: [5],
          size_columns: [6],
        },
      },
      data: {
        data: [
          ["Level0", "Level1", "Level2", "Level3", "Level4", "Label", "Value"],
          [
            "Valore Progetti",
            "Avanzamento",
            "Rendicontabile",
            "Rendicontato",
            "Incassato",
            "Incassato",
            data.cashedIn,
          ],
          [
            "Valore Progetti",
            "Avanzamento",
            "Rendicontabile",
            "Rendicontato",
            "Non Incassato",
            "Non Incassato",
            Math.abs(data.reportedAmount - data.cashedIn),
          ],
          [
            "Valore Progetti",
            "Avanzamento",
            "Rendicontabile",
            "Non Rendicontato",
            "_",
            "Non Rendicontato",
            data.reportableNotReportedAmount,
          ],
          [
            "Valore Progetti",
            "Avanzamento",
            "Non Elegibile",
            "_",
            "_",
            "Non Elegibile",
            data.notCompliantAmount,
          ],
          [
            "Valore Progetti",
            "Avanzamento",
            "In Lavorazione",
            "_",
            "_",
            "In Lavorazione",
            data.inProgressAmount,
          ],
          [
            "Valore Progetti",
            "Non Consuntivato",
            "_",
            "_",
            "_",
            "Altro",
            data.totalAmount - data.finalAmount,
          ],
        ],
      },
      metadata: {
        data: {
          0: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          1: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          2: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          3: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          4: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          5: {
            type_id: "string$arbitrary_string",
            type: "string",
            output_format_id: "string$arbitrary_string",
          },
          6: {
            type_id: "number$none_point",
            type: "number",
            output_format_id: "number$none_point",
          },
        },
      },
    };

    const parent = document.getElementById("sunburst-chart");
    // remove all children of parent
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }

    new Flourish.Live(tempConfig);
  }, [data, sizeBySum]);

  return (
    <>
      <script src="https://cdn.flourish.rocks/flourish-live-v4.0.0.min.js"></script>
        <Container className="simple-box scenaritab position-relative w-100 h-100" fluid="true">
          <Row>
            <Col className="col-6">
              <h5 className="title-simple-box margin-title-box text-uppercase">
                Dettaglio
              </h5>
            </Col>
            <Col className="col-6 text-end">
              <span className="me-2">Valori</span>
              <Form.Check
                type="switch"
                id="toggle-switch"
                inline
                name="group1"
                label="Somma"
                value="true"
                defaultChecked={sizeBySum}
                onClick={(e) => setSizeBySum(e.target.checked)}
              />
            </Col>
          </Row>

          <Row>
            <Col
              style={{ position: "relative" }}
              className="col-md-4 col-sm-12"
            >
              <div id="sunburst-chart"></div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                }}
              ></div>
            </Col>
            <Col className="col-md-8 col-sm-12">
              <Table responsive>
                <thead>
                  <tr>
                    {["", "Item", "Valore €"].map((item, index) => (
                      <td
                        style={{
                          color: "#1c2743",
                          fontWeight: "bold",
                          borderBottom: "1px solid #c2c923",
                          fontSize: "1.1rem",
                          fontFamily: "inherit",
                          lineHeight: "normal",
                          ...(index === 2 && {
                            textAlign: "right",
                          }),
                        }}
                        key={index}
                      >
                        {item}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      color: "#00346E",
                      label: "Valore Progetti",
                      indent: 0,
                      value: data.totalAmount,
                    },
                    {
                      color: "#08A2AA",
                      label: "Avanzamento",
                      indent: 1,
                      value: data.finalAmount,
                    },
                    {
                      color: "#80C923",
                      label: "Rendicontabile",
                      indent: 2,
                      value:
                        data.reportableNotReportedAmount - data.reportedAmount,
                    },
                    {
                      color: "#23C98D",
                      label: "Rendicontato",
                      indent: 3,
                      value: data.reportedAmount,
                    },
                    {
                      color: "#C2C923",
                      label: "Incassato",
                      indent: 4,
                      value: data.cashedIn,
                    },
                    {
                      color: "#D9D9D9",
                      label: "Non Incassato",
                      indent: 4,
                      value: data.reportedAmount - data.cashedIn,
                    },
                    {
                      color: "#CB1B1B",
                      label: "Non Rendicontato",
                      indent: 3,
                      value:
                        data.reportableNotReportedAmount - data.reportedAmount,
                    },
                    {
                      color: "#CB1B4A",
                      label: "Non Elegibili",
                      indent: 2,
                      value: data.notCompliantAmount,
                    },
                    {
                      color: "#FFB317",
                      label: "In Lavorazione",
                      indent: 2,
                      value: data.inProgressAmount,
                    },
                  ].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          style={{
                            display: "inline-block",
                            width: "1rem",
                            height: "1rem",
                            borderRadius: "50%",
                            marginRight: "0.5rem",
                            backgroundColor: item.color,
                          }}
                        ></span>
                      </td>
                      <td
                        style={{
                          paddingLeft: `${item.indent}rem`,
                          fontSize: "1.1rem",
                        }}
                      >
                        {item.label}
                      </td>
                      <td style={{ textAlign: "right", fontSize: "1.1rem" }}>
                        {formatToDecimals(Math.abs(item.value))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
    </>
  );
};
export default SunburstChart;
