import React from 'react';
import { ProjectWizard } from '@components/project';

const Project = () => {
  return (
    <div id="project" className="megaContent">
      <div className="container-fluid">
        <ProjectWizard />
      </div>
    </div>
  );
};

export default Project;
