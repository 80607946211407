import React from 'react';
import SetupPage from '@components/setup/setupPage';

const Setup = () => {
  return (
    <div id="setup" className="megaContent">
      <div className="container-fluid">
        <SetupPage />
      </div>
    </div>
  );
};

export default Setup;