import React from "react";
import { OpenRepLogo } from "loghi";
import { OETrans } from "components/translation/OETrans";

const LoginHero = () => {
  const styleHighlight = {
    color: "rgba(8, 162, 170, 1)",
  };

  return (
    <>
      <div className="d-flex space-between align-items-center">
        <OpenRepLogo width="250" />
      </div>
      <h1
        className="mt-5"
        style={{
          fontSize: "48px",
          fontWeight: "600",
          lineHeight: "57.6px",
          fontFamily: "Barlow",
        }}
      >
        <OETrans code='common.hero.header1' fallback={'La soluzione integrata per la'} />
        <br />
        <OETrans code='common.hero.header2' fallback={'governance dei contributi'} />
        <br />
        <OETrans code='common.hero.header3' fallback={'pubblici'} />
      </h1>
      <p
        className="pt-4"
        style={{
          fontSize: "36px",
          fontWeight: "300",
          lineHeight: "43.2px",
          fontFamily: "Barlow",
        }}
      >
         <OETrans code='common.hero.text1' fallback={'Gestisci la rendicontazione in maniera'} />
        <br /><span style={styleHighlight}><OETrans code='common.hero.text2' fallback={'efficace'} /></span>&nbsp;<OETrans code='common.hero.text3' fallback={'e'} />&nbsp;<span style={styleHighlight}><OETrans code='common.hero.text4' fallback={'sicura'} /></span>,&nbsp;<OETrans code='common.hero.text5' fallback={'in ottemperanza agli'} />
        <br /><OETrans code='common.hero.text6' fallback={'obblighi normativi e ai prescritti'} />
        <br /><OETrans code='common.hero.text7' fallback={'specifici delle misure'} />.
      </p>
    </>
  );
};

export default LoginHero;
