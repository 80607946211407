import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { getProjectStats } from "@lib/api";
import ReportFilters from "./ReportFilters";
import ReportOverview from "./ReportOverview";
//import ReportQuantityTable from './ReportQuantityTable';
import ReportBudgetCashflow from "./ReportBudgetCashflow";
import { IcoChevronDown } from "@icons";
import SunburstChart from "@components/charts/flourishCharts/SunburstChart";

const ReportsPage = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [stats, setStats] = useState({});
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedNotices, setSelectedNotices] = useState([]);
  const [selectedBusinessArea, setSelectedBusinessArea] = useState([]);
  //console.log('stats:', stats);

  useEffect(() => {
    let queryParamsStringProjectIds = "";

    let arrayOfAllSelected = selectedProjects.concat(
      selectedNotices,
      selectedBusinessArea
    );

    arrayOfAllSelected.forEach((el, i) => {
      queryParamsStringProjectIds += `${i === 0 ? "" : "&"}${el.queryParam}[]=${
        el.value
      }`;

      return queryParamsStringProjectIds;
    });

    getProjectStats(undefined, queryParamsStringProjectIds).then(
      (retrievedStats) => {
        if (retrievedStats.success) {
          setStats(retrievedStats.data);
        }
      }
    );
  }, [selectedProjects, selectedNotices, selectedBusinessArea]);

  const handleShowDetails = () => {
    setIsShowDetails(!isShowDetails);
  };

  return (
    <>
      <Row className="justify-content-center">
        <Col className="col-11">
          <Row>
            <Col>
              <Button
                style={{
                  padding: "0.37rem 0.65rem",
                  paddingRight: "0.35rem",
                  fontSize: "0.86rem",
                  color: "#79889A",
                  textTransform: "capitalize",
                }}
                variant={
                  selectedBusinessArea.length > 0 ? "outline-info" : "light"
                }
                onClick={(e) => {
                  handleShowDetails();
                }}
              >
                {isShowDetails && (
                  <IcoChevronDown
                    className="chevron-filter"
                    style={{ transform: "rotate(90deg)", marginTop: "-2px" }}
                  />
                )}
                {isShowDetails ? "Torna Indietro" : "Dettagli"}
              </Button>
            </Col>
            <Col>
              <ReportFilters
                selectedProjects={selectedProjects}
                selectedNotices={selectedNotices}
                selectedBusinessArea={selectedBusinessArea}
                setSelectedProjects={setSelectedProjects}
                setSelectedNotices={setSelectedNotices}
                setSelectedBusinessArea={setSelectedBusinessArea}
              />
            </Col>
          </Row>

          <Row className="justify-content-between align-items-stretch">
            {!isShowDetails ? (
              <>
                <Col className="col-5">
                  <ReportOverview overviewStats={stats} />
                </Col>
                <Col className="col-7">
                  <ReportBudgetCashflow dataStats={stats} />
                </Col>
              </>
            ) : (
              <Col className="col-12">
                  { stats ? <SunburstChart data={stats} /> : null }
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ReportsPage;
