import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { OpeneconomicsLogo } from 'loghi';
import moment from 'moment';

function Footer() {
   return (
      <footer id="site-footer">
         <Container>
            <Row>
               <Col className="text-center">
                  {window.location.pathname !== '/login' && (
                     <>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                           href="https://www.openeconomics.eu/"
                           target="_blank"
                           alt="OpenEconomics"
                           title="OpenEconomics"

                        >
                           <div className='mt-4 mb-3'>
                              <small>Powered by</small>
                              <OpeneconomicsLogo
                                 className="openeconomicslogo"
                                 style={{ height: '25px', marginLeft: '0.7rem', marginBottom: '0.2rem' }}
                              />
                           </div>

                        </a>
                     </>
                  )}
                  <div className="copyright mb-3">
                     © {moment().format('Y')} Copyright OpenEconomics | Vat 12504821005
                  </div>
               </Col>
            </Row>
         </Container>
      </footer>
   );
}

export default Footer;
