import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col } from 'react-bootstrap';
import { editGroup, deleteGroup } from '@lib/api';
import { IcoCanc, IcoEdit } from '@icons';
import StatusBar from '@components/StatusBar';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const ActionBtnRendererGroups = ({ data, setRefresh }) => {
   const id = data.id;
   const [group, setGroup] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   //console.log('group', group);

   useEffect(() => {
      setGroup(data);
   }, [data]);

   const handleCloseModal = (setShowModal) => {
      setGroup(data);
      setSbShow(<></>);
      setShowModal(false);
   };
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const onChange = (e, fieldName) => {
      let tempGroup = { ...group };
      let value;

      switch (fieldName) {
         case 'name':
            value = e.target.value;

            tempGroup[fieldName] = value;
            break;
         case 'partnership':
            value = e.target.checked;

            tempGroup[fieldName] = value;
            break;
         default:
            break;
      }

      setGroup(tempGroup);
   }

   const handleEdit = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const { companies, createdAt, createdBy, updatedAt, updatedBy, ...restGroup } = group;

      if (!group.name) {
         handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      restGroup.companyids = companies.map((group) => group.value || group.id);;

      const editResult = await editGroup(restGroup);
      //console.log('editResult', editResult);

      if (editResult.success) {
         handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true);

         setTimeout(() => {
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar('error', editResult.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
      }
   };

   const handleDelete = async () => {
      const deleteResult = await deleteGroup(id);
      console.log('deleteResult', deleteResult);

      if (deleteResult.success) {
         handleStatusBar('success', translate('common.message.deleted', 'Elemento eliminato correttamente'), true);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar('error', deleteResult.message ?? translate('common.error.delete', 'Si è verificato un errore durante l’eliminazione delle informazioni'), false);
      }
   };

   const editTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.edit' fallback={'Modifica'}/>
      </Tooltip>
   );

   const deleteTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>
      </Tooltip>
   );

   return (
      <div>
         <OverlayTrigger placement='top' overlay={editTooltip}>
            <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className='second-color ico-small edit' />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement='top' overlay={deleteTooltip}>
            <Button
               variant='link'
               className='me-0'
               onClick={() => handleShowModal(setShowModalDelete)}>
               <IcoCanc className='second-color ico-small cancel' />
            </Button>
         </OverlayTrigger>

         {showModalEdit &&
            <Modal show={showModalEdit} onHide={() => handleCloseModal(setShowModalEdit)} centered>
               <Modal.Header closeButton>
                  <Modal.Title>
                     <OETrans code='common.groups.editgroup' fallback={'Modifica gruppo'}/>
                  </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Form onSubmit={handleEdit} id='form-new-group'>
                     <Row className='mb-3 px-2' style={{ marginTop: '2rem' }}>
                        <Col className="">
                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='groups.name' fallback={'Nome'}/>
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='name'
                                 value={group.name}
                                 onChange={e => onChange(e, 'name')}
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                     <Row className='mb-3 px-2' style={{ marginTop: '2rem' }}>
                     <Col>
                        <Form.Group as={Col}>
                           <Form.Check
                              inline
                              type='checkbox'
                              name='partnership'
                              defaultChecked={group.partnership}
                              onClick={(e) => onChange(e, 'partnership')}
                           />
                           <Form.Label>
                              <OETrans code='groups.partnership' fallback={'Partenariato'}/>
                           </Form.Label>
                        </Form.Group>
                     </Col>
                  </Row>
                  </Form>
                  {sbShow}

                  <Row className='mt-5 mb-2'>
                     <span className='mx-2'>
                        <span className='required-field'>*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                     </span>
                  </Row>
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     <OETrans code='common.cancel' fallback={'Annulla'}/>
                  </Button>
                  <Button variant='primary' onClick={e => handleEdit(e)}>
                     <OETrans code='common.edit' fallback={'Modifica'}/>
                  </Button>
               </Modal.Footer>
            </Modal>
         }

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.warning' fallback={'Attenzione'}/>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>
                     <OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} />
                  </span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant='danger' onClick={() => handleDelete()}>
                  <OETrans code='common.delete' fallback={'Elimina'}/>
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};
export default ActionBtnRendererGroups;