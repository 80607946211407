import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const DataHeader = ({ data, childrenButton = false, customStyle, showCol1 = true }) => {
   return (
      <div className='py-4 px-1' style={{ boxShadow: 'none', ...customStyle, }}>
         <Row className='align-items-start'>
            <Col className={`col-auto`}>
               <Row className='align-items-start'>
                  {data.map((el, i) => {
                     return <Col key={el ? `${el.name}_${el.value}_${i}` : `key-report-${i}`}>
                        <Form>
                           <Row className="justify-content-start align-items-start">
                              {el && showCol1 && <Col className="col-auto align-self-center p-0">{el.icon ?? ''}</Col>}
                              <Col>
                                 <Row className='align-items-start'>
                                    {el && <>
                                       <p className='m-0 dataHeaderName'>{el.name}</p>
                                       <h5 className='m-0'>{el.value ?? 'N.D.'}</h5>
                                    </>}
                                 </Row>
                              </Col>
                           </Row>
                        </Form>
                     </Col>
                  })}
               </Row>
            </Col>
            {childrenButton && (
               <Col className='ms-auto col-auto'>
                  {childrenButton}
               </Col>
            )}
         </Row>
      </div>
   );
};
export default DataHeader;