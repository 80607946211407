import { useState, useEffect, useContext, useRef } from 'react';
import {
   Row,
   Spinner,
   Form,
   Col,
   Modal,
   Button,
   OverlayTrigger, 
   Tooltip,
} from 'react-bootstrap';
import { InputNumber, Calendar } from 'primereact';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import { createTargetProgress } from '@lib/api/index';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import Select from 'react-select';
import StatusBar from '@components/StatusBar';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { months } from '@lib/helpers/calendar';
import ActionBtnRendererProgressTarget from 'components/actionBtnRenderer/ActionBtnRendererTargetProgress';
import { IcoPlus } from '@icons';
import { LevelContext } from 'components/contexts/LevelContext';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   projectTargetId: null,
   value: 0,
   month: null,
   year: null,
   date: null,
   targetValueReasonCode: null,
   notRelevant: false,
};

const TriggerTooltip = ({ parentRef, textValue }) => {
   const [overflowActive, setOverflowActive] = useState(false);
   const childRef = useRef(null);
   const childRefInnerHTML = childRef.current?.innerHTML;

   useEffect(() => {
      if (parentRef.current && childRef.current && childRefInnerHTML) {
         //console.log('childRef offsetWidth', childRef.current.offsetWidth);
         //console.log('parentRef offsetWidth', parentRef.current.offsetWidth);

         setOverflowActive(parentRef.current.offsetWidth < childRef.current.offsetWidth);
      }
   }, [childRef, childRefInnerHTML, parentRef]);

   const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

   return (
      <ConditionalWrapper
         condition={overflowActive}
         wrapper={(children) => (
            <OverlayTrigger placement="top-start" overlay={<Tooltip className="">{textValue}</Tooltip>}>
               {children}
            </OverlayTrigger>
         )}
      >
         <span ref={childRef}>{textValue}</span>
      </ConditionalWrapper>
   );
};

const PhysicalTargetProgressTab = ({
   financialEl,
   optionsTargetReasons,
   projectId,
   setRefresh,
}) => {
   const [newPhysicalProgress, setNewPhysicalProgress] = useState(initialState);
   const [columnDefsFinancial, setColumnDefsFinancial] = useState([]);
   const [target, setTarget] = useState({});
   const [progress, setProgress] = useState(null);
   // const [filteredProgress, setFilteredProgress] = useState(progress ?? []);
   const [showSpinner, setShowSpinner] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [showModalNew, setShowModalNew] = useState(false);
   const [selectedReasonCode, setSelectedReasonCode] = useState([]);
   const [date, setDate] = useState(null);
   const [disabled, setDisabled] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const { isOe, userRole } = useContext(LevelContext);
   const refName = useRef(null);
   //console.log('target', target);

   useEffect(() => {
      if (financialEl?.name) {
         setTarget(financialEl);
         setProgress(financialEl.progresses);
      }
   }, [financialEl]);

   useEffect(() => {
      if (financialEl?.name) {
         setTarget(financialEl);
         setProgress(financialEl.progresses);
      }
   }, [financialEl]);

   useEffect(() => {
      setColumnDefsFinancial([
         {
            headerName: 'Obiettivo',
            field: 'target.name',
            filter: 'agTextColumnFilter',
            flex: 2,
         },
         {
            headerName: 'Realizzato nel mese',
            children: [
               {
                  headerName: 'Valore',
                  field: 'value',
                  filter: 'agTextColumnFilter',
                  type: 'rightAligned',
                  minWidth: 170,
               },
               {
                  headerName: 'Mese',
                  field: 'month',
                  cellRenderer: (params) => {
                     return months()[params.value - 1];
                  },
                  // filter: 'agTextColumnFilter',
                  minWidth: 170,
                  ...columnCentered(),
               },
               {
                  headerName: 'Anno',
                  field: 'year',
                  // filter: 'agTextColumnFilter',
                  minWidth: 130,
                  ...columnCentered(),
               },
            ],
         },
         {
            headerName: 'Cumulato',
            children: [
               {
                  headerName: 'Valore',
                  field: 'accruedValue',
                  filter: 'agTextColumnFilter',
                  type: 'rightAligned',
                  minWidth: 170,
               },
               {
                  headerName: '%',
                  // field: 'perc',
                  valueGetter: (params) => {
                     return `${formatToDecimals(
                        (params.data.accruedValue / params.data.target.targetValue) * 100
                     )}`;
                  },
                  filter: 'agTextColumnFilter',
                  type: 'rightAligned',
                  minWidth: 100,
               },
            ],
         },
         {
            /*  minWidth: 130,
             maxWidth: 130, */
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererProgressTarget,
            cellRendererParams: {
               setRefresh: setRefresh,
               optionsTargetReasons: optionsTargetReasons,
            },
         },
      ]);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const resetForm = () => {
      setNewPhysicalProgress(initialState);
      setSelectedReasonCode([]);
      setDisabled(false);
      setDate(null);
      setSbShow(<></>);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);
   const handleCloseModal = (setShowModal) => {
      resetForm();
      setShowModal(false);
   };
   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const onChange = (e, fieldName, setSelect) => {
      //console.log('e', e);

      let tempNewPhysicalProgress = { ...newPhysicalProgress, projectTargetId: financialEl.id };
      let value;

      switch (fieldName) {
         case 'targetValueReasonCode':
            value = e.value;
            tempNewPhysicalProgress[fieldName] = value;
            setSelect(e);
            break;
         case 'value':
            value = e.value === null ? 0 : e.value;
            tempNewPhysicalProgress[fieldName] = value;
            if (value !== 0) {
               setDisabled(true);
               tempNewPhysicalProgress['targetValueReasonCode'] = null;
               setSelectedReasonCode([]);
            }
            if (value === 0 || value === null) setDisabled(false);
            break;
         case 'notRelevant':
            value = e.target.checked;
            tempNewPhysicalProgress[fieldName] = value;
            break;
         case 'date':
            value = e.value;
            tempNewPhysicalProgress[fieldName] = dateFormatter(value);
            tempNewPhysicalProgress['year'] = value.getFullYear();
            tempNewPhysicalProgress['month'] = value.getMonth() + 1;
            setSelect(value);
            break;
         default:
            value = e.target.value;
            tempNewPhysicalProgress[fieldName] = value;
      }
      setNewPhysicalProgress(tempNewPhysicalProgress);
   };

   const handleSave = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const {
         createdAt,
         createdBy,
         updatedAt,
         updatedBy,
         targetValueReasonCode,
         notRelevant,
         value,
         ...restPhysicalProgress
      } = newPhysicalProgress;

      const isProcedureFilled = Object.values(restPhysicalProgress).every(
         (prop) => prop !== '' && prop != null
      )
         ? true
         : false;
      if (value === 0 && targetValueReasonCode === null) {
         handleStatusBar('error', 'Selezionare un motivo del progresso target', false);
         return;
      }

      if (!isProcedureFilled) {
         handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }
      console.log('SENT', projectId, newPhysicalProgress.projectTargetId, newPhysicalProgress);
      let result;

      setIsLoading(true);
      setShowSpinner(true);
      result = await createTargetProgress(
         projectId,
         newPhysicalProgress.projectTargetId,
         newPhysicalProgress
      );
      //console.log('result', result);

      if (result.success) {
         handleStatusBar('success', translate('common.message.created', 'Elemento creato con successo'), true);
         setTimeout(() => {
            setShowModalNew(false);
            resetForm();
            setRefresh(true);
         }, 2500);
      } else {
         handleStatusBar(
            'error',
            result.success === false
               ? Object.values(result.data).join()
               : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }
      setIsLoading(false);
      setShowSpinner(false);
   };

   return (
      <>
         {Object.keys(target).length > 0 &&
            <table className="show-targets-tab mb-5">
               <thead>
                  <tr>
                     <th></th>
                     <th className="f-600 text-center">Data</th>
                     <th className="f-600">UOM</th>
                     <th className="f-600 text-end">Valore</th>
                     <th className="invisible" />
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td className="f-600" ref={refName}><TriggerTooltip parentRef={refName} textValue={target.name ?? "N.D."} /></td>
                     <td><span className="mt-3">{target?.targetDate ? dateFormatter(target?.targetDate) : "N.D"}</span></td>
                     <td><div>{target?.uom ?? "N.D."}</div></td>
                     <td className="text-end">
                        <span>
                           {formatToDecimals(
                              target?.targetValue,
                              target?.uom === "Abitanti" ? 0 : 2,
                              target?.uom === "Abitanti" ? 0 : 2)
                           }
                        </span>
                     </td>
                     <td className="invisible" />
                  </tr>
               </tbody>
            </table>
         }

         {target?.progresses && (
            <OESimpleTable
               columnDefs={columnDefsFinancial}
               rowData={progress?.sort(function (a, b) {
                  if (a.year === b.year) {
                     return b.month - a.month;
                  } else {
                     return b.year - a.year;
                  }
               })}
               pageSizeId={`progressTarget_${financialEl.name}`}
               showSearchbox={false}
               buttonToolbarVisible={true}
               buttonChildren={
                  (isOe || userRole === 5) && (
                     <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'>Nuovo Progresso Target</span>
                     </Button>
                  )
               }
            />
         )}
         <Modal
            show={showModalNew}
            className='modal-with-nav'
            size='lg'
            onHide={() => handleCloseModal(setShowModalNew)}
            centered>
            <Modal.Header>
               <Modal.Title>Nuovo Progresso Target</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form id={`form-new-target-progress_${financialEl.name}`}>
                  <Row style={{ marginBottom: '40px' }}>
                     <Col className='col-6'>
                        <Form.Group as={Col}>
                           <Form.Label>Valore</Form.Label>
                           <InputNumber
                              allowEmpty={false}
                              name='value'
                              min={0}
                              step={1}
                              onChange={(e) => onChange(e, 'value')}
                              mode='decimal'
                              locale={`${localStorage
                                 .getItem('userLanguage')
                                 .toLowerCase()}-${localStorage.getItem('userLanguage')}`}
                              className='md'
                              suffix={` ${financialEl.uom}`}
                              value={newPhysicalProgress.value ?? ''}
                              disabled={isLoading}
                           />
                        </Form.Group>
                     </Col>
                     <Col className='col-6'>
                        <Form.Group as={Col}>
                           <Form.Label>Motivo del Progresso Target</Form.Label>
                           <Select
                              components={{
                                 IndicatorSeparator: () => null,
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              isDisabled={disabled || isLoading}
                              name='targetValueReasonCode'
                              placeholder={translate('common.placeholder.select', 'Seleziona...')}
                              value={selectedReasonCode}
                              options={optionsTargetReasons}
                              onChange={(e) => onChange(e, 'targetValueReasonCode', setSelectedReasonCode)}
                           />
                        </Form.Group>
                        {!disabled && (
                           <p className='m-0' style={{ fontSize: '.8rem' }}>
                              In caso di valore impostato a 0 indicarne il motivo.
                           </p>
                        )}
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '40px' }}>
                     <Col className='col-6'>
                        <Form.Group as={Col}>
                           <Form.Label>
                              Data Progresso<span className='required-field'>*</span>
                           </Form.Label>
                           <Calendar
                              value={date}
                              onChange={(e) => onChange(e, 'date', setDate)}
                              view='month'
                              dateFormat='mm/yy'
                              style={{ height: '38px' }}
                              disabled={isLoading}
                           />
                           {/* <Form.Control
                    type='date'
                    name='date'
                    value={newPhysicalProgress?.date ? newPhysicalProgress.date : ''}
                    onChange={(e) => onChange(e, 'date')}
                  /> */}
                        </Form.Group>
                     </Col>
                     <Col className='col-6 d-flex align-items-end justify-content-start'>
                        <Form.Group as={Col}>
                           <Form.Check
                              inline
                              type='checkbox'
                              name='notRelevant'
                              // defaultChecked={tender.dnsh}
                              onClick={(e) => onChange(e, 'notRelevant')}
                              disabled={isLoading}
                           />
                           <Form.Label className=''>Non Rileva</Form.Label>
                        </Form.Group>
                     </Col>
                  </Row>
               </Form>
               {sbShow}
               <Row>
                  <Col className="col-12">
                     <span className="label">
                        <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                     </span>
                  </Col>
               </Row>
            </Modal.Body>

            <Modal.Footer>
               <Button
                  disabled={isLoading}
                  variant='secondary'
                  onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button disabled={isLoading} variant='primary' onClick={(e) => handleSave(e)}>
                  <OETrans code='common.create' fallback={'Crea'}/>
                  {showSpinner && (
                     <Spinner
                        as='span'
                        className='ms-2'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                     />
                  )}
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default PhysicalTargetProgressTab;
