import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Tab,
   Tabs,
   Spinner,
} from 'react-bootstrap';
import { IcoEdit } from '@icons';
import { getPaymentByInfo } from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { OETrans } from 'components/translation/OETrans';

const ProjectPaymentModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   isSummary,
   spinnerVisible,
   setModalData,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [payment, setPayment] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   const [rowData, setRowData] = useState([]);
   const [columnDef, setColumnDef] = useState([]);

   useEffect(() => {
      if ((modalIsVisible || (modalIsVisible === undefined && showModalEdit)) && projectId && data.id) {
         getPaymentByInfo(projectId, data.id).then((retrievedPayment) => {
            if (retrievedPayment.success) {
               setPayment(retrievedPayment.data);
               setRowData(retrievedPayment.data.invoicePayments);
            }
         });
      }
   }, [data, projectId, showModalEdit, isSummary, modalIsVisible]);

   const handleCloseModal = (setter) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      if (isSummary) {
         setModalData([]);
         setModalIsVisible(false);
      } else {
         setter(false);
      }
   };

   const handleShowModal = (setter) => {
      if (isSummary) {
         setModalIsVisible(true);
      } else {
         setter(true);
      }
   };

   useEffect(() => {
      setColumnDef([
         {
            headerName: 'Codice ERP',
            field: 'invoice.rpCode',
            filter: 'agTextColumnFilter',
            minWidth: 330,
         },
         {
            headerName: 'Nr. Fattura',
            field: 'invoice.number',
            filter: 'agTextColumnFilter',
            minWidth: 120,
         },

         {
            headerName: 'Imponibile Fattura €',
            field: 'invoiceTaxable',
            valueGetter: (params) => {
               return formatToDecimals(params?.data?.invoiceTaxable);
            },
            filter: 'agTextColumnFilter',
            minWidth: 220,
            type: 'rightAligned',
         },
         {
            headerName: 'Importo Fattura da Avviso €',
            field: 'invoiceAmount',
            valueGetter: (params) => {
               return formatToDecimals(params?.data?.invoiceAmount);
            },
            filter: 'agTextColumnFilter',
            minWidth: 300,
            type: 'rightAligned',
         },
      ]);
   }, []);

   const whichStateToUse = isSummary ? modalIsVisible : showModalEdit;

   const editTooltip = (props) => <Tooltip {...props}>Dettaglio</Tooltip>;

   return (
      <div>
         {!isSummary && (
            <OverlayTrigger placement='top' overlay={editTooltip}>
               <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
                  <IcoEdit className='second-color path ico-small edit' />
               </Button>
            </OverlayTrigger>
         )}

         {whichStateToUse && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={whichStateToUse}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Pagamento {Number(userRole) === 1 || Number(userRole) <= 30 ? payment?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill
                        variant='pills'
                     >
                        {/* Anagrafica */}
                        <Tab eventKey='registry' title={'Dati Pagamento'}>
                           <Form id='form-new-project'>
                              <Row style={{ marginBottom: '40px' }}>
                                 <Form.Group as={Col}>
                                    <Form.Label>Nome Beneficiario</Form.Label>
                                    <Form.Text as='p'>{payment?.payeeName ?? 'N.D.'}</Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>Codice Beneficiario</Form.Label>
                                    <Form.Text as='p'>{payment?.payeeCode ?? 'N.D.'}</Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       Nome Beneficiario Definitivo (Factor)
                                    </Form.Label>
                                    <Form.Text as='p'>{payment?.lastPayeeName ?? 'N.D.'}</Form.Text>
                                 </Form.Group>
                              </Row>

                              <Row style={{ marginBottom: '40px' }}>
                                 <Form.Group as={Col}>
                                    <Form.Label>Tipologia Pagamento</Form.Label>
                                    <Form.Text as='p'>{payment?.paymentType ?? 'N.D.'}</Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label >Nr. Pagamento</Form.Label>
                                    <Form.Text as='p'>{payment?.paymentNumber ?? 'N.D.'}</Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>Cod. Proposta Amm.Ne Richiedente (Mandato)</Form.Label>
                                    <Form.Text as='p'>{payment?.proposalCode ?? 'N.D.'}</Form.Text>
                                 </Form.Group>
                              </Row>

                              <Row style={{ marginBottom: '40px' }}>
                                 <Form.Group as={Col}>
                                    <Form.Label>Data Pagamento</Form.Label>
                                    <Form.Text as='p'>
                                       {payment?.date ? dateFormatter(payment?.date) : 'N.D.'}
                                    </Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label className='mb-3'>Divisa di Pagamento</Form.Label>
                                    <Form.Text as='p'>{payment?.currencyCode ?? 'N.D.'}</Form.Text>
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>CRO/Swift Code Pagamento</Form.Label>
                                    <Form.Text as='p'>{payment?.cro ?? 'N.D'}</Form.Text>
                                 </Form.Group>
                              </Row>
                           
                              {/* <Row className='mb-2rem'>
                                 <Form.Group as={Col} className='px-4'>
                                    <Form.Label className='mb-3'>Documento Sap Registrazione</Form.Label>
                                    <Form.Text as='p'>{payment?.sapDocument ?? 'N.D'}</Form.Text>
                                 </Form.Group>
                                 <Form.Group as={Col} className='px-4'>
                                    <Form.Label className='mb-3'>Sap Competente</Form.Label>
                                    <Form.Text as='p'>{payment?.sat ?? 'N.D'}</Form.Text>
                                 </Form.Group>
                                 <Form.Group as={Col} className='px-4'>
                                    <Form.Label className='mb-3'>Importo Massimo Rendicontabile (€)</Form.Label>
                                    <Form.Text as='p'>{payment?.maxReportableAmount ?? 'N.D'}</Form.Text>
                                 </Form.Group>
                              </Row> */}
                              
                              <Row className="flex-column">
                                 <Col><h2>Fatture</h2></Col>
                                 <Col>
                                    {rowData && rowData.length > 0 ? (
                                       <OESimpleTable
                                          columnDefs={columnDef}
                                          rowData={rowData}
                                          buttonToolbarVisible={false}
                                          pageSizeId={'payment_invoice_relation'}
                                       />
                                    ) : (
                                       <Row className='text-center my-5'>
                                          <span>Non sono presenti fatture.</span>
                                       </Row>
                                    )}
                                 </Col>
                              </Row>
                           </Form>
                        </Tab>

                        {/* Documenti */}
                        <Tab eventKey='upload' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                           {payment.id && <UploadDocument entity='payments' disableEdit={Number(userRole) > 40 && isSummary} entityId={payment.id} />}
                        </Tab>

                        {/* CheckLists */}
                        {/* <Tab eventKey='checklist' title={'Checklist'} className='px-5'>
                  {payment?.invoicePayments?.[0]?.id && (
                    <CheckList
                      entityType='invoicePayments'
                      entityId={payment?.invoicePayments?.[0]?.id}
                    />
                  )}
                </Tab> */}
                     </Tabs>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     Chiudi
                  </Button>
                  {activeKey === 'checklist' && (!isSummary || isOe || userRole === 5) ? (
                     <Button
                        type='submit'
                        form='checklist-invoicePayments'
                        onClick={() => setIsEdited(true)}
                        variant='primary'>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                     </Button>
                  ) : (
                     ''
                  )}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};

export default ProjectPaymentModal;