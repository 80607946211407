import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { OETrans } from 'components/translation/OETrans';

const SetupNavHorizontal = () => {
   const urlSearch = window.location.search;

   return (
      <>
         <Navbar className='sub-navbar'>
            <Nav activeKey={urlSearch} className="justify-content-evenly">
               <Nav.Item>
                  <Nav.Link as={Link} to='/setup?funds' eventKey='?funds'>
                     <OETrans code='common.setupnav.funds' fallback={'Fondi'}/>
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/setup?instruments' eventKey='?instruments'>
                     <OETrans code='common.setupnav.instruments' fallback={'Strumenti'}/> 
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/setup?institutions' eventKey='?institutions'>
                     <OETrans code='common.setupnav.institutions' fallback={'Enti'}/> 
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/setup?notices' eventKey='?notices'>
                     <OETrans code='common.setupnav.notices' fallback={'Bandi'}/> 
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/setup?checklist' eventKey='?checklist'>
                     <OETrans code='common.setupnav.checklists' fallback={'Checklist'} />
                  </Nav.Link>
               </Nav.Item>
            </Nav>
         </Navbar>
      </>
   );
};
export default SetupNavHorizontal;
