import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ButtonToolbar,
  Spinner,
  Button,
  Modal,
  Tab,
  Tabs,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import { IcoPlus } from '@icons';
import { getInstruments, createInstrument, editInstrument } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererInstruments from 'components/actionBtnRenderer/ActionBtnRendererInstruments';
import UploadDocument from 'components/form/upload/uploadDocuments';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
  name: '',
  managingAuthority: '',
};

const InstrumentsList = ({ setSetupInstruments }) => {
  const [instruments, setInstruments] = useState(null);
  const [instrumentsMeta, setInstrumentsMeta] = useState({});
  const [newInstrument, setNewInstrument] = useState(initialState);
  const [columnDefs, setColumnDefs] = useState([]);
  const [activeKey, setActiveKey] = useState('registry');
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [isFirstResearch, setIsFirstResearch] = useState(true);
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;
  //console.log('instruments', instruments);
  //console.log('newInstrument', newInstrument);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setActiveKey('registry');
    setShowModal(false);
    setRefresh(true);
    setNewInstrument(initialState);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  // const handleStatusBar = (status, message, timeout) => {
  //    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  // };
  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  useEffect(() => {
    getInstruments('instruments').then((retrievedInstruments) => {
      setInstruments(retrievedInstruments.data);
      if (retrievedInstruments.success) {
        setInstruments(retrievedInstruments.data);
        const { data, success, ...restResp } = retrievedInstruments;
        setInstrumentsMeta(restResp);
      }
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      getInstruments('instruments').then((retrievedInstruments) => {
        if (retrievedInstruments.success) {
          setInstruments(retrievedInstruments.data);
          setSetupInstruments(retrievedInstruments.data);
          const { data, success, ...restResp } = retrievedInstruments;
          setInstrumentsMeta(restResp);
        }
      });

      setRefresh(false);
    }
  }, [refresh, setSetupInstruments]);

  const onGridReady = useCallback(() => {
    getInstruments('instruments').then((retrievedInstruments) => {
      if (retrievedInstruments.success) {
        setInstruments(retrievedInstruments.data);
        const { data, success, ...restResp } = retrievedInstruments;
        setInstrumentsMeta(restResp);
      }
    });
  }, []);

  const defaultColDef = {
    resizable: false,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: translate('instruments.name', 'Strumento'),
        field: 'name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: translate('instruments.managingAuthority', 'Soggetto gestore'),
        field: 'managingAuthority',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: '',
        minWidth: 250,
        maxWidth: 250,
        cellStyle: { textAlign: 'center', padding: 0 },
        cellRenderer: ActionBtnRendererInstruments,
        cellRendererParams: {
          setRefresh: setRefresh,
        },
      },
    ]);
  }, []);

  const onChange = (e, fieldName) => {
    //console.log('e', e);

    let tempNewInstrument = { ...newInstrument };
    let value;

    switch (fieldName) {
      case 'name':
      case 'managingAuthority':
        value = e.target.value;
        break;
      case 'docs':
        break;
      default:
        value = e.target.value;
    }

    tempNewInstrument[fieldName] = value;

    setNewInstrument(tempNewInstrument);
  };

  const handleNewInstrument = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    if (!newInstrument.name || !newInstrument.managingAuthority) {
      handleStatusBar({ success: false, message: translate('common.error.requiredFields', 'Compila i campi obbligatori'), });
      return;
    }

    const { createdAt, createdBy, updatedAt, updatedBy, ...restInstrument } = newInstrument;
    const result = newInstrument.id
      ? await editInstrument(restInstrument)
      : await createInstrument(restInstrument);
    //console.log('result', result);

    if (result.success) {
      handleStatusBar({
        success: true,
        message: newInstrument.id
          ? translate('common.message.edited', 'Elemento modificato con successo')
          : translate('common.message.created', 'Elemento creato con successo'),
      });

      setNewInstrument(result.data);
    } else {
      handleStatusBar(result);
    }
  };

  return (
    <>
      <div className='simple-box scenaritab'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-4'>{/* <h5 className='title-simple-box'>Lista strumenti</h5> */}</div>
          <div className='col-8 text-right px-0 py-1'>
            <ButtonToolbar className='justify-content-end'>
              <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                <IcoPlus className='light-color ico-small plus new-button' />
                <span className='d-md-inline'><OETrans code='common.groups.newinstrument' fallback={'Crea Strumento'}/></span>
              </Button>
              <CustomQuickFilterBox
                  tableRef={gridRef}
                  dataMeta={instrumentsMeta}
                  setData={setInstruments}
                  dataLength={instruments?.length}
                  isFirstResearch={isFirstResearch} 
                  setIsFirstResearch={setIsFirstResearch}
                  refTable='notices'
               />
            </ButtonToolbar>
          </div>
        </div>
          <div className='row'>
            {Array.isArray(instruments) ? (
               <div className='col-12' id='grid-wrapper'>
                  <div className='ag-theme-alpine list-table'>
                     <AgGridReact
                     ref={gridRef}
                     columnDefs={columnDefs}
                     rowData={instruments}
                     defaultColDef={defaultColDef}
                     domLayout='autoHeight'
                     tooltipShowDelay={0}
                     tooltipHideDelay={2000}
                     tooltipMouseTrack={true}
                     onGridReady={onGridReady}
                     //onFirstDataRendered={onFirstDataRendered}
                     //onModelUpdated={onModelUpdated}
                     headerHeight={50}
                     rowHeight={50}
                     localeText={localeText}
                     ariaDescription='ag-grid-table'
                     suppressCellFocus={true}
                     />
                     {Object.keys(instrumentsMeta).length > 0 && (
                        <CustomPaginationMui
                           dataMeta={instrumentsMeta} 
                           setData={setInstruments} 
                           setDataMeta={setInstrumentsMeta}                     
                           refTable='instruments'
                           setIsFirstResearch={setIsFirstResearch}
                        />
                     )}
                  </div>
               </div>
               ) : (
               <div className='text-center my-4'>
                 <Spinner animation='border' role='status'>
                  <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                 </Spinner>
               </div>
             )}
          </div>
      </div>

      <Modal
        show={showModalNew}
        className='modal-with-nav'
        size='xl'
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.instruments.createinstrument' fallback={'Crea Strumento'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} variant='pills' fill>
            {/* Anagrafica */}
            <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')}>
              <Form onSubmit={handleNewInstrument} id='form-new-instrument'>
                <Row style={{ marginBottom: '40px' }}>
                  <Col className='col-6'>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='instruments.name' fallback={'Nome'}/>
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='name'
                        value={newInstrument.name}
                        onChange={(e) => onChange(e, 'name')}
                      />
                    </Form.Group>
                  </Col>

                  <Col className='col-6'>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='instruments.managingAuthority' fallback={'Soggetto gestore'}/>
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='managingAuthority'
                        value={newInstrument.managingAuthority}
                        onChange={(e) => onChange(e, 'managingAuthority')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              {sbShow}
            </Tab>

            {/* Documenti */}
            <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!newInstrument.id}>
              <UploadDocument entity='instruments' entityId={newInstrument.id} />
              {sbShow}
            </Tab>
          </Tabs>

          <Row>
            <Col className="col-12">
              <span className="label"><span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/></span>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button
            variant='primary'
            onClick={(e) => handleNewInstrument(e)}
            disabled={activeKey === 'docs'}>
            {newInstrument.id ? <OETrans code='common.edit' fallback={'Modifica'}/> : <OETrans code='common.create' fallback={'Crea'}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InstrumentsList;
