import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const ButtonWithOverlay = ({ tooltipText, children, onClick, ...props }) => {
  const overlayProps = {};
  const buttonProps = {};

  // Divide props in overlay and button props
  Object.keys(props).forEach((key) => {
    if (key.startsWith("overlay")) {
      overlayProps[key] = props[key];
    } else {
      buttonProps[key] = props[key];
    }
  });

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip {...overlayProps}>{tooltipText}</Tooltip>}
    >
      <Button
        {...buttonProps}
        {...(props.variant && { variant: props.variant })}
        onClick={onClick}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
};

export default ButtonWithOverlay;
