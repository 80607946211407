import React from 'react';
import AccessOEUser from 'components/accessOEUser';

const LoginOEUser = () => {
  return (
    <div id="admin-login">
      <AccessOEUser />
    </div>
  );
};

export default LoginOEUser;