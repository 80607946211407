import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const MarkdownReaderComponent = ({ text }) => {
  const [textToSanitize, setTextToSanitize] = useState(null);

  useEffect(() => {
    if (text) {
      setTextToSanitize(text);
    }
  }, [text]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return <div className='w-100' dangerouslySetInnerHTML={createMarkup(textToSanitize)}></div>;
};

export default MarkdownReaderComponent;