import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import StatusBar from '@components/StatusBar';
import { IcoUploadFile, CloudArrowDown, IcoCanc } from '@icons';
import { Spinner } from 'react-bootstrap';
import { LevelContext } from 'components/contexts/LevelContext';
import {
   getOeDocuments,
   uploadDocument,
   getDocuments,
   downloadDocument,
   deleteDocument,
} from '@lib/api';
import { Fragment } from 'react';

const CheckDocTab = ({ tab, conditionalitiesFilterCodes, detail, isValidated, setDetail, handleChanges }) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [isPending, setIsPending] = useState(false);
   const [oeDocuments, setOeDocuments] = useState([]);
   const [counter, setCounter] = useState({});
   const [uploadedDocuments, setUploadedDocuments] = useState([]);
   const [sbShow, setSbShow] = useState(<></>);
   //console.log('docs uploadedDocuments:', uploadedDocuments);
   //console.log('docs oeDocuments:', oeDocuments);

   /* const returnFieldNameNotice = (docCode) => {
      let value;

      switch (docCode) {
         case 'R_COND_DIGITALE':
            value = 'digitalTagging';
            break;
         case 'R_COND_DNSH':
            value = 'dnsh';
            break;
         case 'R_COND_AMBIENTALE':
            value = 'environmentalTagging';
            break;
         case 'R_COND_PARITA':
            value = 'genderEquality';
            break;
         default:
            break;
      }

      return value;
   }; */

   /* const returnFieldNameCheck = (docCode) => {
      let value;

      switch (docCode) {
         case 'R_COND_DIGITALE':
            value = 'checkDigitalTagging';
            break;
         case 'R_COND_DNSH':
            value = 'checkDnsh';
            break;
         case 'R_COND_AMBIENTALE':
            value = 'checkEnvironmentalTagging';
            break;
         case 'R_COND_PARITA':
            value = 'checkGenderEquality';
            break;
         default:
            break;
      }

      return value;
   }; */

   useEffect(() => {
      getOeDocuments('statements').then((oeDocuments) => {
         if (oeDocuments.success === true) {
            let tempDocuments = oeDocuments.data.filter(oeDoc => {
               return conditionalitiesFilterCodes.some(filterCode => {
                  return oeDoc.section === filterCode && !oeDoc.deleted;
               });
            }).sort((a, b) => a.position - b.position);

            /* if (tab === 'conditionality' && !isValidated) {
               setOeDocuments(tempDocuments.filter(tempDoc => tempDoc.code === (detail[returnFieldNameNotice(tempDoc.code)] === true ? tempDoc.code : null)));
            } else {
               setOeDocuments(tempDocuments);
            } */

            setOeDocuments(tempDocuments);
         }
      });
   }, [conditionalitiesFilterCodes, detail, isValidated, tab]);

   useEffect(() => {
      if (detail.id) {
         getUploadedDocuments();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [detail.id]);

   const getUploadedDocuments = () => {
      getDocuments('statements', detail.id).then((documents) => {
         if (documents.success === true) {
            const counter = new Map();

            documents.data.forEach(({ documentCode, missing }) => {
               if (missing !== undefined && !missing) {
                  counter.set(documentCode, (counter.get(documentCode) || 0) + 1);
               }
            });

            const counterObject = Object.fromEntries(counter);
            setCounter(counterObject);
            console.log(documents.data);
            setUploadedDocuments(documents.data);
         }
      });
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const handleUpload = async (event, index, document) => {
      const code = document.code;
      const entityType = document.entityType;
      let matchMissingFile = null;
      let id = null;
      const curDoc = uploadedDocuments.filter((item) => item.documentCode === document.code).shift();
      if (curDoc && curDoc.missing) {
         matchMissingFile = curDoc.missing;
         id = curDoc.id;
      }
      //console.log(matchMissingFile, id);
      setSbShow(<></>);
      //console.log(event.target.files);
      const files = [...event.target.files];
      //console.log('files', files);
      const file = event.target.files[0];
      //console.log('files', files);
      event.target.value = null;
      //console.log('files', files);
      setIsPending(true);

      try {
         // UPLOAD
         //console.log(file)
         if (file.size > 200000000) {
            handleStatusBar('error', 'File troppo grande (massimo consentito: 200MB)', true);
            setIsPending(false);
            return false;
         }

         //console.log('o', files);
         let uploadPromise = [];

         files.forEach((file) => {
            uploadPromise.push(uploadDocument(entityType, detail.id, code, file, matchMissingFile, id));
         });

         const result = await Promise.allSettled(uploadPromise);
         //console.log('result', result);

         let errorMessage = [];
         let successMessage = [];

         result.forEach((item) => {
            if (item.status === 'rejected') {
               //console.log('item', item);
               errorMessage.push(item.reason.message);
            }
            if (item.status === 'fulfilled') {
               successMessage.push(item.value.message);
            }
         });

         //console.log('errorMessage', errorMessage);
         //console.log('successMessage', successMessage);

         if (errorMessage.length > 0) {
            handleStatusBar('error', errorMessage.join(', '), true);
            setIsPending(false);
         }

         if (successMessage.length > 0) {
            handleStatusBar('success', successMessage.join(', '), true);
            setIsPending(false);
         }

         getUploadedDocuments();
      } catch (error) {
         console.log(error);
      }
   };

   const handleDownload = async (event, upDocument) => {
      setSbShow(<></>);
      setIsPending(true);

      try {
         // UPLOAD
         const result = await downloadDocument(upDocument);

         if (result.success) {
            // File upload successful
            handleStatusBar('success', 'Documento scaricato con successo', true);
            setIsPending(false);
         } else {
            const message = result.data.file.join(',');
            handleStatusBar('error', message, false);
            setIsPending(false);
         }
      } catch (error) { }
   };

   const handleDelete = async (event, upDocument) => {
      setSbShow(<></>);
      setIsPending(true);

      try {
         const result = await deleteDocument(upDocument);

         if (result.success) {
            handleStatusBar('success', 'Documento eliminato con successo', true);
            setIsPending(false);

            //const newList = uploadedDocuments.filter((item) => item.id !== upDocument.id);
            //setUploadedDocuments(newList);
         } else {
            const message = result.data.file.join(',');
            handleStatusBar('error', message, false);
            setIsPending(false);
         }

         getUploadedDocuments()
      } catch (error) { }
   };

   return (
      <>
         <Row>
            <Col>
               <Row className="mb-4">
                  <Col className='col-3'><Form.Label>Argomento</Form.Label></Col>
                  {/* {tab === 'conditionality' &&
                     <Col className='col-2 text-center'><Form.Label>Check documento</Form.Label></Col>
                  } */}
                  <Col className='col-1 text-center'><Form.Label>Upload</Form.Label></Col>
                  <Col></Col>
               </Row>

               {oeDocuments.map((oeDocument, index) => {
                  //console.log('test:', returnFieldNameCheck(oeDocument.code));
                  //console.log('check:', detail[returnFieldNameCheck(oeDocument.code)]);

                  return <Fragment key={`oedoc-${index}_${detail.id}`}>
                     <Row className="align-items-center mb-3">
                        {/* LABEL */}
                        <Col className='col-3'>
                           <Form.Group as={Col} className='pe-2'>
                              <span /* className={tab === 'conditionality' && !detail[returnFieldNameNotice(oeDocument.code)] ? 'text-muted' : ''} */>{oeDocument.name}</span>
                           </Form.Group>
                        </Col>

                        {/* CHECK */}
                        {/* {tab === 'conditionality' &&
                           <Col className='col-2 text-center'>
                              <Form.Group as={Col} className={!detail[returnFieldNameNotice(oeDocument.code)] ? 'text-muted pe-2' : 'pe-2'}>
                                 <Form.Check
                                    inline
                                    className='ms-3'
                                    type='checkbox'
                                    name={returnFieldNameCheck(oeDocument.code)}
                                    defaultChecked={detail[returnFieldNameCheck(oeDocument.code)]}
                                    //onClick={(e) => onChange(e, returnFieldNameCheck(oeDocument.code))}
                                    onClick={(e) => handleChanges(e, returnFieldNameCheck(oeDocument.code))}
                                    //disabled={tab === 'integration' ? false : (detail[returnFieldNameNotice(oeDocument.code)] && !isValidated ? false : true)}
                                    disabled={isValidated}
                                 />
                              </Form.Group>
                           </Col>
                        } */}

                        {/* ICONA UPLOAD */}
                        {/* {((tab === 'conditionality' && detail[returnFieldNameNotice(oeDocument.code)]) || tab !== 'conditionality') && */}
                        <Form key={`key-upload-docs-${detail.id}`} id={`form-upload-docs-${detail.id}`} className="col-1 p-0 text-center">
                           <div className='d-flex justify-content-center align-items-center'>
                              {isPending ?
                                 <Spinner
                                    animation='border'
                                    role='status'
                                    style={{ display: 'flex', margin: '0 auto' }}
                                 /> : <>
                                    {(isOe || userRole === 5) && (
                                       <>
                                          <Form.Group controlId={`${oeDocument.code}_${oeDocument.entityType}_${detail.id}`} className='required-field position-relative'>
                                             <Form.Label className="m-0">
                                                <IcoUploadFile 
                                                   style={{ cursor: isPending || (isValidated && tab !== 'integration') ? 'default' : 'pointer' }}
                                                   className={`ico-small download-upload align-bottom ${isPending || (isValidated && tab !== 'integration') ? 'ico-disabled' : 'second-color'}`}
                                                />
                                             </Form.Label>
                                             <Form.Control
                                                type='file'
                                                disabled={isPending || (isValidated && tab !== 'integration') ? true : false}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleUpload(e, index, oeDocument)}
                                             />
                                          </Form.Group>
                                       </>
                                    )}

                                    <span
                                       className={`position-absolute ${counter[oeDocument.code] < oeDocument.min || counter[oeDocument.code] === undefined
                                          ? 'danger' : 'required-field'}`}
                                       style={{ transform: 'translateX(135%)' }}
                                    >
                                       {oeDocument.min > 0 && !isValidated ?
                                          (counter[oeDocument.code] === undefined ? 0 + '/' + oeDocument.min
                                             : counter[oeDocument.code] + '/' + oeDocument.min)
                                          : ''}
                                    </span>
                                 </>
                              }
                           </div>
                        </Form>
                        {/* } */}

                        {/* NOME FILE */}
                        {/* {((tab === 'conditionality' && detail[returnFieldNameNotice(oeDocument.code)]) || tab !== 'conditionality') && */}
                        <Col>
                           {uploadedDocuments.filter(upDoc => upDoc.documentCode === oeDocument.code).map((upDocument, index) =>
                              <Fragment key={`docs-${index}_${oeDocument.code}_${detail.id}`}>
                                 {!upDocument.missing && (
                                    <span className='ms-3'>
                                       <span style={{ margin: '8px' }} />
                                       <CloudArrowDown
                                          /* style={{ cursor: isPending || (isValidated && tab !== 'integration') ? 'default' : 'pointer' }}
                                          className={
                                             isPending || (isValidated && tab !== 'integration')
                                                ? 'ico-disabled ico-small download-upload'
                                                : 'second-color ico-small download-upload'
                                          } */
                                          style={{ cursor: 'pointer' }}
                                          className="second-color ico-small download-upload"
                                          onClick={(e) => handleDownload(e, upDocument)}
                                       />
                                       <span style={{ margin: '8px' }} />
                                       {(isOe || userRole === 5) && (
                                          <IcoCanc
                                             style={{
                                                cursor: isPending || (isValidated && tab !== 'integration') ? 'default' : 'pointer',
                                                viewBox: '0 0 24 24',
                                                transform: 'translate(0, -1px)',
                                                marginRight: '15px',
                                                width: '24px',
                                                height: '24px',
                                             }}
                                             className={
                                                isPending || (isValidated && tab !== 'integration')
                                                   ? 'ico-disabled ico-small download-upload'
                                                   : 'second-color ico-small download-upload'
                                             }
                                             {...(isPending || (isValidated && tab !== 'integration') ? {} : { onClick: (e) => handleDelete(e, upDocument) })}
                                          //onClick={(e) => handleDelete(e, upDocument)}
                                          />
                                       )}
                                    </span>
                                 )}

                                 {upDocument.fileName}

                                 {upDocument.missing !== undefined && upDocument.missing ?
                                    <span className='badge bg-danger ms-2'>File mancante</span> : ''
                                 }
                                 <br />
                              </Fragment>
                           )}
                        </Col>
                        {/* } */}
                     </Row>
                  </Fragment>
               })}
            </Col>

            {sbShow}
         </Row>
      </>
   );
};

export default CheckDocTab;