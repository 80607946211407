import React, { useState, useEffect, Fragment, useContext } from 'react';
import {
   Form,
   Row,
   Col,
   Spinner,
   Button,
} from 'react-bootstrap';
import Select from 'react-select';
import StatusBar from '@components/StatusBar';
import {
   getTemplateConfigurations,
   generateFilesTemplates,
} from '@lib/api';
import { LevelContext } from 'components/contexts/LevelContext';
import Unauthorized from 'components/Unauthorized';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';
import UploadFilesForTemplates from './UploadFilesForTemplates';

const initialState = {
   contracts: false,
   invoices: false,
   payments: false,
   timesheets: false,
};

const GenerateTemplatesSection = ({
   projectCompanyId,
   projectId,
   sbShow,
   setSbShow,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [isPending, setIsPending] = useState(false);
   const [optionsConfigTemplates, setOptionsConfigTemplates] = useState([]);
   const [checkedEntities, setCheckedEntities] = useState(initialState);
   const [selectedConfigTemplate, setSelectedConfigTemplate] = useState([]);
   const [addedDocuments, setAddedDocuments] = useState([]);
   //console.log('addedDocuments:', addedDocuments);

   useEffect(() => {
      getTemplateConfigurations(projectCompanyId).then((retrievedTemplates) => {
         if (retrievedTemplates.success) {
            setOptionsConfigTemplates(retrievedTemplates.data.map(templ => ({
               value: templ.id,
               label: templ.label,
               files: templ.files,
               inputType: templ.inputType,
            })));
         }
      });
   }, [projectCompanyId]);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const onChange = (e, fieldName, setSelect) => {
      //console.log('e onChange:', e);

      switch (fieldName) {
         case 'configTemplates':
            setSelect(e ?? []);

            if (!e) {
               let tempCheckedEntities = { ...checkedEntities };
               Object.keys(tempCheckedEntities).forEach(key => tempCheckedEntities[key] = false);

               setCheckedEntities(tempCheckedEntities);
            }
            break;
         case 'all':
            let tempCheckedEntities = { ...checkedEntities };
            Object.keys(tempCheckedEntities).forEach(key => tempCheckedEntities[key] = e.target.checked);

            setCheckedEntities(tempCheckedEntities);
            break;
         case 'contracts':
         case 'invoices':
         case 'payments':
         case 'timesheets':
            setCheckedEntities({
               ...checkedEntities,
               [e.target.name]: e.target.checked,
            })
            break;
         default:
            break;
      }
   };

   const generateTemplates = async (e) => {
      //console.log('e', e);
      setSbShow(<></>);
      e.target.value = null;

      setIsPending(true);

      try {
         let output = '';
         let tempAddedDocuments = [...addedDocuments];
         let configurationId = selectedConfigTemplate.value;
         let fileTypes = tempAddedDocuments.map(el => el.documentCode);

         if (Object.values(checkedEntities).every(el => el === true)) {
            output = 'all';
         } else {
            let tempOutput = [];

            for (const [key, value] of Object.entries(checkedEntities)) {
               if (value) { tempOutput.push(key) };
            }

            output = tempOutput.join('|'); //or logico per BE
         }

         const result = await generateFilesTemplates(projectId, configurationId, output, addedDocuments, fileTypes);
         //console.log('result', result);

         if (result.success) {
            setIsPending(false);
            setSelectedConfigTemplate([]);
            setCheckedEntities(initialState);
            setAddedDocuments([]);

            handleStatusBar(
               "success",
               translate('common.message.operationcomplited', 'Operazione eseguita con successo'),
               true
            );
         } else {
            handleStatusBar(
               "error",
               result.data ? Object.values(result.data).join()
                  : (result.message ?? translate('common.error.load', 'Si è verificato un errore durante il caricamento delle informazioni')),
               false
            );

            setIsPending(false);
            setSelectedConfigTemplate([]);
            setCheckedEntities(initialState);
            setAddedDocuments([]);
         }
      } catch (error) {
         setIsPending(false);
         //console.log('error', error);
      }
   };

   if (!isOe && userRole !== 5) return <Unauthorized drilled={true} />;
   return (
      <Fragment>
         <Row className='mt-5 mb-5'>
            <Col>
               <Row>
                  <Col>
                     <span></span>
                  </Col>
               </Row>
               <Row>
                  <Form.Group as={Col} className="col-6">
                     <Form.Label>1. <OETrans code='common.project.configurationtemplate' fallback={'Scegli una configurazione'} /></Form.Label>
                     <Select
                        components={{ IndicatorSeparator: () => null, }}
                        className="react-select-container ps-3 mt-2"
                        classNamePrefix="react-select"
                        name='role'
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedConfigTemplate}
                        options={optionsConfigTemplates}
                        onChange={(e) => onChange(e, 'configTemplates', setSelectedConfigTemplate)}
                        isClearable
                     />
                  </Form.Group>
               </Row>

               <Row className="mt-5">
                  <Form.Label>2. <OETrans code='common.project.selectentities' fallback={'Scegli le entità'} /></Form.Label>
                  <Form.Group as={Col} className="col-12 my-2">
                     <Form.Check
                        className="ps-3"
                        disabled={selectedConfigTemplate.length === 0}
                        inline
                        type="checkbox"
                        name="all"
                        checked={Object.values(checkedEntities).every(el => el === true)}
                        onClick={(e) => onChange(e, 'all')}
                     />
                     <OETrans code='common.project.selectentities.all' fallback={'Seleziona tutto'} />
                  </Form.Group>

                  <Form.Group as={Col} className="col-12 my-2">
                     <Form.Check
                        className="ps-3"
                        disabled={selectedConfigTemplate.length === 0}
                        inline
                        type="checkbox"
                        name="contracts"
                        checked={checkedEntities.contracts}
                        onClick={(e) => onChange(e, 'contracts')}
                     />
                     <OETrans code='common.project.selectentities.contracts' fallback={'Contratti'} />
                  </Form.Group>

                  <Form.Group as={Col} className="col-12 my-2">
                     <Form.Check
                        className="ps-3"
                        disabled={selectedConfigTemplate.length === 0}
                        inline
                        type="checkbox"
                        name="invoices"
                        checked={checkedEntities.invoices}
                        onClick={(e) => onChange(e, 'invoices')}
                     />
                     <OETrans code='common.project.selectentities.invoices' fallback={'Fatture'} />
                  </Form.Group>

                  <Form.Group as={Col} className="col-12 my-2">
                     <Form.Check
                        className="ps-3"
                        disabled={selectedConfigTemplate.length === 0}
                        inline
                        type="checkbox"
                        name="payments"
                        checked={checkedEntities.payments}
                        onClick={(e) => onChange(e, 'payments')}
                     />
                     <OETrans code='common.project.selectentities.payments' fallback={'Pagamenti'} />
                  </Form.Group>

                  <Form.Group as={Col} className="col-12 my-2">
                     <Form.Check
                        className="ps-3"
                        disabled={selectedConfigTemplate.length === 0}
                        inline
                        type="checkbox"
                        name="timesheets"
                        checked={checkedEntities.timesheets}
                        onClick={(e) => onChange(e, 'timesheets')}
                     />
                     <OETrans code='common.project.selectentities.timesheets' fallback={'Timesheets'} />
                  </Form.Group>
               </Row>

               <Row className="mt-5">
                  <Form.Label>3. <OETrans code='common.project.uploadsourcefile' fallback={'Carica file sorgente'} /></Form.Label>
                  <Col className="ps-3 pt-3">
                     <UploadFilesForTemplates
                        selectedConfigTemplate={selectedConfigTemplate}
                        isPending={isPending}
                        setIsPending={setIsPending}
                        addedDocuments={addedDocuments}
                        setAddedDocuments={setAddedDocuments}
                        setSbShow={setSbShow}
                     />
                  </Col>
               </Row>

               <Row className="mt-5 mb-2 text-center">
                  <Col>
                     <Button
                        onClick={e => generateTemplates(e)}
                        disabled={selectedConfigTemplate.length === 0 || Object.values(checkedEntities).every(el => el === false)}
                     >
                        <OETrans code='common.generatetemplates' fallback={'Genera template'} />
                        {isPending && (
                           <Spinner
                              as="span"
                              className="ms-2"
                              animation='border'
                              role='status'
                              size="sm"
                              aria-hidden="true"
                           />
                        )}
                     </Button>
                  </Col>
               </Row>
            </Col>

            {sbShow}
         </Row>
      </Fragment>
   );
};

export default GenerateTemplatesSection;
