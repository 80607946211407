import React, {
   useState,
   useEffect,
   useCallback,
   useRef,
   useContext,
} from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
   Button,
   ButtonToolbar,
   Spinner,
   Row,
   Col,
   ProgressBar,
   OverlayTrigger,
   Tooltip,
} from "react-bootstrap";
import useDebounce from "@components/customHooks/useDebounce";
import { IcoPlus } from "@icons";
import { getProjects } from "@lib/api";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import CustomTooltip from "@components/ag-grid/CustomTooltip";
import ActionBtnRendererProjects from "@components/actionBtnRenderer/ActionBtnRendererProjects";
import ProjectModal from "./ProjectModal";
import { LevelContext } from "@components/contexts/LevelContext";
import DashboardWheelsSection from "components/charts/DashboardWheelsSection";
import { columnCentered, headerToCenter } from "@lib/helpers/agGridHelpers";
import { dateFormatter, formatToDecimals } from "@lib/helpers/formatting";
import CustomPaginationMui from "components/materialComponents/CustomPaginationMui";
import CustomQuickFilterBox from "components/ag-grid/CustomQuickFilterBox";

const ProjectsList = () => {
   const { isOe, userRole } = useContext(LevelContext);
   const [projects, setProjects] = useState(null);
   const [projectsMeta, setProjectsMeta] = useState({});
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalProject, setShowModalProject] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   const navigate = useNavigate();
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   //console.log('projects', projects);

   const handleShowModal = (setShowModal) => setShowModal(true);

   const fetchProjects = useCallback( async (e) => {
      const resp = await getProjects(undefined, 'projects');

      if (resp.success) {
         setProjects(resp.data);
         const { data, success, ...restResp } = resp;
         setProjectsMeta(restResp);
      }
   }, []);

   useDebounce(() => {
      fetchProjects();
   });

   /* useEffect(() => {
      fetchProjects();
   }, [fetchProjects]); */

   const onGridReady = useCallback((_) => {
      const allColumnIds = [];
      gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
   }, []);

   useEffect(() => {
      if (refresh) {
         fetchProjects();
         setRefresh(false);
      }
   }, [fetchProjects, refresh]);

   const defaultColDef = {
      resizable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      cellStyle: {
         paddingLeft: "1rem",
         paddingRight: "1rem",
         textOverflow: "ellipsis",
         whiteSpace: "nowrap",
         overflow: "hidden",
      },
      tooltipComponent: CustomTooltip,
   };

   const TextWithTooltipRenderer = useCallback(
      (params) => {
         const ConditionalWrapper = ({ condition, wrapper, children }) =>
            condition ? wrapper(children) : children;

         return (
            <ConditionalWrapper
               condition={params.link}
               wrapper={(children) => (
                  <Button
                     variant="link"
                     className="projectModalRenderBtn pb-1"
                     onClick={() => {
                        navigate("project/" + params.data.id + "/dashboard");
                     }}
                  >
                     {children}
                  </Button>
               )}
            >
               <span >{params?.value}</span>

               {/* <OverlayTrigger
               placement="top"
               overlay={
                  <Tooltip id={`tooltip-top-${params.rowIndex}`} className="text-left">
                     {params.value}
                  </Tooltip>
               }
            >
               <span>{params.value}</span>
            </OverlayTrigger> */}
            </ConditionalWrapper>
         );
      },
      [navigate]
   );

   const ProgressBarCellRenderer = useCallback((params) => {
      if (params.data && params.queryParam) {
         let result = 0;

         if (params.queryParam === "final-balance") {
            result =
               params.data.totalFinalAmount !== null &&
                  params.data.totalAmount !== null
                  ? (params.data.totalFinalAmount / params.data.totalAmount) * 100
                  : 0;
            result = isFinite(result) && result > 0 ? result : 0;
         } else if (params.queryParam === "final-reportable") {
            result =
               params.data.amountReportable !== null &&
                  params.data.totalAmount !== null
                  ? (params.data.amountReportable / params.data.totalFinalAmount) *
                  100
                  : 0;
            result = isFinite(result) && result > 0 ? result : 0;
         }

         const progressBarTooltip = (props) => (
            <Tooltip {...props}>{`${!result ? 0 : formatToDecimals(result)
               }%`}</Tooltip>
         );

         return (
            <Row className="justify-content-center align-items-center flex-row">
               <Col className="m-0 p-0 col-auto">
                  <span style={{ fontSize: "1rem", fontWeight: '600' }} className="second-color">
                     {!result ? 0 : formatToDecimals(result, 0, 0)}%
                  </span>
               </Col>

               <Col className="col-auto pe-0">
                  <OverlayTrigger placement="top" overlay={progressBarTooltip}>
                     <ProgressBar
                        className="aqua-bar p-0"
                        style={{ width: "7rem" }}
                        now={result}
                        min={0}
                        max={100}
                     />
                  </OverlayTrigger>
               </Col>
            </Row>
         );
      }
   }, []);

   useEffect(() => {
      setColumnDefs([
         {
            headerName: "Progetto",
            field: "name",
            filter: "agTextColumnFilter",
            tooltipField: 'name',
            cellRenderer: TextWithTooltipRenderer,
            cellRendererParams: {
               link: true,
            },
            cellStyle: {
               color: '#09A8B0',
               textDecoration: 'underline',
            },
            wrapText: false,
            autoHeight: false,
         },
         {
            headerName: "CUP",
            field: "cup",
            filter: "agTextColumnFilter",
            tooltipField: 'cup',
            wrapText: false,
            autoHeight: false,
         },
         {
            headerName: "Bando",
            field: "notice",
            filter: "agTextColumnFilter",
            valueGetter: (params) => {
               return params.data.notice.call;
            },
            tooltipField: 'notice.call',
            cellRenderer: TextWithTooltipRenderer,
            wrapText: false,
            autoHeight: false,
         },
         {
            headerName: "Beneficiario",
            field: "company.name",
            filter: "agTextColumnFilter",
            cellRenderer: TextWithTooltipRenderer,
            tooltipField: 'company.name',
            wrapText: false,
            autoHeight: false,
         },
         {
            headerName: "Avvio",
            field: "startDate",
            ...columnCentered(),
            valueGetter: (params) => {
               return dateFormatter(params.data.startDate);
            },
            maxWidth: 170,
         },
         {
            headerName: "Costo (€)",
            field: "amount",
            filter: "agTextColumnFilter",
            type: "rightAligned",
            valueGetter: (params) => {
               return formatToDecimals(params.data.totalAmount);
            },
            minWidth: 200,
         },
         {
            headerName: "Avanzamento spesa",
            //pinned: "right",
            minWidth: 200,
            maxWidth: 200,
            cellRenderer: ProgressBarCellRenderer,
            cellRendererParams: {
               queryParam: "final-balance",
            },
            cellStyle: {
               paddingLeft: 0,
               paddingRight: 0,
            },
            resizable: false,
            ...headerToCenter(),
         },
         /* {
            headerName: "Eleggibile",
            pinned: "right",
            cellRenderer: ProgressBarCellRenderer,
            cellRendererParams: {
               queryParam: "final-reportable",
            },
            ...headerToCenter("ps-5"),
         }, */
         {
            headerName: "",
            //pinned: "right",
            minWidth: 70,
            maxWidth: 70,
            cellRenderer: ActionBtnRendererProjects,
            cellRendererParams: {
               setRefresh: setRefresh,
            },
            resizable: false,
            cellStyle: { paddingLeft: '0rem', paddingRight: '0rem' },
         },
      ]);
   }, [ProgressBarCellRenderer, TextWithTooltipRenderer]);

   return (
      <>
         <DashboardWheelsSection global={true} userRole={userRole} />

         {/* lista progetti */}
         <div className="simple-box scenaritab">
            <div className="row d-flex align-items-center mb-3">
               <div className="col-4">
                  {/* <h5 className='title-simple-box'>Esplora</h5> */}
               </div>
               <div className="col-8 text-right pt-3 pb-4">
                  <ButtonToolbar className="justify-content-end">
                     {(isOe || userRole === 5) && (
                        <Button
                           variant="success"
                           onClick={() => handleShowModal(setShowModalProject)}
                           className=""
                        >
                           <IcoPlus className="light-color ico-small plus new-button" />
                           <span className="d-md-inline">Nuovo Progetto</span>
                        </Button>
                     )}
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={projectsMeta}
                        setData={setProjects}
                        setDataMeta={setProjectsMeta}
                        dataLength={projects?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='projects'
                     />
                  </ButtonToolbar>
               </div>
            </div>
            <div className="row">
               {Array.isArray(projects) ? (
                  <div className="col-12" id="grid-wrapper">
                     <div className="ag-theme-alpine list-table projects-list">
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={projects}
                           defaultColDef={defaultColDef}
                           domLayout="autoHeight"
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription="ag-grid-table"
                           suppressCellFocus={true}
                           autoSizeStrategy={{ type: "fitCellContents" }}
                        />
                        {Object.keys(projectsMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={projectsMeta} 
                              setData={setProjects} 
                              setDataMeta={setProjectsMeta}
                              refTable='projects'
                              setIsFirstResearch={setIsFirstResearch}
                              rowsPerPageOptions={[5, 100, 200, 500]}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className="text-center my-4">
                     <Spinner animation="border" role="status">
                        <span style={{ visibility: "hidden" }}>
                           Caricamento...
                        </span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>

         {/* NEW PROJECT */}
         {
            <ProjectModal
               setRefresh={setRefresh}
               showModalProject={showModalProject}
               setShowModalProject={setShowModalProject}
            />
         }
      </>
   );
};
export default ProjectsList;
