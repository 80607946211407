import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ButtonToolbar,
  Spinner,
  Button,
  Modal,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { IcoPlus } from '@icons';
import { getInstitutions, createInstitution } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererInstitutions from 'components/actionBtnRenderer/ActionBtnRendererInstitutions';
import UploadDocument from 'components/form/upload/uploadDocuments';
import { editInstitution } from '@lib/api/index';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
  name: '',
  code: '',
  managingSystem: '',
};

const InstitutionsList = ({ setSetupInstitutions }) => {
  const [institutions, setInstitutions] = useState(null);
  const [institutionsMeta, setInstitutionsMeta] = useState({});
  const [newInstitution, setNewInstitution] = useState(initialState);
  const [activeKey, setActiveKey] = useState('registry');
  const [columnDefs, setColumnDefs] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [isFirstResearch, setIsFirstResearch] = useState(true);
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setActiveKey('registry');
    setShowModal(false);
    setRefresh(true);
    setNewInstitution(initialState);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  useEffect(() => {
    getInstitutions('institutions').then((retrievedInstitutions) => {
      if (retrievedInstitutions.success) {
        setInstitutions(retrievedInstitutions.data);
        const { data, success, ...restResp } = retrievedInstitutions;
        setInstitutionsMeta(restResp);
      }
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      getInstitutions('institutions').then((retrievedInstitutions) => {
        if (retrievedInstitutions.success) {
          setInstitutions(retrievedInstitutions.data);
          setSetupInstitutions(retrievedInstitutions.data);
          const { data, success, ...restResp } = retrievedInstitutions;
          setInstitutionsMeta(restResp);
        }
      });

      setRefresh(false);
    }
  }, [refresh, setSetupInstitutions]);

  const onGridReady = useCallback(() => {
    getInstitutions('institutions').then((retrievedInstitutions) => {
      if (retrievedInstitutions.success) {
        setInstitutions(retrievedInstitutions.data);
        const { data, success, ...restResp } = retrievedInstitutions;
        setInstitutionsMeta(restResp);
      }
    });
  }, []);

  const defaultColDef = {
    resizable: false,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: translate('institutions.name', 'Ente'),
        field: 'name',
        filter: 'agTextColumnFilter',
        minWidth: 350,
      },
      {
        headerName: translate('institutions.managingSystem', 'Portale di monitoraggio'),
        field: 'managingSystem',
        filter: 'agTextColumnFilter',
        //maxWidth: 250,
      },
      {
        headerName: translate('institutions.code', 'Codice'),
        field: 'code',
        filter: 'agTextColumnFilter',
        //maxWidth: 150,
      },
      {
        headerName: '',
        minWidth: 250,
        maxWidth: 250,
        cellStyle: { textAlign: 'center', padding: 0 },
        cellRenderer: ActionBtnRendererInstitutions,
        cellRendererParams: {
          setRefresh: setRefresh,
        },
      },
    ]);
  }, []);

  const onChange = (e, fieldName) => {
    //console.log('e', e);

    let tempNewInstitution = { ...newInstitution };
    let value;

    switch (fieldName) {
      case 'name':
      case 'code':
      case 'managingSystem':
        value = fieldName === 'code' ? e.target.value.toUpperCase() : e.target.value;
        break;
      default:
        value = e.target.value;
    }

    tempNewInstitution[fieldName] = value;

    setNewInstitution(tempNewInstitution);
  };

  const handleNewInstitution = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    if (!newInstitution.name || !newInstitution.code) {
      handleStatusBar({ success: false, message: translate('common.error.requiredFields', 'Compila i campi obbligatori'), });
      return;
    }

    const { createdAt, createdBy, updatedAt, updatedBy, ...restInstitution } = newInstitution;
    const result = newInstitution.id
      ? await editInstitution(restInstitution)
      : await createInstitution(restInstitution);
    //console.log('result', result);

    if (result.success) {
      handleStatusBar({
        success: true,
        message: newInstitution.id
          ? translate('common.message.edited', 'Elemento modificato con successo')
          : translate('common.message.created', 'Elemento creato con successo'),
      });

      setNewInstitution(result.data);
    } else {
      handleShowModal(result);
    }
  };

  return (
    <>
      <div className='simple-box scenaritab'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-4'>{/* <h5 className='title-simple-box'>Lista enti</h5> */}</div>
          <div className='col-8 text-right px-0 py-1'>
            <ButtonToolbar className='justify-content-end'>
              <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                <IcoPlus className='light-color ico-small plus new-button' />
                <span className='d-md-inline'><OETrans code='common.institutions.addinstitution' fallback={'Crea Ente'}/></span>
              </Button>
              <CustomQuickFilterBox
                  tableRef={gridRef}
                  dataMeta={institutionsMeta}
                  setData={setInstitutions}
                  dataLength={institutions?.length}
                  isFirstResearch={isFirstResearch} 
                  setIsFirstResearch={setIsFirstResearch}
                  refTable='institutions'
               />
            </ButtonToolbar>
          </div>
        </div>
          <div className='row'>
            {Array.isArray(institutions) ? (
               <div className='col-12' id='grid-wrapper'>
                  <div className='ag-theme-alpine list-table'>
                     <AgGridReact
                     ref={gridRef}
                     columnDefs={columnDefs}
                     rowData={institutions}
                     defaultColDef={defaultColDef}
                     domLayout='autoHeight'
                     tooltipShowDelay={0}
                     tooltipHideDelay={2000}
                     tooltipMouseTrack={true}
                     onGridReady={onGridReady}
                     //onFirstDataRendered={onFirstDataRendered}
                     //onModelUpdated={onModelUpdated}
                     headerHeight={50}
                     rowHeight={50}
                     localeText={localeText}
                     ariaDescription='ag-grid-table'
                     suppressCellFocus={true}
                     />
                     {Object.keys(institutionsMeta).length > 0 && (
                        <CustomPaginationMui
                           dataMeta={institutionsMeta} 
                           setData={setInstitutions} 
                           setDataMeta={setInstitutionsMeta}
                           refTable='institutions'
                           setIsFirstResearch={setIsFirstResearch}
                        />
                     )}
                  </div>
               </div>
               ) : (
               <div className='text-center my-4'>
                 <Spinner animation='border' role='status'>
                  <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                 </Spinner>
               </div>
             )}
          </div>
      </div>

      <Modal
        show={showModalNew}
        className='modal-with-nav'
        size='xl'
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.institutions.createinstitution' fallback={'Crea ente'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} variant='pills' fill>
            {/* Anagrafica */}
            <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')}>
              <Form onSubmit={handleNewInstitution} id='form-new-fund'>
                <Row style={{ marginBottom: '40px' }}>
                  <Col className='col-4'>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='institutions.name' fallback={'Nome'}/>
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='name'
                        value={newInstitution.name}
                        onChange={(e) => onChange(e, 'name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='col-4'>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='institutions.code' fallback={'Codice'}/>
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='code'
                        value={newInstitution.code}
                        onChange={(e) => onChange(e, 'code')}
                      />
                    </Form.Group>
                  </Col>
                  <Col className='col-4'>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='institutions.managingSystem' fallback={'Portale di monitoraggio'}/>
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='managingSystem'
                        value={newInstitution.managingSystem}
                        onChange={(e) => onChange(e, 'managingSystem')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              {sbShow}
            </Tab>

            {/* Documenti */}
            <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!newInstitution.id}>
              <UploadDocument entity='institutions' entityId={newInstitution.id} />
              {sbShow}
            </Tab>
          </Tabs>

          <Row>
            <Col className="col-12">
              <span className="label">
                <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
              </span>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button
            variant='primary'
            onClick={(e) => handleNewInstitution(e)}
            disabled={activeKey === 'docs'}>
            {newInstitution.id ? <OETrans code='common.edit' fallback={'Modifica'}/> : <OETrans code='common.create' fallback={'Crea'}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InstitutionsList;
