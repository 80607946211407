import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { IcoEdit, IcoCanc, Lens } from "@icons";
import { editProceduralProgress, deleteProceduralProgress } from "@lib/api";
import Select from "react-select";
import moment from "moment";
import UploadDocument from "components/form/upload/uploadDocuments";
import StatusBar from "@components/StatusBar";
import { LevelContext } from "components/contexts/LevelContext";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const ActionBtnRendererAdvancement = ({
  data,
  setRefresh,
  optionsProcedureProgesses,
}) => {
  const id = data.id;
  const [editProcedureProgress, setEditProcedureProgress] = useState({});
  const [selectedProcedureProgress, setSelectedProcedureProgress] = useState(
    []
  );
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOe, userRole } = useContext(LevelContext);

  useEffect(() => {
    setEditProcedureProgress(data);
  }, [data]);

  useEffect(() => {
    if (editProcedureProgress.id) {
      setSelectedProcedureProgress(
        optionsProcedureProgesses
          .filter(
            (option) =>
              option.value === editProcedureProgress.proceduralProgressCode
          )
          .map((filteredOpt) => ({
            value: filteredOpt.value,
            label: filteredOpt.label,
          }))
      );
    }
  }, [editProcedureProgress, optionsProcedureProgesses]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setEditProcedureProgress(data);
    setActiveKey("registry");
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  };

  const handleDelete = async () => {
    const deleteResult = await deleteProceduralProgress(data?.projectId, id);

    if (deleteResult.success) {
      handleStatusBar("success", translate('common.message.deleted', 'Elemento eliminato correttamente'), true);
      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(
        "error",
        deleteResult.message ??
          translate('common.error.delete', 'Si è verificato un errore durante l’eliminazione delle informazioni'),
        false
      );
    }
  };
  const onChange = (e, fieldName, setSelect) => {
    //console.log('e', e);

    let tempNewProceduralProgress = { ...editProcedureProgress };
    let value;

    switch (fieldName) {
      case "proceduralProgressCode":
        value = e.value;
        tempNewProceduralProgress[fieldName] = value;
        setSelect(e);
        break;
      case "forecastStartDate":
      case "forecastEndDate":
      case "startDate":
      case "endDate":
      default:
        value = e.target.value;
        tempNewProceduralProgress[fieldName] = value;
    }
    setEditProcedureProgress(tempNewProceduralProgress);
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      ...restProceduralProgress
    } = editProcedureProgress;
    const {
      startDate,
      endDate,
      forecastEndDate,
      forecastStartDate,
      ...copyToCheck
    } = restProceduralProgress;

    let isForecastDateValid;
    let isStartDateValid;
    if (forecastStartDate !== null && forecastEndDate !== null) {
      isForecastDateValid = forecastStartDate > forecastEndDate;
    }
    if (startDate !== null && endDate !== null) {
      isStartDateValid = startDate > endDate;
    }

    const isProcedureFilled = Object.values(copyToCheck).every(
      (prop) => prop !== "" && prop != null
    )
      ? true
      : false;

    if (!isProcedureFilled) {
      handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
      return;
    }
    if (isForecastDateValid || isStartDateValid) {
      handleStatusBar(
        "error",
        "La data di inizio non può essere successiva alla data di fine",
        false
      );
      return;
    }

    let result;
    setIsLoading(true);
    setShowSpinner(true);
    result = await editProceduralProgress(
      editProcedureProgress?.projectId,
      editProcedureProgress?.id,
      restProceduralProgress
    );

    if (result.success) {
      handleStatusBar("success", translate('common.message.edited', 'Elemento modificato con successo'), true);
      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2500);
    } else {
      handleStatusBar(
        "error",
        result.success === false
          ? Object.values(result.data).join()
          : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
        false
      );
    }

    setIsLoading(false);
    setShowSpinner(false);
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      {isOe || userRole === 5 ? <OETrans code='common.tooltip.edit' fallback={'Modifica'}/> : "Visualizza"}
    </Tooltip>
  );
  const deleteTooltip = (props) => 
   <Tooltip {...props}>
      <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>
   </Tooltip>;

  return (
    <div>
      <OverlayTrigger placement="top" overlay={editTooltip}>
        <span>
          <Button
            variant="link"
            onClick={() => handleShowModal(setShowModalEdit)}
          >
            {isOe || userRole === 5 ? (
              <IcoEdit className="second-color ico-small edit me-3" />
            ) : (
              <Lens className="second-color ico-small edit path me-3" />
            )}
          </Button>
        </span>
      </OverlayTrigger>
      {(isOe || userRole === 5) && (
        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <span>
            <Button
              variant="link"
              className="me-0"
              onClick={() => handleShowModal(setShowModalDelete)}
            >
              <IcoCanc className="second-color ico-small cancel" />
            </Button>
          </span>
        </OverlayTrigger>
      )}
      {showModalEdit && (
        <Modal
          size="lg"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Modifica Progresso Procedurale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              fill
            >
              {/* Documenti */}
              <Tab eventKey="registry" title={"Dettagli"}>
                <Form id="form-new-procedural-progress">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>
                          Progresso Procedurale
                          <span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={(!isOe && userRole !== 5) || isLoading}
                          name="proceduralProgress"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedProcedureProgress}
                          options={optionsProcedureProgesses}
                          onChange={(e) =>
                            onChange(
                              e,
                              "proceduralProgressCode",
                              setSelectedProcedureProgress
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>Data Prevista Inizio</Form.Label>
                        <Form.Control
                          disabled={(!isOe && userRole !== 5) || isLoading}
                          type="date"
                          name="forecastStartDate"
                          value={
                            editProcedureProgress?.forecastStartDate
                              ? moment(
                                  editProcedureProgress.forecastStartDate
                                ).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => onChange(e, "forecastStartDate")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>Data Prevista Fine</Form.Label>
                        <Form.Control
                          disabled={(!isOe && userRole !== 5) || isLoading}
                          type="date"
                          name="forecastEndDate"
                          value={
                            editProcedureProgress?.forecastEndDate
                              ? moment(
                                  editProcedureProgress.forecastEndDate
                                ).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(e) => onChange(e, "forecastEndDate")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>Data Effettiva Inizio</Form.Label>
                        <Form.Control
                          disabled={(!isOe && userRole !== 5) || isLoading}
                          type="date"
                          name="startDate"
                          value={
                            editProcedureProgress?.startDate
                              ? moment(editProcedureProgress.startDate).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onChange(e, "startDate")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-6">
                      <Form.Group as={Col}>
                        <Form.Label>Data Effettiva Fine</Form.Label>
                        <Form.Control
                          disabled={(!isOe && userRole !== 5) || isLoading}
                          type="date"
                          name="endDate"
                          value={
                            editProcedureProgress?.endDate
                              ? moment(editProcedureProgress.endDate).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onChange(e, "endDate")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>
              <Tab eventKey="documents" title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument
                  entity="projectProceduralProgresses"
                  entityId={editProcedureProgress.id}
                />
                {sbShow}
              </Tab>
            </Tabs>

            <Row>
              <Col className="col-12">
                <span className="label">
                  <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                </span>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              {isOe || userRole === 5 ? <OETrans code='common.cancel' fallback={'Annulla'}/> : "Chiudi"}
            </Button>
            {(isOe || userRole === 5) && (
              <Button
                variant="primary"
                onClick={(e) => handleEdit(e)}
                disabled={activeKey === "docs" || isLoading}
              >
                <OETrans code='common.edit' fallback={'Modifica'}/>
                {showSpinner && (
                  <Spinner
                    as="span"
                    className="ms-2"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code='common.delete' fallback={'Elimina'}/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRendererAdvancement;
