import React, { useEffect } from "react";
import Flourish from "@flourish/live-api"

/* const test = [
   ["Tipo", "Valore"],
   ["Valore progetti", 396767440],
   ["Avanzamento", 15864981.09],
   ["Incassato", 1154170.30]
]; */

const ColumnChart = ({ data = [] }) => {

   useEffect(() => { 
      let tempConfig = {
         base_visualisation_id: "16391203", // ID of chart
         version: "27.9.0",
         api_key: "-Djdpl96Ne3iVl38YMO6NL1Kq_loiaBZ0bycoGhajplHwe0SiFK0gDfQmu8nYuW6",
         container: "#column-chart", // Selector for target DOM element
         state: {
            chart_type: "column_grouped",
            color: {
               categorical_custom_palette: "Valore progetti:  #1c2743\nAvanzamento: #08A2AA\nIncassato: #C2C923"
            },
            color_mode: "row",
            column_padding_in_group: 15,
            column_padding_inner: 50,
            facet_aspect: 1.9,
            facet_aspect_mobile: 1.5,
            height_mode: "aspect",
            legend_mode: "off",
            number_format: {
               advanced: true,
               strip_separator: false,
               suffix: " €",
               transform_labels: false
            },
            popup: {
               mode: "popup",
               popup_align: "start",
               popup_custom_header: "{{Tipo}}",
               popup_custom_main: "{{Valore}}",
               popup_header_type: "inline",
               popup_is_custom: false,
               popup_list_type: "ul",
               show_popup_styles: true
            },
            x: {
               tick_label_angle: "0",
               tick_label_styling: true
            },
            y: {
               numeric_scale_type: "linear"
            }
         },
         bindings: {
            data: {
               label: 0,
               metadata: [],
               value: [
                  1
               ]
            }
         },
         data: {
            data: data,
         },
         metadata: {
            data: {
               0: {
                  type_id: "string$arbitrary_string",
                  type: "string",
                  output_format_id: "string$arbitrary_string"
               },
               1: {
                  type_id: "number$none_point",
                  type: "number",
                  output_format_id: "number$comma_point"
               }
            }
         }
      };

      const parent = document.getElementById("column-chart");

      const child = parent.querySelector('iframe');
      if (child != null) child.remove();
   
      new Flourish.Live(tempConfig);
   }, [data]);

   return (<>
      <script src="https://cdn.flourish.rocks/flourish-live-v4.0.0.min.js"></script>
      <div id="column-chart"></div>
   </>
   );
};
export default ColumnChart;