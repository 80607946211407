import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

const DnshAccordion = ({ data, adapOrMiti }) => {
   const [dnshData, setDnshData] = useState({});
   //console.log('dnshData', dnshData);

   useEffect(() => {
      if (data.length) {
         setDnshData(...data);
      }
   }, [data]);

   return (
      <>
         <div className='row d-flex align-items-center my-4'>
            <div className='col-auto'>
               <h5>{dnshData?.activity}</h5>
            </div>
         </div>
         <Accordion className='my-3' defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
               <Accordion.Header className='expanded-accordion'>
                  {/* <span>{`${dnshData?.sector} contribution to climate ${adapOrMiti}`}</span> */}
                  <span>{`${dnshData?.sector}: contributo ${adapOrMiti === 'adaptation' ? "all'adattamento climatico" : 'alla mitigazione climatica'}`}</span>
               </Accordion.Header>
               <Accordion.Body>
                  <Accordion alwaysOpen>
                     <Accordion.Item eventKey='0' className='accordionBorder'>
                        <Accordion.Header className='expanded-sub-accordion'>Descrizione</Accordion.Header>
                        <Accordion.Body>
                           <p>{dnshData.description}</p>
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey='1' className='accordionBorder'>
                        <Accordion.Header className='expanded-sub-accordion'>
                           Criteri contributivi sostanziali
                        </Accordion.Header>
                        <Accordion.Body>
                           <p>{dnshData.substantialContributionCriteria}</p>
                        </Accordion.Body>
                     </Accordion.Item>
                     <Accordion.Item eventKey='2' className='accordionBorder'>
                        <Accordion.Header className='expanded-sub-accordion'>
                           Criteri DNSH (nessun danno significativo)
                        </Accordion.Header>
                        <Accordion.Body>
                           <Accordion alwaysOpen>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                 <Accordion.Header className='expanded-sub-accordion'>
                                    {adapOrMiti === 'adaptation' ? 'Adattamento climatico' : 'Mitigazione climatica'}
                                 </Accordion.Header>
                                 <Accordion.Body>
                                    <p>
                                       {adapOrMiti === 'adaptation'
                                          ? dnshData.dnshOnClimateMitigation
                                          : dnshData.dnshOnClimateAdaptation}
                                    </p>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='1' className='accordionBorder'>
                                 <Accordion.Header className='expanded-sub-accordion'>Acqua</Accordion.Header>
                                 <Accordion.Body>
                                    <p>{dnshData.dnshOnWater}</p>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='2' className='accordionBorder'>
                                 <Accordion.Header className='expanded-sub-accordion'>
                                    Economia circolare
                                 </Accordion.Header>
                                 <Accordion.Body>
                                    <p>{dnshData.dnshOnCircularEconomy}</p>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='3' className='accordionBorder'>
                                 <Accordion.Header className='expanded-sub-accordion'>
                                    Prevenzione dell'inquinamento
                                 </Accordion.Header>
                                 <Accordion.Body>
                                    <p>{dnshData.dnshOnPollutionPrevention}</p>
                                 </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='4' className='accordionBorder'>
                                 <Accordion.Header className='expanded-sub-accordion'>
                                    Biodiversità
                                 </Accordion.Header>
                                 <Accordion.Body>
                                    <p>{dnshData.dnshOnBiodiversity}</p>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>
                        </Accordion.Body>
                     </Accordion.Item>
                  </Accordion>
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </>
   );
}
export default DnshAccordion;
