import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { formatToDecimals } from '@lib/helpers/formatting';
//import { propTrans, propTransTooltip } from '@lib/i18n';

// const colors = [
//   '#C2C923',
//   '#08A2AA',
//   '#CB1B4A',
//   '#FFB317',
//   '#053D58',
//   '#378288',
//   '#5BCFD4',
//   '#D4AFCD',
//   '#F5D0C5',
//   '#D69F7E',
// ];

// const colorbrewer = (length) => colors.slice(0, length);

const AnimatedDonutChartDash = ({
   chartId,
   data,
   valueInDonut = true,
   showTooltip = false,
   labelOnHover = false,
   totalName,
   code,
   valueInCircle = null,
}) => {
   // eslint-disable-next-line react-hooks/exhaustive-deps
   const plotData = [];
   let textInCircle = '';

   if (valueInCircle === null) {

      valueInCircle =
         data.filter((el) => el.propertyName === totalName).length > 0
            ? formatToDecimals(data.filter((el) => el.propertyName === totalName)?.[0].propertyValue)
            : formatToDecimals(0);
   } else {
      valueInCircle = formatToDecimals(valueInCircle, 0, 0);
   }

   switch (code) {
      case 'avanzamentoGlobal':
         textInCircle = 'Spesa effettuata';
         break;
      case 'avanzamentoProject':
         textInCircle = 'Spesa effettuata';
         break;
      case 'controllo':
         textInCircle = 'Spesa eleggibile';
         break;
      default:
         break;
   }

   if (data.length === 0 || data.every((num) => Number(num.propertyValue) === 0)) {
      plotData.push(1);
      data = [{ propertyName: 'empty', propertyValue: 1 }];
   } else {
      //if (chartId === 'investment') { data.total = data.CAPEX };
      if (data.filter((el) => el.propertyName === totalName).length === 0) {
         data.push({
            propertyName: totalName,
            propertyValue: data.reduce((tot, elem) => {
               return tot + Number(elem.realValue);
            }, 0),
         });
      }
      data.forEach((el) => {
         if (el.propertyName !== totalName) {
            plotData.push(el.propertyValue);
         }
      });
   }

   // if (Array.isArray(data)) data = data.filter((el) => el.propertyValue !== 0);

   useEffect(() => {
      // let colors =
      //   data.filter((el) => el.propertyName === 'empty').length > 0
      //     ? ['#e5e5e5']
      //     : colorbrewer(plotData.length);

      let sizes = {
         innerRadius: 100,
         outerRadius: 118,
         // outerRadius: 100,
      };

      let durations = {
         entryAnimation: 2000,
      };

      d3.select(`#${chartId}`).html('');

      let generator = d3.pie().padAngle(0.04).sort(null);

      let chart = generator(plotData);

      let arcs = d3
         .select(`#${chartId}`)
         .append('g')
         .attr('transform', 'translate(100, 100)')
         .selectAll('path')
         .data(chart)
         .enter()
         .append('path')
         .attr('class', 'arcs')
         .style('fill', (d, i) => {
            const dataFiltered = data.filter((el) => el.propertyName !== totalName);
            return dataFiltered[i].color ? dataFiltered[i].color : '#c0c0c080';
         })
         .attr('data-value', (d, i) => {
            const dataFiltered = data.filter((el) => el.propertyName !== totalName);
            const value = dataFiltered[i].realValue;

            return value;
         })
         .attr('data-label', (d, i) => {
            // return d.data
            const dataFiltered = data.filter((el) => el.propertyName !== totalName);
            const label = dataFiltered[i].translation;

            return label;
         });

      const parseHTML = (html) => {
         const parser = new DOMParser();
         const parsedDocument = parser.parseFromString(html, 'text/html');
         const textContent = parsedDocument.body.textContent;
         console.log('parsedDocument', parsedDocument.body);
         return textContent;
      };

      let g = d3.select(`#${chartId}`).selectAll('g');

      g.append('text')
         .attr('text-anchor', 'middle')
         .attr('dominant-baseline', 'central')
         .attr('font-size', '3rem')
         .attr('y', data.filter((el) => el.propertyName === 'empty').length > 0 ? '-1em' : '-0.4em')
         .attr('x', '5')
         .attr('font-weight', '700')
         .attr('dy', data.filter((el) => el.propertyName === 'empty').length > 0 ? '1em' : '0em')
         .text(valueInDonut ? `${isNaN(valueInCircle) ? 0 : valueInCircle}%` : null); //number in circle
      //.call(wrap, 50)

      if (valueInDonut && textInCircle &&  data.filter((el) => el.propertyName === 'empty').length === 0) {
         g.append('foreignObject')
            .attr('width', '8rem')
            .attr('text-anchor', 'middle')
            .attr('height', 70)
            .attr('y', '0em')
            .attr('x', -65)
            .append('xhtml:div')
            .style('text-align', 'center')
            .style('padding-top', '0.5rem')
            .style('font-size', '1.3rem')
            .style('font-weight', '600')
            //.style('white-space', 'nowrap')
            /* .html(
               valueInDonut ? `<text>${textInCircle}</text>` : null
            ) */
            .html(valueInDonut ? 
               `<text>${textInCircle}</text>${showTooltip ? '&nbsp;<svg id="info-tooltip" width="14" height="14" viewBox="0 0 14 14" fill="none" style="vertical-align: inherit" xmlns="http://www.w3.org/2000/svg"><path d="M7.00036 0C3.13379 0 3.79829e-08 3.1338 3.79829e-08 6.99964C-9.57303e-05 7.91896 0.180909 8.82931 0.532676 9.67867C0.884443 10.528 1.40008 11.2998 2.05015 11.9499C2.70021 12.5999 3.47196 13.1156 4.32133 13.4673C5.17069 13.8191 6.08104 14.0001 7.00036 14C10.8655 14 14 10.8662 14 6.99964C14 3.1338 10.8655 0 7.00036 0ZM7.65366 2.52716C8.33613 2.52716 8.53664 2.92308 8.53664 3.37587C8.53664 3.94094 8.08458 4.46373 7.31243 4.46373C6.66642 4.46373 6.35873 4.13926 6.37769 3.6019C6.37769 3.14911 6.75611 2.52716 7.65366 2.52716ZM5.90521 11.1921C5.43857 11.1921 5.09807 10.9085 5.42399 9.66533L5.95844 7.45972C6.05104 7.10682 6.06635 6.96537 5.95844 6.96537C5.81918 6.96537 5.21327 7.2089 4.856 7.44951L4.6234 7.06817C5.7572 6.12104 7.06088 5.56544 7.61866 5.56544C8.08531 5.56544 8.16259 6.11666 7.93 6.96537L7.31754 9.284C7.2089 9.69377 7.25556 9.83522 7.3642 9.83522C7.50419 9.83522 7.96209 9.66606 8.41269 9.31097L8.67663 9.66533C7.57346 10.7678 6.37113 11.1921 5.90521 11.1921Z" fill="#C4C4C4"/></svg>' : ''}`
               : null
            );
         //i icon in circle
      }

      let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());

      let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
      let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

      let arc = d3.arc();

      arcs
         .transition()
         .duration(durations.entryAnimation)
         .attrTween('d', (d) => {
            let originalEnd = d.endAngle;
            return (t) => {
               let currentAngle = angleInterpolation(t);
               if (currentAngle < d.startAngle) {
                  return '';
               }

               d.endAngle = Math.min(currentAngle, originalEnd);

               return arc(d);
            };
         });

      d3.select(`#${chartId}`)
         .transition()
         .duration(durations.entryAnimation)
         .tween('arcRadii', () => {
            return (t) =>
               arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
         })
         .on('end', () => {
            //console.log(InvestmentChartIcon)
            //d3.select(`#${chartId}`).node().append()
         });

      const tooltip = d3
         .select('body')
         .append('div')
         .attr('class', 'svg-tooltip')
         .style('position', 'absolute')
         .style('visibility', 'hidden');

      const mouseEnterCallback = (d) => {
         // console.log('d.target.attributes', d.target.attributes);
         d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

         tooltip.style('visibility', 'visible').text(
            data.filter((el) => el.propertyName === 'empty').length > 0
               ? textInCircle
               : `${!valueInDonut || labelOnHover ? d.target.attributes['data-label'].value : ''
               } - ${formatToDecimals(+d.target.attributes['data-value'].value)} € 
            `
         );
      };

      const mouseLeaveCallback = (d) => {
         d3.select(d.target).attr('stroke-width', '0');
         tooltip.style('visibility', 'hidden');
      };

      d3.select(`#${chartId}`)
         .selectAll('.arcs')
         .on('mouseenter', mouseEnterCallback)
         .on('mousemove', (event) => {
            tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
         })
         .on('mouseleave', mouseLeaveCallback);

      //tooltip info icon
      const tooltipInfo = d3
         .select('body')
         .append('div')
         .attr('class', 'svg-tooltip')
         .style('position', 'absolute')
         .style('visibility', 'hidden');

      const mouseEnterCallbackInfo = (d) => {
         d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

         tooltipInfo.style('visibility', 'visible').text(parseHTML(textInCircle)); //text tooltip
         // .text(parseHTML(`${data.filter((el) => el.propertyName === totalName)?.[0].translation}`)); //text tooltip
         //OLD propTransTooltip({ prop: codeLangTag })
      };

      const mouseLeaveCallbackInfo = (d) => {
         d3.select(d.target).attr('stroke-width', '0');
         tooltipInfo.style('visibility', 'hidden');
      };

      d3.select(`#${chartId}`)
         .select('#info-tooltip')
         .on('mouseenter', mouseEnterCallbackInfo)
         .on('mousemove', (event) => {
            tooltipInfo.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
         })
         .on('mouseleave', mouseLeaveCallbackInfo);
   }, [chartId, data, plotData, valueInCircle, valueInDonut, totalName, labelOnHover, textInCircle, showTooltip]);

   return (
      <div>
         {data.length && (
            <svg id={chartId} preserveAspectRatio='xMinYMin meet' viewBox='-25 -25 250 250'></svg>
         )}
      </div>
   );
};
export default AnimatedDonutChartDash;
