import React, {useEffect} from "react";
import Flourish from "@flourish/live-api"

/* const test = [
   ["Period","Cumulato","Avanzamento","Incassato"],
   ["06/2020",6,0,6],
   ["07/2020",7,0,7],
   ["11/2021",0,-2,2],
   ["09/2022",-1,-5,4],
   ["11/2022",-5,-5,0],
   ["12/2023",4,0,4],
   ["03/2024",2,-3,5],
   ["04/2024",-2,-2,0],
   ["11/2024",3,0,3],
]; */

const LinesColumnsChart = ({ data = [] }) => {

   useEffect(() => {
      let tempConfig = {
         base_visualisation_id: "16378389", // ID of chart
         version: "27.10.1",
         api_key: "-Djdpl96Ne3iVl38YMO6NL1Kq_loiaBZ0bycoGhajplHwe0SiFK0gDfQmu8nYuW6",
         container: "#lines-columns-chart", // Selector for target DOM element
         state: {
            axes_highlights: {
               x_enabled: false
            },
            chart_bg: {
               background_color_enabled: false,
               background_image_enabled: false
            },
            chart_type: "column_stacked_line",
            color: {
               categorical_custom_palette: "Cumulato: #FFB317\nAvanzamento: #08A2AA\nIncassato: #C2C923",
               categorical_extend: true,
               categorical_palette: [
                  "#08A2AA",
                  "#C2C923",
                  "#CB1B4A",
                  "#ff8800",
                  "#ffc502",
                  "#007d8e",
                  "#1aa7ee",
                  "#29dae4",
                  "#88e99a",
                  "#019c00",
                  "#c11f1f",
                  "#730000"
               ]
            },
            color_mode: "column",
            column_opacity: 0.9,
            column_padding_inner: 10,
            column_padding_stack: 1,
            dual_axis: false,
            facet_aspect: 1.9,
            facet_aspect_breakpoint: 500,
            facet_aspect_mobile: 1.6,
            facet_layout: "single",
            height_mode: "auto",
            labels: false,
            labels_color_mode: "auto",
            labels_hide_overlaps: false,
            layout: {
               background_color_enabled: false,
               body_font: {
                  name: "Roboto Condensed",
                  url: "https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700"
               },
               border: {
                  enabled: false
               },
               header_logo_enabled: false,
               layout_order: "stack-default",
               max_width_align: "center",
               max_width_target: "none",
               space_between_sections: "1",
               space_between_sections_custom: 10
            },
            legend_categorical: {
               max_width: 100,
               order_override: "Cumulato\nIncassato\nAvanzamento",
               swatch_width: 0.8
            },
            legend_filter_mode: "none",
            legend_mode: "auto",
            line_dash_items: "",
            line_dash_width: 5,
            line_interpolate: true,
            line_opacity: 1,
            line_width: 0.2,
            number_format: {
               advanced: true,
               negative_sign: "-$nk",
               strip_zeros: true,
               suffix: " €",
               transform: "multiply",
               transform_labels: false
            },
            popup: {
               show_popup_styles: false
            },
            popup_series_text: "Avanzamento :: Comment about Period",
            secondary_series: 1,
            shade_between_lines: false,
            show_series_text: false,
            stack_labels: false,
            x: {
               axis_position: "bottom",
               gridlines_visible: false,
               title: "",
               title_mode: "custom",
               title_styling: false
            },
            y: {
               axis_visible: true,
               gridline_category_dividers: false,
               gridlines_styling: false,
               gridlines_visible: true,
               numeric_scale_type: "linear",
               tick_label_position: "default"
            },
            y2: {
               axis_visible: false,
               numeric_scale_type: "linear"
            }
         },
         bindings: {
            data: {
               label: 0,
               metadata: [],
               value: [
                  1,
                  2,
                  3
               ]
            }
         },
         data: {
            data: data,
         },
         metadata: {
            data: {
               0: {
                  type_id: "datetime$%m/%Y",
                  type: "datetime",
                  output_format_id: "datetime$%b %Y"
               },
               1: {
                  type_id: "number$none_point",
                  type: "number",
                  output_format_id: "number$point_comma"
               },
               2: {
                  type_id: "number$none_point",
                  type: "number",
                  output_format_id: "number$point_comma"
               },
               3: {
                  type_id: "number$comma_point",
                  type: "number"
               }
            }
         }
      };

      const parent = document.getElementById("lines-columns-chart");

      const child = parent.querySelector('iframe');
      if (child != null) child.remove();
   
      new Flourish.Live(tempConfig);
   }, [data]);

   return (<>
      <script src="https://cdn.flourish.rocks/flourish-live-v4.0.0.min.js"></script>
      <div id="lines-columns-chart"></div>
   </>
   );
};
export default LinesColumnsChart;