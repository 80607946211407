import React, { useState } from "react";

const OEIframePreview = ({ blob, mimeType = "application/json" }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  if (!blob) {
    return <div>Caricamento...</div>;
  }

  if (blob.type === "application/pkcs7-mime") {
    blob = new Blob([blob], { type: mimeType });
  }

  const blobUrl = URL.createObjectURL(blob);
  return (
    <div className="h-100">
      {!isLoaded && <div>Loading...</div>}
      <iframe
        title="preview"
        src={blobUrl}
        onLoad={handleLoad}
        style={{
          display: isLoaded ? "block" : "none",
          width: "100%",
          //height: "100%",
          minHeight: '65vh',
          position: 'sticky',
          top: 0,
        }}
      />
    </div>
  );
};

export default OEIframePreview;
