import React from 'react';
import { ProjectsList } from '@components/projects';

const Projects = () => {
  return (
    <div id="progetti" className="megaContent">
      <div className="container-fluid">
        <ProjectsList />
      </div>
    </div>
  );
};

export default Projects;
