import { createContext } from "react";

export const LevelContext = createContext({
   userLanguage: localStorage.getItem('userLanguage') ?? 'IT',
   setUserLanguage: () => {},
   translations: [],
   setTranslations: () => {},
   userInfo: {},
   setUserInfo: () => {},
   devMode: localStorage.getItem('devMode') === 'true' ?? false,
   setDevMode: () => {},
   isOe: Number(localStorage.getItem('token')?.split('|')[3]) === 1 ? true : false,
   setIsOe: () => {}, //da eliminare
   userRole: Number(localStorage.getItem('token')?.split('|')[2] ?? 70),
   setUserRole: () => {}, //da eliminare
   setEmailForPasswordReset: (email) => {
      if (email === "") {
         localStorage.removeItem("emailForPasswordReset");
      } else {
         localStorage.setItem("emailForPasswordReset", email);
      }
   },
   emailForPasswordReset: localStorage.getItem("emailForPasswordReset") ?? "",
});