import { useState, useEffect, useCallback } from "react";
//import useDebounce from "@components/customHooks/useDebounce";
import AnimatedDonutChartCard from "components/charts/AnimatedDonutChartCard";
import { Row, Col, Spinner } from "react-bootstrap";
import { getProjectStats } from "@lib/api";
import StatusBar from "@components/StatusBar";
import AnimatedHistogramChartCard from "./AnimatedHistogramChartCard";

const DashboardWheelsSection = ({
  projectId,
  global,
  setSummaryWheelsValues,
  userRole,
}) => {
  const [projectStats, setProjectStats] = useState({});
  const [wheelsValues, setWheelsValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [percentageFirstDonut, setPercentageFirstDonut] = useState(0);
  const [percentageSecondDonut, setPercentageSecondDonut] = useState(0);
  const [, /* sbShow */ setSbShow] = useState(<></>);

  const handleStatusBar = useCallback((status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  }, []);

  /* useDebounce(
  /* useDebounce(
    () => {
      fetchData();
    },
    500,
    [projectId, global]
  ); */

  useEffect(() => {
   if (projectId !== undefined || global === true) {
     fetchData();
   }
 }, [projectId, global]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        const apiProjectId = global === true ? "" : projectId;
        const retrievedStats = await getProjectStats(apiProjectId);
        if (!retrievedStats.success) {
          let message = retrievedStats.message.length
            ? retrievedStats.message
            : "Si è verificato un errore durante il caricamento delle informazioni.";
          throw new Error(message);
        }
        setProjectStats(retrievedStats.data);
      } catch (error) {
        handleStatusBar("error", error.message, false);
      } finally {
        setIsLoading(false);
      }
    },
    [global, handleStatusBar, projectId]
  );

  // function IsNot0(element, index, array) {
  //   return element.propertyValue === 0;
  // }

  function histogramDataFilter(data) {
    const filteredData = {};

    data.forEach((item) => {
      if (
        item.propertyName === "reportedAmount" ||
        item.propertyName === "cashedIn"
      ) {
        filteredData[item.propertyName] = item.realValue;
      }
    });

    return filteredData;
  }

  const objectToArray = useCallback(
    (obj, setState) => {
      const translatedPropertyNames = {
        totalAmount: { name: "Totale investimento", color: "", position: 1 },
        finalAmount: {
          name: "Spesa effettuata",
          color: "#C2C923",
          position: 3,
        },
        quotaAmount: {
          name: "Importo della Quota",
          color: "#08A2AA",
          position: 2,
        },
        //
        totalApprovedAmount: {
          name: "Sovvenzione pubblica",
          color: "#C2C923",
          position: 4,
        },
        selfFinancedAmount: {
          name: "Mezzi propri",
          color: "#08A2AA",
          position: 5,
        },
        //
        reportedAmount: { name: "Rendicontato", color: "#C2C923", position: 6 },
        reportableNotReportedAmount: {
          name: "Rendicontabile",
          color: "#C2C923",
          position: 7,
        },
        notReportableNotReportedAmount: {
          name: "In lavorazione",
          color: "#FFB317",
          position: 8,
        },
        notSpent: {
          name: "Spesa da effettuare",
          color: "#08A2AA",
          position: 9,
        },
        //
        cashedIn: { name: "Incassato", color: "#08A2AA", position: 10 },
        notCashedIn: { name: "Da incassare", color: "#666666", position: 11 },
        compliantAmount: { name: "Eleggibile", color: "#C2C923", position: 12 },
        inProgressAmount: {
          name: "In lavorazione",
          color: "#08A2AA",
          position: 13,
        },
        notCompliantAmount: {
          name: "Non eleggibile",
          color: "#666666",
          position: 14,
        },

        empty: { name: "", color: "", position: 15 },
      };

      const result = Object.keys(obj).map((key) => {
        let total;

        switch (key) {
          case "finalAmount":
          case "notSpent":
            total = obj.totalAmount;
            break;
          case "compliantAmount":
          case "notCompliantAmount":
          case "inProgressAmount":
            total = obj.finalAmount;
            break;
          default:
            total = obj.totalAmount;
            break;
        }

        const percentageTotalValue = total / 100;

        let propertyValue =
          obj[key] < percentageTotalValue && obj[key] !== 0
            ? obj[key] + percentageTotalValue
            : obj[key];

        return {
          propertyName: key,
          propertyValue: propertyValue,
          realValue: obj[key],
          translation: translatedPropertyNames[key]?.name,
          color: translatedPropertyNames[key]?.color,
          position: translatedPropertyNames[key]?.position,
        };
      });

      const notCashedIn =
        obj.reportedAmount < obj.cashedIn
          ? 0
          : obj.reportedAmount - obj.cashedIn;

      result.push({
        propertyName: "notCashedIn",
        propertyValue: notCashedIn,
        realValue: notCashedIn,
        translation: translatedPropertyNames.notCashedIn.name,
        color: translatedPropertyNames.notCashedIn.color,
        position: translatedPropertyNames.notCashedIn.position,
      });

      const notSpent = obj.totalAmount - obj.finalAmount;

      result.push({
        propertyName: "notSpent",
        propertyValue: notSpent,
        realValue: notSpent,
        translation: translatedPropertyNames.notSpent.name,
        color: translatedPropertyNames.notSpent.color,
        position: translatedPropertyNames.notSpent.position,
      });

      const empty = "";

      result.push({
        propertyName: "empty",
        propertyValue: empty,
        realValue: empty,
        translation: translatedPropertyNames.empty.name,
        color: translatedPropertyNames.empty.color,
        position: translatedPropertyNames.empty.position,
      });

      //console.log('obj', obj);
      setPercentageFirstDonut(
        isNaN((obj.finalAmount * 100) / obj.totalAmount)
          ? 0
          : (obj.finalAmount * 100) / obj.totalAmount
      );
      setPercentageSecondDonut(
        isNaN((obj.compliantAmount * 100) / obj.finalAmount)
          ? 0
          : (obj.compliantAmount * 100) / obj.finalAmount
      );

      setState(result);
      if (setSummaryWheelsValues) {
        setSummaryWheelsValues(result);
      }
    },
    [setSummaryWheelsValues]
  );

  useEffect(() => {
    if (Object.keys(projectStats).length) {
      objectToArray(projectStats, setWheelsValues);
    }
  }, [projectStats, objectToArray]);

  function filterArrayByValues(arr, targetValues, withNull = true) {
    const test = arr
      .filter((item) => {
        const isTarget = targetValues.includes(item.propertyName);
        const isNonZero = item.propertyValue !== 0;
        return withNull ? isTarget && isNonZero : isTarget;
      })
      .sort((a, b) => a.position - b.position);

    return test;
  }

  if (userRole >= 70) return;
  return (
    <>
      <Row
        className="flex-wrap justify-content-around"
        style={{ columnGap: "1%" }}
      >
        <Col>
          <div
            className={`simple-box scenaritab ${
              global ? "card-chart" : "card-chart-project"
            } ${isLoading ? "" : "minheight"}`}
          >
            {!isLoading && wheelsValues.length ? (
              <AnimatedDonutChartCard
                chartId="dashDon_0"
                donutData={
                  wheelsValues &&
                  filterArrayByValues(wheelsValues, ["finalAmount", "notSpent"])
                }
                cardData={filterArrayByValues(
                  wheelsValues,
                  ["finalAmount", "notSpent", "empty"],
                  false
                )}
                labelOnHover={true}
                totalName="totalAmount"
                title={"Avanzamento (€)"}
                code={global ? "avanzamentoGlobal" : "avanzamentoProject"}
                valueInCircle={percentageFirstDonut}
              />
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status" variant="dark">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div
            className={`simple-box scenaritab card-chart ${
              isLoading ? "" : "minheight"
            }`}
          >
            {!isLoading && wheelsValues.length ? (
              <AnimatedDonutChartCard
                chartId={"dashDon_1"}
                donutData={filterArrayByValues(wheelsValues, [
                  "compliantAmount",
                  "notCompliantAmount",
                  "inProgressAmount",
                ])}
                cardData={filterArrayByValues(
                  wheelsValues,
                  ["compliantAmount", "notCompliantAmount", "inProgressAmount"],
                  false
                )}
                labelOnHover={true}
                shouldDifference={true}
                totalName="totalAmount"
                title={"Controllo (€)"}
                code={"controllo"}
                valueInCircle={percentageSecondDonut}
              />
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status" variant="dark">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div
            className={`simple-box scenaritab card-chart ${
              isLoading ? "" : "minheight"
            }`}
          >
            {!isLoading && wheelsValues.length ? (
              <>
                {/* <AnimatedDonutChartCard
                           chartId='dashDon_2'
                           donutData={filterArrayByValues(wheelsValues, [
                              'totalApprovedAmount',
                              'cashedIn',
                              'notCashedIn',
                           ])}
                           cardData={filterArrayByValues(
                              wheelsValues,
                              [
                                 // 'totalApprovedAmount',
                                 'cashedIn',
                                 'notCashedIn',
                              ],
                              false
                           )}
                           labelOnHover={true}
                           shouldDifference={true}
                           totalName='totalApprovedAmount'
                           title={'Incasso'}
                        /> */}

                <AnimatedHistogramChartCard
                  title={"Rendicontazione (€)"}
                  data={histogramDataFilter(wheelsValues)}
                  cardData={filterArrayByValues(
                    wheelsValues,
                    ["reportedAmount", "cashedIn", "notCashedIn"],
                    false
                  )}
                  totalName="reportedAmount"
                />
              </>
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status" variant="dark">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashboardWheelsSection;
