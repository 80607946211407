import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Tab, Tabs, Accordion, Spinner } from 'react-bootstrap';
import {
   getDnshCompassClimateMitigation,
   getDnshCompassClimateAdaptation,
   getDnshChecklists,
} from '@lib/api';
import CheckListDnsh from '@components/checklist/checkListDnsh';
import StatusBar from '@components/StatusBar';
import DnshAccordion from '@components/dnshAccordionComponent';
import UploadDocument from '@components/form/upload/uploadDocuments';

const convertTitle = (inputString) => {
   const match = inputString.match(/(\d+)$/);
   const number = match ? match[0] : '';

   const newTitle = `Scheda Tecnica ${number}`;

   return newTitle;
};

const Dnsh = ({ project }) => {
   const [sbShow, setSbShow] = useState(<></>);
   const [queryParams, setQueryParams] = useState({});
   const [mitigationData, setMitigationData] = useState([]);
   const [adaptationData, setAdaptationData] = useState([]);
   const [checklists, setChecklists] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   //console.log('project', project);
   const fetchData = useCallback(
      async (getUrlFunc, queryString, setState, setLoadingState, setErrorShow) => {
         try {
            setErrorShow(<></>);
            const response = await getUrlFunc(queryString);

            if (!response.success) {
               let message = response.message.length
                  ? response.message
                  : 'Si è verificato un errore durante il download delle informazioni.';
               throw new Error(message);
            }
            setState(response.data);
         } catch (error) {
            handleStatusBar('error', error.message, true);
         }
         setLoadingState(false);
      },
      []
   );

   const createQueryString = (activity, activityNumber, sector) => {
      /* if (activity && activityNumber && sector) {
         return `sector=${sector}&activity=${activity}&activityNumber=${activityNumber}`;
      } */
      if (activityNumber) {
         return `activityNumber=${activityNumber}`;
      }
      return '';
   };

   useEffect(() => {
      if (project.id) {
         const {
            dnshMitigationActivity,
            dnshMitigationActivityNumber,
            dnshMitigationSector,
            dnshAdaptationActivity,
            dnshAdaptationActivityNumber,
            dnshAdaptationSector,
         } = project;
         setQueryParams({
            mitigationQuery: createQueryString(
               dnshMitigationActivity,
               dnshMitigationActivityNumber,
               dnshMitigationSector
            ),
            adaptationQuery: createQueryString(
               dnshAdaptationActivity,
               dnshAdaptationActivityNumber,
               dnshAdaptationSector
            ),
         });
      }
   }, [project]);

   useEffect(() => {
      const fetchDataForQuery = (apiFunction, query, setData) => {
         if (project.id && query) {
            fetchData(apiFunction, query, setData, setIsLoading, setSbShow);
         }
      };

      fetchDataForQuery(
         getDnshCompassClimateMitigation,
         queryParams.mitigationQuery,
         setMitigationData
      );
      fetchDataForQuery(
         getDnshCompassClimateAdaptation,
         queryParams.adaptationQuery,
         setAdaptationData
      );
   }, [project.id, queryParams.mitigationQuery, queryParams.adaptationQuery, fetchData]);

   useEffect(() => {
      if (project.id) {
         if (
            (project.dnshClimateAdaptation && project.dnshAdaptationActivityNumber) ||
            (project.dnshClimateMitigation && project.dnshMitigationActivityNumber)
         ) {
            fetchData(getDnshChecklists, project.id, setChecklists, setIsLoading, setSbShow);
         } else {
            setIsLoading(false);
         }
      }
   }, [
      project.id,
      project.dnshClimateAdaptation,
      project.dnshClimateMitigation,
      fetchData,
      project.dnshAdaptationActivityNumber,
      project.dnshMitigationActivityNumber,
   ]);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   return (
      <>
         {sbShow}
         <div className='candidacy simple-box scenaritab'>
            <Row>
               {isLoading ? (
                  <Row>
                     <Col className='d-flex justify-content-center align-items-center '>
                        <Spinner animation='border' role='status' variant='dark'>
                           <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                     </Col>
                  </Row>
               ) : (!project.dnshClimateAdaptation && !project.dnshClimateMitigation) ||
                  (!mitigationData.length && !adaptationData.length) ? (
                  <Row className='text-center my-5'>
                     <span>Non sono presenti elementi disponibili per la visualizzazione del DNSH</span>
                  </Row>
               ) : (
                  <Tabs variant='pills' defaultActiveKey='infoDnsh' id='uncontrolled-tab-example'>
                     <Tab eventKey='infoDnsh' title='Info' className="mt-5">
                        {/* <div className='row d-flex align-items-center my-3'>
                  <div className='col-4'>
                    <h5>Trasmissione e distribuzione di energia elettrica</h5>
                  </div>
                </div> */}
                        {project?.dnshClimateMitigation && mitigationData.length && (
                           <DnshAccordion data={mitigationData} adapOrMiti={'mitigation'} />
                        )}
                        {project?.dnshClimateAdaptation && adaptationData.length && (
                           <DnshAccordion data={adaptationData} adapOrMiti={'adaptation'} />
                        )}
                        {/* <Accordion className='my-3' defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header className='expanded-accordion'>
                      Contributo sostanziale alla mitigazione dei cambiamenti climatici
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion alwaysOpen>
                        <Accordion.Item eventKey='0' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Descrizione
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Costruzione e gestione di sistemi di trasmissione che trasportano
                              l'energia elettrica sul sistema interconnesso ad altissima tensione e
                              ad alta tensione.
                            </p>
                            <p>
                              Costruzione e gestione di impianti di distribuzione che trasportano
                              energia elettrica su sistemi di distribuzione ad alta, media e bassa
                              tensione.
                            </p>
                            <p>
                              Le attività economiche di questa categoria potrebbero essere associate
                              a diversi codici NACE , in particolare D35.12 e D35.13 conformemente
                              alla classificazione statistica delle attività economiche stabilita
                              dal regolamento (CE) n. 1893/2006.
                            </p>
                            <p>
                              Un'attività economica in questa categoria è un'attività abilitante ai
                              sensi dell'articolo 10, paragrafo 1, lettera i), del regolamento (UE)
                              2020/852, se soddisfa i criteri di vaglio tecnico di cui alla presente
                              sezione.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='1' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Criteri contributivi sostanziali
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>L'attività soddisfa uno dei seguenti criteri:</p>
                            <ol>
                              <li>
                                L'infrastruttura o l'apparecchiatura di trasmissione e distribuzione
                                si trova in un sistema elettrico che soddisfa almeno uno dei
                                seguenti criteri:
                              </li>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  il sistema è il sistema europeo interconnesso, ovvero le aree di
                                  controllo interconnesse di Stati membri, Norvegia, Svizzera e
                                  Regno Unito, ei suoi sistemi subordinati;
                                </li>
                                <li>
                                  oltre il 67% della capacità di generazione di nuova abilitazione
                                  nel sistema è inferiore al valore soglia di generazione di 100
                                  gCO2e/kWh misurato sulla base del ciclo di vita secondo i criteri
                                  di generazione di energia elettrica, su un periodo mobile di
                                  cinque anni;
                                </li>
                                <li>
                                  il fattore di emissione di rete medio della rete, calcolato come
                                  il totale delle emissioni annue della produzione di energia
                                  elettrica connessa al sistema, diviso per la produzione totale
                                  annua netta di energia elettrica in tale sistema, è inferiore al
                                  valore soglia di 100 gCO2e/kWh misurato sulla base del ciclo di
                                  vita conformemente ai criteri di produzione di energia elettrica,
                                  su un periodo di cinque anni consecutivi;
                                </li>
                              </ol>
                              <p>
                                Un'infrastruttura adibita alla creazione di una connessione diretta
                                o all'ampliamento di una connessione diretta esistente tra una
                                sottostazione o una rete e un impianto di produzione di energia
                                elettrica con un'intensità di gas a effetto serra superiore a 100
                                gCO2e/kWh misurata in base al ciclo di vita non è conforme.
                              </p>
                              <p>
                                L'installazione di infrastrutture di misurazione che non soddisfano
                                i requisiti dei sistemi di misurazione intelligenti di cui
                                all'articolo 20 della direttiva (UE) 2019/944 non è conforme.
                              </p>
                              <li>L'attività rientra in una delle seguenti categorie:</li>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  costruzione e gestione della connessione diretta, o ampliamento
                                  della connessione diretta esistente, di produzione di elettricità
                                  a basse emissioni di carbonio al di sotto della soglia di 100
                                  gCO2e/kWh misurata sulla base del ciclo di vita di una
                                  sottostazione o di una rete;
                                </li>
                                <li>
                                  costruzione e gestione di stazioni di ricarica per veicoli
                                  elettrici (VE) e infrastrutture elettriche di supporto per
                                  l'elettrificazione dei trasporti, subordinatamente al rispetto dei
                                  criteri di vaglio tecnico di cui alla sezione trasporti del
                                  presente allegato;
                                </li>
                                <li>
                                  installazione di trasformatori di trasmissione e distribuzione
                                  conformi ai requisiti di livello 2 (1° luglio 2021) di cui
                                  all'allegato I del regolamento (UE) n. 548/2014 della Commissione
                                  (178)e, per i trasformatori di media potenza con tensione massima
                                  per apparecchiature non superiori a 36 kV, con requisiti di
                                  livello AAA0 sulle perdite a vuoto stabiliti nella norma EN
                                  50588-1 (179).
                                </li>
                                <li>
                                  costruzione/installazione e gestione di apparecchiature e
                                  infrastrutture in cui l'obiettivo principale è un aumento della
                                  produzione o dell'uso di energia elettrica da fonti rinnovabili;
                                </li>
                                <li>
                                  installazione di apparecchiature per aumentare la controllabilità
                                  e l'osservabilità del sistema elettrico e per consentire lo
                                  sviluppo e l'integrazione delle fonti energetiche rinnovabili, tra
                                  cui:
                                </li>
                                <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                  <li>
                                    sensori e strumenti di misura (inclusi i sensori meteorologici
                                    per la previsione della produzione rinnovabile);
                                  </li>
                                  <li>
                                    comunicazione e controllo (compresi software avanzati e sale di
                                    controllo, automazione di sottostazioni o alimentatori e
                                    capacità di controllo della tensione per adattarsi a
                                    un'alimentazione rinnovabile più decentralizzata).
                                  </li>
                                </ol>
                                <li>
                                  installazione di apparecchiature quali, a titolo esemplificativo
                                  ma non esaustivo, i futuri sistemi di misurazione intelligenti o
                                  quelli che sostituiscono i sistemi di misurazione intelligenti in
                                  linea con l'articolo 19, paragrafo 6, della direttiva (UE)
                                  2019/944 del Parlamento europeo e del Consiglio (180), che
                                  soddisfano i requisiti di cui all'articolo 20 della direttiva (UE)
                                  2019/944 , in grado di trasportare informazioni agli utenti per
                                  agire a distanza sui consumi, compresi gli hub dati dei clienti;
                                </li>
                                <li>
                                  costruzione/installazione di apparecchiature per consentire lo
                                  scambio di energia elettrica specificatamente rinnovabile tra gli
                                  utenti;
                                </li>
                                <li>
                                  costruzione e gestione di interconnettori tra i sistemi di
                                  trasmissione, a condizione che uno dei sistemi sia conforme.
                                </li>
                              </ol>
                              <p>
                                Ai fini della presente sezione, si applicano le seguenti specifiche:
                              </p>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  il quinquennio mobile utilizzato per determinare il rispetto delle
                                  soglie si basa su cinque anni storici consecutivi, compreso l'anno
                                  per il quale sono disponibili i dati più recenti;
                                </li>
                                <li>
                                  per "sistema" si intende l'area di controllo della potenza della
                                  rete di trasmissione o distribuzione in cui è installata
                                  l'infrastruttura o l'apparecchiatura;
                                </li>
                                <li>
                                  i sistemi di trasmissione possono comprendere capacità di
                                  generazione connesse a sistemi di distribuzione subordinati;
                                </li>
                                <li>
                                  d. anche i sistemi di distribuzione subordinati a un sistema di
                                  trasmissione considerato in una traiettoria verso la piena
                                  decarbonizzazione possono essere considerati in una traiettoria
                                  verso la piena decarbonizzazione;
                                </li>
                                <li>
                                  per determinare la conformità, è possibile considerare un sistema
                                  che copra più aree di controllo che sono interconnesse e con
                                  scambi energetici significativi tra di loro, nel qual caso viene
                                  utilizzato il fattore di emissione medio ponderato su tutte le
                                  aree di controllo incluse, e singoli sistemi di trasmissione o
                                  distribuzione subordinati all'interno tale sistema non è tenuto a
                                  dimostrare la conformità separatamente;
                                </li>
                                <li>
                                  è possibile che un sistema diventi non conforme dopo essere stato
                                  precedentemente conforme. Nei sistemi che diventano non conformi,
                                  nessuna nuova attività di trasmissione e distribuzione è conforme
                                  da quel momento in poi, fino a quando il sistema non è nuovamente
                                  conforme alla soglia (ad eccezione di quelle attività sempre
                                  conformi, vedi sopra). Le attività nei sistemi subordinati possono
                                  ancora essere conformi, se tali sistemi subordinati soddisfano i
                                  criteri della presente sezione;
                                </li>
                                <li>
                                  un collegamento diretto o un ampliamento di un collegamento
                                  diretto esistente agli impianti di produzione include
                                  l'infrastruttura indispensabile per trasportare l'energia
                                  elettrica associata dall'impianto di produzione ad una
                                  sottostazione o alla rete.
                                </li>
                              </ol>
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Criteri DNSH
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion alwaysOpen>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Adattamento ai cambiamenti climatici
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>L'attività è conforme ai criteri indicati nell'Appendice A.</p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='1' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Acqua
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>N/A</p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='2' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Economia Circolare
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>
                                    Esiste un piano di gestione dei rifiuti che garantisce il
                                    massimo riutilizzo o riciclaggio alla fine del ciclo di vita in
                                    conformità con la gerarchia dei rifiuti, anche attraverso
                                    accordi contrattuali con i partner di gestione dei rifiuti,
                                    riflesso nelle proiezioni finanziarie o nella documentazione
                                    ufficiale del progetto.
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='3' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Prevenzione dell'Inquinamento
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>Linee ad alta tensione in superficie:</p>
                                  <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                    <li>
                                      per le attività di cantiere, le attività seguono i principi
                                      delle Linee guida generali per l'ambiente, la salute e la
                                      sicurezza IFC (181).
                                    </li>
                                    <li>
                                      rispettano le norme e i regolamenti applicabili per limitare
                                      l'impatto delle radiazioni elettromagnetiche sulla salute
                                      umana, anche per le attività svolte nell'Unione, la
                                      raccomandazione del Consiglio sulla limitazione
                                      dell'esposizione del pubblico ai campi elettromagnetici (da 0
                                      Hz a 300 GHz) (182 )e per le attività svolte in paesi terzi,
                                      le linee guida del 1998 della Commissione internazionale per
                                      la protezione dalle radiazioni non ionizzanti (ICNIRP) (183).
                                    </li>
                                  </ol>
                                  <p>Le attività non utilizzano policlorobifenili (PCB).</p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='4' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Biodiversità
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>
                                    L'attività è conforme ai criteri stabiliti nell'Appendice D.
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion className='my-3' defaultActiveKey='0'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header className='expanded-accordion'>
                      Contributo sostanziale all'adattamento ai cambiamenti climatici
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion alwaysOpen>
                        <Accordion.Item eventKey='0' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Descrizione
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              Costruzione e gestione di sistemi di trasmissione che trasportano
                              energia elettrica sul sistema interconnesso ad altissima tensione e ad
                              alta tensione.
                            </p>
                            <p>
                              Costruzione e gestione di impianti di distribuzione che trasportano
                              energia elettrica su sistemi di distribuzione ad alta, media e bassa
                              tensione.
                            </p>
                            <p>
                              Le attività economiche di questa categoria potrebbero essere associate
                              a diversi codici NACE , in particolare D35.12 e D35.13 conformemente
                              alla classificazione statistica delle attività economiche stabilita
                              dal regolamento (CE) n. 1893/2006 .
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='1' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Criteri di contributo sostanziale
                          </Accordion.Header>
                          <Accordion.Body>
                            <ol>
                              <li>
                                L'attività economica ha implementato soluzioni fisiche e non fisiche
                                ("soluzioni di adattamento") che riducono sostanzialmente i più
                                importanti rischi climatici fisici che sono materiali per tale
                                attività.
                              </li>

                              <li className='mt-4'>
                                I rischi climatici fisici che sono rilevanti per l'attività sono
                                stati identificati tra quelli elencati nell'appendice A del presente
                                allegato eseguendo una solida valutazione del rischio climatico e
                                della vulnerabilità con le seguenti fasi:
                              </li>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  screening dell'attività per individuare quali rischi climatici
                                  fisici dall'elenco dell'appendice A del presente allegato possono
                                  influire sull'andamento dell'attività economica durante il suo
                                  ciclo di vita previsto;
                                </li>
                                <li>
                                  se l'attività è ritenuta a rischio da uno o più dei rischi
                                  climatici fisici elencati nell'appendice A del presente allegato,
                                  una valutazione del rischio climatico e della vulnerabilità per
                                  valutare la rilevanza dei rischi climatici fisici sull'attività
                                  economica;
                                </li>
                                <li>
                                  una valutazione delle soluzioni di adattamento che possono ridurre
                                  il rischio climatico fisico identificato.
                                </li>
                              </ol>
                              <p>
                                La valutazione del rischio climatico e della vulnerabilità è
                                proporzionata alla portata dell'attività e alla sua durata prevista,
                                in modo tale che:
                              </p>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  per le attività con una durata di vita prevista inferiore a 10
                                  anni, la valutazione è effettuata, almeno utilizzando proiezioni
                                  climatiche alla scala più piccola appropriata;
                                </li>
                                <li>
                                  per tutte le altre attività, la valutazione viene eseguita
                                  utilizzando la massima risoluzione disponibile, proiezioni
                                  climatiche all'avanguardia in tutta la gamma esistente di scenari
                                  futuri (279)coerenti con la durata prevista dell'attività,
                                  compresi, almeno, scenari di proiezioni climatiche da 10 a 30 anni
                                  per i principali investimenti.
                                </li>
                              </ol>
                              <li className='mb-4'>
                                Le proiezioni climatiche e la valutazione degli impatti si basano
                                sulle migliori pratiche e sugli orientamenti disponibili e tengono
                                conto dello stato dell'arte della scienza per l'analisi della
                                vulnerabilità e del rischio e delle relative metodologie in linea
                                con le più recenti relazioni del Gruppo intergovernativo sui
                                cambiamenti climatici (280), pubblicazioni scientifiche
                                peer-reviewed e open source (281)o modelli paganti.
                              </li>
                              <li>Le soluzioni di adattamento implementate</li>
                              <ol className='my-4' style={{ listStyleType: 'lower-alpha' }}>
                                <li>
                                  non pregiudicare gli sforzi di adattamento o il livello di
                                  resilienza ai rischi fisici del clima di altre persone, della
                                  natura, del patrimonio culturale, dei beni e di altre attività
                                  economiche;
                                </li>
                                <li>
                                  favorire soluzioni basate sulla natura (282)o fare affidamento su
                                  infrastrutture blu o verdi (283)per quanto possibile;
                                </li>
                                <li>
                                  sono coerenti con i piani e le strategie di adattamento locali,
                                  settoriali, regionali o nazionali;
                                </li>
                                <li>
                                  sono monitorati e misurati rispetto a indicatori predefiniti e si
                                  prende in considerazione un'azione correttiva laddove tali
                                  indicatori non siano soddisfatti;
                                </li>
                                <li>
                                  se la soluzione implementata è fisica e consiste in un'attività
                                  per la quale sono stati specificati criteri di vaglio tecnico nel
                                  presente allegato, la soluzione è conforme ai criteri di vaglio
                                  tecnico non nuocere in modo significativo per tale attività.
                                </li>
                              </ol>
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2' className='accordionBorder'>
                          <Accordion.Header className='expanded-sub-accordion'>
                            Criteri DNSH
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion alwaysOpen>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Mitigazione del clima
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>
                                    L'infrastruttura non è dedicata alla creazione di una
                                    connessione diretta o all'espansione di una connessione diretta
                                    esistente a un impianto di produzione di energia elettrica in
                                    cui le emissioni dirette di gas a effetto serra superano i 270
                                    gCO2e/kWh.
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='1' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Acqua
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>N/A</p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='2' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Economia Circolare
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>
                                    Esiste un piano di gestione dei rifiuti che garantisce il
                                    massimo riutilizzo o riciclaggio alla fine del ciclo di vita in
                                    conformità con la gerarchia dei rifiuti, anche attraverso
                                    accordi contrattuali con i partner di gestione dei rifiuti,
                                    riflesso nelle proiezioni finanziarie o nella documentazione
                                    ufficiale del progetto.
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='3' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Prevenzione dell'Inquinamento
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>Linee ad alta tensione in superficie:</p>
                                  <ol>
                                    <li>
                                      per le attività di cantiere, le attività seguono i principi
                                      delle Linee guida generali per l'ambiente, la salute e la
                                      sicurezza dell'International Finance Corporation (IFC) (284).
                                    </li>
                                    <li>
                                      rispettano le norme e i regolamenti applicabili per limitare
                                      l'impatto delle radiazioni elettromagnetiche sulla salute
                                      umana, anche per le attività svolte nell'Unione, la
                                      raccomandazione del Consiglio sulla limitazione
                                      dell'esposizione del pubblico ai campi elettromagnetici (da 0
                                      Hz a 300 GHz) (285 )e per le attività svolte in paesi terzi le
                                      linee guida del 1998 della Commissione internazionale per la
                                      protezione dalle radiazioni non ionizzanti (ICNIRP ) (286).
                                    </li>
                                  </ol>
                                  <p>Le attività non utilizzano policlorobifenili (PCB).</p>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey='4' className='accordionBorder'>
                                <Accordion.Header className='expanded-sub-accordion'>
                                  Biodiversità
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>
                                    L'attività è conforme ai criteri stabiliti nell'Appendice D.
                                  </p>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
                        {(mitigationData.length || adaptationData.length) && (
                           <Accordion className='my-3' defaultActiveKey='0'>
                              <Accordion.Item eventKey='0'>
                                 <Accordion.Header className='expanded-accordion'>
                                    Tutele minime
                                 </Accordion.Header>
                                 <Accordion.Body>
                                    <ol>
                                       <li className='my-4'>
                                          <p>
                                             Le garanzie minime di cui all'articolo 3, lettera (c), sono procedure attuate da 
                                             un'impresa che svolge un'attività economica per garantire l'allineamento con le 
                                             linee guida dell'OCSE per le imprese multinazionali e i principi guida delle 
                                             Nazioni Unite su imprese e diritti umani, compresi i principi e i diritti enunciati 
                                             nelle otto convenzioni fondamentali identificate nella Dichiarazione dell’Organizzazione 
                                             Internazionale del Lavoro sui principi e i diritti fondamentali nel lavoro e nella Carta 
                                             Internazionale dei Diritti Umani.
                                          </p>
                                       </li>
                                       <li>
                                          <p>
                                             Nell’attuazione delle procedure di cui al comma 1 del presente articolo, le imprese si 
                                             attengono al principio del “non arrecare danno significativo” di cui all’articolo 2, 
                                             punto 17, del regolamento (UE) 2019/2088.
                                          </p>
                                       </li>
                                    </ol>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>
                        )}
                     </Tab>
                     
                     {checklists &&
                        checklists?.map((el) => {
                           return el?.numberOfQuestions ? (
                              <Tab
                                 key={el.dnshquestions[0].technicalFile}
                                 eventKey={el.dnshquestions[0].technicalFile}
                                 title={convertTitle(el.dnshquestions[0].technicalFile)}
                                 className="mt-3">
                                 <CheckListDnsh data={el} />
                              </Tab>
                           ) : null;
                        })}

                     <Tab eventKey='documents' title='Relazione DNSH'>
                        <UploadDocument
                           entity='projects'
                           entityId={project.id}
                           filterBySection={['dnsh']}
                        />
                     </Tab>
                  </Tabs>
               )}
            </Row>
         </div>
      </>
   );
};
export default Dnsh;
