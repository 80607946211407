import React, { useState, Suspense, useEffect, useContext } from 'react';
import './styles/main.scss';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CompatRouter } from "react-router-dom-v5-compat";
import { logout } from '@lib/api';
import client from '@lib/graphql';

import { LayoutBase, LayoutSimple, LayoutSideMenu, LayoutNoHeader } from './layouts';
import {
   HomePage,
   Administration,
   Clients,
   NotFoundPage,
   Projects,
   Project,
   Setup,
   Tenders,
   Opportunity,
   Reports,
   Icone,
   Profile,
} from '@pages';

import { Spinner } from 'react-bootstrap';
import { GlobalToastList } from '@components/GlobalToastList';
import { LevelContext } from '@components/contexts/LevelContext';
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { OETrans } from 'components/translation/OETrans';

const theme = createTheme({
   typography: {
      fontFamily: "Barlow"
   },
   components: {
      MuiCssBaseline: {
         styleOverrides: {
            "@font-face": {
               fontFamily: "Barlow",
               //src: `url(${InspirationTTF}) format("truetype")`
            },
            /* body: {
              fontSize: "3rem",
              color: "purple"
            } */
         }
      }
   }
});

const App = () => {
   const { /* isOe,  */userRole, setEmailForPasswordReset, emailForPasswordReset} = useContext(LevelContext);
   const [userLanguage, setUserLanguage] = useState(localStorage.getItem('userLanguage') ?? 'IT');
   const [translations, setTranslations] = useState(
      JSON.parse(localStorage.getItem('translations')) ?? []
   );
   const [userInfo, setUserInfo] = useState({});
   const [isOe, setIsOe] = useState(Number(localStorage.getItem('token')?.split('|')[3]) === 1 ? true : false);
   //const [userRole, setUserRole] = useState(Number(localStorage.getItem('userRole')) ?? 3); // [1,2,3
   const [apiError, setApiError] = useState(false);
   const [devMode, setDevMode] = useState(localStorage.getItem('devMode') === 'true' ? true : false);
   //console.log('userInfo APP:', userInfo);
   //console.log('isOe APP:', isOe);

   useEffect(() => {
      let translations = localStorage.getItem('translations');
      translations = translations ? JSON.parse(translations) : [];

      setTranslations(translations);
   }, []); 

   useEffect(() => {
      localStorage.setItem('devMode', devMode);
   }, [devMode]); // eslint-disable-line react-hooks/exhaustive-deps

   window.addEventListener('storage', function(e) {
      // Check if the userRole has changed and not exists impersonate key
      if (e.storageArea === localStorage && e.key === 'token' && !localStorage.getItem('impersonate')) {
         if (e.oldValue !== e.newValue && e.oldValue !== null) {
            // Go to logout page
            window.location.href = '/logout';
         }
      }
  });

   return (
      <>
         {apiError ? (<div><OETrans code='common.error.backend' fallback={'Errore di connessione col backend'}/></div>) : (
            <Suspense fallback='loading'>
               <ApolloProvider client={client}>
                  <BrowserRouter>
                     <CompatRouter> {/* for react-router-dom v6 compatibility with v5 */}
                        <LevelContext.Provider value={{userInfo, setUserInfo, userLanguage, setUserLanguage, isOe, setIsOe, userRole, translations, setTranslations, devMode, setDevMode, setEmailForPasswordReset, emailForPasswordReset}}>
                           {/* value is mandatory for Context */}

                           <ThemeProvider theme={theme}> {/* for add custom style to Material components */}
                              <ScopedCssBaseline /> {/* for add custom style to Material components only to the children */}
                              <Switch>
                                 <UnauthenticatedRoute
                                    path='/'
                                    exact
                                    component={(props) => <HomePage setApiError={setApiError} />}
                                    layout={LayoutNoHeader} /* {LayoutSimple} */
                                 />

                                 {/* props contiene match,location,history mi serve per capire la rotta esatta e caricare il giusto sottocomponente */}
                                 <AuthenticatedRoute
                                    path='/projects'
                                    exact
                                    component={() => <Projects />}
                                    layout={LayoutSimple}
                                    roles={[1, 2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/project/:projectId/:tab?/:submenu?/:subtab?' // /execution/economic/contracts
                                    component={(props) => <Project {...props} />}
                                    layout={LayoutBase}
                                    roles={[1, 2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/administration'
                                    exact
                                    component={(props) => <Administration {...props} />}
                                    layout={LayoutBase}
                                 //roles={[2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/clients' //?groups
                                    exact
                                    component={(props) => <Clients {...props} />}
                                    layout={LayoutBase}
                                 //roles={[2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/setup'
                                    component={() => <Setup />}
                                    layout={LayoutBase}
                                 //roles={[2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/tenders'
                                    component={() => <Tenders />}
                                    layout={LayoutSideMenu}
                                    //roles={[2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/opportunity'
                                    exact
                                    component={() => <Opportunity />}
                                    layout={LayoutBase}
                                 />

                                 <AuthenticatedRoute
                                    path='/reports'
                                    exact
                                    component={() => <Reports />}
                                    layout={LayoutSimple}
                                    roles={[1, 2, 3]}
                                 />

                                 <AuthenticatedRoute
                                    path='/profile'
                                    exact
                                    component={() => <Profile />}
                                    layout={LayoutSideMenu}
                                 />

                                 <LogoutRoute path='/logout' setIsOe={setIsOe} exact />

                                 <UnauthenticatedRoute
                                    path='/icone'
                                    exact
                                    component={() => <Icone />}
                                    layout={LayoutSimple}
                                 />

                                 <UnauthenticatedRoute component={NotFoundPage} layout={LayoutBase} />
                              </Switch>
                           </ThemeProvider>
                        </LevelContext.Provider>
                     </CompatRouter>
                  </BrowserRouter>
               </ApolloProvider>
            </Suspense>)}
      </>
   );
};

const AuthenticatedRoute = ({ component: Component, layout: Layout, ...rest }) => {
   const [hasRole /* setHasRole */] = useState(true);

   if (hasRole /* && rest.roles.includes(userRole) */) {
      return (
         <Route
            {...rest}
            render={(props) => {
               return (
                  <Layout {...props}>
                     <Component {...props} />
                     <GlobalToastList />
                  </Layout>
               );
            }}
         />
      );
   } else {
      return (
         <div className='App'>
            <div
               className='d-flex aligns-items-center justify-content-center align-items-center'
               style={{ height: '100vh' }}>
               <Spinner animation='border' role='status'>
                  <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
               </Spinner>
            </div>
         </div>
      );
   }
};

const UnauthenticatedRoute = ({ component: Component, layout: Layout, ...rest }) => {
   if (rest.auth === true && rest.location.pathname === '/') {
      return <Redirect to={{ pathname: '/projects', state: { referrer: rest.location.pathname } }} />;
   } else {
      return (
         <Route
            {...rest}
            render={(props) => (
               <Layout {...props}>
                  <Component {...props} />
                  <GlobalToastList />
               </Layout>
            )}
         />
      );
   }
};

const LogoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
   //rest.setIsOe(false); //aggiunge info a LevelContext
   logout();
   return <Redirect to='/' />;
};

export default App;
