import React, { useState, useContext } from "react";
import { forgotPassword, validateOtp, resetPassword } from "lib/api";
import { LoginHero } from "components/atomic/molecules";
import { LevelContext } from "components/contexts/LevelContext";
import { validateOtpCode, validdateEmail } from "utils/validation";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { OtpInput } from "components/atomic/atoms/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Component
const ResetPasswordForm = ({ setShowLogin }) => {
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line

  // Context
  const { setEmailForPasswordReset, emailForPasswordReset } =
    useContext(LevelContext);

  // State management
  const [resetPasswordForm, setResetPasswordForm] = useState({
    email: "",
    otpCode: "",
    password: "",
    passwordConfirmation: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "", title: "" });
  const [showOtp, setShowOtp] = useState(
    emailForPasswordReset !== "" ? true : false
  );
  const [showPass, setShowPass] = useState(false);
  const [successReset, setSuccessReset] = useState(false);

  // Event handlers
  const handleClick = async (e) => {
    const email = showOtp
      ? emailForPasswordReset !== ""
        ? emailForPasswordReset
        : resetPasswordForm.email
      : resetPasswordForm.email;
    if (validdateEmail(email)) {
      setIsLoading(true);
      await forgotPassword(email).then((resp) => {
        if (resp.success) {
          setError({
            show: true,
            message: "Codice OTP inviato con successo",
            title: "Successo",
          });
          setIsLoading(false);
          setResetPasswordForm({ ...resetPasswordForm, email: email });
          setShowOtp(true);
        } else {
          setIsLoading(false);
          let message = "";
          if (resp.data === undefined) {
            message = resp.message;
            setShowOtp(true);
            setEmailForPasswordReset(email);
            setResetPasswordForm({ ...resetPasswordForm, email: email });
          } else {
            // Check if data is an object
            if (typeof resp.data === "object") {
              for (let key in resp.data) {
                message += resp.data[key] + " ";
              }
            }
          }
          setError({
            show: true,
            message: message,
            title: "Errore",
          });
        }
      });
    } else {
      setError({
        show: true,
        message: "Email o password non validi",
        title: "Errore",
      });
    }
  };

  const handleResetPassword = async (e) => {
    if (resetPasswordForm.password === "") {
      setError({
        show: true,
        message: "Inserisci la password",
        title: "Errore",
      });
      return;
    }

    if (resetPasswordForm.passwordConfirmation === "") {
      setError({
        show: true,
        message: "Conferma la password",
        title: "Errore",
      });
      return;
    }

    if (resetPasswordForm.password !== resetPasswordForm.passwordConfirmation) {
      setError({
        show: true,
        message: "Le password non corrispondono",
        title: "Errore",
      });
      return;
    }

    if (!passwordRegex.test(resetPasswordForm.password)) {
      setError({
        show: true,
        message:
          "La password deve contenere almeno 8 caratteri, di cui almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale",
        title: "Errore",
      });
      return;
    }

    setIsLoading(true);
    await resetPassword(
      resetPasswordForm.email,
      resetPasswordForm.password,
      resetPasswordForm.passwordConfirmation,
      resetPasswordForm.otpCode
    ).then((resp) => {
      if (resp.success) {
        setError({
          show: true,
          message: "Password cambiata con successo",
          title: "Successo",
        });
        setIsLoading(false);
        setEmailForPasswordReset("");
        // reset all state
        setResetPasswordForm({
          email: "",
          otpCode: "",
          password: "",
          passwordConfirmation: "",
        });
        setShowOtp(false);
        setShowPass(true);
        setSuccessReset(true);
      } else {
        setIsLoading(false);
        let message = "";
        if (resp.data === undefined) {
          message = resp.message;
        } else {
          // Check if data is an object
          if (typeof resp.data === "object") {
            for (let key in resp.data) {
              message += resp.data[key] + " ";
            }
          }
        }
        setError({
          show: true,
          message: message,
          title: "Errore",
        });
      }
    });
  };

  const handleValidateOtp = async (e) => {
    const email = showOtp
      ? emailForPasswordReset !== ""
        ? emailForPasswordReset
        : resetPasswordForm.email
      : resetPasswordForm.email;

    if (validateOtpCode(resetPasswordForm.otpCode) && validdateEmail(email)) {
      setIsLoading(true);
      await validateOtp(email, resetPasswordForm.otpCode).then((resp) => {
        if (resp.success) {
          setError({
            show: true,
            message: "Codice OTP valido",
            title: "Successo",
          });
          setIsLoading(false);
          setShowPass(true);
          setResetPasswordForm({
            email: email,
            otpCode: resetPasswordForm.otpCode,
          });
        } else {
          setIsLoading(false);
          let message = "";
          if (resp.data === undefined) {
            message = resp.message;
          } else {
            // Check if data is an object
            if (typeof resp.data === "object") {
              for (let key in resp.data) {
                message += resp.data[key] + " ";
              }
            }
          }
          setError({
            show: true,
            message: message,
            title: "Errore",
          });
        }
      });
    } else {
      setError({
        show: true,
        message: "Codice OTP non valido",
        title: "Errore",
      });
    }
  };

  return (
    <Row className="w-100 d-flex justify-content-center m-0">
      <Col
        lg={8}
        className="p-4 d-none d-lg-flex flex-column justify-content-center"
      >
        <LoginHero />
      </Col>
      <Col
        xs={12}
        md={12}
        lg={4}
        className="p-4 d-flex flex-column justify-content-center text-center"
      >
        <Row>
          <Col>
            <Alert
              className="text-start"
              variant={error.title === "Errore" ? "danger" : "success"}
              onClose={() => setError({ show: false })}
              dismissible
              show={error.show}
            >
              <Alert.Heading
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "15.6px",
                  fontFamily: "Barlow",
                }}
              >
                {error.title}
              </Alert.Heading>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "300",
                  lineHeight: "18px",
                  fontFamily: "Barlow",
                }}
              >
                {error.message}
              </p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5
              style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "28.8px",
                fontFamily: "Barlow",
                color: "rgba(8, 162, 170, 1)",
              }}
              className="pb-3"
            >
              {showPass ? (
                successReset ? null : (
                  "Inserisci la nuova password"
                )
              ) : showOtp ? (
                <>
                  <FontAwesomeIcon
                    color="#2C3D68"
                    icon={faCircleArrowLeft}
                    onClick={() => {
                      setShowOtp(false);
                      setEmailForPasswordReset("");
                    }}
                    className="px-2"
                    style={{ cursor: "pointer" }}
                  />
                  Inserisci il codice OTP
                </>
              ) : (
                "Recupera password"
              )}
            </h5>
          </Col>
        </Row>
        {showPass ? (
          <>
            {successReset ? (
              <Row>
                <Col>
                  <Button
                    variant="info"
                    onClick={() => {
                      setShowLogin(true);
                      setEmailForPasswordReset("");
                    }}
                  >
                    Torna al login
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="text-center"
                        type="password"
                        name="password"
                        defaultValue={""}
                        placeholder="Password"
                        onChange={({ target: { value } }) =>
                          setResetPasswordForm({
                            ...resetPasswordForm,
                            password: value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Control
                        className="text-center"
                        type="password"
                        name="passwordConfirmation"
                        defaultValue={""}
                        placeholder="Conferma password"
                        onChange={({ target: { value } }) =>
                          setResetPasswordForm({
                            ...resetPasswordForm,
                            passwordConfirmation: value,
                          })
                        }
                      />
                    </Form.Group>
                    <Button
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResetPassword(e);
                      }}
                      variant="info"
                      type="button"
                    >
                      {isLoading ? "Caricamento..." : "Cambia password"}
                    </Button>
                  </Form>
                </Col>
              </Row>
            )}
          </>
        ) : !showOtp ? (
          <>
            <Row>
              <Col>
                <Form>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="text-center"
                      type="email"
                      name="email"
                      value={resetPasswordForm.email}
                      placeholder="Email"
                      onChange={({ target: { value } }) =>
                        setResetPasswordForm({
                          ...resetPasswordForm,
                          email: value,
                        })
                      }
                    />
                  </Form.Group>
                  <Button
                    disabled={isLoading}
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    variant="info"
                    type="button"
                  >
                    {isLoading ? "Caricamento..." : "Recupera password"}
                  </Button>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "Barlow",
                  }}
                  className="pt-3"
                >
                  Hai già un account?{" "}
                  <Button
                    variant="link"
                    disabled={isLoading}
                    onClick={() => {
                      setShowLogin(true);
                      setEmailForPasswordReset("");
                    }}
                    style={{
                      color: "rgb(8, 162, 170)",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      fontFamily: "Barlow",
                    }}
                  >
                    Accedi
                  </Button>
                </p>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="text-center">
                {/** Testo informativo per inserimento del codice otp */}
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "Barlow",
                  }}
                >
                  Inserisci il codice OTP inviato alla tua email
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="pb-3">
                <Form>
                  <OtpInput
                    onInputChange={(otp) => {
                      setResetPasswordForm({
                        ...resetPasswordForm,
                        otpCode: otp,
                      });
                    }}
                  />
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  disabled={isLoading}
                  onClick={(e) => {
                    handleValidateOtp(e);
                  }}
                  variant="info"
                  type="button"
                >
                  {isLoading ? "Caricamento..." : "Valida codice OTP"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "Barlow",
                  }}
                  className="pt-3"
                >
                  Non hai ricevuto il codice?{" "}
                  <Button
                    variant="link"
                    disabled={isLoading}
                    onClick={(e) => {
                      handleClick(e);
                    }}
                    style={{
                      color: "rgb(8, 162, 170)",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      fontFamily: "Barlow",
                    }}
                  >
                    Richiedi un nuovo codice
                  </Button>
                </p>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default ResetPasswordForm;
