import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Tab, Tabs, Spinner } from 'react-bootstrap';
import { getEntity } from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { formatToDecimals, dateFormatter } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { OETrans } from 'components/translation/OETrans';

const ContractDetailSummaryModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   setModalData,
   spinnerVisible,
   isSummary,
}) => {
   const { userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [detail, setDetail] = useState({});
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   //console.log('detail', detail);

   useEffect(() => {
      if (modalIsVisible && projectId && data.id) {
         getEntity('contracts', projectId, data.id).then((retrievedContract) => {
            if (retrievedContract.success) {
               setDetail(retrievedContract.data);
            }
         });
      }
   }, [data, modalIsVisible, projectId]);

   const handleCloseModal = (setShowModal) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      setSbShow(<></>);
      setModalData([]);
      setShowModal(false);
   };

   return (
      <div>
         {modalIsVisible && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={modalIsVisible}
               onHide={() => handleCloseModal(setModalIsVisible)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Incarico/attingimento {Number(userRole) === 1 || Number(userRole) === 5 ? detail?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill>
                        {/* Anagrafica */}
                        <Tab
                           eventKey='registry'
                           title={'Dati Incarico/attingimento'}
                        >
                           <Row style={{ marginBottom: '40px'}}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nome Fornitore</Form.Label>
                                 <Form.Text as='p'>{detail.supplierName ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Codice Fornitore</Form.Label>
                                 <Form.Text as='p'>{detail.supplierCode ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Lettera D'Incarico</Form.Label>
                                 <Form.Text as='p'>{detail?.po === false ? detail?.number : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px'}}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Attingimento</Form.Label>
                                 <Form.Text as='p'>{detail?.po === true ? detail?.number : 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Data Documento</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.date ? dateFormatter(detail?.date) : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>CUP</Form.Label>
                                 <Form.Text as='p'>{detail?.project?.cup ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px'}}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Procedura</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.frameworkContract?.tender?.tenderNumber ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>CIG</Form.Label>
                                 <Form.Text as='p'> {detail.cigs?.map((cig) => (
                                    <Fragment key={cig.id}>
                                       {cig.cig}
                                       <br />
                                    </Fragment>
                                 )) ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Contratto Quadro</Form.Label>
                                 <Form.Text as='p'> 
                                    {detail?.po === true && detail?.frameworkContract
                                    ? detail?.frameworkContract.number
                                    : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>                             
                           </Row>

                           <Row style={{ marginBottom: '40px'}}>
                              <Form.Group as={Col}>
                                 <Form.Label>Imponibile Consuntivato (€)</Form.Label>
                                 <Form.Text as='p'>{detail?.baseAmount ? formatToDecimals(detail.baseAmount) : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>
                           {sbShow}
                        </Tab>

                        {/* Documenti */}
                        <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                           {detail.id && <UploadDocument entity='contracts' entityId={detail.id} externalBorder={true} />}
                        </Tab>

                        {/* CheckLists */}
                        {userRole < 70 && (
                           <Tab eventKey='checklist' title={'Checklist'}>
                              {detail.id && (
                                 <CheckList
                                    entityType='contracts'
                                    entityId={detail.id}
                                    disabled={detail?.reported}
                                 />
                              )}
                           </Tab>
                        )}
                     </Tabs>
                  )}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
                     Chiudi
                  </Button>
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default ContractDetailSummaryModal;
