import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const OtpInput = ({onInputChange}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value.replace(/[^0-9]/g, ''); // Accetta solo numeri

    setOtp(newOtp);

    onInputChange(newOtp.join(''));

    // Se inserisci un numero, sposta il focus al successivo
    if (e.target.value) {
      focusNext(index);
    }
  };

  const handleKeyDown = (e, index) => {
    // Gestione dei tasti Canc e Delete
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = ''; // Cancella l'input corrente
      setOtp(newOtp);
      onInputChange(newOtp.join(''));
      // Se premi Canc e l'input corrente è vuoto, sposta il focus al precedente
      if (index > 0 && !otp[index]) {
        focusPrevious(index);
      }
    }
  };

  const focusNext = (index) => {
    if (index < 5) {
      const nextInput = document.getElementById(`otpInput-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const focusPrevious = (index) => {
    if (index > 0) {
      const previousInput = document.getElementById(`otpInput-${index - 1}`);
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('').map(char => char.replace(/[^0-9]/g, ''));
    if (pasteData.length === 6 && pasteData.every(char => char)) {
      setOtp(pasteData);
      document.getElementById(`otpInput-5`).focus();
    }
    onInputChange(pasteData.join(''));  
  };

  useEffect(() => {
    document.getElementById('otpInput-0').focus();
  }, []);

  return (
    <div>
      {otp.map((value, index) => (
        <Form.Control
          key={index}
          id={`otpInput-${index}`}
          type="text"
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          maxLength="1"
          style={{ width: '40px', display: 'inline-block', margin: '5px' }}
        />
      ))}
    </div>
  );
};

export default OtpInput;