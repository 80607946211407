import React, { useState, useRef, useEffect, Fragment, useCallback, useContext } from 'react';
import {
   Form,
   Row,
   Col,
   Spinner,
   OverlayTrigger,
   Tooltip,
   Button,
   Tabs,
   Tab,
   ButtonToolbar,
   Modal,
   Badge,
} from 'react-bootstrap';
import StatusBar from '@components/StatusBar';
import {
   getBatchesByInfo,
   projectMassiveUpload,
   projectMassiveUploadDocuments,
   projectUploadChecklist,
   //projectUploadChecklistPayments,
   getProjectStagingTable,
   getProjectChecklistStagingTable,
   getEntityList,
   getPaymentsByInfo,
   getDocumentsStaging,
   //getLastBatch,
   downloadDocument,
   downloadProjectEntityDocument,
   downloadProjectEntityChecklistsDocument,
   downloadEntityTemplate,
   deleteDocumentsStaging,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import { IcoDanger, IcoRefresh, TooltipInfoIcon, IcoUploadFile, IcoDownloadFile, IcoReplaced } from '@icons';
import useInterval from '@lib/hooks/useInterval';
import { LevelContext } from 'components/contexts/LevelContext';
import Unauthorized from 'components/Unauthorized';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import DownloadExcelButtons from '@components/form/DownloadExcelButtons';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';
import { dateFormatter } from "@lib/helpers/formatting";

const MassiveUploadProject = ({
   column,
   columnStage,
   columnStageCl,
   entityType,
   projectId,
   sbShow,
   setSbShow,
   errors,
   setErrors,
   projectName,
   projectCup,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [isPending, setIsPending] = useState(false);
   const [/* isError */, setIsError] = useState(false);
   const [/* isComplete */, setIsComplete] = useState(false);
   const [entities, setEntities] = useState(null);
   const [entitiesMeta, setEntitiesMeta] = useState({});
   const [entityStaging, setEntityStaging] = useState(null);
   const [entityStagingMeta, setEntityStagingMeta] = useState({});
   const [checklistsStaging, setChecklistsStaging] = useState(null);
   const [checklistsStagingMeta, setChecklistsStagingMeta] = useState({});
   const [documentsStaging, setDocumentsStaging] = useState([]);
   const [documentsStagingMeta, setDocumentsStagingMeta] = useState({});
   const [selectedDocumentsStaging, setSelectedDocumentsStaging] = useState([]);
   //const [errors, setErrors] = useState([]);
   //const [sbShow, setSbShow] = useState(<></>);
   const [optionsPagination, setOptionsPagination] = useState([]);
   const [columnDefs, setColumnDefs] = useState([]);
   const [columnDefsStaging, setColumnDefsStaging] = useState([]);
   const [columnDefsClStaging, setColumnDefsClStaging] = useState([]);
   const [columnDefsErrors, setColumnDefsErrors] = useState([]);
   const [columnDefsDocuments, setColumnDefsDocuments] = useState([]);
   const [subActiveKey, setSubActiveKey] = useState('entities');
   const [batchPollingEnabled, setBatchPollingEnabled] = useState(true);
   const [uploadPartial, setUploadPartial] = useState('FULL');
   const [eventFile, setEventFile] = useState('');
   const [showModalPartial, setShowModalPartial] = useState(false);
   const [isDownloadingButton1, setIsDownloadingButton1] = useState(false);
   const [isDownloadingButton2, setIsDownloadingButton2] = useState(false);
   const [isFirstResearch1, setIsFirstResearch1] = useState(true);
   const [isFirstResearch2, setIsFirstResearch2] = useState(true);
   const [isFirstResearch3, setIsFirstResearch3] = useState(true);
   const [isFirstResearch4, setIsFirstResearch4] = useState(true);
   const localeText = AG_GRID_LOCALE_IT;
   const gridRef = useRef();
   const gridRefStaging = useRef();
   const gridRefClStaging = useRef();
   const gridRefErrors = useRef();
   const gridRefDocumentsStaging = useRef();
   const inputRef1 = useRef();
   const inputRef2 = useRef();
   const inputRef3 = useRef();
   //const inputRef4 = useRef();
   //console.log('entityType:', entityType);
   //console.log('documentsStaging:', documentsStaging);

   const translateEntityType = (entityType) => {
      const textMap = [
         { entityType: 'contracts', translate: 'Contratti', type: 'contracts' },
         { entityType: 'invoices', translate: 'Fatture', type: 'invoices' },
         { entityType: 'timesheets', translate: 'Record', type: 'timesheets' },
         { entityType: 'payments', translate: 'Pagamenti', type: 'invoicepayments' },
      ];

      let translatedString = textMap.filter(el => el.entityType === entityType).pop();

      return translatedString;
   };

   const pollDataBatch = useCallback(() => {
      if (projectId) {
         switch (subActiveKey) {
            case 'entities':
               if (entityType === 'payments') {
                  getPaymentsByInfo(projectId, entityType).then((entityList) => {
                     if (entityList.success) {
                        setEntities(entityList.data);
                        const { data, success, ...restResp } = entityList;
                        setEntitiesMeta(restResp);
                     }
                  });
               } else {
                  getEntityList(entityType, projectId, entityType).then((entityList) => {
                     if (entityList.success) {
                        var list = entityList.data;

                        setEntities(list);
                        const { data, success, ...restResp } = entityList;
                        setEntitiesMeta(restResp);
                     }
                  });
               }

               break;
            case 'log-entities':
               getProjectStagingTable(entityType, projectId, 'XLSX').then((stagings) => {
                  setEntityStaging(stagings.data);
                  const { data, success, ...restResp } = stagings;
                  setEntityStagingMeta(restResp);
               });

               break;
            case 'log-checklists':
               let tempEntityType = entityType === 'payments' ? 'invoicepayments' : entityType;
               getProjectChecklistStagingTable(tempEntityType, projectId, entityType + 'CLStaging').then((stagingChecklists) => {
                  setChecklistsStaging(stagingChecklists.data);
                  const { data, success, ...restResp } = stagingChecklists;
                  setChecklistsStagingMeta(restResp);
               });
               break;
            case 'log-documents':
               let docEntityType = 'projects';
               let docTaskEntity = entityType;

               /* getLastBatch(docEntityType, docTaskEntity, 'ZIP', projectId).then(lastBatch => {
                 console.log('lastBatch', lastBatch);
     
                 if (lastBatch.success && lastBatch.data.length > 0) {
                   console.log('lastBatch.data[0].id', lastBatch.data[0].id);
                   let lastBatchId = lastBatch.data[0].id;
     
                   getDocumentsStaging(entityType, projectId, lastBatchId).then((retrievedDocs) => {
                     setDocumentsStaging(retrievedDocs.data);
                     const { data, success, ...restResp } = retrievedDocs;
                     setDocumentsStagingMeta(restResp);
                   });
                 }
               }); */

               getDocumentsStaging(docEntityType, docTaskEntity, projectId, entityType + 'DocStaging').then((retrievedDocs) => {
                  setDocumentsStaging(retrievedDocs.data);
                  const { data, success, ...restResp } = retrievedDocs;
                  setDocumentsStagingMeta(restResp);
               });

               setSelectedDocumentsStaging([]);
               break;
            default:
         }

         getBatchesByInfo('projects', projectId).then((batches) => {
            let filteredBatches;

            if (batches.success) {
               if (batches.data.length > 0) {
                  filteredBatches = batches.data.filter(
                     (el) => el.taskEntity === entityType && el.entityType === 'projects'
                  );

                  const lastBatch = filteredBatches[filteredBatches.length - 1];
                  const isDone =
                     !lastBatch?.status === 'DONE' && !lastBatch?.status === 'DONE WITH ERRORS';

                  setBatchPollingEnabled(isDone);
               } else {
                  setBatchPollingEnabled(false);
               }
            }
         });
      }
   }, [entityType, projectId, subActiveKey]);

   useInterval(pollDataBatch, 2000, batchPollingEnabled);

   useEffect(() => {
      pollDataBatch();
   }, [entityType, projectId, subActiveKey, pollDataBatch]);

   useEffect(() => {
      setOptionsPagination([
         <Fragment key='options'>
            <option value='100' key='100'>
               100
            </option>
            <option value='500' key='500'>
               500
            </option>
            <option defaultValue='1000' key='1000'>
               1000
            </option>
         </Fragment>,
      ]);
   }, []);

   const LogCellRenderer = useCallback((params) => {
      const red = '#C94723';
      // const yellow = '#D4BE31';
      const green = '#689689';

      const legendStyles = {
         bullet: {
            display: 'inline-block',
            width: '1.2em',
            height: '1.2em',
            borderRadius: '2em',
            //top: '0.30em',
            //position: 'relative',
            // boxShadow: '1px 1px 3px 0px #666666',
         },
         label: {
            display: 'inline-block',
            width: '50%',
            lineHeight: '2.5em',
         },
      };

      if (params.value) {
         return (
            <i
               style={{
                  ...legendStyles.bullet,
                  backgroundColor: params.value === 'OK' ? green : red,
                  textAlign: 'left',
                  verticalAlign: 'sub',
               }}
            />
         );
      }
   }, []);

   const handleDownloadDocument = useCallback((params) => {
      const handleDownload = async (e) => {
         setSbShow(<></>);
         setIsPending(true);
         setIsError(false);
         setIsComplete(false);

         const result = await downloadDocument(params.data);
         console.log('result', result);

         if (result.success) {
            // File upload successful
            setIsComplete(true);
            setIsPending(false);
         } else {
            /* const message = result.data.file.join(',')
                    handleStatusBar('error', message, false); */
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
         }
      };

      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.download' fallback={'Scarica'} /></Tooltip>}>
            <Button variant='link' className='' onClick={(e) => handleDownload(e)}>
               <IcoDownloadFile className='second-color ico-small massive-upload align-bottom' />
            </Button>
         </OverlayTrigger>
      );
   }, [setSbShow]);

   const DocCellRenderer = useCallback((params) => {
      if (params?.data) {

         return (
            <Row className='align-items-center justify-content-center h-100'>
               <Col className='col-auto text-center'>
                  {!params.data.isNew ?
                     <OverlayTrigger placement='top' overlay={<Tooltip>
                        <OETrans code='common.docisreplaced' fallback={'Questo documento è stato sovrascritto in data'} />&nbsp;{dateFormatter(params.data?.document?.updatedAt)}
                     </Tooltip>}>
                        <IcoReplaced style={{ fill: '#689689' }} />
                     </OverlayTrigger> : null
                  }

                  {params.data.isNew ? <Badge bg="secondary"><OETrans code='common.tooltip.new' fallback={'Nuovo'} /></Badge> : null}
                  &nbsp;<span className='ms-1'>{params.data.fileName}</span>
               </Col>
            </Row>
         );
      }
   }, []);

   useEffect(() => {
      setColumnDefs(column);
      setColumnDefsStaging(columnStage);
      setColumnDefsClStaging(columnStageCl);
      setColumnDefsErrors([
         {
            headerName: translate('common.column', 'Colonna'),
            field: 'colonna',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('common.error', 'Errore'),
            field: 'errore',
            filter: 'agTextColumnFilter',
            minWidth: 500,
            cellStyle: { color: 'red' },
         },
      ]);
      setColumnDefsDocuments([
         {
            headerName: '',
            field: 'status',
            cellRenderer: LogCellRenderer,
            filter: 'agTextColumnFilter',
            maxWidth: 100,
            minWidth: 100,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            ...columnCentered(),
         },
         {
            headerName: translate('documentsstaging.fileName', 'Nome file'),
            field: 'fileName',
            filter: 'agTextColumnFilter',
            cellRenderer: DocCellRenderer,
            minWidth: 300,
         },
         {
            headerName: translate('documentsstaging.log', 'Messaggi'),
            field: 'log',
            filter: 'agTextColumnFilter',
            minWidth: 400,
            cellStyle: { color: 'red' },
         },
         {
            headerName: translate('documentsstaging.missing', 'Mancante'),
            field: 'missing',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.missing ? translate('common.yes', 'Sì') : translate('common.no', 'No');
            },
            maxWidth: 150,
            minWidth: 150,
         },
         {
            headerName: translate('documentsstaging.documentCode', 'Codice documento'),
            field: 'documentCode',
            filter: 'agTextColumnFilter',
            minWidth: 170,
         },
         /* {
            headerName: translate('documentsstaging.isNew', 'Sostituito'),
            field: 'isNew',
            filter: 'agTextColumnFilter',
            cellRenderer: DocCellRenderer,
            ...columnCenteredWithFilter,
         }, */
         {
            headerName: '',
            pinned: 'right',
            minWidth: 90,
            maxWidth: 90,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: handleDownloadDocument,
         },
      ]);
   }, [column, columnStage, columnStageCl, handleDownloadDocument, LogCellRenderer, DocCellRenderer]);

   const defaultColDef = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '3px 1rem' },
      //suppressSizeToFit: false,
      //suppressColumnVirtualisation=true
   };

   const defaultColDefLogs = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '3px 1rem', fontStyle: 'italic' },
      headerClass: 'fst-italic',
      //suppressSizeToFit: false,
      //suppressColumnVirtualisation=true
   };

   const onGridReady = useCallback(
      (params, gridRef) => {
         if (entityType !== undefined && projectId) {
            // if (entityType === 'payments') {
            //   getPaymentsByInfo(projectId).then((entityList) => {
            //     if (entityList.success) {
            //       setEntities(entityList.data);
            //       const { data, success, ...restResp } = entityList;
            //       setEntitiesMeta(restResp);
            //     }
            //   });
            // } else {
            //   getEntityList(entityType, projectId, entityType).then((entityList) => {
            //     if (entityList.success) {
            //       setEntities(entityList.data);
            //       const { data, success, ...restResp } = entityList;
            //       setEntitiesMeta(restResp);
            //     }
            //   });
            // }
            // pollDataBatch();
         }

         params.api.sizeColumnsToFit();

         window.addEventListener('resize', function () {
            setTimeout(function () {
               params.api.sizeColumnsToFit();
            });
         });

         //gridRef.current.api.sizeColumnsToFit();

         if (window.screen.width >= 1600) {
            //&& window.screen.height >= 768
            // Resolution is 1600 //x768 or above
            gridRef.current.api.sizeColumnsToFit();
         } else {
            const allColumnIds = [];
            gridRef.current.columnApi.getAllColumns().forEach((column) => {
               allColumnIds.push(column.getId());
            });
            gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
         }
      },
      [entityType, projectId]
   );

   const onPageSizeChanged = useCallback((type, ref) => {
      let value = document.getElementById('page-size-' + type).value;
      ref.current.api.paginationSetPageSize(Number(value));
   }, []);

   /* const onFirstDataRendered = useCallback((ref) => {
        //console.log('ref', ref);
        //gridRef.current.api.sizeColumnsToFit();
 
        const allColumnIds = [];
        ref?.current.columnApi.getAllColumns().forEach((column) => {
           allColumnIds.push(column.getId());
        });
        ref?.current.columnApi.autoSizeColumns(allColumnIds, false);
     }, []); */

   /* const onModelUpdated = useCallback((ref, state, setState, stateMeta, setStateMeta) => {
       setState(state);
       setStateMeta(stateMeta);
     }, []); */

   // const refreshData = () => {
   //   if (entityType === 'payments') {
   //     getPaymentsByInfo(projectId).then((entityList) => {
   //       if (entityList.success) {
   //         setEntities(entityList.data);
   //         const { data, success, ...restResp } = entityList;
   //         setEntitiesMeta(restResp);
   //       }
   //     });
   //   } else {
   //     getEntityList(entityType, projectId, entityType).then((entityList) => {
   //       if (entityList.success) {
   //         setEntities(entityList.data);
   //         const { data, success, ...restResp } = entityList;
   //         setEntitiesMeta(restResp);
   //       }
   //     });
   //   }

   //   //PAYMENTSSTAGING/
   //   /* if (entityType !== 'payments') { */
   //   /* getProjectStagingTable(entityType, projectId).then(stagingEntities => {
   //           setEntityStaging(stagingEntities.data)
   //        }) */

   //   getProjectChecklistStagingTable(entityType, projectId).then((stagingChecklists) => {
   //     setChecklistsStaging(stagingChecklists.data);

   //     const { data, success, ...restResp } = stagingChecklists;
   //     setChecklistsStagingMeta(restResp);
   //   });
   //   /* } */

   //   //PAYMENTSSTAGING/
   //   getBatchesByInfo('projects').then((batches) => {
   //     if (batches.success && batches.data.length > 0) {
   //       const lastBatchId = batches.data
   //         .filter((batch) => batch.taskEntity === entityType)
   //         .reduce((prev, current) => (prev.id > current.id ? prev : current)).id;

   //       getProjectStagingTable(entityType, projectId).then((stagings) => {
   //         setEntityStaging(stagings.data.filter((stagings) => stagings.batchId === lastBatchId));

   //         fetchForPaginationWithQueryParams(
   //           stagings.meta.path,
   //           `?projectId=${projectId}&batchId=${lastBatchId}`
   //         ).then((resultData) => {
   //           console.log('resultData:', resultData);
   //           const { data, success, ...restResp } = resultData;
   //           setEntityStagingMeta(restResp);
   //         });

   //         //aggiungere query params con fetchdata function
   //         /* const { data, success, ...restResp} = stagings;
   //         setEntityStagingMeta(restResp); */
   //       });
   //     }
   //   });

   //   setErrors([]);
   //   setIsComplete(true); //useless
   //   setIsPending(false);

   //   setSbShow(<></>);
   // };
   const refreshData = () => {
      pollDataBatch();
   };
   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const cleanData = async () => {
      setSbShow(<></>);

      const data = {
         docIds: selectedDocumentsStaging,
      };

      const result = await deleteDocumentsStaging(data, 'projects', entityType, projectId);

      if (result.success) {
         handleStatusBar('success', translate('common.message.logsdeleted', 'Log eliminati con successo'), true);

         pollDataBatch();
      } else {
         handleStatusBar('error', result.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
      }
   };

   /* const handleClickUpload = () => {
        inputRef.current?.click();
     }; */

   const handleUpload = async (event, projectId, inputRef) => {
      //console.log('event', event);
      setSbShow(<></>);
      event?.preventDefault();

      //const file = inputRef.current?.files[0];
      const file = event.target.files[0];

      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await projectMassiveUpload(entityType, projectId, file, uploadPartial);
      //console.log('result handleUpload:', result);

      if (result.success) {
         // File upload successful
         handleStatusBar(
            'success',
            translate('common.message.uploadedsuccesfully', 'Documento caricato con successo. I dati saranno disponibili a breve'),
            true
         );
         setBatchPollingEnabled(true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         if (result.data?.file) {
            const message = result.data.file.join(',');
            handleStatusBar('error', message, false);
            setIsPending(false);

         } else if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         } else {
            handleStatusBar(
               'error',
               translate('common.error.uploadederror', 'Il documento non è stato caricato, controlla la tabella degli errori'),
               false
            );

            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
            const dataArray = [];

            for (const key in result.data) {
               // key = riga1 || partial
               const element = result.data[key][0];
               //console.log('element:', typeof element, element);
               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else { //string
                  colonna = 'N.A.';
                  errore = element;
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }

            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setUploadPartial('FULL');
      setSubActiveKey('log-entities');
   };

   const handleUploadChecklist = async (event, projectId, inputRef) => {
      event?.preventDefault();

      setSbShow(<></>);
      const file = inputRef.current?.files[0];
      //const file = event.target.files[0];
      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      // if (entityType === 'payments') {
      //   result = await projectUploadChecklistPayments(file, invoiceType);
      // } else {
      //   result = await projectUploadChecklist(entityType, projectId, file);
      // }

      const result = await projectUploadChecklist(
         entityType === 'payments' ? 'invoicepayments' : entityType,
         projectId,
         file
      );

      console.log('result', result);

      if (result.success) {
         // File upload successful
         setBatchPollingEnabled(true);
         handleStatusBar(
            'success',
            translate('common.message.uploadedsuccesfully', 'Documento caricato con successo. I dati saranno disponibili a breve'),
            true
         );
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         console.log('result else', result);
         if (result.success === false && result.message) {
            const message = result.message;
            handleStatusBar('error', message, false);
         } else if (result.data.file) {
            const message = result.data.file.join(',');
            handleStatusBar('error', message, false);
         } else {
            handleStatusBar(
               'error',
               translate('common.error.uploadederror', 'Il documento non è stato caricato, controlla la tabella degli errori'),
               false
            );
         }
         setIsError(true);
         setIsPending(false);
         setIsComplete(true);

         const dataArray = [];
         if (result.data) {
            for (const key in result.data) {
               const element = result.data[key][0];
               //console.log('element:', element);
               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else { //string
                  colonna = 'N.A.';
                  errore = element;
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }
         }

         setErrors(dataArray);
      }

      event.target.value = '';

      setSubActiveKey('log-checklists');
   };

   const handleUploadDocuments = async (event, projectId, inputRef) => {
      event?.preventDefault();
      setSbShow(<></>);
      //const file = inputRef.current?.files[0];
      const file = event.target.files[0];

      //se il file è più grande di 100MB (in byte)
      if (file.size > 104857600) {
         handleStatusBar('error', translate('common.error.filecannotexceed', 'Il file non può superare i 100MB'), true);
         return;
      }

      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await projectMassiveUploadDocuments(entityType, projectId, file);

      if (result.success) {
         // File upload successful
         setBatchPollingEnabled(true);
         handleStatusBar('success', translate('common.message.documentsinprocess', 'Documenti in elaborazione'), true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         } else {
            const message = result.data.file.join(',');
            handleStatusBar('error', message, false);
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
            const dataArray = [];
            for (const key in result.data) {
               const element = result.data[key][0];
               console.log('element:', element);
               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else {
                  return;
                  /* const startIndex = element.indexOf("'") + 1;
                  const endIndex = element.indexOf("'", startIndex);
                  colonna = element.slice(startIndex, endIndex);
                  errore = element.slice(endIndex + 1).trim(); */
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }
            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setSubActiveKey('log-documents');
   };

   const handleDownloadTemplate = async (entityType) => {
      setSbShow(<></>);
      setIsPending(true);

      const result = await downloadEntityTemplate(entityType);

      if (result) {
         setIsPending(false);
      }
   };

   const handleDownloadEntityDocument = async (entityType) => {
      setSbShow(<></>);
      setIsPending(true);

      const result = await downloadProjectEntityDocument([projectId, entityType]);

      if (result) {
         setIsPending(false);
      }
   };

   const reloadButton = () => {
      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.reloaddata' fallback={'Ricarica i dati'} /></Tooltip>}>
            <Button onClick={() => refreshData()} style={{ padding: '0.2rem' }}>
               <IcoRefresh />
            </Button>
         </OverlayTrigger>
      );
   };

   const cleanButton = () => {
      return (
         <Button variant='danger' className="me-2" onClick={() => cleanData()}>
            <OETrans code='common.cleanlog' fallback={'Pulisci Log'} />
         </Button>
      );
   };

   const entitiesTable = () => {

      return (
         <Fragment>
            <Row className='align-items-center mb-3'>
               <Col className='col-4'></Col>
               <Col className='col-auto text-right py-1 ms-auto'>
                  <ButtonToolbar className='justify-content-end'>
                     <DownloadExcelButtons
                        textHeader={translate('common.downloadexcel', 'Scarica Excel')}
                        dropdownItems={[
                           { text: `Elenco ${translateEntityType(entityType).translate}`, isDownloadingButton: isDownloadingButton1, setIsDownloadingButton: setIsDownloadingButton1, apiDownload: downloadProjectEntityDocument, apiProps: [projectId, translateEntityType(entityType).entityType] },
                           { text: `Elenco Checklists (${translateEntityType(entityType).translate})`, isDownloadingButton: isDownloadingButton2, setIsDownloadingButton: setIsDownloadingButton2, apiDownload: downloadProjectEntityChecklistsDocument, apiProps: [projectId, translateEntityType(entityType).type] }
                        ]}
                     />
                     {reloadButton()}
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={entitiesMeta}
                        setData={setEntities}
                        dataLength={entities?.length}
                        isFirstResearch={isFirstResearch1}
                        setIsFirstResearch={setIsFirstResearch1}
                        refTable={entityType}
                     />
                  </ButtonToolbar>
               </Col>
            </Row>

            <Row>
               <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                  {Array.isArray(entities) ? (
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={entities}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRef)}
                           //onFirstDataRendered={() => onFirstDataRendered(gridRef)}
                           //onModelUpdated={() => onModelUpdated(gridRef, entities, setEntities, entitiesMeta, setEntitiesMeta)}
                           //pagination={true}
                           //paginationPageSize={100}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        {Object.keys(entitiesMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={entitiesMeta}
                              setData={setEntities}
                              setDataMeta={setEntitiesMeta}
                              refTable={entityType}
                              setIsFirstResearch={setIsFirstResearch1}
                           />
                        )}
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </Fragment>
      );
   };

   const entitiesStagingTable = () => {

      return (
         <>
            <>
               <Row className='align-items-center mb-3 ps-4'>
                  <Col className='col-4'>
                     {/* <Row className='align-items-center mt-4 mb-3'>
                           <Col className='col-auto p-0'>
                              <IcoDanger className='me-1 ico-yellow' />
                           </Col>
                           <Col className='col-auto p-1'>
                              <h5 className='m-0'>Non è stato possibile creare i seguenti elementi:</h5>
                           </Col>
                        </Row> */}
                  </Col>
                  <Col className='col-8 text-right py-1'>
                     <ButtonToolbar className='justify-content-end'>
                        {reloadButton()}
                        <CustomQuickFilterBox
                           tableRef={gridRefStaging}
                           dataMeta={entityStagingMeta}
                           setData={setEntityStaging}
                           dataLength={entityStaging?.length}
                           isFirstResearch={isFirstResearch2}
                           setIsFirstResearch={setIsFirstResearch2}
                           refTable={entityType + 'Staging'}
                        />
                     </ButtonToolbar>
                  </Col>
               </Row>

               <Row>
                  <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                     {Array.isArray(entityStaging) ?
                        (<div className='ag-theme-alpine list-table'>
                           <AgGridReact
                              ref={gridRefStaging}
                              columnDefs={columnDefsStaging}
                              rowData={entityStaging}
                              defaultColDef={defaultColDefLogs}
                              domLayout='autoHeight'
                              tooltipShowDelay={0}
                              tooltipHideDelay={2000}
                              tooltipMouseTrack={true}
                              onGridReady={(e) => onGridReady(e, gridRefStaging)}
                              //onFirstDataRendered={() => onFirstDataRendered(gridRefStaging)}
                              //onModelUpdated={() => onModelUpdated(gridRefStaging)}
                              //pagination={true}
                              //paginationPageSize={100}
                              headerHeight={50}
                              rowHeight={50}
                              ariaDescription='ag-grid-table'
                              suppressCellFocus={true}
                              animateRows={true}
                              localeText={localeText}
                           />
                           {Object.keys(entityStagingMeta).length > 0 && (
                              <CustomPaginationMui
                                 dataMeta={entityStagingMeta}
                                 setData={setEntityStaging}
                                 setDataMeta={setEntityStagingMeta}
                                 refTable={entityType + 'Staging'}
                                 setIsFirstResearch={setIsFirstResearch2}
                              />
                           )}
                        </div>
                        ) : (
                           <div className='text-center my-4'>
                              <Spinner animation='border' role='status'>
                                 <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                              </Spinner>
                           </div>
                        )}
                  </Col>
               </Row>
            </>
         </>
      );
   };

   const checklistsStagingTable = () => {

      return (
         <>
            <Row className='align-items-center mb-3 ps-4'>
               <Col className='col-4'>
                  {/* <Row className='align-items-center mt-4 mb-3'>
                           <Col className='col-auto p-0'>
                              <IcoDanger className='me-1 ico-yellow' />
                           </Col>
                           <Col className='col-auto p-1'>
                              <h5 className='m-0'>Non è stato possibile creare le seguenti checklist:</h5>
                           </Col>
                        </Row> */}
               </Col>
               <Col className='col-8 text-right py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     {reloadButton()}
                     <CustomQuickFilterBox
                        tableRef={gridRefClStaging}
                        dataMeta={checklistsStagingMeta}
                        setData={setChecklistsStaging}
                        dataLength={checklistsStaging?.length}
                        isFirstResearch={isFirstResearch3}
                        setIsFirstResearch={setIsFirstResearch3}
                        refTable={entityType + 'CLStaging'}
                     />
                  </ButtonToolbar>
               </Col>
            </Row>

            <Row>
               <Col className='col-12'>
                  {Array.isArray(checklistsStaging) ? (
                     <div className='ag-theme-alpine list-table' id='grid-wrapper'>
                        <AgGridReact
                           ref={gridRefClStaging}
                           columnDefs={columnDefsClStaging}
                           rowData={checklistsStaging}
                           defaultColDef={defaultColDefLogs}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRefClStaging)}
                           //onFirstDataRendered={(e) => onFirstDataRendered(gridRefClStaging)}
                           //onModelUpdated={(e) => onModelUpdated(gridRefClStaging)}
                           //pagination={true}
                           //paginationPageSize={100}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        {Object.keys(checklistsStagingMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={checklistsStagingMeta}
                              setData={setChecklistsStaging}
                              setDataMeta={setChecklistsStagingMeta}
                              refTable={entityType + 'CLStaging'}
                              setIsFirstResearch={setIsFirstResearch3}
                           />
                        )}
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </>
      );
   };

   const documentsStagingTable = () => {

      const onSelectionChanged = (e) => {
         //Prende solo le righe selezionate e filtrate
         let rowData = [];
         e.api.forEachNode((node) => (node.isSelected() ? rowData.push(node.data) : null));

         const selectedData = rowData.map((node) => node);

         setSelectedDocumentsStaging(selectedData.map(el => el.id));
      };

      return (
         <>
            {/* {documentsStagingMeta.meta?.total > 0 ? ( */}
            {documentsStaging.length > 0 ? (
               <>
                  <Row className='align-items-center mb-3 ps-4'>
                     <Col className='col-4'>
                        {/* <Row className='align-items-center mt-4 mb-3'>
                           <Col className='col-auto p-0'>
                              <IcoDanger className='me-1 ico-yellow' />
                           </Col>
                           <Col className='col-auto p-1'>
                              <h5 className='m-0'>Non è stato possibile inserire i seguenti documenti:</h5>
                           </Col>
                        </Row> */}
                     </Col>
                     <Col className='col-8 text-right py-1'>
                        <ButtonToolbar className='justify-content-end'>
                           {selectedDocumentsStaging.length > 0 && cleanButton()}
                           {reloadButton()}
                           <CustomQuickFilterBox
                              tableRef={gridRefDocumentsStaging}
                              dataMeta={documentsStagingMeta}
                              setData={setDocumentsStaging}
                              dataLength={documentsStaging?.length}
                              isFirstResearch={isFirstResearch4}
                              setIsFirstResearch={setIsFirstResearch4}
                              refTable={entityType + 'DocStaging'}
                           />
                        </ButtonToolbar>
                     </Col>
                  </Row>

                  <Row>
                     <Col className='col-12'>
                        {Array.isArray(documentsStaging) ? (
                           <div className='ag-theme-alpine list-table' id='grid-wrapper'>
                              <AgGridReact
                                 ref={gridRefDocumentsStaging}
                                 columnDefs={columnDefsDocuments}
                                 rowData={documentsStaging}
                                 defaultColDef={defaultColDefLogs}
                                 domLayout='autoHeight'
                                 tooltipShowDelay={0}
                                 tooltipHideDelay={2000}
                                 tooltipMouseTrack={true}
                                 onGridReady={(e) => onGridReady(e, gridRefDocumentsStaging)}
                                 //onFirstDataRendered={(e) => onFirstDataRendered(gridRefDocumentsStaging)}
                                 //onModelUpdated={(e) => onModelUpdated(gridRefDocumentsStaging)}
                                 //pagination={true}
                                 //paginationPageSize={100}
                                 headerHeight={50}
                                 rowHeight={50}
                                 ariaDescription='ag-grid-table'
                                 suppressCellFocus={true}
                                 animateRows={true}
                                 localeText={localeText}
                                 rowSelection={'multiple'}
                                 onSelectionChanged={onSelectionChanged}
                                 suppressRowClickSelection={true}
                                 rowMultiSelectWithClick={true}
                              />
                              {Object.keys(documentsStagingMeta).length > 0 ? (
                                 <CustomPaginationMui
                                    dataMeta={documentsStagingMeta}
                                    setData={setDocumentsStaging}
                                    setDataMeta={setDocumentsStagingMeta}
                                    refTable={entityType + 'DocStaging'}
                                    setIsFirstResearch={setIsFirstResearch4}
                                 />
                              ) : (
                                 <div className='page-size-table'>
                                    <OETrans code='common.itemsperpage' fallback={'Elementi per pagina'} />:&nbsp;
                                    <select
                                       onChange={() =>
                                          onPageSizeChanged('log-documents', gridRefDocumentsStaging)
                                       }
                                       className='page-size'
                                       id='page-size-log-documents'>
                                       {optionsPagination}
                                    </select>
                                 </div>
                              )}
                           </div>
                        ) : (
                           <div className='text-center my-4'>
                              <Spinner animation='border' role='status'>
                                 <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                              </Spinner>
                           </div>
                        )}
                     </Col>
                  </Row>
               </>
            ) : (
               <Row className='text-center my-5'>
                  <span><OETrans code='common.nologs' fallback={'Non sono presenti log'} /></span>
               </Row>
            )}
         </>
      );
   };

   const errorsTable = () => {
      return (
         <Fragment>
            <Row className='title-simple-box justify-content-center align-items-center mt-4 mb-3'>
               <Col className='col-auto p-0'>
                  <IcoDanger className='me-1 ico-red' />
               </Col>
               <Col className='col-auto p-1'>
                  <h5 className='m-0'><OETrans code='common.errorlist' fallback={'Lista Errori'} /></h5>
               </Col>
            </Row>

            <Row className='mb-5'>
               <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                  {Array.isArray(errors) ? (
                     <div className='ag-theme-alpine list-table' style={{ width: '100%' }}>
                        <AgGridReact
                           ref={gridRefErrors}
                           columnDefs={columnDefsErrors}
                           rowData={errors}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRefErrors)}
                           //onFirstDataRendered={() => onFirstDataRendered(gridRefErrors)}
                           //onModelUpdated={() => onModelUpdated(gridRefErrors)}
                           //pagination={true}
                           //paginationPageSize={100}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        {/* <div className='page-size-table'>
                           <OETrans code='common.errorsperpage' fallback={'Errori per pagina'}/>:&nbsp;
                           <select
                              onChange={() => onPageSizeChanged('errors', gridRefErrors)}
                              className='page-size'
                              id='page-size-errors'>
                              {optionsPagination}
                           </select>
                        </div> */}
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </Fragment>
      );
   };

   const renderTooltip = (props) => {
      let type = '';
      let message = '';

      if (props.popper.state) {
         type = props.popper.state.options.testObj;

         switch (type) {
            case 'excel':
               message = <>
                  <OETrans code='common.filesallowed' fallback={'Ammessi solo file'} />:
                  <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
                     <li><OETrans code='common.extensionxls' fallback={'con estensione .xls, .xlsx'} /></li>
                  </ul>
               </>;
               break;
            case 'zip':
               message = <>
                  <OETrans code='common.filesallowed' fallback={'Ammessi solo file'} />:
                  <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
                     <li><OETrans code='common.extensionzip' fallback={'con estensione .zip'} /></li>
                     <li><OETrans code='common.noexceed' fallback={'che non superino i 100MB'} /></li>
                  </ul>
               </>;
               break;
            case 'complete':
               message = <><OETrans code='common.tooltip.completeupload' fallback={'Usare questo caricamento per sovrascrivere tutti i dati esistenti'} /></>;
               break;
            case 'partial':
               message = <><OETrans code='common.tooltip.partialupload' fallback={'Usare questo caricamento se si vuole aggiungere nuovi dati o modificare quelli esistenti'} /></>;
               break;
            case 'partialDelta':
               message = <><OETrans code='common.tooltip.partialdeltaupload' fallback={'Usare questo caricamento se si vuole aggiungere nuovi dati o sommare i consuntivi esistenti'} /></>;
               break;
            default:
               break;
         }
      }

      return (
         <Tooltip id='button-tooltip' {...props}>
            {message}
         </Tooltip>
      );
   };

   if (!isOe && userRole !== 5) return <Unauthorized drilled={true} />;
   return (
      <Fragment key={entityType}>
         <Row id={entityType} className='mt-5 mb-5'>
            <Col>
               <Row>
                  <Col className='text-center'>
                     <Form.Group className='px-3' controlId={`${entityType}_1`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center" >
                              <IcoUploadFile
                                 className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`}
                              />
                              <span>
                                 <OETrans code='common.importdata' fallback={'Importa dati'} />
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           ref={inputRef1}
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => entityType === 'payments' ? handleUpload(e, projectId, inputRef1) : (setEventFile(e), setShowModalPartial(true))}
                        />
                     </Form.Group>
                  </Col>

                  <Col className='text-center'>
                     <Form.Group as={Col} className='px-3' controlId={`${entityType}_2`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center" >
                              <IcoUploadFile
                                 className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`}
                              />
                              <span>
                                 <OETrans code='common.uploaddocuments' fallback={'Carica documenti'} />
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'zip' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           ref={inputRef3}
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUploadDocuments(e, projectId, inputRef3)}
                        />
                     </Form.Group>
                  </Col>

                  <Col className='text-center'>
                     <Form.Group className='px-3' controlId={`${entityType}_3`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoUploadFile
                                 className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`}
                              />
                              <span>
                                 <OETrans code='common.uploadchecklist' fallback={'Carica checklist'} />
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           ref={inputRef2}
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUploadChecklist(e, projectId, inputRef2)}
                        />
                     </Form.Group>
                  </Col>

                  <Col className="text-center">
                     <Form.Group as={Col} className='px-3' controlId={`export_template`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoDownloadFile className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span><OETrans code='common.uploadtemplate' fallback={'Scarica template'} /></span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onClick={() => handleDownloadTemplate(translateEntityType(entityType).type)}
                        />
                     </Form.Group>
                  </Col>

                  {entityType === 'invoices' && (
                     <>
                        <Col className="text-center">
                           <Form.Group as={Col} className='px-3' controlId={`export_regis`}>
                              <Form.Label style={{ cursor: 'pointer' }}>
                                 <Row className="justify-content-center">
                                    <IcoDownloadFile className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                                    <span>Scarica file regis</span>
                                 </Row>
                              </Form.Label>
                              <Form.Control
                                 type='file'
                                 disabled={isPending ? true : false}
                                 style={{ display: 'none' }}
                                 onClick={() => handleDownloadEntityDocument('regis')}
                              />
                           </Form.Group>
                        </Col>

                        <Col className="text-center">
                           <Form.Group as={Col} className='px-3' controlId={`export_clockings`}>
                              <Form.Label style={{ cursor: 'pointer' }}>
                                 <Row className="justify-content-center">
                                    <IcoDownloadFile className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                                    <span>Scarica timbrature</span>
                                 </Row>
                              </Form.Label>
                              <Form.Control
                                 type='file'
                                 disabled={isPending ? true : false}
                                 style={{ display: 'none' }}
                                 onClick={() => handleDownloadEntityDocument('regis/stamps')}
                              />
                           </Form.Group>
                        </Col>
                     </>
                  )}

                  {/* RIATTIVARE CON LE QUIETANZE  */}
                  {/* {entityType === 'payments' && (
                     <Col className='text-center'>
                        <Form.Group className='px-3' controlId={`${entityType}_4`}>
                           <Form.Label style={{ cursor: 'pointer' }}>
                              <Row className="justufy-content-center">
                                 <IcoUploadFile
                                    className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`}
                                 />
                                 <span>
                                    Carica CL quietanze
                                    <OverlayTrigger
                                       placement='top'
                                       delay={{ show: 200, hide: 400 }}
                                       overlay={renderTooltip}
                                       popperConfig={{ testObj: 'excel' }}>
                                       <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                    </OverlayTrigger>
                                 </span>
                              </Row>
                           </Form.Label>
                           <Form.Control
                              type='file'
                              ref={inputRef4}
                              disabled={isPending ? true : false}
                              style={{ display: 'none' }}
                              onChange={(e) =>
                                 handleUploadChecklist(e, projectId, inputRef4, 'invoicereceipts')
                              }
                           />
                        </Form.Group>
                     </Col>
                  )} */}
               </Row>

               <Row className='justify-content-center align-items-center'>
                  <Col className='text-center py-3 ps-0' style={{ paddingRight: '5.5rem' }}>
                     {isPending && (
                        <Spinner
                           animation='border'
                           role='status'
                           style={{ display: 'flex', margin: '0 auto' }}></Spinner>
                     )}
                  </Col>
               </Row>
            </Col>

            {sbShow}
         </Row>

         {/* <Row className="justify-content-center align-items-center">
            <Col className="text-center py-3 ps-0" style={{ paddingRight: '5.5rem' }}>
               {isPending && <Spinner animation="border" role="status" style={{ display: 'flex', margin: '0 auto' }}></Spinner>}
               {sbShow}
            </Col>
         </Row> */}

         <Tabs
            activeKey={subActiveKey}
            className='mb-3 mt-3'
            onSelect={(k) => {
               setSubActiveKey(k);
               setSbShow(<></>);
               setErrors([]);
               setUploadPartial('FULL');
            }}>
            <Tab eventKey='entities' title={translate('common.list', 'Elenco')}>
               <div className='mt-4 px-3'>
                  {errors.length > 0 && errorsTable()}

                  {subActiveKey === 'entities' && entitiesTable()}
               </div>
            </Tab>
            {!isOe && userRole !== 5 && entityType === 'payments' ? null :
               <Tab eventKey='log-entities' title={translate('common.loglist', 'Log Elenco')}>
                  <div className='mt-4'>
                     {errors.length > 0 && errorsTable()}

                     {subActiveKey === 'log-entities' && entitiesStagingTable()}
                  </div>
               </Tab>
            }
            {!isOe && userRole !== 5 && entityType === 'payments' ? null :
               <Tab eventKey='log-checklists' title={translate('common.logchecklist', 'Log Checklist')}>
                  <div className='mt-4'>
                     {errors.length > 0 && errorsTable()}

                     {subActiveKey === 'log-checklists' && checklistsStagingTable()}
                  </div>
               </Tab>
            }
            {!isOe && userRole !== 5 && entityType === 'payments' ? null :
               <Tab eventKey='log-documents' title={translate('common.documentlog', 'Log Documenti')}>
                  <div className='mt-4 px-3'>
                     {errors.length > 0 && errorsTable()}

                     {subActiveKey === 'log-documents' && documentsStagingTable()}
                  </div>
               </Tab>
            }
         </Tabs>

         <Modal
            show={showModalPartial}
            onHide={() => setShowModalPartial(false)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='mb-3'><OETrans code='common.warning.uploadtype' fallback={'Quale tipo di caricamento vuoi effettuare?'} /></div>
               <Form.Check
                  className="mb-3"
                  inline
                  type='radio'
                  id={`complete`}
                  name={`upload-type`}
                  label={<>
                     <OETrans code='common.fullupload' fallback={'Caricamento completo'} />
                     <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{ testObj: 'complete' }}>
                        <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                     </OverlayTrigger>
                  </>}
                  onChange={() => setUploadPartial('FULL')}
                  defaultChecked={uploadPartial === 'FULL'}
               />
               <br />
               <Form.Check
                  className="mb-3"
                  inline
                  type='radio'
                  id={`partial`}
                  name={`upload-type`}
                  label={<>
                     <OETrans code='common.partialupload' fallback={'Caricamento parziale'} />
                     <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{ testObj: 'partial' }}>
                        <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                     </OverlayTrigger>
                  </>}
                  onChange={() => setUploadPartial('PARTIAL')}
                  defaultChecked={uploadPartial === 'PARTIAL'}
               />
               {entityType === 'invoices' &&
                  <>
                     <br />
                     <Form.Check
                        inline
                        type='radio'
                        id={`partialDelta`}
                        name={`upload-type`}
                        label={<>
                           <OETrans code='common.partialdeltaupload' fallback={'Caricamento con Delta'} />
                           <OverlayTrigger
                              placement='top'
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: 'partialDelta' }}>
                              <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                           </OverlayTrigger>
                        </>}
                        onChange={() => setUploadPartial('PARTIAL_DELTA')}
                        defaultChecked={uploadPartial === 'PARTIAL_DELTA'}
                     />
                  </>
               }
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => { setEventFile(''); setShowModalPartial(false); setUploadPartial('FULL') }}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant="primary" onClick={() => { handleUpload(eventFile, projectId); setShowModalPartial(false) }}>
                  <OETrans code='common.confirm' fallback={'Conferma'} />
               </Button>
            </Modal.Footer>
         </Modal>
      </Fragment>
   );
};

export default MassiveUploadProject;
