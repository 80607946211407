import React, { useState, useEffect } from "react";
import { Row, Col, Form, Tabs, Tab, Button } from "react-bootstrap";
import { Alert } from "@components/atomic/atoms";
import { OETrans } from "@components/translation/OETrans";
import { getProfile, updateProfile, changePassword } from "@lib/api";
import { translate } from "components/translation/translation";

const Profile = () => {
  // Initial state
  const profileInitialState = {
    firstName: "",
    lastName: "",
    email: "",
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line

  // State
  const [alert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(profileInitialState);
  const [fieldsError, setFieldsError] = useState({});

  const validation = (fields) => {
    const newErrors = { ...fieldsError };
    fields.forEach((field) => {
      if (!profile[field] || profile[field].trim() === "") {
        newErrors[field] = true;
      }
    });

    // Check if password is valid
    if (
      profile.password &&
      !profile.password.match(passwordRegex) &&
      fields.includes("password")
    ) {
      newErrors["password"] = true;
    }

    // Check if passwords match
    if (
      profile.password !== profile.passwordConfirmation &&
      fields.includes("password")
    ) {
      newErrors["passwordConfirmation"] = true;
    }
    setFieldsError(newErrors);
    return newErrors;
  };

  // Effects
  useEffect(() => {
    resetShowAlert();
    setIsLoading(true);
    getProfile()
      .then((response) => {
        setProfile(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: (
            <OETrans
              code="common.error.retrievinginformation"
              fallback={"Errore durante il recupero delle informazioni"}
            />
          ),
          type: "danger",
        });
        setIsLoading(false);
      });
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    const errorKeys = Object.keys(fieldsError);
    if (errorKeys.length > 0) {
      const message = errorKeys.includes("passwordConfirmation") ? (
        <OETrans
          code='common.passwordmatch'
          fallback={'le password non coincidono'}
        />
      ) : errorKeys.includes("password") ? (
         <>
            <OETrans code='common.passwordmustcontain' fallback={'La password deve contenere'} />:
            <ul style={{
                  textAlign: "left",
                  paddingLeft: "20px",
                  margin: "auto",
               }}
            >
               <li><OETrans code='common.capitalletter' fallback={'una lettera maiuscola'}/>,</li>
               <li><OETrans code='common.number' fallback={'un numero'}/>,</li>
               <li><OETrans code='common.specialcharacter' fallback={'un carattere speciale'}/>,</li>
               <li><OETrans code='common.minimumlength' fallback={'una lunghezza minima di 8 caratteri'}/>.</li>
            </ul>
         </>
      ) : (
        <OETrans code='common.error.requiredFields' fallback={'Compila i campi obbligatori'}/>
      );
      setShowAlert({
        show: true,
        message,
        type: "danger",
      });
    }
  }, [fieldsError]);

  useEffect(() => {
    if (isLoading) {
      setShowAlert({
        show: true,
        message: (translate('common.loading', 'Caricamento') + '...'),
        type: "info",
      });
    }
  }, [isLoading]);

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });

    // Remove error if field is not empty
    if (fieldsError[name] && value.trim()) {
      const newErrors = { ...fieldsError };
      delete newErrors[name];
      setFieldsError(newErrors);
    }
  };

  const resetShowAlert = () => {
    setShowAlert({
      show: false,
      message: "",
      type: "",
    });
  };

  const handleSaveProfile = () => {
    resetShowAlert();
    const fields = ["firstName", "lastName"];
    const newErrors = validation(fields);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      updateProfile(profile)
        .then((response) => {
          if (response.success) {
            setShowAlert({
              show: true,
              message: translate('common.message.edited', 'Elemento modificato con successo'),
              type: "success",
            });
          } else {
            setShowAlert({
              show: true,
              message: response.data.message,
              type: "danger",
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setShowAlert({
            show: true,
            message: translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            type: "danger",
          });
          setIsLoading(false);
        });
    }
  };

  const handleSavePassword = () => {
    resetShowAlert();
    const fields = ["oldPassword", "password", "passwordConfirmation"];
    const newErrors = validation(fields);
    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      changePassword(profile)
        .then((response) => {
          if (response.success) {
            setShowAlert({
              show: true,
              message: (
                <OETrans
                  code='common.passwordUpdatedSuccessfully'
                  fallback={'Password aggiornata con successo'}
                />
              ),
              type: "success",
            });
          } else {
            setShowAlert({
              show: true,
              message: response.data.oldPassword[0] || response.data.message,
              type: "danger",
            });
          }
          // Clear password fields
          setProfile({
            ...profile,
            oldPassword: "",
            password: "",
            passwordConfirmation: "",
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setShowAlert({
            show: true,
            message: (
              <OETrans
                code='common.errorUpdatingPassword'
                fallback={'Errore nell’aggiornamento della password'}
              />
            ),
            type: "danger",
          });
          setIsLoading(false);
        });
    }
  };

  return (
    <div id="profile" className="megaContent">
      <div className="container-fluid">
        <Row
          className="flex-wrap justify-content-around"
          style={{ columnGap: "1%" }}
        >
          {alert.show && (
            <Col className="pt-3 col-12">
              <Alert
                show={alert.show}
                message={alert.message}
                variant={alert.type}
                delay={5000}
              />
            </Col>
          )}
          <Col>
            <div className={`simple-box scenarita`}>
              <Form autoComplete="off">
                <Tabs
                  defaultActiveKey="profile"
                  variant="pills"
                  className="mb-3"
                >
                  <Tab eventKey="profile" title={translate('common.profile', 'Profilo')}>
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <OETrans code='profile.firstName' fallback={'Nome'}/>
                          &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          {...(isLoading && { disabled: true })}
                          value={profile.firstName || ""}
                          {...(fieldsError.firstName && { isInvalid: true })}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='profile.lastName' fallback={'Cognome'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          {...(isLoading && { disabled: true })}
                          value={profile.lastName || ""}
                          {...(fieldsError.lastName && { isInvalid: true })}
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>
                    </Row>
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <OETrans code='profile.email' fallback={'Email'}/>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={profile.email || ""}
                          autoComplete="new-password"
                          readOnly
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          type="button"
                          {...(isLoading && { disabled: true })}
                          className="float-end"
                          onClick={handleSaveProfile}
                        >
                          <OETrans code='common.edit' fallback={'Modifica'} />
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="passAndAuth" title={translate('common.profile.authentication', 'Password e autenticazione')}>
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='profile.oldPassword' fallback={'Password attuale'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="oldPassword"
                          value={profile.oldPassword || ""}
                          {...(isLoading && { disabled: true })}
                          autoComplete="new-password"
                          onChange={(e) => handleChange(e)}
                          {...(fieldsError.oldPassword && {
                            isInvalid: true,
                          })}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='profile.newPassword' fallback={'Nuova password'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={profile.password || ""}
                          autoComplete="new-password"
                          {...(isLoading && { disabled: true })}
                          onChange={(e) => handleChange(e)}
                          {...(fieldsError.password && { isInvalid: true })}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='profile.confirmNewPassword' fallback={'Conferma nuova password'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="passwordConfirmation"
                          autoComplete="new-password"
                          value={profile.passwordConfirmation || ""}
                          {...(isLoading && { disabled: true })}
                          onChange={(e) => handleChange(e)}
                          {...(fieldsError.passwordConfirmation && {
                            isInvalid: true,
                          })}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          type="button"
                          className="float-end"
                          {...(isLoading && { disabled: true })}
                          onClick={handleSavePassword}
                        >
                          <OETrans code='common.edit' fallback={'Modifica'} />
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Profile;
