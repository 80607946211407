import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { OETrans } from 'components/translation/OETrans';

const NotFoundPage = () => {

return (
    <div className="simple-box text-center not-found error">
        <Row>
            <Col>
                <img
                    src="/img/404-error.png"
                    alt="error"
                    className="img-fluid error-img"
                />
                <h2><OETrans code='common.404.text' fallback={'La pagina che stavi cercando non è stata trovata'}/></h2>
            
                <Link to="/projects"><OETrans code='common.404.link' fallback={'Ritorna ai progetti'}/></Link> 
            </Col>
        </Row>
        
    </div>
    );
};

export default NotFoundPage;