import React from "react";
import { Link } from "react-router-dom";
import { IcoUtenteBox, WorldIcon } from "@icons";

const AdministrationNav = () => {
  const urlSearch = window.location.search;
  const navigation = [
    {
      name: "Utenti",
      link: "/administration?users",
      search: "?users",
      icon: <IcoUtenteBox style={{ width: "35px", height: "35px" }} />,
    },
    {
      name: "Traduzioni",
      link: "/administration?translations",
      search: "?translations",
      icon: <WorldIcon />,
    },
  ];

  return (
    <>
      <ul className="menu-container side-nav">
        {navigation.map((item, index) => {
          // if urlSearch is equal to item.link, then the item is active or if urlSearch is empty and the item is the first one
          const active =
            urlSearch === item.search
              ? "active"
              : urlSearch === "" && index === 0
              ? "active"
              : "";

          return (
            <li className="menu-item" key={index}>
              <Link
                to={item.link}
                className="text-decoration-none"
                title={item.name}
              >
                <span className={`menu-link ${active}`}>
                  <div className="nav-ico">{item.icon}</div>
                  <div className="nav-text">{item.name}</div>
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AdministrationNav;
