export const columnCentered = (headerClass = '', cellStyle = {}) => {
   let tempCellStyle = cellStyle;
   tempCellStyle.textAlign = 'center'; // Add the following if you are using .ag-header-cell-menu-button
   // and column borders are set to none.
   // marginLeft: '-16px'

   return {
      headerClass: 'text-center ' + headerClass,
      cellStyle: tempCellStyle,
   }
};

export const columnCenteredWithFilter = {
   headerClass: 'text-center header-center-with-filter',
   cellStyle: {
      textAlign: 'center',
      // Add the following if you are using .ag-header-cell-menu-button
      // and column borders are set to none.
      // marginLeft: '-16px'
   },
};

export const columnToRight = (headerClass = '', cellClass = '') => {
   /* headerClass: 'text-end',
    cellStyle: {
       textAlign: 'end',
       // Add the following if you are using .ag-header-cell-menu-button
       // and column borders are set to none.
       // marginLeft: '-16px'
    }, */

   return {
      headerClass: 'ag-right-aligned-header ' + headerClass,
      cellClass: 'ag-right-aligned-cell ' + cellClass,
   }
};

export const headerToRight = (headerClass = '') => {
   return {
      headerClass: 'ag-right-aligned-header ' + headerClass,
   }
};

export const headerToCenter = (headerClass = '') => {
   return {
      headerClass: 'text-center ' + headerClass,
   }
};

export const customCellStyle = {
   height: '100%',
   display: 'flex ',

   alignItems: 'center ',
};

export const columnRightGreen = {
   headerClass: 'ag-right-aligned-header ag-header-green',
   cellClass: 'ag-right-aligned-cell',
};

export const columnRightYellow = {
   headerClass: 'ag-right-aligned-header ag-header-yellow',
   cellClass: 'ag-right-aligned-cell',
};

export const columnRightRed = {
   headerClass: 'ag-right-aligned-header ag-header-red',
   cellClass: 'ag-right-aligned-cell',
};