import React from 'react';
import { Footer, Header, SidenavMenu } from './components';

const LayoutSideMenu = ({ children }) => {

   return (
      <div className="App">
         <Header />
         <div className="main-container">
            <div className="desktop-menu">
               <SidenavMenu />
            </div>
            <div className="content-page">
               {children}
            </div>
         </div>
         <Footer />
      </div>
   )
};

const LayoutBase = ({ children }) => {

   return (
      <div className="App">
         <Header />
         <div className="main-container">
            <div className="content-page">
               {children}
            </div>
         </div>
         <Footer />
      </div>
   )
};

const LayoutSimple = ({ children }) => (
   <div className="App">
      <Header />
      {children}
      <Footer />
   </div>
);

const LayoutNoHeader = ({ children }) => (
   <div className="App no-header">
      {children}
      <Footer />
   </div>
);

export { LayoutBase, LayoutSimple, LayoutSideMenu, LayoutNoHeader };
