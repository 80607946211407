import React from "react";
import UsersList from "@components/administration/usersList";
import Translations from "@components/atomic/organisms/Translations/Translations";
import AdministrationNavHorizontal from "@components/nav/administrationNavHorizontal";

const Administration = () => {
   const urlSearch = window.location.search;
   return (<>
      <AdministrationNavHorizontal />

      <div id="administration" className="megaContent">
         {urlSearch === "?users" || urlSearch === "" ? (
            <UsersList isOEUserManagement={true} />
         ) : null}
         {urlSearch === "?translations" ? <Translations /> : null}
      </div>
   </>
   );
};

export default Administration;
