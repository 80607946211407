import React from 'react';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IcoAnalisys } from '@icons';

const ActionBtnRendererProjects = ({ data }) => {
   const id = data.id;

   return (
      <Row className='justify-content-center align-items-center'>
         <Col className='col-auto'>
            <Row>
               <OverlayTrigger placement="top" overlay={<Tooltip>Controllo</Tooltip>}>
                  <Button
                     style={{ padding: '0.25rem 0.45rem', marginTop: '0.15rem' }}
                     variant="outline-info"
                     href={'project/' + id + '/summary'}
                     className="ico-path-fill-second-color"
                  >
                     <IcoAnalisys 
                        className="ico-path-fill-second-color"
                        style={{ width: '17', height: '17'}}  
                     />
                  </Button>
               </OverlayTrigger>
            </Row>
         </Col>
      </Row>
   );
};
export default ActionBtnRendererProjects;
