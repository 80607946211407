import React, { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom-v5-compat";
import {
  IcoSummary,
  IcoCandidacy,
  IcoAgenda,
  IcoEconomic,
  IcoMonitoring,
  IcoReporting,
  IcoTax,
  IcoAnalisys,
  IcoBag,
  Environment,
  ArrowDownUp,
} from '@icons';
import { LevelContext } from 'components/contexts/LevelContext';
import { useEffect } from 'react';

const ProjectNav = () => {
  const { isOe, userRole } = useContext(LevelContext);
  const { projectId } = useParams();
  const { tab, submenu } = useParams();
  const [showDropdown1, setShowDropdown1] = useState(tab === 'execution' && submenu ? true : false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!submenu) {
      setShowDropdown1(false);
    }
  }, [submenu]);

  const handleDropdownToggle = (setDropdown, setOtherDropdown = undefined) => {
    setDropdown((prevState) => !prevState);
    if (setOtherDropdown) setOtherDropdown(false);
  };

  if (!tab) navigate(`/project/${projectId}/dashboard`);

  return (
    <>
      <ul className='menu-container side-nav'>
        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/dashboard`}
            className='text-decoration-none'
            title='Dashboard'>
            <span className={tab === 'dashboard' || !tab ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoSummary />
              </div>
              <div className='nav-text'>{/* <Trans code='A0382' /> */} Dashboard</div>
            </span>
          </Link>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/candidacy`}
            className='text-decoration-none'
            title='Candidatura'>
            <span className={tab === 'candidacy' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoCandidacy />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Candidatura</div>
            </span>
          </Link>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/planning`}
            className='text-decoration-none'
            title='Pianificazione'>
            <span className={tab === 'planning' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoAgenda />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Pianificazione</div>
            </span>
          </Link>
        </li>

        {/* {isOe && ( */}
        <li className={`menu-item ${showDropdown1 ? 'show-dropdown' : ''}`}>
          <Link
            to={`/project/${projectId}/execution/economic/${
              (isOe || userRole === 5) ? 'contracts' : 'industrialaccounts'
            }`}
            className='text-decoration-none'
            onClick={() => handleDropdownToggle(setShowDropdown1)}
            title='Avanzamento'>
            <span className={tab === 'execution' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoEconomic />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Avanzamento</div>
              <div className={`${showDropdown1 ? 'rotateArrow' : ''} arrow-ico mx-2`}>
                <ArrowDownUp />
              </div>
            </span>
          </Link>

          <ul className={`${showDropdown1 ? 'dropdown-content-open' : 'dropdown-content '}`}>
            <li className='menu-item'>
              <Link
                to={`/project/${projectId}/execution/economic/${
                  (isOe || userRole === 5) ? 'contracts' : 'industrialaccounts'
                }`}
                className='text-decoration-none'
                title='Economico'>
                <span className={submenu === 'economic' ? 'menu-link active' : 'menu-link'}>
                  <div className='nav-ico'>
                    <IcoTax />
                  </div>
                  <div className='nav-text'>Economico</div>
                </span>
              </Link>
            </li>
            <li className='menu-item'>
              <Link
                to={`/project/${projectId}/execution/physical`}
                className='text-decoration-none'
                title='Fisico'>
                <span className={submenu === 'physical' ? 'menu-link active' : 'menu-link'}>
                  <div className='nav-ico'>
                    <IcoBag />
                  </div>
                  <div className='nav-text'>Fisico</div>
                </span>
              </Link>
            </li>
          </ul>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/summary`}
            className='text-decoration-none'
            title='Controllo'>
            <span className={tab === 'summary' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoAnalisys />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Controllo</div>
            </span>
          </Link>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/advancement`}
            className='text-decoration-none'
            title='Monitoraggio'>
            <span className={tab === 'advancement' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoMonitoring />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Monitoraggio</div>
            </span>
          </Link>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/dnsh`}
            className='text-decoration-none'
            title='Rendicontazione'>
            <span className={tab === 'dnsh' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <Environment />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} DNSH</div>
            </span>
          </Link>
        </li>

        <li className='menu-item'>
          <Link
            to={`/project/${projectId}/reporting/history`}
            className='text-decoration-none'
            title='Rendicontazione'>
            <span className={tab === 'reporting' ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <IcoReporting />
              </div>
              <div className='nav-text'>{/* <Trans code='A0543' /> */} Rendicontazione</div>
            </span>
          </Link>
        </li>
      </ul>
    </>
  );
};
export default ProjectNav;
