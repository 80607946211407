import React, { useContext } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import Procedural from '@components/advancement/Procedural';
import Financial from '@components/advancement/Financial';
import Physical from '@components/advancement/Physical';
import Milestones from 'components/advancement/Milestones';
import UploadDocument from '@components/form/upload/uploadDocuments';
import Risk from '@components/atomic/organisms/Risk/Risk';
import Deliverable from '@components/atomic/organisms/Deliverable/Deliverable';
import { LevelContext } from 'components/contexts/LevelContext';

const Advancement = ({ project }) => {
   const { isOe, userRole } = useContext(LevelContext);
   const canEdit = isOe || userRole < 50;

  return (
    <>
      <div className='candidacy simple-box scenaritab'>
        <Row>
          <Tabs variant='pills' defaultActiveKey='procedurale' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='procedurale' title='Procedurale'>
              {project?.id && <Procedural projectId={project.id} />}
            </Tab>
            <Tab eventKey='finanziario' title='Finanziario'>
              {project?.id && <Financial projectId={project.id} />}
            </Tab>
            <Tab eventKey='fisico' title='Fisico'>
              {project?.id && <Physical projectId={project.id} />}
            </Tab>
            {userRole < 50 && (
               <Tab eventKey='deliverable' title='Deliverables'>
                  {project?.id && <Deliverable projectId={project.id} />}
               </Tab>
            )}
            {userRole < 50 && (
               <Tab eventKey='milestones' title='Milestones'>
                  {project?.id && <Milestones projectId={project.id} canEdit={canEdit} />}
               </Tab>
            )}
            {userRole < 50 && (
               <Tab eventKey='risk' title='Rischi'>
                  {project?.id && <Risk projectId={project.id} />}
               </Tab>
            )}
            <Tab eventKey='monitoraggio' title='Monitoraggio'>
              {project?.id && <UploadDocument entity='projects' entityId={project.id} filterBySection={['monitoring']} />}
            </Tab>
          </Tabs>
        </Row>
      </div>
    </>
  );
};
export default Advancement;
