import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { IcoEdit, IcoCanc, Lens } from "@icons";
import { editTargetProgresses, deleteTargetProgress } from "@lib/api";
import Select from "react-select";
import { InputNumber, Calendar } from "primereact";
import UploadDocument from "components/form/upload/uploadDocuments";
import StatusBar from "@components/StatusBar";
import { dateFormatter } from "@lib/helpers/formatting";
import { LevelContext } from "components/contexts/LevelContext";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
const ActionBtnRendererProgressTarget = ({
  data,
  setRefresh,
  optionsTargetReasons,
}) => {
  const [editTargetProgress, setEditTargetProgress] = useState({});
  const [selectedTargetReason, setSelectedTargetReason] = useState([]);
  const [optionTargetReason, setOptionTargetReason] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  const [showSpinner, setShowSpinner] = useState(false);
  const [date, setDate] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOe, userRole } = useContext(LevelContext);

  useEffect(() => {
    if (data.id) {
      setEditTargetProgress(data);
    }
  }, [data]);

  useEffect(() => {
    if (optionsTargetReasons.length && data.id)
      setOptionTargetReason(optionsTargetReasons);
  }, [optionsTargetReasons, data]);

  useEffect(() => {
    if (editTargetProgress?.id) {
      if (editTargetProgress?.value !== 0) {
        setDisabled(true);
      } else if (editTargetProgress?.value === 0 && optionTargetReason.length) {
        setDisabled(false);
        setSelectedTargetReason(
          optionTargetReason
            .filter(
              (option) =>
                option.value === editTargetProgress?.targetValueReasonCode
            )
            .map((filteredOpt) => ({
              value: filteredOpt.value,
              label: filteredOpt.label,
            }))
        );
      }
    }
    if (!date && editTargetProgress.id) {
      setDate(new Date(editTargetProgress.date));
    }
  }, [editTargetProgress, optionTargetReason, date]);

  // console.log('editTargetProgress', editTargetProgress);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setEditTargetProgress(data);
    setDate(null);
    // setSelectedTargetReason([]);
    setActiveKey("registry");
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  };

  const handleDelete = async () => {
    const deleteResult = await deleteTargetProgress(
      editTargetProgress?.target?.projectId,
      editTargetProgress?.projectTargetId,
      editTargetProgress?.id
    );

    if (deleteResult.success) {
      handleStatusBar("success", translate('common.message.deleted', 'Elemento eliminato correttamente'), true);
      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(
        "error",
        deleteResult.message ??
          translate('common.error.delete', 'Si è verificato un errore durante l’eliminazione delle informazioni'),
        false
      );
    }
  };

  const onChange = (e, fieldName, setSelect, elIndex, list) => {
    //console.log('e', e);

    let tempNewPhysicalProgress = {
      ...editTargetProgress,
      projectTargetId: editTargetProgress.projectTargetId,
    };
    let value;

    switch (fieldName) {
      case "targetValueReasonCode":
        value = e.value;
        tempNewPhysicalProgress[fieldName] = value;
        setSelect(e);
        break;
      case "value":
        value = !e.value ? 0 : e.value;
        console.log("VALUEC", value);
        tempNewPhysicalProgress[fieldName] = value;
        if (value !== 0) {
          setDisabled(true);
          tempNewPhysicalProgress["targetValueReasonCode"] = null;
          setSelectedTargetReason([]);
        }
        if (value === 0 || value === null) setDisabled(false);
        break;
      case "notRelevant":
        value = e.target.checked;
        tempNewPhysicalProgress[fieldName] = value;
        break;
      case "date":
        value = e.value;
        tempNewPhysicalProgress[fieldName] = dateFormatter(value);
        tempNewPhysicalProgress["year"] = value.getFullYear();
        tempNewPhysicalProgress["month"] = value.getMonth() + 1;
        setSelect(value);
        break;
      default:
        value = e.target.value;
        tempNewPhysicalProgress[fieldName] = value;
    }
    setEditTargetProgress(tempNewPhysicalProgress);
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      target,
      href,
      accruedValue,
      id,
      ...restEditTargetProgress
    } = editTargetProgress;

    const { value, targetValueReasonCode, notRelevant, ...copyToCheck } =
      restEditTargetProgress;

    if (value === 0 && targetValueReasonCode === null) {
      handleStatusBar(
        "error",
        "Selezionare un motivo del progresso target",
        false
      );
      return;
    }

    const isProcedureFilled = Object.values(copyToCheck).every(
      (prop) => prop !== "" && prop != null
    )
      ? true
      : false;

    if (!isProcedureFilled) {
      handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
      return;
    }

    let result;

    setIsLoading(true);
    setShowSpinner(true);
    result = await editTargetProgresses(
      editTargetProgress?.target?.projectId,
      editTargetProgress?.target?.id,
      editTargetProgress?.id,
      restEditTargetProgress
    );

    if (result.success) {
      handleStatusBar("success", translate('common.message.edited', 'Elemento modificato con successo'), true);
      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2500);
    } else {
      handleStatusBar(
        "error",
        result.success === false
          ? Object.values(result.data).join()
          : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
        false
      );
    }

    setIsLoading(false);
    setShowSpinner(false);
  };

  const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.delete' fallback={'Elimina'}/></Tooltip>;
  return (
    <div>
      <OverlayTrigger placement="top" overlay={editTooltip}>
        <span>
          <Button
            variant="link"
            onClick={() => {
              console.log("TargetProgressID", editTargetProgress.id);
              handleShowModal(setShowModalEdit);
            }}
          >
            {isOe || userRole === 5 ? (
              <IcoEdit className="second-color ico-small edit me-3" />
            ) : (
              <Lens className="second-color ico-small edit path me-3" />
            )}
          </Button>
        </span>
      </OverlayTrigger>
      {(isOe || userRole === 5) && (
        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <span>
            <Button
              variant="link"
              className="me-0"
              onClick={() => handleShowModal(setShowModalDelete)}
            >
              <IcoCanc className="second-color ico-small cancel" />
            </Button>
          </span>
        </OverlayTrigger>
      )}
      {showModalEdit && (
        <Modal
          size="lg"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Modifica Progresso Target</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              fill
            >
              {/* Documenti */}
              <Tab eventKey="registry" title={"Dettagli"}>
                {editTargetProgress?.id && (
                  <Form
                    id={`form-edit-target-progress_${editTargetProgress?.target?.name}_${editTargetProgress?.id}`}
                  >
                    <Row style={{ marginBottom: "40px" }}>
                      <Col className="col-6">
                        <Form.Group as={Col}>
                          <Form.Label>Valore</Form.Label>
                          <InputNumber
                            disabled={(!isOe && userRole !== 5) || isLoading}
                            allowEmpty={false}
                            name="value"
                            min={0}
                            step={1}
                            minFractionDigits={0}
                            maxFractionDigits={2}
                            onChange={(e) => onChange(e, "value")}
                            mode="decimal"
                            locale={`${localStorage
                              .getItem("userLanguage")
                              .toLowerCase()}-${localStorage.getItem(
                              "userLanguage"
                            )}`}
                            className="md"
                            suffix={` ${editTargetProgress?.target?.uom}`}
                            value={editTargetProgress.value ?? ""}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="col-6">
                        <Form.Group as={Col}>
                          <Form.Label>Motivo del Progresso Target</Form.Label>
                          <Select
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isDisabled={
                              disabled || (!isOe && userRole !== 5) || isLoading
                            }
                            name="targetValueReasonCode"
                            placeholder={translate('common.placeholder.select', 'Seleziona...')}
                            value={selectedTargetReason}
                            options={optionTargetReason}
                            onChange={(e) =>
                              onChange(
                                e,
                                "targetValueReasonCode",
                                setSelectedTargetReason
                              )
                            }
                          />
                        </Form.Group>
                        {!disabled && (
                          <p className="m-0" style={{ fontSize: ".8rem" }}>
                            In caso di valore impostato a 0 indicarne il motivo.
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "40px" }}>
                      <Col className="col-6">
                        <Form.Group as={Col}>
                          <Form.Label>
                            Data<span className="required-field">*</span>
                          </Form.Label>
                          <Calendar
                            disabled={(!isOe && userRole !== 5) || isLoading}
                            value={date}
                            onChange={(e) => onChange(e, "date", setDate)}
                            view="month"
                            dateFormat="mm/yy"
                            style={{ height: "38px" }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="col-6 d-flex align-items-end justify-content-start">
                        <Form.Group as={Col}>
                          <Form.Check
                            disabled={(!isOe && userRole !== 5) || isLoading}
                            inline
                            type="checkbox"
                            name="notRelevant"
                            defaultChecked={editTargetProgress.notRelevant}
                            onClick={(e) => onChange(e, "notRelevant")}
                          />
                          <Form.Label className="">Non Rileva</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
                {sbShow}
              </Tab>
              <Tab eventKey="documents" title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument
                  entity="projectTargetProgresses"
                  entityId={editTargetProgress.id}
                />
                {sbShow}
              </Tab>
            </Tabs>

            <Row>
              <Col className="col-12">
                <span className="label">
                  <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                </span>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              {isOe || userRole === 5 ? <OETrans code='common.cancel' fallback={'Annulla'}/> : "Chiudi"}
            </Button>
            {(isOe || userRole === 5) && (
              <Button
                variant="primary"
                onClick={(e) => handleEdit(e)}
                disabled={activeKey === "docs" || isLoading}
              >
                <OETrans code='common.edit' fallback={'Modifica'}/>
                {showSpinner && (
                  <Spinner
                    as="span"
                    className="ms-2"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code='common.delete' fallback={'Elimina'}/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRendererProgressTarget;
