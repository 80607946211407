import React from 'react';
import {
   Lens, IcoPlus, TooltipInfoIcon, IcoInvestimentoBox, IcoPilBox, IcoFatturatoBox, IcoImposteBox, IcoRedditiBox, IcoOccupazioneBox,
   IcoMondoBox, IcoUtenteBox, IcoListBox, IcoCircleBox, IcoScenari, IcoScenario, IcoSetup, IcoGeneral, IcoTechnical, IcoEconomic, IcoAdditional,
   IcoAnalisys, IcoAnalisysBox, IcoDashboard, IcoMonitoring, IcoKpiStandard, ArrowDownUp, ArrowUp, ArrowDown, MaxMinimize,
   IcoLens, IcoCanc, IcoEdit, IcoBookBox, CloudArrowDown, Link, IcoCantiere, IcoFatturato, IcoEnterprise, IcoTax,
   IcoHome, IcoBag, IcoHeart, IcoDanger, IcoSuccess, IcoWarning, IcoInfo, ArrowsMeeting, Handshake, SocialNetworking, Sustainable,
   Environment, Logout, Eye, EyeSlash, UsersGear, User, IcoDownloadFile, IcoUploadFile, Print, IcoMinusCircled, IcoSummary, 
   IcoMegaphone, IcoInstitution, IcoCandidacy, IcoAgenda, IcoReporting, IcoGear, IcoCheck, IcoCheckFailed, IcoReported, IcoReportedPartially, 
   ExcelIcon, IcoHexagon, IcoCoins, IcoInfoSerif, IcoTiming,
} from '@icons';
import {
   Ico3, Ico8, Ico19, Ico21, Ico29, Ico33, Ico35, Ico36,
   Ico42, Ico44, Ico45, Ico47, Ico89, Ico90, Ico91, Ico92, Ico93,
   Ico94, Ico95, Ico96, Ico97,
   Ico98, Ico99, Ico100, Ico101,
} from '@icons/new';
import { IcoExclamationPoint } from 'icone/index';

const Icone = () => {
   return (
      <div id='icons-page'>
         <Lens title="Lens" />
         <IcoPlus title="IcoPlus" />
         <TooltipInfoIcon title="TooltipInfoIcon" />
         <IcoInvestimentoBox title="IcoInvestimentoBox" />
         <IcoPilBox title="IcoPilBox" />
         <IcoFatturatoBox title="IcoFatturatoBox" />
         <IcoImposteBox title="IcoImposteBox" />
         <IcoRedditiBox title="IcoRedditiBox" />
         <IcoOccupazioneBox title="IcoOccupazioneBox" />
         <IcoMondoBox title="IcoMondoBox" />
         <IcoUtenteBox title="IcoUtenteBox" />
         <IcoListBox title="IcoListBox" />
         <IcoCircleBox title="IcoCircleBox" />
         <IcoScenari title="IcoScenari" />
         <IcoScenario title="IcoScenario" />
         <IcoSetup title="IcoSetup" />
         <IcoGeneral title="IcoGeneral" />
         <IcoTechnical title="IcoTechnical" />
         <IcoEconomic title="IcoEconomic" />
         <IcoAdditional title="IcoAdditional" />
         <IcoAnalisys title="IcoAnalisys" />
         <IcoAnalisysBox title="IcoAnalisysBox" />
         <IcoDashboard title="IcoDashboard" />
         <IcoMonitoring title="IcoMonitoring" />
         <IcoKpiStandard title="IcoKpiStandard" />
         <ArrowDownUp title="ArrowDownUp" />
         <ArrowUp title="ArrowUp" />
         <ArrowDown title="ArrowDown" />
         <MaxMinimize title="MaxMinimize" />
         <IcoLens title="IcoLens" />
         <IcoCanc title="IcoCanc" />
         <IcoEdit title="IcoEdit" />
         <IcoBookBox title="IcoBookBox" />
         <CloudArrowDown title="CloudArrowDown" />
         <Link title="Link" />
         <IcoCantiere title="IcoCantiere" />
         <IcoFatturato title="IcoFatturato" />
         <IcoEnterprise title="IcoEnterprise" />
         <IcoTax title="IcoTax" />
         <IcoHome title="IcoHome" />
         <IcoBag title="IcoBag" />
         <IcoHeart title="IcoHeart" />
         <IcoDanger title="IcoDanger" />
         <IcoSuccess title="IcoSuccess" />
         <IcoWarning title="IcoWarning" />
         <IcoInfo title="IcoInfo" />
         <ArrowsMeeting title="ArrowsMeeting" />
         <Handshake title="Handshake" />
         <SocialNetworking title="SocialNetworking" />
         <Sustainable title="Sustainable" />
         <Environment title="Environment" />
         <Logout title="Logout" />
         <Eye title="Eye" />
         <EyeSlash title="EyeSlash" />
         <UsersGear title="UsersGear" />
         <User title="User" style={{fill: 'black'}} />
         <IcoDownloadFile title="IcoDownloadFile" />
         <IcoUploadFile title="IcoUploadFile" />
         <Print title="Print" />
         <IcoMinusCircled title="IcoMinusCircled" />
         <IcoSummary title="IcoSummary" style={{fill: 'black'}}/>
         <Ico3 title="Ico3" />
         <Ico8 title="Ico8" />
         <Ico19 title="Ico19" />
         <Ico21 title="Ico21" />
         <Ico29 title="Ico29" />
         <Ico33 title="Ico33" />
         <Ico35 title="Ico35" />
         <Ico36 title="Ico36" />
         <Ico42 title="Ico42" />
         <Ico44 title="Ico44" />
         <Ico45 title="Ico45" />
         <Ico47 title="Ico47" />
         <Ico89 title="Ico89" />
         <Ico90 title="Ico90" />
         <Ico91 title="Ico91" />
         <Ico92 title="Ico92" />
         <Ico93 title="Ico93" />
         <Ico94 title="Ico94" />
         <Ico95 title="Ico95" />
         <Ico96 title="Ico96" />
         <Ico97 title="Ico97" />
         <Ico98 title="Ico98" />
         <Ico99 title="Ico99" />
         <Ico100 title="Ico100" />
         <Ico101 title="Ico101" />
         <IcoInstitution title="IcoInstitution"/>
         <IcoMegaphone title="IcoMegaphone" />
         <IcoCandidacy title="IcoCandidacy" />
         <IcoAgenda title="IcoAgenda" />
         <IcoReporting title="IcoReporting" />
         <IcoGear title="IcoGear" style={{fill: 'black'}} />
         <IcoCheck title="IcoCheck" />
         <IcoCheckFailed title="IcoCheckFailed" />
         <IcoReported title="IcoReported" />
         <IcoReportedPartially title="IcoReportedPartially" />
         <ExcelIcon title="ExcelIcon" style={{fill: 'black'}} />
         <IcoHexagon title="IcoHexagon" style={{fill: 'black'}} /> 
         <IcoCoins title="IcoCoins" />
         <IcoInfoSerif title="IcoInfoSerif"/>
         <IcoTiming title="IcoTiming"/>
         <IcoExclamationPoint title="IcoExclamationPoint"/>
      </div>
   );
};

export default Icone;