import React, { useContext, useState } from 'react';
import FundsList from '@components/setup/FundsList';
import InstrumentsList from '@components/setup/InstrumentsList';
import NoticesList from '@components/setup/NoticesList';
import InstitutionsList from '@components/setup/InstitutionsList';
import ChecklistsList from '@components/setup/ChecklistsList';
//import TendersList from '@components/setup/TendersList';
import { LevelContext } from '@components/contexts/LevelContext';
import Unauthorized from 'components/Unauthorized';
import SetupNavHorizontal from '@components/nav/setupNavHorizontal';

const SetupPage = () => {
  const urlSearch = window.location.search;
  const { isOe, userRole } = useContext(LevelContext);
  const [setupInstruments, setSetupInstruments] = useState(null);
  const [setupInstitutions, setSetupInstitutions] = useState(null);
  //console.log('urlSearch:', urlSearch);
  if (!isOe && userRole !== 5) return <Unauthorized />;
  return (
    <>
      <SetupNavHorizontal />

      {urlSearch === '?funds' && <FundsList />}
      {urlSearch === '?instruments' && (
        <InstrumentsList setSetupInstruments={setSetupInstruments} />
      )}
      {urlSearch === '?institutions' && (
        <InstitutionsList setSetupInstitutions={setSetupInstitutions} />
      )}
      {urlSearch === '?notices' && (
        <NoticesList setupInstruments={setupInstruments} setupInstitutions={setupInstitutions} />
      )}
      {urlSearch === '?checklist' && <ChecklistsList />}
      {/* {urlSearch === '?tenders' && <TendersList />} */}
    </>
  );
};
export default SetupPage;
