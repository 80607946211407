import React, { useEffect, useState, useRef, useCallback } from "react";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { columnCentered } from "@lib/helpers/agGridHelpers";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IcoEdit, IcoCanc, IcoPlus, Lens } from "@icons";
import CustomTooltip from "@components/ag-grid/CustomTooltip";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { dateFormatter } from "@lib/helpers/formatting";
import { ButtonToolbar, FormControl, InputGroup, Col } from "react-bootstrap";
import { Alert } from "components/atomic/atoms";
import { getRisks } from "@lib/api";
import { OETrans } from "components/translation/OETrans";

const RiskTable = ({
  projectId,
  setRiskData,
  setShowFormModal,
  setShowDeleteModal,
  setShowAlert,
  isLoading,
  setIsLoading,
  canEdit,
}) => {
  // State
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();

  const defaultColDef = {
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    suppressMovable: true,
    cellStyle: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params) => {
      return params.value;
    },
  };

  const columnDefs = [
    {
      headerName: "N.",
      headerTooltip: "N.",
      field: "number",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
    },
    {
      headerName: "Descrizione",
      headerTooltip: "Descrizione",
      field: "description",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    },
    {
      headerName: "WP n.",
      headerTooltip: "WP n.",
      field: "wp",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    },
    {
      headerName: "Misura di mitigazione",
      headerTooltip: "Misura di mitigazione",
      field: "mitigation",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    },
    {
      headerName: "Periodo di riferimento",
      headerTooltip: "Periodo di riferimento",
      field: "referencePeriod",
      filter: "agDateColumnFilter",
      minWidth: 150,
      resizable: true,
      flex: 4,
    },
    {
      headerName: "Data effettiva (gg/mm/aaaa)",
      headerTooltip: "Data effettiva (gg/mm/aaaa)",
      field: "effectiveDate ",
      filter: "agTextColumnFilter",
      valueGetter: (params) => {
        if (!params.data.effectiveDate) return "";
        return dateFormatter(params.data.effectiveDate);
      },
      ...columnCentered(),
      minWidth: 150,
    },
    {
      headerName: "La misura è stata applicata?",
      headerTooltip: "La misura è stata applicata?",
      field: "applied",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 3,
      valueGetter: (params) => {
        if (typeof params.data.applied !== "string") {
          return params.data.applied === true ? "SI" : "NO";
        }
        return params.data.applied === "1" ? "SI" : "NO";
      },
      ...columnCentered(),
    },
    {
      headerName: "Il rischio si è concretizzato?",
      headerTooltip: "Il rischio si è concretizzato?",
      field: "happened",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 3,
      valueGetter: (params) => {
        if (typeof params.data.happened !== "string") {
          return params.data.happened === true ? "SI" : "NO";
        }
        return params.data.happened === "1" ? "SI" : "NO";
      },
      ...columnCentered(),
    },
    {
      headerName: "Note",
      headerTooltip: "Note",
      field: "note",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
      hide: true,
    },
    {
      headerName: "",
      minWidth: 110,
      maxWidth: 110,
      sortable: false,
      cellRenderer: (params) => {
        let buttons = [];

        if (canEdit) {
          buttons = [
            {
              action: "edit",
              icon: <IcoEdit className="second-color ico-small edit" />,
              tooltip: <OETrans code='common.tooltip.edit' fallback={'Modifica'}/>,
            },
            {
              action: "delete",
              icon: <IcoCanc className="second-color ico-small cancel" />,
              tooltip: <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>,
            },
          ];
        } else {
          buttons = [
            {
              action: "view",
              icon: <Lens className="second-color ico-small edit path" />,
              tooltip: "Visualizza",
            },
          ];
        }

        return (
          <div className="d-flex justify-content-center align-items-center mt-2">
            {buttons.map((button, index) => (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={<Tooltip>{button.tooltip}</Tooltip>}
              >
                <Button
                  variant="link"
                  className={`me-${index === 0 ? 3 : 0}`}
                  onClick={() => {
                    // check if params.data.applied and params.data.happened are boolean
                    if (typeof params.data.applied !== "string") {
                      params.data.applied =
                        params.data.applied === true ? "1" : "0";
                    }

                    if (typeof params.data.happened !== "string") {
                      params.data.happened =
                        params.data.happened === true ? "1" : "0";
                    }

                    // Remove createdAt, createdBy, updatedAt, updatedBy
                    delete params.data.createdAt;
                    delete params.data.createdBy;
                    delete params.data.updatedAt;
                    delete params.data.updatedBy;
                    setRiskData(params.data);
                    if (button.action === "edit") {
                      setShowFormModal(true);
                    } else if (button.action === "delete") {
                      setShowDeleteModal(true);
                    } else {
                      setShowFormModal(true);
                    }
                  }}
                >
                  {button.icon}
                </Button>
              </OverlayTrigger>
            ))}
          </div>
        );
      },
    },
  ];

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [risks, setRisks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getRisks(projectId).then((res) => {
        if (res.success) {
          setRisks(res.data);
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      });
    };
    if (!isLoading && projectId) {
      fetchData();
    }
  }, [projectId, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 text-right py-1">
          <ButtonToolbar className="justify-content-end">
            {canEdit && (
              <Button
                variant="success"
                {...(isLoading && { disabled: true })}
                onClick={() => setShowFormModal(true)}
              >
                <IcoPlus className="light-color ico-small plus new-button" />
                <span className="d-md-inline">Aggiungi nuovo rischio</span>
              </Button>
            )}
            <InputGroup className="search-table align-items-center mt-3 mt-md-0">
              <FormControl
                type="text"
                className="filter-text-box"
                onInput={(e) => onFilterTextBoxChanged(e)}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 p-0">
          {alert.show && (
            <Col className="pt-3 col-12">
              <Alert
                show={alert.show}
                message={alert.message}
                variant={alert.type}
                delay={5000}
              />
            </Col>
          )}
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
              </div>
            </div>
          ) : (
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={risks}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onGridReady={null}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                ariaDescription="ag-grid-table"
                suppressCellFocus={true}
                autoSizeStrategy={{ type: "fitCellContents" }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RiskTable;
