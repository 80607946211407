const AG_GRID_LOCALE_IT = {
  // Set Filter
  selectAll: '(Seleziona tutto)',
  selectAllSearchResults: '(Seleziona tutti i risultati di ricerca)',
  searchOoo: 'Cerca...',
  blanks: '(Spazi vuoti)',
  noMatches: 'Nessuna corrispondenza',

  // Number Filter & Text Filter
  filterOoo: 'Filtra...',
  equals: 'È uguale a',
  notEqual: 'Non è uguale a',
  blank: 'Vuoto',
  notBlank: 'Non vuoto',
  empty: 'Scegline uno',

  // Number Filter
  lessThan: 'Meno di',
  greaterThan: 'Più grande di',
  lessThanOrEqual: 'Minore o uguale',
  greaterThanOrEqual: 'Maggiore o uguale',
  inRange: 'Nel range',
  inRangeStart: 'da',
  inRangeEnd: 'a',

  // Text Filter
  contains: 'Contiene',
  notContains: 'Non contiene',
  startsWith: 'Inizia con',
  endsWith: 'Finisce con',

  // Date Filter
  dateFormatOoo: 'dd/mm/yyyy',

  // Filter Conditions
  andCondition: 'e',
  orCondition: 'oppure',

  // Filter Buttons
  applyFilter: 'Applica',
  resetFilter: 'Ripristina',
  clearFilter: 'Pulisci',
  cancelFilter: 'Cancella',

  // Filter Titles
  textFilter: 'Filtro di testo',
  numberFilter: 'Filtro numerico',
  dateFilter: 'Filtro data',
  setFilter: 'Imposta filtro',

  // Side Bar
  columns: 'Colonne',
  filters: 'Filtri',

  // columns tool panel
  pivotMode: 'Modalità pivot',
  groups: 'Gruppi di righe',
  rowGroupColumnsEmptyMessage: 'Trascina qui per impostare i gruppi di righe',
  values: 'Valori',
  valueColumnsEmptyMessage: 'Trascina qui per aggregare',
  pivots: 'Etichette di colonna',
  pivotColumnsEmptyMessage:
    'Trascina qui per impostare le etichette delle colonne',

  // Header of the Default Group Column
  group: 'Gruppo',

  // Row Drag
  rowDragRows: 'righe',

  // Other
  loadingOoo: 'Caricamento...',
  noRowsToShow: 'Nessun elemento da mostrare',
  enabled: 'Abilitato',

  // Menu
  pinColumn: 'Pin Column',
  pinLeft: 'Pin Left',
  pinRight: 'Pin Right',
  noPin: 'No Pin',
  valueAggregation: 'Value Aggregation',
  autosizeThiscolumn: 'Autosize This Column',
  autosizeAllColumns: 'Autosize All Columns',
  groupBy: 'Group by',
  ungroupBy: 'Un-Group by',
  addToValues: `Add item to values`, //${variable}
  removeFromValues: `Remove item from values`, //${variable}
  addToLabels: `Add item to labels`, //${variable}
  removeFromLabels: `Remove item from labels`, //${variable}
  resetColumns: 'Reset Columns',
  expandAll: 'Expand All',
  collapseAll: 'Close All',
  copy: 'Copy',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copy With Headers',
  copyWithHeaderGroups: 'Copy With Header Groups',
  paste: 'Paste',
  ctrlV: 'Ctrl+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'None',
  count: 'Count',
  avg: 'Average',
  filteredRows: 'Filtered',
  selectedRows: 'Selected',
  totalRows: 'Total Rows',
  totalAndFilteredRows: 'Rows',
  more: 'More',
  to: 'a',
  of: 'di',
  page: 'Pagina',
  nextPage: 'Prossima Pagina',
  lastPage: 'Ultima Pagina',
  firstPage: 'Prima Pagina',
  previousPage: 'Pagina Precedente',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',

  combinationChart: 'Combination',
  columnLineCombo: 'Column & Line',
  AreaColumnCombo: 'Area & Column',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  combinationGroup: 'Combination',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  columnLineComboTooltip: 'Column & Line',
  areaColumnComboTooltip: 'Area & Column',
  customComboTooltip: 'Custom Combination',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',
  seriesChartType: 'Series Chart Type',
  seriesType: 'Series Type',
  secondaryAxis: 'Secondary Axis',

  // ARIA
  ariaHidden: 'hidden',
  ariaVisible: 'visible',
  ariaChecked: 'checked',
  ariaUnchecked: 'unchecked',
  ariaIndeterminate: 'indeterminate',
  ariaDefaultListName: 'List',
  ariaColumnSelectAll: 'Toggle Select All Columns',
  ariaInputEditor: 'Input Editor',
  ariaDateFilterInput: 'Date Filter Input',
  ariaFilterList: 'Filter List',
  ariaFilterInput: 'Filter Input',
  ariaFilterColumnsInput: 'Filter Columns Input',
  ariaFilterValue: 'Filter Value',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterToValue: 'Filter to value',
  ariaFilteringOperator: 'Filtering Operator',
  ariaColumn: 'Column',
  ariaColumnList: 'Column List',
  ariaColumnGroup: 'Column Group',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaSearch: 'Search',
  ariaSearchFilterValues: 'Search filter values',

  ariaRowGroupDropZonePanelLabel: 'Row Groups',
  ariaValuesDropZonePanelLabel: 'Values',
  ariaPivotDropZonePanelLabel: 'Column Labels',
  ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
  ariaDropZoneColumnValueItemDescription:
    'Press ENTER to change the aggregation type',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.',
};

export default AG_GRID_LOCALE_IT;
