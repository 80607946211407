import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Tab, Tabs, Spinner } from 'react-bootstrap';
import { getEntity } from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { formatToDecimals } from '@lib/helpers/formatting';
import { columnCentered, columnToRight } from '@lib/helpers/agGridHelpers';
import { LevelContext } from 'components/contexts/LevelContext';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { months } from '@lib/helpers/calendar';
import { OETrans } from 'components/translation/OETrans';

const TimesheetDetailSummaryModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   setModalData,
   spinnerVisible,
   isSummary,
   isProjectSco,
}) => {
   const { userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [detail, setDetail] = useState({});
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   const [columnDef, setColumnDef] = useState([]);
   //console.log('detail', detail);

   useEffect(() => {
      if (modalIsVisible && projectId && data.id) {
         getEntity('timesheets', projectId, data.id).then((retrievedTimesheet) => {
            if (retrievedTimesheet.success) {
               setDetail(retrievedTimesheet.data);
            }
         });
      }
   }, [data, modalIsVisible, projectId]);

   const handleCloseModal = (setShowModal) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      setSbShow(<></>);
      setModalData([]);
      setShowModal(false);
   };

   useEffect(() => {
      setColumnDef([
         {
            headerName: 'Anno',
            field: 'year',
            //filter: 'agTextColumnFilter',
            maxWidth: 120,
            minWidth: 100,
            ...columnCentered(),
         },
         {
            headerName: 'Mese',
            field: 'month',
            cellRenderer: (params) => {
               return months()[params.value - 1];
            },
            //filter: 'agTextColumnFilter',
            maxWidth: 140,
            minWidth: 120,
            ...columnCentered(),
         },
         {
            headerName: 'Giorno',
            field: 'day',
            //filter: 'agTextColumnFilter',
            // cellRenderer: ChecklistCellRenderer,

            maxWidth: 130,
            minWidth: 110,
            ...columnCentered(),
         },
         {
            headerName: 'Ore Totali',
            field: 'workHours',
            valueGetter: (params) => {
               return params.data.workHours ? params.data.workHours.toString().replace(".", ',') : '';
            },
            //filter: 'agTextColumnFilter',
            ...columnCentered(),
            maxWidth: 140,
            minWidth: 120,
         },
         {
            headerName: 'Ore Sul Progetto',
            field: 'projectHours',
            valueGetter: (params) => {
               return params.data.projectHours ? params.data.projectHours.toString().replace(".", ',') : '';
            },
            ...columnCentered(),
            maxWidth: 190,
            minWidth: 170,
            //filter: 'agTextColumnFilter',
         },
         {
            headerName: 'Costo Orario €',
            field: 'hourlyRate',
            valueGetter: (params) => {
               return formatToDecimals(params.data.hourlyRate);
            },
            filter: 'agTextColumnFilter',
            //cellStyle: { textAlign: 'right', alignItems: 'center' },
            maxWidth: 190,
            minWidth: 170,
            ...columnToRight(),
         },
         {
            headerName: 'Costo sul Progetto €',
            field: 'projectCost',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.projectCost);
            },
            //cellStyle: { textAlign: 'right', alignItems: 'center' },
            maxWidth: 240,
            minWidth: 220,
            ...columnToRight(),
         },
         {
            headerName: 'Resp. budget',
            field: 'budgetResponsability',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.budgetResponsability ?? 'N.D.';
            },
         },
         {
            headerName: 'Ordine',
            field: 'orderNumber',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.orderNumber ?? 'N.D.';
            },
         },
         {
            headerName: 'WBE',
            field: 'wbe',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.wbe ?? 'N.D.';
            },
         },
         {
            headerName: 'CID',
            field: 'cid',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.cid ?? 'N.D.';
            },
         },


         /* {
            headerName: 'Codice Impianto',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            valueGetter: (params) => {
               return params.data.plant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            valueGetter: (params) => {
               return params.data.plant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.plant?.plantName ?? 'N.D.';
            },
         },
         {
            headerName: 'Codice Impianto Primario',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto Primario',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 240,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto Primario',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 320,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantName ?? 'N.D.';
            },
         }, */
      ]);
   }, []);

   return (
      <div>
         {modalIsVisible && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={modalIsVisible}
               onHide={() => handleCloseModal(setModalIsVisible)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Record {Number(userRole) === 1 || Number(userRole) === 5 ? detail?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill>
                        {/* Anagrafica */}
                        <Tab
                           eventKey='registry'
                           title={'Dati Record'}
                        >
                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>CUP</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.project?.cup ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Stream</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.stream?.streamId
                                       ? `${detail.stream.streamId} - ${detail.stream.name}`
                                       : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Sottovoce di Spesa</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.projectExpenseItem?.name ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Nr. Record</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.number ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Matricola</Form.Label>
                                 <Form.Text as='p'> {detail.serialNumber ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              {!isProjectSco && ( 
                                 <Form.Group as={Col} className='col-4'>
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Text as='p'>
                                       {detail?.employee?.surname ?? 'N.D.'}
                                    </Form.Text>
                                 </Form.Group>
                              )}

                              {!isProjectSco && ( 
                                 <Form.Group as={Col} className='col-4'>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Text as='p'>{detail?.employee?.name ?? 'N.D.'}</Form.Text>
                                 </Form.Group>
                              )}

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Unità Operativa</Form.Label>
                                 <Form.Text as='p'>{detail?.operationalUnit ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Società</Form.Label>
                                 <Form.Text as='p'>{detail?.company ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Data Ingresso</Form.Label>
                                 <Form.Text as='p'>{detail?.entryDate ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Data Uscita</Form.Label>
                                 <Form.Text as='p'>{detail?.exitDate ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Categoria</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.category ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Malattia</Form.Label>
                                 <Form.Text as='p'>{detail?.sickDays ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Ferie</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.vacationDays ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Permessi</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.leaveDays ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Fattispecie RSI</Form.Label>
                                 <Form.Text as='p'>{detail.rdiTypeCode ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              {/* <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Resp. budget</Form.Label>
                                 <Form.Text as='p'>{detail?.details?.budgetResponsability ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>Ordine</Form.Label>
                                 <Form.Text as='p'>{detail?.details?.orderNumber ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>WBE</Form.Label>
                                 <Form.Text as='p'>{detail?.wbe ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>CID</Form.Label>
                                 <Form.Text as='p'>{detail?.details?.cid ?? 'N.D.'}</Form.Text>
                              </Form.Group> */}

                              <Form.Group as={Col} className='col-4'/>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <h2>Dettaglio</h2>
                              {detail?.details && detail?.details.length > 0 ? (
                                 <div><OESimpleTable
                                    columnDefs={columnDef}
                                    rowData={detail?.details}
                                    buttonToolbarVisible={false}
                                    pageSizeId={'payment_timesheets_details'}
                                 /></div>
                              ) : (
                                 <Row className='text-center my-5'>
                                    <span>Non sono presenti fatture.</span>
                                 </Row>
                              )}
                           </Row>

                           {sbShow}
                        </Tab>

                        {/* Documenti */}
                        <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                           {detail.id && <UploadDocument entity='timesheets' entityId={detail.id} projectId={projectId} externalBorder={true} />}
                        </Tab>

                        {/* CheckLists */}
                        {userRole < 70 && (
                           <Tab eventKey='checklist-invoices' title={'Checklist Spese del Personale'} >
                              {detail.id && (
                                 <CheckList
                                    entityType='timesheets'
                                    entityId={detail.id}
                                    disabled={detail?.reported || detail?.reporting}
                                 />
                              )}
                           </Tab>
                        )}
                     </Tabs>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
                     Chiudi
                  </Button>
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default TimesheetDetailSummaryModal;
