import { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { getAllProjectTarget, getOEProjectTargetReasons } from '@lib/api/index';
import PhysicalTargetProgressTab from './PhysicalTargetProgressTab';

const Physical = ({ projectId }) => {
  const [financialList, setFinancialList] = useState([]);
  const [optionsTargetReasons, setOptionsTargetReasons] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (projectId) {
      getAllProjectTarget(projectId).then((projectTarget) => {
        setFinancialList(projectTarget.data);
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (refresh) {
      getAllProjectTarget(projectId).then((projectTarget) => {
        setFinancialList(projectTarget.data);
      });
      setRefresh(false);
    }
  }, [refresh, projectId]);

  useEffect(() => {
    getOEProjectTargetReasons().then((targetReasons) => {
      setOptionsTargetReasons(
        targetReasons.data.map((targetReason) => ({
          value: targetReason.code,
          label: targetReason.name,
        }))
      );
    });
  }, []);

  return (
    <>
      {financialList.length > 0 && (
        <Tabs
          defaultActiveKey={`financial${financialList?.[0].name}`}
          id='financial_progress-tabs'
          className='mb-3'>
          {financialList?.map((financialEl, index) => {
            return (
              <Tab
                key={financialEl.name}
                id={`financial_progress-tabs_${financialEl.name}`}
                eventKey={`financial${financialEl.name}`}
                title={'Target ' + (index + 1)}>
                <PhysicalTargetProgressTab
                  key={financialEl.name}
                  financialEl={financialEl}
                  optionsTargetReasons={optionsTargetReasons}
                  projectId={projectId}
                  setRefresh={setRefresh}
                />
              </Tab>
            );
          })}
        </Tabs>
      )}
    </>
  );
};

export default Physical;
