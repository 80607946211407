import React, { useState, useEffect, useCallback } from 'react';
import NewLegend from '@components/charts/chartLegend';
import { Row, Col } from 'react-bootstrap';
//import { formatToDecimals } from '@lib/helpers/formatting';
import ColumnChart from '@components/charts/flourishCharts/ColumnChart';

const ReportOverview = ({ overviewStats }) => {
   const [totals, setTotals] = useState({});
   const [chartData, setChartData] = useState([]);
   const [legendData, setLegendData] = useState([]);
   //console.log('totals:', totals);

   useEffect(() => {
      setTotals(overviewStats);
   }, [overviewStats]);

   useEffect(() => {
      if (Object.keys(totals).length > 0) {
         //"Valore progetti" -> totalAmount
         //"Avanzamento" -> finalAmount
         //"Incassato" -> cashedIn
         //"Rendicontabile" -> reportedAmount + reportableNotReportedAmount

         let tempChartData = [
            ["Tipo", "Valore"],
            ["Valore progetti", totals.totalAmount],
            ["Avanzamento", totals.finalAmount],
            ["Incassato", totals.cashedIn],
         ];

         setChartData(tempChartData);
      }
   }, [totals]);

   const objectToArray = useCallback(
      (obj, setState) => {
         const translatedPropertyNames = {
            totalAmount: { name: 'Valore progetti', color: '#1c2743', position: 1 },
            finalAmount: { name: 'Avanzamento', color: '#08A2AA', position: 2 },
            cashedIn: { name: 'Incassato', color: '#C2C923', position: 3 },
         };

         const result = Object.keys(obj).map((key) => {
            let total;

            switch (key) {
               case 'finalAmount':
                  total = obj.totalAmount;
                  break;
               default:
                  total = obj.totalAmount;
                  break;
            }

            const percentageTotalValue = total / 100;

            let propertyValue =
               obj[key] < percentageTotalValue && obj[key] !== 0
                  ? obj[key] + percentageTotalValue
                  : obj[key];

            return {
               propertyName: key,
               propertyValue: propertyValue,
               realValue: obj[key],
               translation: translatedPropertyNames[key]?.name,
               color: translatedPropertyNames[key]?.color,
               position: translatedPropertyNames[key]?.position,
            };
         });

         setState(result);
      },
      []
   );

   useEffect(() => {
      if (Object.keys(totals).length) {
         objectToArray(totals, setLegendData);
      }
   }, [totals, objectToArray]);

   const filterArrayByValues = (arr, targetValues, withNull = true) => {
      const test = arr
         .filter((item) => {
            const isTarget = targetValues.includes(item.propertyName);
            const isNonZero = item.propertyValue !== 0;
            return withNull ? isTarget && isNonZero : isTarget;
         })
         .sort((a, b) => a.position - b.position);

      return test;
   };

   return (
      <>
         <div className='simple-box scenaritab position-relative' style={{ minHeight: '43rem', maxHeight: '43rem' }}>
            <div className='d-flex'>
               <div className='col-auto me-auto'>
                  <h5 className='title-simple-box margin-title-box text-uppercase'>Overview</h5>
               </div>

               <div className='col-auto' />
            </div>

            <Row className="">
               <Col className="pt-4">
                  <div style={{ maxWidth: '50rem' }}>
                     {chartData.length > 0 && <ColumnChart data={chartData} />}
                  </div>
               </Col>
            </Row>

            <Row className="position-absolute" style={{bottom: 0, width: '95%'}}>
               <Col className="py-3 px-4">
                  {legendData.length > 0 && (
                     <NewLegend
                        data={filterArrayByValues(legendData, ['totalAmount', 'finalAmount', 'cashedIn'], false)}
                        scope={'TITLE'}
                        totalName='overview-legend-chart'
                        title={'Overview'}
                        className={'chartId'}
                     />
                  )}
               </Col>
            </Row>
         </div>
      </>
   );
};
export default ReportOverview;
