import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const ButtonWithPopover = ({
  popoverText,
  popoverTitle,
  children,
  placement,
  onClick,
  ...props
}) => {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      placement={placement ?? "top"}
      overlay= {<Popover style={{maxWidth: "300px"}}>
      <Popover.Header as="h3">{popoverTitle}</Popover.Header>
      <Popover.Body>
        {popoverText}
      </Popover.Body>
    </Popover>}
    >
      <Button
        {...props}
        {...(props.variant && { variant: props.variant })}
        onClick={onClick}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
};

export default ButtonWithPopover;
