import React, { useEffect, useState } from "react";
import {
  LoginForm,
  ResetPasswordForm,
} from "components/atomic/organisms/index";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";

const HomePage = ({setApiError}) => {
  const url = window.location.href;
  // Check if url contains reset password
  const [showLogin, setShowLogin] = useState( url.includes("reset-password") ? false : true);
  const navigate = useNavigate();

  useEffect(() => {
    if (showLogin === false) {
      // Change showLogin to false
      setShowLogin(false);
      // change navigation url reset-password
      navigate("?reset-password");
    } else {
      setShowLogin(true);
      // change navigation url login
      navigate("/");
    }
  }, [showLogin, setShowLogin]); // eslint-disable-line

  const containerStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Container style={containerStyle} className="container-fluid">
      {showLogin ? (
        <LoginForm setShowLogin={setShowLogin} setApiError={setApiError} />
      ) : (
        <ResetPasswordForm setShowLogin={setShowLogin} />
      )}
    </Container>
  );
};
export default HomePage;
