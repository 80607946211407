import React, { useState, useEffect, useContext } from 'react';
import { OverlayTrigger, Tooltip, Form, Accordion, Button, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { TooltipInfoIcon, IcoDownloadFile } from '@icons';
import { getChecklist, editChecklist, downloadChecklist } from '@lib/api';
import { LevelContext } from '@components/contexts/LevelContext';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import DownloadExcelButtons from '@components/form/DownloadExcelButtons';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const QuestionComponent = ({
   question,
   answerByQuestion,
   handleAnswersChange,
   handleChecked,
   handleValue,
   indexQ,
   checklistId,
   disabled,
   devMode,
}) => {
   const optionsNotes = question.notes.map(note => ({
      value: note.code,
      label: note.name,
   }));

   const renderTooltip = (props) => (
      <Tooltip id='button-tooltip' {...props}>
         {question.description}
      </Tooltip>
   );

   return (
      <>
         <div id={`questionID-${question.code}`} name={question.code} questionid={question.code}>
            {Number(indexQ) !== 0 && <hr className='hr-question' />}
            <div className='row justify-content-start'>
               {devMode ?
                  <div className='col-1'>
                     <span className='question-code'>{question.code}</span>
                  </div> : null
               }

               <div className={`col-6 ${devMode ? '' : 'ps-4'}`}>
                  <div className='row'>
                     {question?.questionsFormatted?.length > 0
                        ? question.questionsFormatted
                        : question.questions}
                     {question?.objective?.length > 0 ? (
                        <span className='fw-bold text-start ps-0' style={{ fontSize: '.7rem' }}>
                           {question?.objective}
                        </span>
                     ) : (
                        ''
                     )}

                     {question.description ? (
                        <OverlayTrigger
                           placement='top'
                           delay={{ show: 200, hide: 400 }}
                           overlay={renderTooltip}>
                           <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                        </OverlayTrigger>
                     ) : null}
                  </div>

                  <div className='row mt-4'>
                     <Form.Control
                        as='textarea'
                        rows={2}
                        disabled={disabled}
                        name={`${question.code}`}
                        placeholder='Note'
                        defaultValue={handleValue(checklistId, question.code, 'note')}
                        onChange={(e) => handleAnswersChange(e, 'note')}
                     />
                  </div>

                  {optionsNotes.length > 0 ?
                     <div className='row mt-4'>
                        <span className='fw-bold text-start ps-0' style={{ fontSize: '.7rem' }}>
                           Dettaglio risposta
                        </span>
                        <Select
                           components={{
                              IndicatorSeparator: () => null,
                           }}
                           className="react-select-container value-nowrap px-0"
                           classNamePrefix="react-select"
                           name="noteCode"
                           placeholder={translate('common.placeholder.select', 'Seleziona...')}
                           value={optionsNotes.filter(opt => opt.value === answerByQuestion.noteCode)}
                           options={optionsNotes}
                           onChange={(e) => handleAnswersChange(e, 'noteCode', question.code)}
                           isDisabled={disabled}
                           isClearable
                        />
                     </div> : null
                  }

                  {/* <div className='row mt-3'>
                     <Form.Control
                        as='textarea'
                        rows={1}
                        disabled={disabled}
                        name={`${question.code}`}
                        placeholder='Note documenti'
                        defaultValue={handleValue(checklistId, question.code, 'documents')}
                        onChange={(e) => handleAnswersChange(e, 'documents')}
                     />
                  </div> */}
               </div>

               <div className='col ps-4 pe-0'>
                  <div className='row text-center'>
                     <div className='col pe-0'>
                        <Form.Check
                           inline
                           type='radio'
                           id={`${question.code}-yes`}
                           disabled={disabled}
                           name={`${question.code}`}
                           onChange={(e) => handleAnswersChange(e, 'yes')}
                           defaultChecked={handleChecked(question.code, 'yes')}
                        />
                     </div>
                     <div className='col pe-0'>
                        <Form.Check
                           inline
                           type='radio'
                           id={`${question.code}-rec`}
                           disabled={disabled}
                           name={`${question.code}`}
                           onChange={(e) => handleAnswersChange(e, 'rec')}
                           defaultChecked={handleChecked(question.code, 'rec')}
                        />
                     </div>
                     <div className='col pe-0'>
                        <Form.Check
                           inline
                           type='radio'
                           id={`${question.code}-no`}
                           disabled={disabled}
                           name={`${question.code}`}
                           onChange={(e) => handleAnswersChange(e, 'no')}
                           defaultChecked={handleChecked(question.code, 'no')}
                        />
                     </div>
                     <div className='col pe-0'>
                        <Form.Check
                           inline
                           type='radio'
                           id={`${question.code}-na`}
                           disabled={disabled}
                           name={`${question.code}`}
                           onChange={(e) => handleAnswersChange(e, 'na')}
                           defaultChecked={handleChecked(question.code, 'na')}
                        />
                     </div>
                     <div className='col pe-0'>
                        <Form.Check
                           inline
                           type='radio'
                           id={`${question.code}-nd`}
                           disabled={disabled}
                           name={`${question.code}`}
                           onChange={(e) => handleAnswersChange(e, 'nd')}
                           defaultChecked={handleChecked(question.code, 'nd')}
                        />
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </>
   );
};

const CheckList = ({ entityType, entityId, disabled = false }) => {
   const { devMode, userRole } = useContext(LevelContext);
   const [checklist, setChecklist] = useState({});
   const [categories, setCategories] = useState([]);
   const [answers, setAnswers] = useState([]);
   const [note, setNote] = useState('');
   const [originNote, setOriginNote] = useState('');
   const [sbShow, setSbShow] = useState(<></>);
   const [initialAnswers, setInitialAnswers] = useState([]);
   const [isPending, setIsPending] = useState(false);
   const [isDownloadingButton1, setIsDownloadingButton1] = useState(false);
   const [isDownloadingButton2, setIsDownloadingButton2] = useState(false);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   useEffect(() => {
      getChecklist(entityType, entityId).then((retrievedChecklist) => {
         if (retrievedChecklist.data) {
            setChecklist(retrievedChecklist.data);
            setAnswers(retrievedChecklist.data.answers);
            setOriginNote(retrievedChecklist.data.note);
            setNote(retrievedChecklist.data.note);

            setInitialAnswers(JSON.parse(JSON.stringify(retrievedChecklist.data.answers)));

            setCategories(
               retrievedChecklist.data.questions
                  .filter(
                     (quest, index, array) =>
                        array.findIndex((el) => el.sectionNumber === quest.sectionNumber) === index
                  )
                  .map((filteredElement) => ({
                     code: filteredElement.code,
                     order: filteredElement.sectionNumber,
                     text: filteredElement.sectionName,
                  }))
            );
         }
      });
   }, [entityId, entityType]);

   useEffect(() => {
      handleChecked();
      handleValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [answers]);

   useEffect(() => {
      setChecklist({});
      setAnswers([]);
      setInitialAnswers([]);
      setCategories([]);
   }, [entityId]);

   const handleAnswersChange = async (e, fieldName, questionCodeSelect) => {
      let tempState = [...answers];

      if (e && e.target) {
         const questionCode = e.target.name;

         switch (e.target.type) {
            case 'radio':
               answers.forEach((answ, index) => {
                  if (answ.questionCode === questionCode) {
                     tempState[index]['yes'] = 0;
                     tempState[index]['no'] = 0;
                     tempState[index]['na'] = 0;
                     tempState[index]['nd'] = 0;
                     tempState[index]['rec'] = 0;
                     tempState[index][fieldName] = 1;
                  }
               });

               break;
            case 'textarea':
               answers.forEach((answ, index) => {
                  if (answ.questionCode === questionCode) {
                     tempState[index][fieldName] = e.target.value;
                  }
               });
               break;
            default:
               break;
         }
      } else if (fieldName === 'noteCode') {
          let value = e ? e.value : null;

            /*let tempElement = {
               ...tempState[elIndex],
               [fieldNameSubject]: value,
               frameworkContractId: detail.id,
            };

            tempState[elIndex] = tempElement; */

            answers.forEach((answ, index) => {
               if (answ.questionCode === questionCodeSelect) {
                  tempState[index][fieldName] = value;
               }
            });
      }

      setAnswers(tempState);
   };

   const handleChecked = (questionCode, fieldName) => {
      let boolean = false;

      answers.forEach((answ) => {
         if (answ.questionCode === questionCode) {
            if (answ[fieldName]) {
               boolean = true;
            } else {
               boolean = false;
            }
         }
      });

      return boolean;
   };

   const handleValue = (checklistId, questionCode, fieldName) => {
      let value;

      value = answers
         .filter((answ) => answ.checklistId === checklistId && answ.questionCode === questionCode)
         .map((filteredAnsw) => filteredAnsw[fieldName]);

      return value ?? '';
   };

   const handleSave = async (e) => {
      e.preventDefault();

      setSbShow(<></>);

      //Take from answers only the modified ones
      let modifiedAnswers = answers.filter((answ) => {
         const initialAnswer = initialAnswers.find((el) => el.id === answ.id);
         return JSON.stringify(answ) !== JSON.stringify(initialAnswer);
      });

      modifiedAnswers = modifiedAnswers.map(({ createdAt, createdBy, updatedAt, updatedBy, ...restItem }) => {
         return restItem;
      });

      if (modifiedAnswers.length === 0 && note === originNote) {
         handleStatusBar({ success: true, message: 'Nessuna modifica da salvare' });
      } else {
         let payload = {
            ...note !== originNote && { note: note },
            ...modifiedAnswers.length > 0 && { answers: modifiedAnswers },
         };

         const editResult = await editChecklist(checklist.id, payload);

         handleStatusBar(editResult);

         if (editResult.success) setInitialAnswers(JSON.parse(JSON.stringify(answers)))
      }
   };

   const handleDownloadCL = async () => {
      setSbShow(<></>);
      setIsPending(true);

      const result = await downloadChecklist(entityType, entityId);

      if (result) {
         setIsPending(false);
      }
   };

   const renderTooltip = (props, classWidth) => {
      let message = '';

      if (props.popper.state) {
         message = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id='button-tooltip' {...props} {...(classWidth ? { className: classWidth } : null)}>
            {message}
         </Tooltip>
      );
   };

   return (
      <div>
         <Form onSubmit={(e) => handleSave(e)} id={'checklist-' + entityType} className='p-0'>
            <div /* className='checklist-scroll' */>
               <div className='row checklist-header'>
                  <div className={devMode ? 'col-7' : 'col-6'}>
                     {entityType === 'tenders' || entityType === 'frameworkContracts' ?
                        <DownloadExcelButtons
                           textHeader={translate('common.downloadexcel', 'Scarica excel')}
                           dropdownItems={[
                              { text: translate('common.workchecklist', 'Checklist di lavoro'), isDownloadingButton: isDownloadingButton1, setIsDownloadingButton: setIsDownloadingButton1, apiDownload: downloadChecklist, apiProps: [entityType, entityId] },
                              { text: translate('common.downloadverticalcl', 'Checklist standard'), isDownloadingButton: isDownloadingButton2, setIsDownloadingButton: setIsDownloadingButton2, apiDownload: downloadChecklist, apiProps: [entityType, entityId, true] }
                           ]}
                           customClassStyle='ms-3'
                        /> :
                        <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.downloadcl' fallback={'Scarica checklist'} /></Tooltip>}>
                           <Button variant="link" className="ms-3" onClick={() => handleDownloadCL()}>
                              <IcoDownloadFile className='second-color ico-small massive-upload align-bottom' />
                           </Button>
                        </OverlayTrigger>
                     }
                     {isPending && (
                        <Spinner
                           as='span'
                           className='ms-2'
                           animation='border'
                           size='sm'
                           role='status'
                           aria-hidden='true'
                        />
                     )}
                  </div>
                  <div className='col px-3'>
                     <div className='row text-center flex-nowrap'>
                        <div className='col fw-bold p-0 cl-custom-width'>SÌ</div>
                        <div className='col fw-bold p-0 cl-custom-width'>REC.</div>
                        <div className='col fw-bold p-0 cl-custom-width'>
                           NO
                           <br />
                           <span className='mt-3 summary-check'>
                              {checklist.answers?.reduce((prev, curr) => prev + curr.no, 0) > 0
                                 ? checklist.answers?.reduce((prev, curr) => prev + curr.no, 0)
                                 : null}{' '}
                              {/* number of NO */}
                           </span>
                        </div>
                        <div className='col fw-bold p-0 cl-custom-width'>N.A.</div>
                        <div className='col fw-bold p-0 cl-custom-width'>N.D.</div>
                     </div>
                  </div>
               </div>

               {checklist.numberOfQuestions > 0
                  ? (<>
                  {categories
                     ?.sort((catA, catB) => catA.order - catB.order)
                     ?.map((category, indexCat) => {
                        return (
                           <div className='row w-100' key={'cat-' + indexCat}>
                              <div className='col-lg-12'>
                                 <div>
                                    {checklist.questions.filter(
                                       (question) => question.sectionNumber === category.order
                                    ).length > 0 ? (
                                       <Accordion
                                          defaultActiveKey={indexCat + 1}
                                          style={{ marginBottom: '1.5rem' }}
                                          flush
                                          alwaysOpen>
                                          <Accordion.Item eventKey={indexCat + 1}>
                                             <Accordion.Header as='h5' className='accordion-title'>
                                                <div className='row w-100 align-items-center'>
                                                   <div className='col-8 pe-3'>
                                                      <div
                                                         className='d-flex title-simple-box margin-title-box align-items-center'
                                                         style={{ height: '100%' }}>
                                                         {category.text}
                                                         {category.description ? (
                                                            <OverlayTrigger
                                                               placement='right'
                                                               delay={{ show: 200, hide: 400 }}
                                                               overlay={renderTooltip}
                                                               popperConfig={{ testObj: category.description }}>
                                                               <TooltipInfoIcon
                                                                  className='tooltip-ico'
                                                                  style={{ width: '1rem' }}
                                                               />
                                                            </OverlayTrigger>
                                                         ) : null}
                                                      </div>
                                                   </div>
                                                   {/* <div className='col summary-check'>&zwnj;</div>
                                             <div className='col summary-check'>&zwnj;</div>
                                             <div className='col summary-check'>{Number(category.order) === checklist.questions.sectionNumber}
                                                {answers.filter((answ) => answ.checklistId === checklist.id &&  Number(answ.no) === 1).length > 0 ?
                                                   answers.filter((answ) => answ.checklistId === checklist.id && Number(answ.no) === 1).length
                                                   : null
                                                }
                                             </div>
                                             <div className='col summary-check'>&zwnj;</div>
                                             <div className='col summary-check'>&zwnj;</div>
                                             <div className='col summary-check'>&zwnj;</div> */}
                                                </div>
                                             </Accordion.Header>
                                             <Accordion.Body className=''>
                                                {checklist.questions
                                                   ?.filter(
                                                      (question) => question.sectionNumber === category.order
                                                   )
                                                   ?.sort(
                                                      (questA, questB) =>
                                                         Number(questA.number) - Number(questB.number)
                                                   )
                                                   ?.map((question, indexQ) => {
                                                      return (
                                                         <QuestionComponent
                                                            key={'quest-' + indexQ}
                                                            question={question}
                                                            answerByQuestion={answers.find(answ => answ.questionCode === question.code)}
                                                            handleChecked={handleChecked}
                                                            handleAnswersChange={handleAnswersChange}
                                                            handleValue={handleValue}
                                                            indexQ={indexQ}
                                                            checklistId={checklist.id}
                                                            disabled={disabled || Number(userRole) >= 40}
                                                            devMode={devMode}
                                                         />
                                                      );
                                                   })}
                                             </Accordion.Body>
                                          </Accordion.Item>
                                       </Accordion>
                                    ) : null}
                                 </div>
                              </div>
                           </div>
                        );
                     })}

                  <div className='row w-100 justify-content-start mt-3 pb-3 px-3'>
                     <hr className='hr-question' style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}}/>
                     <div>
                        <div className='row'>
                           <Form.Group className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='checklist.note' fallback={'Note'} />
                              </Form.Label>
                              <Form.Control
                                 as='textarea'
                                 rows={3}
                                 name='note'
                                 value={note ?? ''}
                                 onChange={(e) => setNote(e.target.value)}
                              />
                           </Form.Group>
                        </div>
                     </div>
                  </div>
                  </>)
                  : null}
            </div>
         </Form>
         {sbShow}
      </div>
   );
};
export default CheckList;
