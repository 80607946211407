import { optionsForMonths } from "@lib/helpers/calendar";
import { Form, Row, Col } from "react-bootstrap";
import { InputNumber } from "primereact";
import Select from "react-select";
import { translate } from "components/translation/translation";

const IndustrialAccountForm = ({ industrialAccount, setIndustrialAccount }) => {

  const localLang = `${localStorage
    .getItem("userLanguage")
    .toLowerCase()}-${localStorage.getItem("userLanguage")}`;
  // Handle changes
  const handleChanges = (e) => {
    setIndustrialAccount({
      ...industrialAccount,
      [e.target.name]: e.target.value,
    });
    return;
  };

  return (
    <Form>
      <Row style={{ marginBottom: "40px" }}>
        <Form.Group as={Col} className="col-6">
          <Form.Label>
            Anno<span className="required-field">*</span>
          </Form.Label>
          <InputNumber
            name="year"
            min={2020}
            max={2500}
            useGrouping={false}
            onValueChange={(e) => handleChanges(e)}
            className="md justify-content-center"
            value={industrialAccount.year ?? ""}
          />
        </Form.Group>

        <Form.Group as={Col} className="col-6">
          <Form.Label>
            Mese<span className="required-field">*</span>
          </Form.Label>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="react-select-container"
            classNamePrefix="react-select"
            name="month"
            placeholder={translate('common.placeholder.select', 'Seleziona...')}
            options={optionsForMonths()}
            value={optionsForMonths().find(
              (option) => option.value === industrialAccount.month
            )}
            onChange={(e) =>
              handleChanges({ target: { name: "month", value: e.value } })
            }
          />
        </Form.Group>
      </Row>
      <Row style={{ marginBottom: "40px" }}>
        <Form.Group as={Col} className="col-12">
          <Form.Label>
            Valore (€)<span className="required-field">*</span>
          </Form.Label>
          <InputNumber
            name="amount"
            min={0}
            step={1}
            minFractionDigits={2}
            maxFractionDigits={2}
            onValueChange={(e) => handleChanges(e)}
            mode="decimal"
            locale={localLang}
            className="md justify-content-center"
            value={industrialAccount.amount ?? ""}
          />
        </Form.Group>
      </Row>
      <Row style={{ marginBottom: "40px" }}>
        <Form.Group as={Col} className=" col-12">
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            maxLength="500"
            name="notes"
            style={{ resize: "none" }}
            value={industrialAccount.notes ?? ""}
            onChange={handleChanges}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default IndustrialAccountForm;
