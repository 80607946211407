import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import { storeDeliverable, updateDeliverable } from "@lib/api";
import UploadDocument from "@components/form/upload/uploadDocuments";
import { OETrans } from "components/translation/OETrans";

const DeliverableForm = ({
  deliverableData,
  setDeliverableData,
  showFormModal,
  setShowFormModal,
  isLoading,
  setIsLoading,
  projectId,
  setShowAlert,
  canEdit,
}) => {
  const [requiredFields, setRequiredFields] = useState({});

  const alwaysRequiredFields = [
    "number",
    /*"description",
    "wp",
    "type",
    "forecastDate",
    "effectiveDate",
    "sent",
    "approved",*/
  ];

  const handleChanges = (e) => {
    const { name, value } = e.target;

    setDeliverableData({
      ...deliverableData,
      [name]: value,
    });

    if (alwaysRequiredFields.includes(name)) {
      if (value.trim() === "" || value === null || value === undefined) {
        setRequiredFields({ ...requiredFields, [name]: true });
      } else {
        const newFields = { ...requiredFields };
        delete newFields[name];
        setRequiredFields(newFields);
      }
    }
  };

  const handleSubmit = (type) => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);
    const newRequiredFields = {};
    alwaysRequiredFields.forEach((field) => {
      if (field === "sent" || field === "approved") {
        if (
          deliverableData[field] === undefined ||
          deliverableData[field] === ""
        ) {
          newRequiredFields[field] = true;
        }
      } else {
        if (!deliverableData[field]) {
          newRequiredFields[field] = true;
        }
      }
    });

    if (Object.keys(newRequiredFields).length > 0) {
      setRequiredFields(newRequiredFields);
      setIsLoading(false);
      return;
    }

    // Convert forecastDate from month to date
    if (deliverableData.forecastDate) {
      const [year, month] = deliverableData.forecastDate.split("-");
      deliverableData.forecastDate = `${year}-${month}-01`;
    }

    if (type === "store") {
      // store deliverable
      storeDeliverable(projectId, deliverableData)
        .then((res) => {
          if (res.success) {
            setShowFormModal(false);
            setDeliverableData({});
            setIsLoading(false);
            setShowAlert({
              show: true,
              message: "Salvataggio avvenuto con successo",
              type: "success",
            });
          } else {
            // Get all keys in data and join messages
            Object.keys(res.data).map((key) => {
              // Se key è un required field, set requiredFields
              if (alwaysRequiredFields.includes(key)) {
                setRequiredFields({ ...requiredFields, [key]: true });
              }
              return res.data[key].join(" ");
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setShowAlert({
            show: true,
            message: "Errore durante il salvataggio",
            type: "danger",
          });
          setIsLoading(false);
        });
    } else {
      updateDeliverable(projectId, deliverableData)
        .then((res) => {
          if (res.success) {
            setShowFormModal(false);
            setDeliverableData({});
            setIsLoading(false);
            setShowAlert({
              show: true,
              message: "Modifica avvenuta con successo",
              type: "success",
            });
          } else {
            // Get all keys in data and join messages
            Object.keys(res.data).map((key) => {
              // Se key è un required field, set requiredFields
              if (alwaysRequiredFields.includes(key)) {
                setRequiredFields({ ...requiredFields, [key]: true });
              }
              return res.data[key].join(" ");
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setShowAlert({
            show: true,
            message: "Errore durante la modifica",
            type: "danger",
          });
          setIsLoading(false);
        });
    }
  };

  const handleModalClose = () => {
    setDeliverableData({});
    setShowFormModal(false);
    setRequiredFields({});
    setIsLoading(false);
  };

  return (
    <Modal
      size="lg"
      show={showFormModal}
      onHide={() => handleModalClose()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="pt-0">
          {deliverableData.id ? 
            (canEdit ? <OETrans code='common.edit' fallback={'Modifica'}/> : "Dettagli") : 
            <OETrans code='common.add' fallback={'Aggiungi'}/>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs className="mb-4" defaultActiveKey="detail" fill>
          <Tab eventKey="detail" title={"Dettagli"}>
            <Form>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="number">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Numero
                      {alwaysRequiredFields.includes("number") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="number"
                          value={deliverableData.number || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!deliverableData.number && requiredFields.number
                            ? "Campo obbligatorio"
                            : "Numero già esistente"}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{deliverableData.number}</Form.Text>}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="description">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Descrizione
                      {alwaysRequiredFields.includes("description") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="description"
                          value={deliverableData.description || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>{deliverableData.description}</Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="wp">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      WP
                      {alwaysRequiredFields.includes("wp") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="wp"
                          value={deliverableData.wp || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.wp}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{deliverableData.wp}</Form.Text>}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="type">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Tipo
                      {alwaysRequiredFields.includes("type") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="type"
                          value={deliverableData.type || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.type}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{deliverableData.type}</Form.Text>}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="forecastDate">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Data prevista
                      {alwaysRequiredFields.includes("forecastDate") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="month"
                          name="forecastDate"
                          value={
                            deliverableData.forecastDate
                              ? deliverableData.forecastDate
                                  .split("-")
                                  .slice(0, 2)
                                  .join("-")
                              : ""
                          }
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.forecastDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {deliverableData.forecastDate &&
                        deliverableData.forecastDate
                          .split("-")
                          .slice(0, 2)
                          .reverse()
                          .join("-")}
                    </Form.Text>
                  )}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="effectiveDate">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Data effettiva
                      {alwaysRequiredFields.includes("effectiveDate") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="date"
                          name="effectiveDate"
                          value={deliverableData.effectiveDate || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.effectiveDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {deliverableData.effectiveDate &&
                        deliverableData.effectiveDate
                          .split("-")
                          .reverse()
                          .join("-")}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="sent">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Inviato
                      {alwaysRequiredFields.includes("sent") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Select
                          name="sent"
                          // Check if exist and convert to string
                          value={deliverableData.sent || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.sent}
                        >
                          <option value="">Seleziona</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {deliverableData.sent === "1" ? "SI" : "NO"}
                    </Form.Text>
                  )}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="approved">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Approvato
                      {alwaysRequiredFields.includes("approved") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Select
                          name="approved"
                          value={deliverableData.approved || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.approved}
                        >
                          <option value="">Seleziona</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {deliverableData.approved === "1" ? "SI" : "NO"}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-12">
                  <Form.Group controlId="note">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Note
                      {alwaysRequiredFields.includes("note") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          as="textarea"
                          name="note"
                          style={{ resize: "none" }}
                          value={deliverableData.note || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.note}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{deliverableData.note}</Form.Text>}
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab
            eventKey="documents"
            title={<OETrans code='common.documents' fallback={'Documenti'} />}
            disabled={!deliverableData.id}
          >
            <UploadDocument
              entity="projectDeliverables"
              entityId={deliverableData.id || ""}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {canEdit ? (
          <>
            <Button variant="secondary" onClick={() => handleModalClose()}>
               <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            <Button
              disabled={isLoading || Object.keys(requiredFields).length > 0}
              type="submit"
              onClick={() =>
                deliverableData.id
                  ? handleSubmit("update")
                  : handleSubmit("store")
              }
              variant="primary"
            >
              {deliverableData.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.add' fallback={'Aggiungi'}/>}
            </Button>
          </>
        ) : (
          <Button variant="secondary" onClick={() => handleModalClose()}>
            Chiudi
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeliverableForm;
