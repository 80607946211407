import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col, Tab, Tabs } from 'react-bootstrap';
import { IcoCanc, IcoEdit } from '@icons';
import { editInstrument, deleteInstrument } from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const ActionBtnRendererInstruments = ({ data, setRefresh }) => {
  const id = data.id;
  const [instrument, setInstrument] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState('registry');
  //console.log('instrument', instrument);

  useEffect(() => {
    setInstrument(data);
  }, [data]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setActiveKey('registry');
    setRefresh(true);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  const handleShowModalTab = (setShowModal, tab) => {
    setShowModal(true);
    setActiveKey(tab);
  };

  const onChange = (e, fieldName) => {
    //console.log('e', e);

    let tempInstrument = { ...instrument };
    let value;

    switch (fieldName) {
      case 'name':
      case 'managingAuthority':
        value = e.target.value;
        break;
      case 'docs':
        break;
      default:
        value = e.target.value;
    }

    tempInstrument[fieldName] = value;

    setInstrument(tempInstrument);
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restInstrument } = instrument;
    //console.log('restClass', restClass);

    if (!instrument.name || !instrument.managingAuthority) {
      handleStatusBar({ success: false, message: translate('common.error.requiredFields', 'Compila i campi obbligatori'), });
      return;
    }

    const editResult = await editInstrument(restInstrument);
    console.log('editResult', editResult);

    if (editResult.success) {
      handleStatusBar({ success: true, message: translate('common.message.edited', 'Elemento modificato con successo') });

      setInstrument(editResult.data);
    } else {
      handleStatusBar(editResult);
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteInstrument(id);
    //console.log('deleteResult', deleteResult);

    if (deleteResult.success) {
      handleStatusBar({ success: true, message: translate('common.message.deleted', 'Elemento eliminato correttamente') });

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(deleteResult);
    }
  };

  const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.delete' fallback={'Elimina'}/></Tooltip>;

  return (
    <div>
      <Button
        variant='outline-info'
        className='py-1 me-4'
        onClick={() => handleShowModalTab(setShowModalEdit, 'docs')}>
        <OETrans code='common.documents' fallback={'Documenti'} />
      </Button>

      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button variant='link' className='me-0' onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      {showModalEdit && (
        <Modal
          size='xl'
          className='modal-with-nav'
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered>
          <Modal.Header closeButton>
            <Modal.Title><OETrans code='common.editinstrument' fallback={'Modifica strumento'}/></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} variant='pills' fill>
              {/* Anagrafica */}
              <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')}>
                <Form onSubmit={handleEdit} id='form-edit-instrument'>
                  <Row style={{ marginBottom: '40px' }}>
                    <Col className='col-6'>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='instruments.name' fallback={'Nome'}/>
                           &nbsp;<span className='required-field'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='name'
                          value={instrument.name}
                          onChange={(e) => onChange(e, 'name')}
                        />
                      </Form.Group>
                    </Col>

                    <Col className='col-6'>
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='instruments.managingAuthority' fallback={'Soggetto gestore'}/>
                           &nbsp;<span className='required-field'>*</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='managingAuthority'
                          value={instrument.managingAuthority}
                          onChange={(e) => onChange(e, 'managingAuthority')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Documenti */}
              <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument entity='instruments' entityId={instrument.id} />
                {sbShow}
              </Tab>
            </Tabs>
            
            <Row>
              <Col className="col-12">
                <span className="label"><span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/></span>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
               <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            <Button
              variant='primary'
              onClick={(e) => handleEdit(e)}
              disabled={activeKey === 'docs'}>
              <OETrans code='common.edit' fallback={'Modifica'}/>
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <OETrans code='common.delete' fallback={'Elimina'}/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ActionBtnRendererInstruments;
