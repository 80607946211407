import React from 'react';
import OpportunityPage from '@components/opportunity/opportunityPage';

const Opportunity = () => {
   return (
      <div id="opportunity" className="megaContent">
         <div className="container-fluid">
            <OpportunityPage />
         </div>
      </div>
   );
};

export default Opportunity;