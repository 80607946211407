import { useEffect, useState } from "react";
import {
  addIndustrialAccounts,
  getIndustrialAccounts,
  deleteIndustrialAccounts,
  setIndustrialAccounts,
} from "@lib/api";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { formatToDecimals } from "@lib/helpers/formatting";
import { IcoPlus } from "@icons";
import { months } from "@lib/helpers/calendar";
import {
  IndustrialAccountForm,
  ButtonWithOverlay,
  DialogConfirmation,
} from "components/atomic/molecules";
import { Alert } from "components/atomic/atoms";
import { IndustrialAccountCard } from "components/atomic/molecules";
import { OETrans } from "components/translation/OETrans";
import { translate } from "components/translation/translation";

const IndustrialAccounts = ({ ...props }) => {
  const projectId = props.projectId;

  // State
  const [industrialAccountsData, setIndustrialAccountsData] = useState([]);
  const [industrialAccount, setIndustrialAccount] = useState({
    year: "",
    month: "",
    amount: "",
    notes: "",
    id: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [alert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Fetch industrial accounts
  const featchIndustrialAccounts = async () => {
    const response = await getIndustrialAccounts(props.projectId);
    setIndustrialAccountsData(response.data || []);
  };

  // Handle edit
  const handleEdit = (industrialAccount) => {
    setIndustrialAccount(industrialAccount);
    setShowModal(true);
  };

  // Handle delete
  const handleDelete = (industrialAccount) => {
    setIndustrialAccount(industrialAccount);
    setShowDeleteModal(true);
  };

  const handleSubmitDelete = async () => {
    setShowAlert({ show: false, message: "", type: "" });
    setShowDeleteModal(false);
    await deleteIndustrialAccounts(projectId, industrialAccount.id)
      .then((res) => {
        if (res.success) {
          setShowAlert({
            show: true,
            message: "Contabilità Industriale eliminata con successo",
            type: "success",
          });
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      })
      .finally(async () => {
        setShowDeleteModal(false);
        setIndustrialAccount({});
        await featchIndustrialAccounts();
      });
  };

  // Handle Close Modal
  const handleModalClose = () => {
    setIndustrialAccount({});
    setShowModal(false);
  };

  const handleSubmit = async () => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);

    if (industrialAccount.id) {
      const { id, year, month, amount, notes } = industrialAccount;
      await setIndustrialAccounts({
        id: id,
        year: year,
        month: month,
        amount: amount,
        notes: notes,
        projectId: projectId,
      }).then((res) => {
        if (res.success) {
          setShowAlert({
            show: true,
            message: "Contabilità Industriale modificata con successo",
            type: "success",
          });
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      });
    } else {
      await addIndustrialAccounts(projectId, industrialAccount).then((res) => {
        if (res.success) {
          setShowAlert({
            show: true,
            message: "Contabilità Industriale aggiunta con successo",
            type: "success",
          });
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      });
    }

    await featchIndustrialAccounts();
    setIndustrialAccount({});
    setShowModal(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      featchIndustrialAccounts();
    }
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row className="mb-4 w-100">
        {alert.show && (
          <Col className="pt-3 col-12">
            <Alert
              show={alert.show}
              message={alert.message}
              variant={alert.type}
              delay={5000}
            />
          </Col>
        )}

        {industrialAccountsData &&
          industrialAccountsData.length > 0 &&
          industrialAccountsData.map((industrialAccount, i) => {
            return (
              <Col
                key={i}
                className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2"
                style={{ minHeight: "200px" }}
              >
                <IndustrialAccountCard
                  isFirst={i === 0}
                  year={industrialAccount.year}
                  notes={industrialAccount.notes}
                  month={months()[industrialAccount.month - 1]}
                  amount={formatToDecimals(industrialAccount.amount)}
                  onEdit={() => handleEdit(industrialAccount)}
                  onDelete={() => handleDelete(industrialAccount)}
                />
              </Col>
            );
          })}
        <Col
          style={{ minHeight: "200px" }}
          className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2"
        >
          <div
            className="mb-3 p-3  d-flex justify-content-center align-items-center"
            style={{
              width: "100%",
              height: "78%",
              border: "1px dashed #e5e5e5",
              borderRadius: "10px",
              margin: "20px 0",
            }}
          >
            <ButtonWithOverlay
              tooltipText={translate('common.tooltip.add', 'Aggiungi')}
              variant="link"
              onClick={() => setShowModal(true)}
            >
              <IcoPlus style={{ width: "50", height: "50" }} />
            </ButtonWithOverlay>
          </div>
        </Col>
      </Row>

      <DialogConfirmation
        title="Elimina Contabilità Industriale"
        content="Sei sicuro di voler eliminare la contabilità industriale?"
        confirmLabel={translate('common.delete', 'Elimina')}
        cancelLabel={translate('common.cancel', 'Annulla')}
        onConfirm={() => handleSubmitDelete()}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />

      <Modal show={showModal} onHide={() => handleModalClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pt-0">
            {industrialAccount.id ? <OETrans code='common.edit' fallback={'Modifica'}/> : <OETrans code='common.add' fallback={'Aggiungi'}/>} Contabilità
            Industriale
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IndustrialAccountForm
            industrialAccount={industrialAccount}
            setIndustrialAccount={setIndustrialAccount}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            {...(isLoading && { disabled: true })}
            variant="secondary"
            onClick={() => handleModalClose()}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            onClick={() => handleSubmit()}
            variant="primary"
          >
            {isLoading
              ? "Caricamento..."
              : industrialAccount.id
              ? <OETrans code='common.save' fallback={'Salva'}/>
              : <OETrans code='common.add' fallback={'Aggiungi'}/>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IndustrialAccounts;
