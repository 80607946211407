import React, { useContext } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom-v5-compat";
import { Nav, Navbar, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import { LevelContext } from 'components/contexts/LevelContext';

const ProjectNavHorizontal = () => {
   const { isOe, userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const { tab, submenu } = useParams();
   const location = useLocation();
   const isProjectSection = location.pathname.split('/').slice(0, 2).join('/') === '/project' ? true : false;
   const navigate = useNavigate();

   if (!tab) navigate(`/project/${projectId}/dashboard`);

   return (
      <>
         {isProjectSection && (
            <Navbar className='sub-navbar'>
               <Nav activeKey={tab} className="justify-content-evenly">
                  <Nav.Item>
                     <Nav.Link as={Link} to={`/project/${projectId}/dashboard`} eventKey='dashboard'>
                        Dashboard
                     </Nav.Link>
                  </Nav.Item>

                  {userRole < 70 && (
                     <Nav.Item>
                        <Nav.Link as={Link} to={`/project/${projectId}/candidacy`} eventKey='candidacy'>
                           Candidatura
                        </Nav.Link>
                     </Nav.Item>
                  )}
 
                  {userRole < 50 && (
                     <Nav.Item>
                        <Nav.Link as={Link} to={`/project/${projectId}/planning`} eventKey='planning'>
                           Pianificazione
                        </Nav.Link>
                     </Nav.Item>
                  )}
                  
                  {userRole < 50 && (
                     <Nav activeKey={submenu} className="sub-nav-dropdown">
                        <DropdownButton
                           as={ButtonGroup}
                           className="sub-nav-dropdown horizontal"
                           title="Avanzamento"
                        >
                           <Dropdown.Item 
                              as={Link} 
                              to={`/project/${projectId}/execution/economic/${(isOe || userRole === 5) ? 'contracts' : 'industrialaccounts'}`}
                              eventKey='economic'
                           >
                              Economico
                           </Dropdown.Item>

                           <Dropdown.Item as={Link} to={`/project/${projectId}/execution/physical`} eventKey='physical'>
                              Fisico
                           </Dropdown.Item>
                        </DropdownButton>
                     </Nav>
                  )}

                  <Nav.Item>
                     <Nav.Link as={Link} to={`/project/${projectId}/summary`} eventKey='summary'>
                        Controllo
                     </Nav.Link>
                  </Nav.Item>

                  {userRole < 70 && (
                     <Nav.Item>
                        <Nav.Link as={Link} to={`/project/${projectId}/advancement`} eventKey='advancement'>
                           Monitoraggio
                        </Nav.Link>
                     </Nav.Item>
                  )}

                  {userRole < 70 && (
                     <Nav.Item>
                        <Nav.Link as={Link} to={`/project/${projectId}/dnsh`} eventKey='dnsh'>
                           DNSH
                        </Nav.Link>
                     </Nav.Item>
                  )}

                  {userRole < 70 && (
                     <Nav.Item>
                        <Nav.Link as={Link} to={`/project/${projectId}/reporting/history`} eventKey='reporting'>
                           Rendicontazione
                        </Nav.Link>
                     </Nav.Item>
                  )}
               </Nav>
            </Navbar>
         )}
      </>
   );
};
export default ProjectNavHorizontal;
