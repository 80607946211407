import React from "react";
import { Modal, Button } from "react-bootstrap";

const DialogConfirmation = ({
  title,
  content,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  show,
  ...props
}) => {
  const typeConfirm = props.typeConfirm || "danger";
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3">
          <span>{content}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button variant={typeConfirm} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogConfirmation;
