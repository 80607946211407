import React, { useState, useEffect, useContext } from 'react';
import { OverlayTrigger, Tooltip, Form, Tab, Tabs, Button, Col, Row } from 'react-bootstrap';
import { TooltipInfoIcon } from '@icons';
import { editDnshChecklistsAnswer } from '@lib/api';
import StatusBar from '@components/StatusBar';
import { LevelContext } from 'components/contexts/LevelContext';
import { OETrans } from 'components/translation/OETrans';
import { translate } from 'components/translation/translation';

const QuestionComponent = ({
  question,
  handleAnswersChange,
  handleChecked,
  handleValue,
  indexQ,
  checklistId,
  mandatoryNotes,
  devMode,
}) => {
  const { isOe, userRole } = useContext(LevelContext);
  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {question.description}
    </Tooltip>
  );

  return (
    <>
      <div id={`questionID-${question.code}`} name={question.code} questionid={question.code}>
        {Number(indexQ) !== 0 && <hr className='hr-question' />}
        <div className='row justify-content-start'>
          {question.type === 'Q' ? (
            <>
               {devMode ? 
                  <div className='col-1'>
                     <span className='question-code'>{question.code}</span>
                  </div> : null
               }

              <div className={`col-6 ${devMode ? '' : 'ps-4'}`}>
                <div className='row'>
                  {question?.questionsFormatted ? question.questionsFormatted : question.question}

                  {question?.objective?.length > 0 ? (
                     <span className='fw-bold text-start ps-0' style={{ fontSize: '.7rem' }}>
                        {question?.objective}
                     </span>
                  ) : ''}

                  {question.description ? (
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  ) : null}
                </div>

                <div className='row mt-4'>
                  <Form.Control
                    style={{
                      backgroundColor: `${
                        mandatoryNotes ? 'rgba(8, 162, 170, .1)' : 'transparent'
                      }`,
                    }}
                    as='textarea'
                    rows={2}
                    disabled={(!isOe && userRole !== 5)}
                    name={`${question.code}`}
                    placeholder='Note'
                    defaultValue={handleValue(checklistId, question.code, 'note')}
                    onChange={(e) => handleAnswersChange(e, 'note')}
                  />
                </div>

                {/* <div className='row mt-3'>
                  <Form.Control
                    as='textarea'
                    rows={1}
                    disabled={!isOe && userRole !== 5}
                    name={`${question.code}`}
                    placeholder='Note documenti'
                    defaultValue={handleValue(checklistId, question.code, 'documents')}
                    onChange={(e) => handleAnswersChange(e, 'documents')}
                  />
                </div> */}
              </div>

              <div className='col px-3'>
                <div className='row text-center'>
                  <div className='col ps-3 p-0'>
                    <Form.Check
                      inline
                      type='radio'
                      id={`${question.code}-yes`}
                      disabled={(!isOe && userRole !== 5)}
                      name={`${question.code}`}
                      onChange={(e) => handleAnswersChange(e, 'yes')}
                      defaultChecked={handleChecked(question.code, 'yes')}
                      className='dnshRadioAnswer'
                    />
                  </div>
                  <div className='col ps-3 p-0'>
                    <Form.Check
                      inline
                      type='radio'
                      id={`${question.code}-no`}
                      disabled={(!isOe && userRole !== 5)}
                      name={`${question.code}`}
                      onChange={(e) => handleAnswersChange(e, 'no')}
                      defaultChecked={handleChecked(question.code, 'no')}
                      className='dnshRadioAnswer'
                    />
                  </div>
                  <div className='col ps-3 p-0'>
                    <Form.Check
                      inline
                      type='radio'
                      id={`${question.code}-na`}
                      disabled={(!isOe && userRole !== 5)}
                      name={`${question.code}`}
                      onChange={(e) => handleAnswersChange(e, 'na')}
                      defaultChecked={handleChecked(question.code, 'na')}
                      className='dnshRadioAnswer'
                    />
                  </div>
                  {/* <div className='col p-0'>
                    <Form.Check
                      inline
                      type='radio'
                      id={`${question.code}-nd`}
                      disabled={!isOe && userRole !== 5}
                      name={`${question.code}`}
                      onChange={(e) => handleAnswersChange(e, 'nd')}
                      defaultChecked={handleChecked(question.code, 'nd')}
                    />
                  </div> */}
                  {/* <div className='col p-0'>
                    <Form.Check
                      inline
                      type='radio'
                      id={`${question.code}-rec`}
                      disabled={!isOe && userRole !== 5}
                      name={`${question.code}`}
                      onChange={(e) => handleAnswersChange(e, 'rec')}
                      defaultChecked={handleChecked(question.code, 'rec')}
                    />
                  </div> */}
                </div>
              </div>

              <div className='col-1'></div>
            </>
          ) : (
            <Row
              className='py-3'
              style={{ fontWeight: '500', backgroundColor: 'rgba(194, 201, 35, .1)' }}>
              <div className='col-1'>
                <span className='question-code instruction'>ISTRUZIONE</span>
              </div>

              <div className='col-11'>
                <div className='row' style={{ fontStyle: 'italic' }}>
                  {question.question}
                  {question.description ? (
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  ) : null}
                </div>
              </div>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

const CheckListDnsh = ({ data }) => {
  const { isOe, userRole, devMode } = useContext(LevelContext);
  const [checklist, setChecklist] = useState({});
  const [categories, setCategories] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const [mandatoryNotes, setMandatoryNotes] = useState([]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (data) {
      setChecklist(data);
      setAnswers(data?.dnshanswers);

      //SAve initial answers
      setInitialAnswers(JSON.parse(JSON.stringify(data?.dnshanswers)));

      setCategories(
        data?.dnshquestions
          .filter(
            (quest, index, array) => array.findIndex((el) => el.phase === quest.phase) === index
          )
          .map((filteredElement) => ({
            code: filteredElement.code,
            order: filteredElement.position,
            text: filteredElement.phase,
            techFile: filteredElement.technicalFile,
          }))
      );
    }
  }, [data]);

  useEffect(() => {
    handleChecked();
    handleValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const handleAnswersChange = async (e, fieldName) => {
    const questionCode = e.target.name;

    let tempState = [...answers];

    switch (e.target.type) {
      case 'radio':
        answers.forEach((answ, index) => {
          if (answ.questionCode === questionCode) {
            tempState[index]['yes'] = 0;
            tempState[index]['no'] = 0;
            tempState[index]['na'] = 0;
            tempState[index][fieldName] = 1;
          }
        });
        break;
      case 'textarea':
        answers.forEach((answ, index) => {
          if (answ.questionCode === questionCode) {
            tempState[index][fieldName] = e.target.value;
          }
        });

        break;
      default:
        console.log('');
    }

    setAnswers(tempState);
  };

  const handleChecked = (questionCode, fieldName) => {
    let boolean = false;
    answers.forEach((answ) => {
      if (answ.questionCode === questionCode) {
        if (answ[fieldName]) {
          boolean = true;
        } else {
          boolean = false;
        }
      }
    });

    return boolean;
  };

  const handleValue = (checklistId, questionCode, fieldName) => {
    let value;

    value = answers
      .filter((answ) => answ.dnshChecklistId === checklistId && answ.questionCode === questionCode)
      .map((filteredAnsw) => filteredAnsw[fieldName]);

    return value ?? '';
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let checklistResult = [];
    setMandatoryNotes([]);
    // let naAnswers = [];
    //for..in/for..of waits for async calls

    //Take from answers only the modified ones
    const modifiedAnswers = answers.filter((answ) => {
      const initialAnswer = initialAnswers.find((el) => el.id === answ.id);
      return JSON.stringify(answ) !== JSON.stringify(initialAnswer);
    });

    const naAnswers = modifiedAnswers.filter((el) => el.na === 1 && !el.note);

    if (naAnswers.length > 0) {
      handleStatusBar('error', `Il commento è obbigatorio nelle risposte N.A.`, true);
      setMandatoryNotes(naAnswers);
      return;
    }

    for (const answ of modifiedAnswers) {
      const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } = answ;
      const resultEdit = await editDnshChecklistsAnswer(restItem, answ.dnshChecklistId, answ.id);
      checklistResult.push(resultEdit.success);
    }

    if (modifiedAnswers && !modifiedAnswers.length) {
      console.log('Is Empty');
      handleStatusBar('success', 'Nessuna modifica da salvare', true);
    } else if (checklistResult.length > 0 && checklistResult.every((el) => el === true)) {
      handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true);

      //Update initial answers
      setInitialAnswers(JSON.parse(JSON.stringify(answers)));
      /* setTimeout(() => {
            }, 2000); */
    } else {
      handleStatusBar(
        'error',
        /* result.message ?? */ translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
        false
      );
    }
  };

  // const renderTooltip = (props, classWidth) => {
  //   let message = '';
  //   //console.log('classWidth', classWidth);
  //   //console.log('props', props);

  //   if (props.popper.state) {
  //     message = props.popper.state.options.testObj;
  //   }

  //   return (
  //     <Tooltip id='button-tooltip' {...props} {...(classWidth ? { className: classWidth } : null)}>
  //       {message}
  //     </Tooltip>
  //   );
  // };

  return (
    <div>
      {categories.length > 0 && (
        <Form
          onSubmit={(e) => handleSave(e)}
          id={`checklistdnsh_${data.id}`}
          className='p-0'
          style={{ position: 'relative' }}>
          <Tabs
            defaultActiveKey={`${categories?.[0]?.techFile}_0`}
            id={`tabs_checklistdnsh_${data.id}`}
            className='mb-3'>
            {categories.map((catEl, indexCat) => (
              <Tab
                key={`${catEl.techFile}_${indexCat}`}
                eventKey={`${catEl.techFile}_${indexCat}`}
                title={catEl.text === 'ante' ? 'Checklist Ante' : 'Checklist Post'}>
                <div className='checklist-scroll'>
                  <div className='row checklist-header'>
                    <div className={devMode ? 'col-7' : 'col-6'}></div>
                    <div className='col-4 px-3'>
                      <div className='row text-center flex-nowrap'>
                        <div className='col fw-bold ps-2 pe-0 cl-custom-width'>SÌ</div>
                        <div className='col fw-bold ps-2 pe-0 cl-custom-width'>
                          NO
                          <br />
                          <span className='mt-3 summary-check'>
                            {answers?.reduce((prev, curr) => prev + curr.no, 0) > 0
                              ? answers?.reduce((prev, curr) => prev + curr.no, 0)
                              : null}{' '}
                            {/* number of NO */}
                          </span>
                        </div>
                        <div className='col fw-bold ps-2 pe-0 cl-custom-width'>N.A.</div>
                      </div>
                    </div>
                    <div className='col-1'></div>
                  </div>

                  {checklist.numberOfQuestions > 0 ? (
                    <div className='row w-100' key={'cat-' + indexCat}>
                      <div className='col-lg-12 px-0'>
                        <div>
                          {checklist.dnshquestions.filter(
                            (question) => question.position === catEl.order
                          ).length > 0
                            ? checklist?.dnshquestions
                                ?.filter((question) => question.phase === catEl.text)
                                ?.sort(
                                  (questA, questB) => Number(questA.number) - Number(questB.number)
                                )
                                ?.map((question, indexQ) => {
                                  return (
                                    <div
                                      className='px-3'
                                      key={
                                        'div-' +
                                        data.id +
                                        question.technicalFile +
                                        question.position +
                                        indexQ
                                      }>
                                      <QuestionComponent
                                        key={
                                          'quest-' +
                                          data.id +
                                          question.technicalFile +
                                          question.position +
                                          indexQ
                                        }
                                        questions={checklist}
                                        question={question}
                                        handleChecked={handleChecked}
                                        handleAnswersChange={handleAnswersChange}
                                        handleValue={handleValue}
                                        indexQ={indexQ}
                                        checklistId={checklist.id}
                                        mandatoryNotes={mandatoryNotes.find(
                                          (el) => el.questionCode === question.code
                                        )}
                                        devMode={devMode}
                                      />
                                    </div>
                                  );
                                })
                            : null}
                        </div>
                      </div>
                      {(isOe || userRole === 5) && (
                        <Row className='mt-4'>
                          <Col className='d-flex align-items-center justify-content-center'>
                            <Button
                              type='submit'
                              form={`checklistdnsh_${data.id}`}
                              variant='primary'
                              onClick={() => setSbShow(<></>)}>
                                 <OETrans code='common.save' fallback={'Salva'}/>
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : null}
                </div>
              </Tab>
            ))}
          </Tabs>
        </Form>
      )}
      {sbShow}
    </div>
  );
};
export default CheckListDnsh;
