import React from 'react';
import ReportsPage from '@components/reports/ReportsPage';

const Reports = () => {
  return (
    <div id="reports" className="megaContent">
      <div className="container-fluid">
        <ReportsPage />
      </div>
    </div>
  );
};
export default Reports;