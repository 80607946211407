import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tab, Tabs, Spinner, Accordion } from 'react-bootstrap';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { dateFormatter } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { getOeSubjectRoles, getAllInvolvedSubjectsByFrameworkContract } from '@lib/api/index';
import { OETrans } from 'components/translation/OETrans';

/* const test = [{
   frameworkContractId: 1,
   role: '10',
   name: 'test',
   vatNumber: 'test',
   countryCode: 'test',
   subscriptionDate: '2024/06/06',
   validityPeriod: 'test',
   dl772021: 'test'
},
{
   frameworkContractId: 2,
   role: '10',
   name: 'test',
   vatNumber: 'test',
   countryCode: 'test',
   subscriptionDate: '2024/06/06',
   validityPeriod: 'test',
   dl772021: 'test'
}]; */

const FrameworkContractDetailModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   setModalData,
   spinnerVisible,
   isSummary,
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [detail, setDetail] = useState({});
   const [involvedSubjects, setInvolvedSubjects] = useState([]);
   const [optionsSubjectRoles, setOptionsSubjectRoles] = useState([]);
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   const [selectedSubjectRole, setSelectedSubjectRole] = useState({});
   //console.log('involvedSubjects', involvedSubjects);

   useEffect(() => {
      setDetail(data);
   }, [data]);

   useEffect(() => {
      getOeSubjectRoles().then((retrievedSubjectRole) => {
         if (retrievedSubjectRole.success) {
            setOptionsSubjectRoles(
               retrievedSubjectRole.data.map((subjectRole) => ({
                  value: subjectRole.code,
                  label: subjectRole.name,
               }))
            );

            setSelectedSubjectRole(
               ...retrievedSubjectRole.data
                  .filter((filteredSubject) => filteredSubject.code === data?.subjectRole)
                  .map((subjectRole) => ({
                     value: subjectRole.code,
                     label: subjectRole.name,
                  }))
            );
         }
      });
   }, [data]);

   useEffect(() => {
      if (detail.id) {
         getAllInvolvedSubjectsByFrameworkContract(detail.id).then((retrievedSubjects) => {
            if (retrievedSubjects.success) {
               setInvolvedSubjects(retrievedSubjects.data);
            }
         });
      }
   }, [detail]);

   const handleCloseModal = (setShowModal) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      setSbShow(<></>);
      setModalData([]);
      setShowModal(false);
   };

   return (
      <div>
         {modalIsVisible && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={modalIsVisible}
               onHide={() => handleCloseModal(setModalIsVisible)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Contratto aperto {Number(userRole) === 1 || Number(userRole) === 5 ? detail?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill
                        variant='pills'
                     >
                        {/* Anagrafica */}
                        <Tab
                           eventKey='registry'
                           title={'Dati Contratto'}
                        >
                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>ID Gara</Form.Label>
                                 <Form.Text as='p'>{detail.tender?.tenderNumber ?? ''}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>CIG</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.cigs?.map((cig) => (
                                       <Fragment key={cig.id}>
                                          {cig.cig}
                                          <br />
                                       </Fragment>
                                    )) ?? ''}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>ID Contratto</Form.Label>
                                 <Form.Text as='p'>{detail.number ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Data Sottoscrizione</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.date ? dateFormatter(detail?.date) : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Periodo di Vigenza</Form.Label>
                                 <Form.Text as='p'>{detail?.lifetime ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Estensione</Form.Label>
                                 <Form.Text as='p'>{detail?.extension ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Codice Nazione</Form.Label>
                                 <Form.Text as='p'>{detail?.countryCode ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label >Codice Fiscale - P.IVA Soggetto</Form.Label>
                                 <Form.Text as='p'>{detail?.vatNumber ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Ruolo Soggetto</Form.Label>
                                 <Form.Text as='p'>
                                    {!selectedSubjectRole ? 'N.D.' : selectedSubjectRole.label}
                                 </Form.Text>
                              </Form.Group>
                           </Row>

                           <Row>
                              <Form.Group as={Col} className="col-4">
                                 <Form.Label>Presenza delle Firme</Form.Label>
                                 <Form.Text as='p'>{detail?.checkSignatures ? 'Si' : 'No'}</Form.Text>
                              </Form.Group>
                           </Row>
                           {sbShow}
                        </Tab>

                        {/* Soggetti coinvolti */}
                        <Tab eventKey='involvedSubjects' title={<OETrans code='common.subjectsinvolved' fallback={'Soggetti coinvolti'} />}>
                           {involvedSubjects.length > 0 ?
                              involvedSubjects.map((el, index) => {
                                 return (<Fragment key={"involvedSubjects-" + index}>
                                    <Accordion defaultActiveKey="0">
                                       <Accordion.Item eventKey={index}>
                                          <Accordion.Header>
                                             <span className='align-middle'><OETrans code='common.subject' fallback={'Soggetto'} />&nbsp;{index + 1}</span>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                             <Row>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.role' fallback={'Ruolo'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.role ?
                                                            optionsSubjectRoles.filter(opt => opt.value === el.role).map(el => el.label)
                                                            : 'N.D.'
                                                         }
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.name' fallback={'Nome'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.name ?? 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.vatnumber' fallback={'Partita IVA'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.vatNumber ?? 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                             </Row>

                                             <Row>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.countrycode' fallback={'Prefisso Internazionale'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.countryCode ?? 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.subscriptiondate' fallback={'Data di sottoscrizione'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.subscriptionDate ? dateFormatter(el.subscriptionDate) : 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                                <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.validityperiod' fallback={'Periodo di validità'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.validityPeriod ?? 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col>
                                                {/* <Col className='col-4'>
                                                   <Form.Group as={Col} className='pe-2'>
                                                      <Form.Label className='mb-3'>
                                                         <OETrans code='involvedsubjects.dl772021' fallback={'D.L. 77/2021'} />
                                                      </Form.Label>
                                                      <Form.Text as='p'>
                                                         {el.dl772021 ?? 'N.D.'}
                                                      </Form.Text>
                                                   </Form.Group>
                                                </Col> */}
                                             </Row>
                                          </Accordion.Body>
                                       </Accordion.Item>
                                    </Accordion>
                                 </Fragment>
                                 );
                              }) :
                              <OETrans code='common.opportunity.noitemsfound' fallback={'Nessun elemento trovato'} />
                           }
                        </Tab>

                        {/* Documenti */}
                        <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                           {detail.id && <>
                              <UploadDocument entity='frameworkContracts' entityId={detail.id} />

                              {involvedSubjects.length > 0 &&
                                 <Row>
                                    <span className="mb-2rem">Documenti altri soggetti</span>
                                    {involvedSubjects.map((subj, index) => {
                                       return <Fragment key={"docs-involvedSubjects-" + index}>
                                          <Accordion defaultActiveKey="0">
                                             <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                   <span className='align-middle'><OETrans code='common.subject' fallback={'Soggetto'} />&nbsp;{index + 1}</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                   <UploadDocument entity='involvedSubjects' entityId={subj.id} title='' />
                                                </Accordion.Body>
                                             </Accordion.Item>
                                          </Accordion>
                                       </Fragment>
                                    })}
                                 </Row>
                              }
                           </>
                           }
                        </Tab>

                        {/* CheckLists */}
                        {userRole < 70 && (
                           <Tab eventKey='checklist' title={'Checklist'}>
                              {detail.id && (
                                 <CheckList
                                    entityType='frameworkContracts'
                                    entityId={detail.id}
                                    disabled={detail?.reported}
                                 />
                              )}
                           </Tab>
                        )}
                     </Tabs>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
                     Chiudi
                  </Button>
                  {activeKey === 'checklist' && (!isSummary || isOe || Number(userRole) < 40) ? (
                     <Button
                        type='submit'
                        form='checklist-frameworkContracts'
                        onClick={() => setIsEdited(true)}
                        variant='primary'
                        disabled={detail?.reported}>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                     </Button>
                  ) : null}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default FrameworkContractDetailModal;
