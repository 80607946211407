import React from 'react';
import { Spinner, Dropdown } from 'react-bootstrap';
import { ExcelIcon } from '@icons';
import { OETrans } from 'components/translation/OETrans';

const DownloadExcelButtons = ({ textHeader, dropdownItems, customClassStyle }) => {
   //dropdownItems = {text, setIsDownloadingButton, apiDownload, apiProps }

   const handleFileDownload = async (apiDownload, setIsDownloadingButton, apiProps) => {
      if (setIsDownloadingButton) setIsDownloadingButton(true);

      const result = await apiDownload(apiProps);
      //console.log('result', result);

      if (!result.success) {}

      if (setIsDownloadingButton) setIsDownloadingButton(false);
   };

   return (
      <>
      {/* <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.downloadexcel' fallback={'Scarica Excel'}/></Tooltip>}> */}
         <Dropdown className={'me-2 ' + (customClassStyle ?? '')} autoClose='outside'>
            <Dropdown.Toggle id='dropdown-autoclose-outside' style={{ padding: '0.25rem 0.4rem' }}>
               <ExcelIcon style={{ width: '24px', height: '24px' }} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
               <Dropdown.Header>{textHeader}</Dropdown.Header>
               {dropdownItems.map((el, i) => {
                  return <Dropdown.Item
                     key={el.text + i}
                     as='button'
                     className='d-flex align-items-center'
                     onClick={() => handleFileDownload(el.apiDownload, el.setIsDownloadingButton, el.apiProps)}>
                     {el.text}
                     {el.isDownloadingButton && (
                        <Spinner
                           style={{ height: '20px', width: '20px', marginLeft: '0.5rem' }}
                           animation='border'
                           size='md'
                           role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                        </Spinner>
                     )}
                  </Dropdown.Item>
               })}
            </Dropdown.Menu>
         </Dropdown>
      {/* </OverlayTrigger > */}
      </>
   )
};
export default DownloadExcelButtons;