import React, { useState, useEffect, Fragment } from 'react';
import {
   Form,
   Col,
} from 'react-bootstrap';
import StatusBar from '@components/StatusBar';
import { IcoUploadFile } from "@icons";
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const UploadFilesForTemplates = ({ 
   selectedConfigTemplate,
   isPending, 
   setIsPending, 
   addedDocuments, 
   setAddedDocuments, 
   setSbShow 
}) => {
   const [inputsOfFiles, setInputsOfFiles] = useState([]);

   useEffect(() => {
      if (Object.keys(selectedConfigTemplate).length > 0) {
         setInputsOfFiles(selectedConfigTemplate.files)
      }
   }, [selectedConfigTemplate]);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const addFile = async (event, fileCode) => {
      setSbShow(<></>);

      let file = event.target.files[0];

      event.target.value = null;

      setIsPending(true);

      if (file.size > 200000000) {
         handleStatusBar('error', translate('common.error.filecannotexceed200', 'Il file non può superare i 200MB'), true);
         setIsPending(false);
         return false;
      }

      file.documentCode = fileCode;

      if (addedDocuments.some(el => el.documentCode === fileCode)) {
         const docIndex = addedDocuments.findIndex(el => el.documentCode === fileCode);
         addedDocuments[docIndex] = file;
      } else {
         addedDocuments.push(file);
      }
      
      setAddedDocuments(addedDocuments);

      setIsPending(false);
   };

   const showDocumentList = () => {
      return inputsOfFiles.map((file, index) => (
         <Col key={`doc-${index}_${file.code}`} sm={12}>
            <Form.Group
               as={Col}
               className="px-3"
               controlId={`${file.code}`}
            >
               <Form.Label className="mb-2 me-1">
                  <>
                     <span className="required-field position-relative">
                        <Form.Label>
                           <IcoUploadFile
                              style={{
                                 cursor: "pointer",
                              }}
                              className={`ico-small download-upload align-bottom ${isPending ? 'ico-disabled' : 'second-color'}`}
                           />
                        </Form.Label>
                        <Form.Control
                           type="file"
                           disabled={isPending}
                           style={{ display: "none" }}
                           multiple
                           onChange={e => addFile(e, file.code)}
                        />
                     </span>
                     &nbsp;
                  </>
                  {file.label} {file.code}
               </Form.Label>
               <ol className="ps-3">
                  {addedDocuments.map((upDocument, index) => {
                     return upDocument.documentCode === file.code ? (
                        <Fragment
                           key={`docs-${index}_${file.code}`}
                        >
                           <li className="d-flex justify-content-between">
                              <Col className="col-12 mb-1">
                                 {/* Nome del file */}
                                 <span className="ms-2">{upDocument.name}</span>
                              </Col>
                           </li>
                        </Fragment>
                     ) : null
                  })}
               </ol>
            </Form.Group>
         </Col>
      ))
   };

   return (
      <Fragment>
         {inputsOfFiles.length > 0 ?
            showDocumentList() :
            <OETrans code='common.project.selectconfigurationtemplate' fallback={'Seleziona prima una configurazione'} />
         }
      </Fragment>
   );
};

export default UploadFilesForTemplates;
