import {
  getTranslations,
  storeTranslation,
  updateTranslation,
  deleteTranslation,
  exportTranslations,
  importTranslations,
} from "@lib/api";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from "react";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IcoEdit, IcoCanc, IcoPlus, Lens, ExcelIcon } from "@icons";
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Col,
  Modal,
  Dropdown,
  Form,
} from "react-bootstrap";
import {
  TranslationForm,
  DialogConfirmation,
} from "components/atomic/molecules";
import { Alert } from "components/atomic/atoms";
import { LevelContext } from "@components/contexts/LevelContext";
import { OETrans } from "@components/translation/OETrans";
import CustomTooltip from "@components/ag-grid/CustomTooltip";
import StatusBar from '@components/StatusBar';
import { translate } from "components/translation/translation";

const Translations = () => {
  // Lingue supportate
  // Note: Se si vuole aggiungere una nuova lingua, aggiungere la lingua in questo array
  // Questo array è usato per creare le colonne della tabella e per i campi del form
  const LANGUAGES = ["IT", "EN", "ES"];
  const localeText = AG_GRID_LOCALE_IT;
  const { devMode, setDevMode } = useContext(LevelContext);
  const [translations, setTranslations] = useState([]);
  const [translation, setTranslation] = useState({});
  const [requiredFields, setRequiredFields] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [originalValues, setOriginalValues] = useState({});
  const [alert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "",
  });

  // Ref
  const gridRef = useRef();
  const fileInputRef = useRef();

  // Default column definition
  const defaultColDef = {
    resizable: true,
    wrapText: false,
    autoHeight: false,
    filter: false,
    sortable: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { 
      padding: "0 1rem", 
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden", 
   },
   tooltipComponent: CustomTooltip,
  };

  // Column definition
  const columnDefs = useMemo(() => {
    return [
      {
        headerName: translate('oetranslations.code', 'Code'),
        field: "code",
        //minWidth: 170,
        tooltipField: 'code',
        filter: 'agTextColumnFilter',
      },
      ...LANGUAGES.map((lang, index) => {
        return {
          headerName: lang,
          field: `name_${lang}`,
          resizable: index === LANGUAGES.length - 1 ? false : true,
          minWidth: 130,
          filter: 'agTextColumnFilter',
        };
      }),
      {
        headerName: "",
        minWidth: 110,
        maxWidth: 110,
        sortable: false,
        cellRenderer: (params) => {
          const buttons = [
            {
              action: "edit",
              icon: <IcoEdit className="second-color ico-small edit" />,
              tooltip: <OETrans code='common.tooltip.edit' fallback={'Modifica'}/>,
            },
            {
              action: "delete",
              icon: <IcoCanc className="second-color ico-small cancel" />,
              tooltip: <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>,
            },
          ];

          return (
            <div className="d-flex justify-content-center align-items-center mt-2">
              {buttons.map((button, index) => (
                <OverlayTrigger
                  key={index}
                  placement="top"
                  overlay={<Tooltip>{button.tooltip}</Tooltip>}
                >
                  <Button
                    variant="link"
                    className={`me-${index === 0 ? 3 : 0}`}
                    onClick={() => {
                      if (button.action === "edit") {
                        setTranslation(params.data);
                        setOriginalValues(params.data);
                        setShowModal(true);
                        setIsUpdateMode(true);
                      } else {
                        handleDelete(params.data);
                      }
                    }}
                  >
                    {button.icon}
                  </Button>
                </OverlayTrigger>
              ))}
            </div>
          );
        },
      },
    ];
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

  const handleRequiredFieldsChange = (fields) => {
    setRequiredFields(fields);
  };

  const handleModalClose = () => {
    setTranslation({});
    setShowModal(false);
    setSbShow(<></>);
  };

  const handleSetDevMode = () => {
    setDevMode(!devMode);
  };

  const handleXlsx = async (type, event) => {
    setShowAlert({ show: false, message: "", type: "" });
    if (type === "importa") {
      if (event.target.files.length === 0) {
        setShowAlert({
          show: true,
          message: translate('common.message.validfile', 'Seleziona un file valido da importare'),
          type: "danger",
        });
      }
      setIsLoading(true);
      const file = event.target.files[0];
      const result = await importTranslations(file);
      if (result && result.success) {
        setIsLoading(false);
        setShowAlert({
          show: true,
          message: translate('common.message.importtranslations', 'Traduzioni importate con successo'),
          type: "success",
        });
        // Fetch translations
        fetchTranslations();
      } else {
        setIsLoading(false);
        setShowAlert({
          show: true,
          message: translate('common.error.importfile', 'Errore durante l’importazione del file'),
          type: "danger",
        });
      }
    } else {
      setIsLoading(true);
      const result = await exportTranslations();
      if (result) {
        setIsLoading(false);
        setShowAlert({
          show: true,
          message: translate('common.message.exporttranslations', 'Traduzioni esportate con successo'),
          type: "success",
        });
      }
    }
  };

  // Handle delete
  const handleDelete = (translation) => {
    setTranslation(translation);
    setShowDeleteModal(true);
  };

  const handleSubmitDelete = async () => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);
    setShowDeleteModal(false);

    await deleteTranslation(translation.code)
      .then((res) => {
        if (res.success) {
          setShowAlert({
            show: true,
            message: translate('common.message.deleted', 'Elemento eliminato correttamente'),
            type: "success",
          });
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      })
      .finally(async () => {
        setIsLoading(false);
        setTranslation({});
        // remove element from translations array
        const newTranslationsArray = translations.filter(
          (t) => t.code !== translation.code
        );
        setTranslations(newTranslationsArray);
      });
  };

  // Fetch translations
  const fetchTranslations = async () => {
    setIsLoading(true);
    try {
      const retrievedTranslations = await getTranslations();

      // Group translations by code
      const groupedTranslations = retrievedTranslations.data.reduce(
        (acc, item) => {
          if (!acc[item.code]) {
            acc[item.code] = {
              code: item.code,
              // Inizializza le traduzioni per ogni lingua
              ...LANGUAGES.reduce((acc, lang) => {
                acc[`name_${lang}`] = "";
                acc[`description_${lang}`] = "";
                return acc;
              }, {}),
            };
          }
          // Assegna nome e descrizione per la lingua corrente
          acc[item.code][`name_${item.langCode}`] = item.name;
          acc[item.code][`description_${item.langCode}`] = item.description;
          return acc;
        },
        {}
      );

      const translationsArray = Object.values(groupedTranslations);
      setTranslations(translationsArray);
    } catch (error) {
      setShowAlert({
        show: true,
        message: translate('common.error.retrievinginformation', 'Errore durante il recupero delle informazioni'), 
        type: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (type) => {
    setSbShow(<></>);

    let isCodeExists = translations.find((t) => t.code === translation.code);

   if ((!isUpdateMode && isCodeExists) || (isUpdateMode && isCodeExists && (translation.code !== originalValues.code))) {
      handleStatusBar('error', translate('common.translations.codeexists', 'Codice già esistente'), false);
      return;
   }

   setShowAlert({ show: false, message: "", type: "" });
   setIsLoading(true);

    const newTranslations = LANGUAGES.map((lang) => {
      return {
        code: translation.code,
        langCode: lang,
        name: translation[`name_${lang}`],
        description: translation[`description_${lang}`],
      };
    });

    if (type === "store") {
      // Store translations for each language
      for (let i = 0; i < newTranslations.length; i++) {
        const res = await storeTranslation(newTranslations[i]);
        if (!res.success) {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
          setIsLoading(false);
          return;
        }
      }

      // Show success message
      setShowAlert({
        show: true,
        message: translate('common.message.created', 'Elemento creato con successo'),
        type: "success",
      });
    } else {
      // Update translations
      for (let i = 0; i < newTranslations.length; i++) {
        const res = await updateTranslation(newTranslations[i]);
        if (!res.success) {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
          setIsLoading(false);
          return;
        }
      }

      // Show success message
      setShowAlert({
        show: true,
        message: translate('common.message.edited', 'Elemento modificato con successo'),
        type: "success",
      });
    }

    // Add element to translations array
    const newTranslationsArray = [...translations];
    if (type === "store") {
      newTranslationsArray.push(translation);
    } else {
      const index = newTranslationsArray.findIndex(
        (t) => t.code === translation.code
      );
      newTranslationsArray[index] = translation;
    }

    setShowModal(false);
    setTranslations(newTranslationsArray);
    setTranslation({});
    setIsLoading(false);
  };

  // Filter textbox changed
  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchTranslations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="simple-box">
      <div className="row d-flex align-items-center mb-3">
        <div className="col-2">
          <h5 className="title-simple-box">
            <span>
              <OETrans code="common.translations" fallback="Traduzioni" />
            </span>
          </h5>
        </div>
        <div className="col-10 text-right py-1">
          <ButtonToolbar className="justify-content-end">
            <div className="d-flex align-items-center me-3">
              <Form.Check // prettier-ignore
                type="switch"
                {...(isLoading && { disabled: true })}
                label={translate('common.devmode', 'Modalità sviluppatore')}
                {...(devMode && { checked: true })}
                onChange={() => handleSetDevMode()}
              />
            </div>
            <Dropdown className="me-2" autoClose="outside">
              <Dropdown.Toggle {...(isLoading && { disabled: true })} style={{ padding: "0.25rem 0.4rem" }}>
                <ExcelIcon style={{ width: "24px", height: "28px" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {["Importa", "Esporta"].map((el, i) => {
                  return (
                    <Dropdown.Item
                      key={i}
                      as="button"
                      className="d-flex align-items-center"
                      onClick={() => {
                        if (el === "Importa") {
                          fileInputRef.current.click();
                        } else {
                          handleXlsx(el.toLowerCase());
                        }
                      }}
                    >
                      {el === 'Importa' ? translate('common.import', 'Importa') : translate('common.export', 'Esporta')}
                      &nbsp;
                      {translate('common.translations', 'Traduzioni').toLowerCase()}

                      {el === "Importa" && (
                        <Form.Control
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept=".xls,.xlsx"
                          onChange={(e) => handleXlsx(el.toLowerCase(), e)}
                        />
                      )}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="success"
              {...(isLoading && { disabled: true })}
              onClick={() => setShowModal(true)}
            >
              <IcoPlus className="light-color ico-small plus new-button" />
              <span className="d-md-inline"><OETrans code='common.add' fallback={'Aggiungi'}/></span>
            </Button>
            <InputGroup className="search-table align-items-center mt-3 mt-md-0">
              <FormControl
                type="text"
                className="filter-text-box"
                onInput={(e) => onFilterTextBoxChanged(e)}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="col-12">
        {alert.show && (
          <Col className="pt-3 col-12">
            <Alert
              show={alert.show}
              message={alert.message}
              variant={alert.type}
              delay={5000}
            />
          </Col>
        )}
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden"><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
            </div>
          </div>
        ) : (
          <div className="ag-theme-alpine list-table">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={translations}
              defaultColDef={defaultColDef}
              domLayout="autoHeight"
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              tooltipMouseTrack={true}
              onGridReady={null}
              headerHeight={50}
              rowHeight={50}
              localeText={localeText}
              ariaDescription="ag-grid-table"
              suppressCellFocus={true}
              autoSizeStrategy={{ type: "fitCellContents" }}
            />
          </div>
        )}
      </div>

      <DialogConfirmation
        title={translate('common.translations.deletetranslation', 'Elimina Traduzione')}
        content={translate('common.warning.delete', 'Sei sicuro di voler eliminare questo elemento?')}
        confirmLabel={translate('common.delete', 'Elimina')}
        cancelLabel={translate('common.cancel', 'Annulla')}
        onConfirm={() => handleSubmitDelete()}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />

      <Modal size='lg' show={showModal} onHide={() => handleModalClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pt-0">
            {isUpdateMode? 
               <OETrans code='common.edit' fallback={'Modifica'}/> : 
               <OETrans code='common.add' fallback={'Aggiungi'}/>
            }
            &nbsp;{translate('common.translation', 'Traduzione').toLowerCase()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TranslationForm
            languages={LANGUAGES}
            translation={translation}
            loading={isLoading}
            isUpdateMode={isUpdateMode}
            isCodeExists={translations.find((t) => t.code === translation.code)}
            setTranslation={setTranslation}
            onRequiredFieldsChange={handleRequiredFieldsChange}
            originalValues={originalValues}
          />
         {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleModalClose()}>
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button
            disabled={
              isLoading ||
              Object.keys(requiredFields).length > 0 ||
              !translation.code ||
              LANGUAGES.some((lang) => !translation[`name_${lang}`])
            }
            type="submit"
            onClick={() =>
              translations.find((t) => t.code === translation.code)
                ? handleSubmit("update")
                : handleSubmit("store")
            }
            variant="primary"
          >
            {isUpdateMode? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.add' fallback={'Aggiungi'}/>}
            {/* {translations.find((t) => t.code === translation.code)
              ? <OETrans code='common.save' fallback={'Salva'}/>
              : <OETrans code='common.add' fallback={'Aggiungi'}/>} */}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Translations;
