import React, { useState, useContext } from 'react';
import GroupsList from '@components/administration/groupsList';
import CompaniesList from '@components/administration/companiesList';
import UsersList from '@components/administration/usersList';
import { LevelContext } from 'components/contexts/LevelContext';
import Unauthorized from 'components/Unauthorized';
import ClientsNavHorizontal from '@components/nav/clientsNavHorizontal';

const ClientPage = () => {
  const { isOe } = useContext(LevelContext);
  const [setupGroups, setSetupGroups] = useState(null);
  const urlSearch = window.location.search;
  //console.log('urlSearch', urlSearch);
  if (!isOe) return <Unauthorized />;

  return (
    <>
      <ClientsNavHorizontal />

      {urlSearch === '?groups' && <GroupsList setSetupGroups={setSetupGroups} />}
      {urlSearch === '?companies' && <CompaniesList setupGroups={setupGroups} />}
      {urlSearch === '?users' && <UsersList isOEUserManagement={false} />}
    </>
  );
};
export default ClientPage;
