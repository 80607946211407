import React, { useEffect, useState } from "react";
import { Alert as BSAlert } from "react-bootstrap";

const Alert = ({ message, variant, delay = 5000, ...props }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  switch (variant) {
    case "success":
      variant = "#c2c923";
      break;
    case "danger":
      variant = "#cb1b4a";
      break;
    case "info":
      variant = "#08a2aa";
      break;
    default:
      variant = "#c2c923";
      break;
  }

  return (
    show && (
      <BSAlert
        style={{
          backgroundColor: variant,
          borderColor: variant,
          color: "#fff",
        }}
        {...props}
      >
        {message}
      </BSAlert>
    )
  );
};

export default Alert;
