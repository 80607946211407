import React, { useState, useEffect, useContext } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { IcoEdit } from '@icons';
import ProjectModal from '@components/projects/ProjectModal';
import { LevelContext } from '@components/contexts/LevelContext';

const ProjectHeader = ({ project, setProject }) => {
  const { isOe, userRole } = useContext(LevelContext);
  const [thisProject, setThisProject] = useState({});
  const [showModalProject, setShowModalProject] = useState(false);
  //console.log('project ProjectHeader:', project);

  const handleShowModal = (setShowModal) => setShowModal(true);

  useEffect(() => {
    if (project) {
      setThisProject(project);
    }
  }, [project]);

  return (
    <>
      <Row className='mt-4'>
        <Col className=''>
          <h2 className='mb-2 px-2rem'>
            <span className='pe-2 position-relative'>{thisProject.name}</span>
            <span className='project-details no-pipe'>CUP: {thisProject.cup}</span>
            <span className='project-details no-pipe ps-0'>
              {(isOe || userRole === 5) && (
                <Button
                  variant='link'
                  className='mb-2'
                  onClick={() => handleShowModal(setShowModalProject)}>
                  <IcoEdit
                    title={'Modifica Anagrafica'}
                    className='m-0 edit second-color'
                    style={{ width: '1rem' }}
                  />
                </Button>
              )}
            </span>
          </h2>
        </Col>
      </Row>

      {/* EDIT PROJECT */}
      {
        <ProjectModal
          showModalProject={showModalProject}
          setShowModalProject={setShowModalProject}
          project={thisProject}
          setProjectHeader={setProject}
        />
      }
    </>
  );
};
export default ProjectHeader;
