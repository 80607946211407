import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ChevronRight, IcoChevronDown } from '@icons';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { optionsForMonths } from "@lib/helpers/calendar";

const OpportunityArchive = ({ bulletins, lastBulletinYear, selectedTreeItem, setSelectedTreeItem }) => {
   const [treeData, setTreeData] = useState([]);
   //console.log('treeData', treeData);

   useEffect(() => {
      if (Array.isArray(bulletins) && bulletins.length > 0) {
         let tempTreeData = [];
         let allYears = bulletins.map(bul => new Date(bul.publishedAt).getFullYear()).sort().reverse();
         allYears = [...new Set(allYears)];

         tempTreeData = allYears.map(year => ({
            id: 'root-' + year,
            name: year,
            children: bulletins.filter(bul => new Date(bul.publishedAt).getFullYear() === year).map(bul => ({
               id: bul.id,
               name: optionsForMonths().find((m) => m.value === (new Date(bul.publishedAt).getMonth() + 1)).label,
               monthIndex: optionsForMonths().find((m) => m.value === (new Date(bul.publishedAt).getMonth() + 1)).value,
               bulletins: [{ id: bul.id, fileName: bul.name }],
            })).sort((valueA, valueB) => valueA.monthIndex - valueB.monthIndex),
         }));

         setTreeData(tempTreeData);
      };
   }, [bulletins]);

   const renderTree = (data) => {
      let tree = [];

      if (Array.isArray(data) && data.length > 0) {
         tree = data.map(node => {
            return <TreeItem key={`node-${node.id}`} nodeId={`${node.id}`} label={`${node.name}`}>
               {Array.isArray(node.children) && node.children.length > 0 ?
                  node.children.map(bul => {
                     return <TreeItem
                        key={`item-${bul.id}`}
                        nodeId={`${bul.id}`}
                        //style={{ pointerEvents: 'none', marginLeft: '-23px' }}
                        label={`${bul.name}`}
                     />
                  }) : null
               }
            </TreeItem>
         });
      }

      return tree;
   };

   const handleSelect = (event, nodeId) => {
      if (!nodeId.includes('root')) {
         setSelectedTreeItem(nodeId);
      }
   };

   return (
      <>
         <Row className='mt-2 mb-3'>
            <Col className={`py-3 col-12 px-0`}>
               {lastBulletinYear &&
                  <TreeView
                     className=""
                     defaultExpanded={[`root-${lastBulletinYear}`]}
                     defaultCollapseIcon={<IcoChevronDown />}
                     defaultExpandIcon={<ChevronRight />}
                     onNodeSelect={handleSelect}
                     defaultSelected={[`${selectedTreeItem}`]}
                     //selected={selectedTreeItem}
                  >
                     {treeData.length > 0 && renderTree(treeData)}
                  </TreeView>
               }
            </Col>
         </Row>
      </>
   );
};

export default OpportunityArchive;
