import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import {
   Form,
   Row,
   Col,
   Spinner,
   OverlayTrigger,
   Tooltip,
   Tabs,
   Tab,
   ButtonToolbar,
   Button,
} from 'react-bootstrap';
import { IcoUploadFile, IcoRefresh, IcoDownloadFile } from '@icons';
import {
   getBatchesByInfo,
   getFrameworkContracts,
   uploadFrameworkContract,
   uploadFrameworkContractDocuments,
   getFrameworkContractsStaging,
   downloadDocument,
   getDocumentsStaging,
   downloadTendersDocument,
   downloadTendersChecklistsDocument,
   downloadEntityTemplate,
   deleteDocumentsStaging,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import ContractDetailModal from 'components/contracts/ContractDetailModal';
import { IcoDanger, TooltipInfoIcon } from 'icone/index';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import { dateFormatter } from '@lib/helpers/formatting';
import useInterval from '@lib/hooks/useInterval';
import { getOeSubjectRoles } from '@lib/api/index';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import DownloadExcelButtons from '@components/form/DownloadExcelButtons';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const MassiveUpload = ({ entityId, userRole, isSummary, doDownload = false }) => {
   const [contracts, setContracts] = useState(null);
   const [contractsMeta, setContractsMeta] = useState({});
   const [contractStaging, setContractStaging] = useState(null);
   const [contractStagingMeta, setContractStagingMeta] = useState({});
   const [documentsStaging, setDocumentsStaging] = useState([]);
   const [documentsStagingMeta, setDocumentsStagingMeta] = useState({});
   const [selectedDocumentsStaging, setSelectedDocumentsStaging] = useState([]);
   const [errors, setErrors] = useState(null);
   const [isPending, setIsPending] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [optionsPagination, setOptionsPagination] = useState([]);
   const [columnDefs, setColumnDefs] = useState([]);
   const [columnDefsStaging, setColumnDefsStaging] = useState([]);
   const [columnDefsDocuments, setColumnDefsDocuments] = useState([]);
   const [columnDefsErrors, setColumnDefsErrors] = useState([]);
   const [subActiveKey, setSubActiveKey] = useState('entities');
   const [optionsSubjectRoles, setOptionsSubjectRoles] = useState([]);
   const [batchPollingEnabled, setBatchPollingEnabled] = useState(true);
   const [refresh, setRefresh] = useState(false);
   const [isDownloadingFrameworksContracts, setIsDownloadingFrameworksContracts] = useState(false);
   const [isDownloadingCLFrameworksContracts, setIsDownloadingCLFrameworksContracts] = useState(false);
   const [isFirstResearch1, setIsFirstResearch1] = useState(true);
   const [isFirstResearch3, setIsFirstResearch3] = useState(true);
   const localeText = AG_GRID_LOCALE_IT;
   const gridRef = useRef();
   const gridRefStaging = useRef();
   const gridRefErrors = useRef();
   const gridRefDocumentsStaging = useRef();

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const defaultColDef = {
      resizable: true,
      //wrapText: true,
      //autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   const defaultColDefLogs = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '3px 1rem', fontStyle: 'italic' },
      headerClass: 'fst-italic',
      //suppressSizeToFit: false,
      //suppressColumnVirtualisation=true
   };

   const isValidJSON = (jsonString) => {
      try {
         JSON.parse(jsonString);
         return true;
      } catch (error) {
         return false;
      }
   };

   useEffect(() => {
      if (entityId) {
         getFrameworkContracts('tenderFrameworkcontracts', entityId).then((frameworkContracts) => {

            if (frameworkContracts.success) {
               setContracts(frameworkContracts.data);
               const { data, success, ...restResp } = frameworkContracts;

               setContractsMeta(restResp);
            }
         });
      }
   }, [entityId]);

   const LogsCellRenderer = useCallback((params) => {
      if (params.value) {
         const isValid = isValidJSON(params.value);
         if (isValid) {
            const JSONerrors = JSON.parse(params.value);
            const arrayErrors = Object.values(JSONerrors);
            return arrayErrors.map((error, index) => (
               <p key={index} style={{ color: 'red' }}>
                  {Object.values(error)[0]}
               </p>
            ));
         } else {
            return <span>{params.value}</span>;
         }
      } else {
         return null;
      }
   }, []);

   const LogCellRenderer = useCallback((params) => {
      const red = '#C94723';
      // const yellow = '#D4BE31';
      const green = '#689689';

      const legendStyles = {
         bullet: {
            display: 'inline-block',
            width: '1.2em',
            height: '1.2em',
            borderRadius: '2em',
            //top: '0.30em',
            //position: 'relative',
            // boxShadow: '1px 1px 3px 0px #666666',
         },
         label: {
            display: 'inline-block',
            width: '50%',
            lineHeight: '2.5em',
         },
      };

      if (params.value) {
         return (
            <i
               style={{
                  ...legendStyles.bullet,
                  backgroundColor: params.value === 'OK' ? green : red,
                  textAlign: 'left',
                  verticalAlign: 'sub',
               }}
            />
         );
      }
   }, []);

   const ChecklistCellRenderer = useCallback((params) => {


      const stoplightColor = {
         red: '#C94723',
         yellow: '#D4BE31',
         green: '#689689',
         white: '#ffffff',
         gray: '#C4C4C4', grey: '#C4C4C4',
         green_white: '#689689',
      };

      let circleStyle;
      let message = '';

      if (!params?.value) {
         circleStyle = {
            display: 'inherit',
            width: '1.2em',
            height: '1.2em',
            borderRadius: '2em',
            MozBorderRadius: '2em',
            WebkitBorderRadius: '2em',
            backgroundColor: '#ffffff',
            border: `2px solid ${'#C4C4C4'}`,
         };

         message = translate('common.stoplight.towork', 'Da lavorare');
      } else {
         if (params?.value?.color === 'green_white') {
            circleStyle = {
               display: 'inherit',
               width: '23px',
               height: '23px',
               border: '4px solid #689689',
               borderRadius: '50%',
               padding: '0px',
               background: 'linear-gradient(to right, #689689 50%, transparent 0)',
               boxSizing: 'border-box',
            };

            message = translate('common.stoplight.compliantremedied', 'Conforme - sanata');
         } else {
            circleStyle = {
               display: 'inherit',
               width: '1.2em',
               height: '1.2em',
               borderRadius: '2em',
               MozBorderRadius: '2em',
               WebkitBorderRadius: '2em',
               backgroundColor: stoplightColor[params.value.color],
               border: `2px solid ${params?.value?.color === 'white' ? stoplightColor.grey : stoplightColor[params?.value?.color]}`,
            };

            switch (params?.value?.color) {
               case 'white':
                  message =  translate('common.stoplight.towork', 'Da lavorare');
                  break;
               case 'grey':
               case 'gray':
                  message = translate('common.stoplight.inprogress', 'In lavorazione');
                  break;
               case 'red':
                  message = translate('common.stoplight.notcompliant', 'Non conforme');
                  break;
               case 'yellow':
                  message = translate('common.stoplight.incomplete', 'Incompleta');
                  break;
               case 'green':
                  message = translate('common.stoplight.compliant', 'Conforme');
                  break;
               default:
                  break;
            }
         }
      }

      return (
         <Row className='align-items-center justify-content-center h-100'>
            <Col className='col-auto text-center'>
               <OverlayTrigger placement='top' overlay={<Tooltip>{message}</Tooltip>}>
                  <i style={{ ...circleStyle }} />
               </OverlayTrigger>
            </Col>
         </Row>
      );
   }, []);

   const handleDownloadDocument = useCallback((params) => {
      const handleDownload = async (e) => {
         setSbShow(<></>);
         setIsPending(true);

         const result = await downloadDocument(params.data);

         if (result.success) {
            setIsPending(false);
         } else {
            /* const message = result.data.file.join(',')
                        handleStatusBar('error', message, false); */
            setIsPending(false);
         }
      };

      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.download' fallback={'Scarica'}/></Tooltip>}>
            <Button variant='link' className='' onClick={(e) => handleDownload(e)}>
               <IcoDownloadFile className='second-color ico-small massive-upload align-bottom' />
            </Button>
         </OverlayTrigger>
      );
   }, []);

   useEffect(() => {
      getOeSubjectRoles().then((retrievedSubjectRole) => {
         if (retrievedSubjectRole.success) {
            setOptionsSubjectRoles(
               retrievedSubjectRole.data.map((subjectRole) => ({
                  value: subjectRole.code,
                  label: subjectRole.name,
               }))
            );
         }
      });
   }, []);

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('frameworkcontracts.tender.tenderNumber', 'ID Gara'),
            minWidth: 150,
            field: 'tender.tenderNumber',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.tender.tenderNumber;
            },
         },
         {
            headerName: translate('frameworkcontracts.cigs', 'CIG'),
            field: 'cigs',
            filter: 'agTextColumnFilter',
            minWidth: 150,
            valueGetter: (params) => {
               //console.log('params.data', params.data);
               return params.data.cigs?.map((cig) => cig.cig);
            },
         },
         {
            headerName: translate('frameworkcontracts.number', 'ID Contratto'),
            field: 'number',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('frameworkcontracts.date', 'Data sottoscrizione'),
            field: 'date',
            maxWidth: 150,
            cellRenderer: (params) => {
               return dateFormatter(params.value);
            },
            //filter: 'agTextColumnFilter',
            ...columnCentered(),
         },
         // {
         //   headerName: 'Data di scadenza',
         //   field: 'expirationDate',
         //   //filter: 'agTextColumnFilter',
         //   ...columnCentered(),
         // },
         // {
         //   headerName: 'Nome fornitore',
         //   field: 'supplierName',
         //   filter: 'agTextColumnFilter',
         //   resizable: true,
         // },
         {
            headerName: translate('frameworkcontracts.vatNumber', 'P. IVA'),
            field: 'vatNumber',
            filter: 'agTextColumnFilter',
         },
         // {
         //   headerName: 'Importo base €',
         //   field: 'baseAmount',
         //   filter: 'agTextColumnFilter',
         //   type: 'rightAligned',
         //   resizable: true,
         //   maxWidth: 170,
         //   valueGetter: (params) => {
         //     return formatToDecimals(params.data.baseAmount);
         //   },
         // },
         ...(userRole < 70 ? [
            {
               headerName: '',
               field: 'checkliststatus',
               filter: 'agTextColumnFilter',
               cellRenderer: ChecklistCellRenderer,
               maxWidth: 70,
               cellStyle: { display: 'flex', justifyContent: 'center' },
            },
         ] : []),
         {
            headerName: '',
            minWidth: 70,
            maxWidth: 70,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ContractDetailModal,
            cellRendererParams: {
               contracts: contracts,
               optionsSubjectRole: optionsSubjectRoles,
               setRefresh: setRefresh,
            },
         },
      ]);

      setColumnDefsStaging([
         {
            headerName: translate('frameworkcontractsstaging.contractNumber', 'ID Contratto'),
            //field: 'contractNumber',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.contractNumber;
            },
         },
         {
            headerName: translate('frameworkcontractsstaging.log', 'Messaggi'),
            field: 'log',
            cellRenderer: LogsCellRenderer,
            filter: 'agTextColumnFilter',
            //minWidth: 460,
         },
         {
            headerName: translate('frameworkcontractsstaging.date', 'Data di inizio'),
            field: 'date',
            //filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
               return dateFormatter(params.value);
            },
            ...columnCentered('fst-italic'),
         },
         // {
         //   headerName: 'Data di scadenza',
         //   field: 'expirationDate',
         //   //filter: 'agTextColumnFilter',
         //   ...columnCentered(),
         // },
         // {
         //   headerName: 'Nome fornitore',
         //   field: 'supplierName',
         //   filter: 'agTextColumnFilter',
         //   resizable: true,
         // },
         {
            headerName: translate('frameworkcontractsstaging.vatNumber', 'P.IVA'),
            field: 'vatNumber',
            filter: 'agTextColumnFilter',
         },
         // {
         //   headerName: 'Importo base €',
         //   field: 'baseAmount',
         //   filter: 'agTextColumnFilter',
         //   type: 'rightAligned',
         //   resizable: true,
         //   maxWidth: 170,
         //   valueGetter: (params) => {
         //     return formatToDecimals(params.data.baseAmount);
         //   },
         // },
         /* {
                     headerName: '',
                     pinned: 'right',
                     minWidth: 100,
                     maxWidth: 100,
                     cellStyle: { textAlign: 'center', padding: 0 },
                     cellRenderer: FrameworkContractDetailModal, //cambiare modale con modale visualizzazione errori
                     cellRendererParams: {
                        contractStaging: contractStaging, //passare contratti in staging
                     },
                  } */
      ]);

      setColumnDefsDocuments([
         {
            headerName: '',
            field: 'status',
            cellRenderer: LogCellRenderer,
            filter: 'agTextColumnFilter',
            maxWidth: 100,
            minWidth: 100,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            ...columnCentered(),
         },
         {
            headerName: translate('documentsstaging.fileName', 'Nome file'),
            field: 'fileName',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('documentsstaging.log', 'Messaggi'),
            field: 'log',
            filter: 'agTextColumnFilter',
            //minWidth: 500,
            cellStyle: { color: 'red' },
         },
         {
            headerName: translate('documentsstaging.missing', 'Mancante'),
            field: 'missing',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.missing ? translate('common.yes', 'Sì') : translate('common.no', 'No');
            },
         },
         {
            headerName: translate('documentsstaging.documentCode', ' Codice documento'),
            field: 'documentCode',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: '',
            pinned: 'right',
            minWidth: 90,
            maxWidth: 90,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: handleDownloadDocument,
         },
      ]);

      setColumnDefsErrors([
         {
            headerName: translate('common.column', 'Colonna'),
            field: 'colonna',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('common.error', 'Errore'),
            field: 'errore',
            filter: 'agTextColumnFilter',
            minWidth: 600,
            cellStyle: { color: 'red' },
         },
      ]);

      setOptionsPagination([
         <Fragment key='options'>
            <option defaultValue='100' key='100'>
               100
            </option>
            <option value='500' key='500'>
               500
            </option>
            <option value='1000' key='1000'>
               1000
            </option>
         </Fragment>,
      ]);
   }, [
      ChecklistCellRenderer,
      LogCellRenderer,
      LogsCellRenderer,
      contracts,
      handleDownloadDocument,
      optionsSubjectRoles,
      userRole,
   ]);

   const pollDataBatch = useCallback(() => {
      if (entityId) {
         switch (subActiveKey) {
            case 'entities':
               getFrameworkContracts('tenderFrameworkcontracts', entityId).then((frameworkContracts) => {

                  if (frameworkContracts.success) {
                     setContracts(frameworkContracts.data);
                     const { data, success, ...restResp } = frameworkContracts;
                     setContractsMeta(restResp);
                  }
               });
               break;
            case 'log-entities':
               getFrameworkContractsStaging('tenderFrameworkcontractStaging', entityId).then((stagingFramContracts) => {
                  setContractStaging(stagingFramContracts.data);
                  const { data, success, ...restResp } = stagingFramContracts;
                  setContractStagingMeta(restResp);
               });
               break;
            case 'log-documents':
               let docEntityType = 'tenders';
               let docTaskEntity = 'frameworkContracts';

               /* getLastBatch(docEntityType, docTaskEntity, 'ZIP', entityId).then(lastBatch => {
                  console.log('lastBatch', lastBatch);

                  if (lastBatch.success && lastBatch.data.length > 0) {
                     console.log('lastBatch.data[0].id', lastBatch.data[0].id);
                     let lastBatchId = lastBatch.data[0].id;

                     getDocumentsStaging(docEntityType, undefined, lastBatchId,'tenderFrameworkcontractDocStaging').then((retrievedDocs) => {
                        setDocumentsStaging(retrievedDocs.data);
                        const { data, success, ...restResp } = retrievedDocs;
                        setDocumentsStagingMeta(restResp);
                     });
                  }
               }); */

               //entityType, taskEntity, entityId
               getDocumentsStaging(docEntityType, docTaskEntity, entityId,'tenderFrameworkcontractDocStaging').then((retrievedDocs) => {
                  setDocumentsStaging(retrievedDocs.data);
                  const { data, success, ...restResp } = retrievedDocs;
                  setDocumentsStagingMeta(restResp);
               });

               setSelectedDocumentsStaging([]);
               break;
            default:
         }

         getBatchesByInfo('tenders', entityId).then((batches) => {
            let filteredBatches;


            if (batches.success) {
               if (batches.data.length > 0) {
                  filteredBatches = batches.data.filter(
                     (el) => el.taskEntity === 'frameworkContracts' && el.entityType === 'tenders'
                  );

                  const lastBatch = filteredBatches[filteredBatches.length - 1];
                  const isDone =
                     !lastBatch?.status === 'DONE' && !lastBatch?.status === 'DONE WITH ERRORS';

                  setBatchPollingEnabled(isDone);
               } else {
                  setBatchPollingEnabled(false);
               }
            }
         });
      }
   }, [entityId, subActiveKey]);

   useEffect(() => {
      if (refresh) {
         pollDataBatch();
         setRefresh(false);
      }
   }, [refresh, pollDataBatch]);

   // const loadData = useCallback(() => {
   //   getBatchesByInfo('tenders', entityId).then((batches) => {
   //     //console.log('batches:', batches.data);

   //     let filteredBatches;

   //     if (batches.success && batches.data.length > 0) {
   //       switch (subActiveKey) {
   //         case 'entities':
   //           getFrameworkContracts('tenderFrameworkcontracts', entityId).then((frameworkContracts) => {
   //             if (frameworkContracts.success) {
   //               setContracts(frameworkContracts.data);
   //             }
   //           });
   //           break;
   //         case 'log-entities':
   //           filteredBatches = batches.data.filter(
   //             (batch) => batch.taskEntity === 'frameworkContracts' && batch.taskType === 'XLSX'
   //           );

   //           if (filteredBatches.length > 0) {
   //             const lastBatchIdExcel = filteredBatches.reduce((prev, current) =>
   //               prev.id > current.id ? prev : current
   //             ).id;
   //             //console.log('lastBatchIdExcel', lastBatchIdExcel);

   //             getFrameworkContractsStaging('tenderFrameworkcontractStaging', entityId).then((stagingContracts) => {
   //               setContractStaging(
   //                 stagingContracts.data.filter((stagings) => stagings.batchId === lastBatchIdExcel)
   //               );
   //             });
   //           } else {
   //             setContractStaging([]);
   //           }
   //           break;
   //         case 'log-documents':
   //           filteredBatches = batches.data.filter(
   //             (batch) => batch.taskEntity === 'frameworkContracts' && batch.taskType === 'ZIP'
   //           );

   //           if (filteredBatches.length > 0) {
   //             const lastBatchIdDocs = filteredBatches.reduce((prev, current) =>
   //               prev.id > current.id ? prev : current
   //             ).id;
   //             //console.log('lastBatchIdDocs', lastBatchIdDocs);

   //             getDocumentsStaging(lastBatchIdDocs, 'frameworkContracts','tenderFrameworkcontractDocStaging').then((retrievedDocs) => {
   //               setDocumentsStaging(retrievedDocs.data);
   //             });
   //           } else {
   //             setDocumentsStaging([]);
   //           }
   //           break;
   //         default:
   //       }
   //     }
   //   });
   // }, [entityId, subActiveKey]);

   const onGridReady = useCallback(
      (params) => {
         if (entityId) {
            pollDataBatch();
         }
         //params.api.sizeColumnsToFit();

         if (params.current) {
            const allColumnIds = [];
            params.current.columnApi.columnModel.getColumns().forEach((column) => {
               allColumnIds.push(column.getId());
            });

            params.current.columnApi.columnModel.autoSizeColumns(allColumnIds, false);
         }
      },
      [entityId, pollDataBatch]
   );

   useEffect(() => {
      pollDataBatch();
   }, [entityId, subActiveKey, pollDataBatch]);

   useInterval(pollDataBatch, 2000, batchPollingEnabled);

   const onPageSizeChanged = useCallback((type, ref) => {
      let value = document.getElementById('page-size-' + type).value;
      ref.current.api.paginationSetPageSize(Number(value));
   }, []);

   const handleUpload = async (event, entityId) => {
      event?.preventDefault();

      setSbShow(<></>);
      const file = event.target.files[0];

      setIsPending(true);

      // UPLOAD
      const result = await uploadFrameworkContract(entityId, file);

      if (result.success) {
         // File upload successful
         setBatchPollingEnabled(true);
         handleStatusBar({
            success: true,
            message: translate('common.message.uploadedsuccesfully', 'Documento caricato con successo. I dati saranno disponibili a breve'),
         });
         setIsPending(false);
         setErrors([]);
      } else {
         if (result?.data?.file) {
            // const message = result.data.file.join(',');
            //handleStatusBar('error', message, false);
            handleStatusBar(result);
         } else if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);
         } else {
            handleStatusBar({
               success: false,
               message: translate('common.error.uploadederror', 'Il documento non è stato caricato, controlla la tabella degli errori'),
            });

            setIsPending(false);
            return;
         }

         setIsPending(false);
         const dataArray = [];

         for (const key in result.data) {
            const element = result.data[key][0];
            //console.log('element:', element);
            let colonna;
            let errore;

            if (typeof element === 'object') {
               colonna = element.colonna;
               errore = element.errore[0];
            } else { //string
               colonna = 'N.A.';
               errore = element;
            }

            //console.log('colonna:', colonna);
            //console.log('errore:', errore);
            dataArray.push({ colonna, errore });
         }
         setErrors(dataArray);
      }

      event.target.value = '';

      setSubActiveKey('log-entities');
   };

   const handleUploadDocuments = async (event, entityId) => {
      event?.preventDefault();
      setSbShow(<></>);
      //const file = inputRef.current?.files[0];
      const file = event.target.files[0];

      //se il file è più grande di 100MB (in byte)
      if (file.size > 104857600) {
         handleStatusBar('error', translate('common.error.filecannotexceed', 'Il file non può superare i 100MB'), true);
         return;
      }

      setIsPending(true);

      // UPLOAD
      const result = await uploadFrameworkContractDocuments(entityId, file);

      if (result.success) {
         // File upload successful
         setBatchPollingEnabled(true);
         //handleStatusBar('success', 'Documenti caricati con successo', true);
         handleStatusBar({ success: true, message: translate('common.message.documentsinprocess', 'Documenti in elaborazione')});
         setIsPending(false);
         setErrors([]);
      } else {
         if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         } else {
            // const message = result.data.file.join(',');
            //handleStatusBar('error', message, false);
            handleStatusBar(result);
            setIsPending(false);
            const dataArray = [];
            for (const key in result.data) {
               const element = result.data[key][0];
               //console.log('element:', element);
               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else {
                  return;
                  /* const startIndex = element.indexOf("'") + 1;
                     const endIndex = element.indexOf("'", startIndex);
                     colonna = element.slice(startIndex, endIndex);
                     errore = element.slice(endIndex + 1).trim(); */
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }
            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setSubActiveKey('log-documents');
   };

   const handleDownloadTemplate = async (entityType) => {
      setSbShow(<></>);
      setIsPending(true);

      const result = await downloadEntityTemplate(entityType);

      if (result) {
         setIsPending(false);
      }
   };

   const refreshData = () => {
      pollDataBatch();

      setErrors([]);
      setIsPending(false);

      setSbShow(<></>);
   };

   const cleanData = async () => {
      setSbShow(<></>);

      const data = {
         docIds: selectedDocumentsStaging,
      };

      const result = await deleteDocumentsStaging(data, 'tenders', 'frameworkContracts', entityId);

      if (result.success) {
         handleStatusBar({ success: true, message: translate('common.message.logsdeleted', 'Log eliminati con successo')});

         pollDataBatch();
      } else {
         handleStatusBar({ success: false, message: result.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni') });
      }
   };

   const reloadButton = () => {
      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.reloaddata' fallback={'Ricarica i dati'}/></Tooltip>}>
            <Button onClick={() => refreshData()} style={{ padding: '0.2rem' }}>
               <IcoRefresh />
            </Button>
         </OverlayTrigger>
      );
   };

   const cleanButton = () => {
      return (
         <Button variant='danger' className="me-2" onClick={() => cleanData()}>
            <OETrans code='common.cleanlog' fallback={'Pulisci Log'}/>
         </Button>
      );
   };

   const contractsTable = () => {

      return (
         <Fragment>
            {Array.isArray(contracts) ? (
               <>
                  {doDownload &&
                     <Row className='align-items-center mb-3 ps-4'>
                        <Col className='col-4'></Col>
                        <Col className='col-auto text-right ms-auto py-1'>
                           <ButtonToolbar className='justify-content-end'>
                              <DownloadExcelButtons
                                 textHeader={translate('common.downloadexcel', 'Scarica Excel')}
                                 dropdownItems={[
                                    { text: translate('common.frameworkcontractlist', 'Elenco Contratti Aperti'), isDownloadingButton: isDownloadingFrameworksContracts, setIsDownloadingButton: setIsDownloadingFrameworksContracts, apiDownload: downloadTendersDocument, apiProps: [entityId] },
                                    { text: translate('common.clframeworkcontractlist', 'Elenco Checklists dei Contratti Aperti'), isDownloadingButton: isDownloadingCLFrameworksContracts, setIsDownloadingButton: setIsDownloadingCLFrameworksContracts, apiDownload: downloadTendersChecklistsDocument, apiProps: [entityId] }
                                 ]}
                              />
                              {reloadButton()}
                              <CustomQuickFilterBox
                                 tableRef={gridRef}
                                 dataMeta={contractsMeta}
                                 setData={setContracts}
                                 dataLength={contracts?.length}
                                 isFirstResearch={isFirstResearch1} 
                                 setIsFirstResearch={setIsFirstResearch1}
                                 refTable='tenderFrameworkcontracts'
                              />
                           </ButtonToolbar>
                        </Col>
                     </Row>
                  }

                  <Row>
                     <Col className='col-12'>
                        <div className='ag-theme-alpine list-table'>
                           <AgGridReact
                              ref={gridRef}
                              columnDefs={columnDefs}
                              rowData={contracts}
                              defaultColDef={defaultColDef}
                              domLayout='autoHeight'
                              tooltipShowDelay={0}
                              tooltipHideDelay={2000}
                              tooltipMouseTrack={true}
                              onGridReady={() => onGridReady(gridRef)}
                              //onFirstDataRendered={() => onFirstDataRendered(gridRef)}
                              //onModelUpdated={() => onModelUpdated(gridRef)}
                              headerHeight={50}
                              rowHeight={50}
                              ariaDescription='ag-grid-table'
                              suppressCellFocus={true}
                              animateRows={true}
                           />
                           {Object.keys(contractsMeta).length > 0 && (
                              <CustomPaginationMui
                                 dataMeta={contractsMeta} 
                                 setData={setContracts} 
                                 setDataMeta={setContractsMeta}
                                 refTable='tenderFrameworkcontracts'
                                 setIsFirstResearch={setIsFirstResearch1}
                              />
                           )}
                        </div>
                     </Col>
                  </Row>
               </>
            ) : (
               <div className='text-center my-4'>
                  <Spinner animation='border' role='status'>
                     <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                  </Spinner>
               </div>
            )}
         </Fragment>
      );
   };

   const contractsStagingTable = () => {

      return (
         <Fragment>
            {Array.isArray(contractStaging) ? (
               <div className='ag-theme-alpine list-table'>
                  <AgGridReact
                     ref={gridRefStaging}
                     columnDefs={columnDefsStaging}
                     rowData={contractStaging}
                     defaultColDef={defaultColDefLogs}
                     domLayout='autoHeight'
                     tooltipShowDelay={0}
                     tooltipHideDelay={2000}
                     tooltipMouseTrack={true}
                     onGridReady={() => onGridReady(gridRefStaging)}
                     //onFirstDataRendered={() => onFirstDataRendered(gridRefStaging)}
                     //onModelUpdated={() => onModelUpdated(gridRefStaging)}
                     headerHeight={50}
                     rowHeight={50}
                     ariaDescription='ag-grid-table'
                     suppressCellFocus={true}
                     animateRows={true}
                  />
                  {Object.keys(contractStagingMeta).length > 0 && (
                     <CustomPaginationMui
                        dataMeta={contractStagingMeta} 
                        setData={setContractStaging} 
                        setDataMeta={setContractStagingMeta}
                        refTable='tenderFrameworkcontractStaging'
                     />
                  )}
               </div>
            ) : (
               <div className='text-center my-4'>
                  <Spinner animation='border' role='status'>
                     <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                  </Spinner>
               </div>
            )}
         </Fragment>
      );
   };

   const documentsStagingTable = () => {

      const onSelectionChanged = (e) => {
         //Prende solo le righe selezionate e filtrate
         let rowData = [];
         e.api.forEachNode((node) => (node.isSelected() ? rowData.push(node.data) : null));

         const selectedData = rowData.map((node) => node);

         setSelectedDocumentsStaging(selectedData.map(el => el.id));
      };

      return (
         <Fragment>
            {Array.isArray(documentsStaging) && documentsStaging.length > 0 ? (
               Object.keys(documentsStagingMeta).length > 0 ? (
                  <>
                     <Row className='align-items-center mb-3 ps-4'>
                        <Col className='col-4'></Col>
                        <Col className='col-8 text-right py-1'>
                           <ButtonToolbar className='justify-content-end'>
                              {selectedDocumentsStaging.length > 0 && cleanButton()}
                              {reloadButton()}
                              <CustomQuickFilterBox
                                 tableRef={gridRefDocumentsStaging}
                                 dataMeta={documentsStagingMeta}
                                 setData={setDocumentsStaging}
                                 dataLength={documentsStaging?.length}
                                 isFirstResearch={isFirstResearch3} 
                                 setIsFirstResearch={setIsFirstResearch3}
                                 refTable='tenderFrameworkcontractDocStaging'
                              />
                           </ButtonToolbar>
                        </Col>
                     </Row>

                     <Row>
                        {/* <Col className='col-12 col-xxl-9'> */}
                        <Col className='col-12'>
                           <div className='ag-theme-alpine list-table' id='grid-wrapper'>
                              <AgGridReact
                                 ref={gridRefDocumentsStaging}
                                 columnDefs={columnDefsDocuments}
                                 rowData={documentsStaging}
                                 defaultColDef={defaultColDefLogs}
                                 domLayout='autoHeight'
                                 tooltipShowDelay={0}
                                 tooltipHideDelay={2000}
                                 tooltipMouseTrack={true}
                                 onGridReady={() => onGridReady(gridRefDocumentsStaging)}
                                 //onFirstDataRendered={(e) => onFirstDataRendered(gridRefDocumentsStaging)}
                                 //onModelUpdated={(e) => onModelUpdated(gridRefDocumentsStaging)}
                                 //pagination={true}
                                 //paginationPageSize={100}
                                 headerHeight={50}
                                 rowHeight={50}
                                 ariaDescription='ag-grid-table'
                                 suppressCellFocus={true}
                                 animateRows={true}
                                 localeText={localeText}
                                 rowSelection={'multiple'}
                                 onSelectionChanged={onSelectionChanged}
                                 suppressRowClickSelection={true}
                                 rowMultiSelectWithClick={true}
                              />
                              {Object.keys(documentsStagingMeta).length > 0 ? (
                                 <CustomPaginationMui
                                    dataMeta={documentsStagingMeta} 
                                    setData={setDocumentsStaging} 
                                    setDataMeta={setDocumentsStagingMeta}
                                    refTable='tenderFrameworkcontractDocStaging'
                                    setIsFirstResearch={setIsFirstResearch3}
                                 />
                              ) : (
                                 <div className='page-size-table'>
                                    <OETrans code='common.itemsperpage' fallback={'Elementi per pagina'} />:&nbsp;
                                    <select
                                       onChange={() =>
                                          onPageSizeChanged('log-documents', gridRefDocumentsStaging)
                                       }
                                       className='page-size'
                                       id='page-size-log-documents'>
                                       {optionsPagination}
                                    </select>
                                 </div>
                              )}
                           </div>
                        </Col>
                     </Row>
                  </>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )
            ) : (
               <Row className='text-center my-5'>
                  <span><OETrans code='common.nologs' fallback={'Non sono presenti log'}/></span>
               </Row>
            )}
         </Fragment>
      );
   };

   const errorsTable = () => {
      return (
         <Fragment>
            <Row className='title-simple-box justify-content-center align-items-center mt-4 mb-3'>
               <Col className='col-auto p-0'>
                  <IcoDanger className='me-1 ico-red' />
               </Col>
               <Col className='col-auto p-1'>
                  <h5 className='m-0'><OETrans code='common.errorlist' fallback={'Lista Errori'}/></h5>
               </Col>
            </Row>
            <Col className='col-12'>
               {Array.isArray(errors) ? (
                  <div className='ag-theme-alpine list-table'>
                     <AgGridReact
                        ref={gridRefErrors}
                        columnDefs={columnDefsErrors}
                        rowData={errors}
                        defaultColDef={defaultColDef}
                        domLayout='autoHeight'
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        tooltipMouseTrack={true}
                        onGridReady={onGridReady}
                        //onFirstDataRendered={() => onFirstDataRendered(gridRefErrors)}
                        //onModelUpdated={() => onModelUpdated(gridRefErrors)}
                        pagination={true}
                        paginationPageSize={100}
                        headerHeight={50}
                        rowHeight={50}
                        ariaDescription='ag-grid-table'
                        suppressCellFocus={true}
                        animateRows={true}
                     />
                     <div className='page-size-table'>
                        <OETrans code='common.itemsperpage' fallback={'Elementi per pagina'} />:&nbsp;
                        <select
                           onChange={() => onPageSizeChanged('errors', gridRefErrors)}
                           className='page-size'
                           id='page-size-errors'>
                           {optionsPagination}
                        </select>
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </Col>
         </Fragment>
      );
   };

   const renderTooltip = (props) => {
      let type = '';

      if (props.popper.state) {
         type = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id='button-tooltip' {...props}>
            <OETrans code='common.filesallowed' fallback={'Ammessi solo file'}/>:
            <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
               {type === 'excel' ? (
                  <>
                     <li><OETrans code='common.extensionxls' fallback={'con estensione .xls, .xlsx'}/></li>
                  </>
               ) : (
                  <>
                     <li><OETrans code='common.extensionzip' fallback={'con estensione .zip'}/></li>
                     <li><OETrans code='common.noexceed' fallback={'che non superino i 100MB'}/></li>
                  </>
               )}
            </ul>
         </Tooltip>
      );
   };

   return (
      <>
         {!isSummary && (
            <Row className='mt-5 mb-5'>
               <Col>
                  <Row className='align-items-center'>
                     <Col className='text-center'>
                        <Form.Group className='px-3' controlId={`tender${entityId}_1`}>
                           <Form.Label style={{ cursor: 'pointer' }}>
                              <Row className="justify-content-center">
                                 <IcoUploadFile className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                                 <span>
                                    <OETrans code='common.importdata' fallback={'Importa dati'}/>
                                    <OverlayTrigger
                                       placement='top'
                                       delay={{ show: 200, hide: 400 }}
                                       overlay={renderTooltip}
                                       popperConfig={{ testObj: 'excel' }}>
                                       <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                    </OverlayTrigger>
                                 </span>
                              </Row>
                           </Form.Label>
                           <Form.Control
                              type='file'
                              disabled={isPending ? true : false}
                              style={{ display: 'none' }}
                              onChange={(e) => handleUpload(e, entityId)}
                           />
                        </Form.Group>
                     </Col>

                     <Col className='text-center'>
                        <Form.Group as={Col} className='px-3' controlId={`tender${entityId}_2`}>
                           <Form.Label style={{ cursor: 'pointer' }}>
                              <Row className="justify-content-center">
                                 <IcoUploadFile className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                                 <span>
                                    <OETrans code='common.uploaddocuments' fallback={'Carica documenti'}/>
                                    <OverlayTrigger
                                       placement='top'
                                       delay={{ show: 200, hide: 400 }}
                                       overlay={renderTooltip}
                                       popperConfig={{ testObj: 'zip' }}>
                                       <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                    </OverlayTrigger>
                                 </span>
                              </Row>
                           </Form.Label>
                           <Form.Control
                              type='file'
                              disabled={isPending ? true : false}
                              style={{ display: 'none' }}
                              onChange={(e) => handleUploadDocuments(e, entityId)}
                           />
                        </Form.Group>
                     </Col>

                     <Col className="text-center">
                        <Form.Group as={Col} className='px-3' controlId={`export_template`}>
                           <Form.Label style={{ cursor: 'pointer' }}>
                              <Row className="justify-content-center">
                                 <IcoDownloadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                                 <span><OETrans code='common.uploadtemplate' fallback={'Scarica template'}/></span>
                              </Row>
                           </Form.Label>
                           <Form.Control
                              type='file'
                              disabled={isPending ? true : false}
                              style={{ display: 'none' }}
                              onClick={() => handleDownloadTemplate('frameworkcontracts')}
                           />
                        </Form.Group>
                     </Col>
                     {/* <Col className='text-center'>
                  <Form.Group className='px-3' controlId={`tender${entityId}_3`}>
                     <Form.Label style={{ cursor: 'pointer' }}>
                        <Row className="justify-content-center">
                           <IcoUploadFile
                                 className={`ico-small download-upload me-1 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`}
                              />
                           <span><OETrans code='common.uploadchecklist' fallback={'Carica checklist'}/></span>
                           <OverlayTrigger
                              placement='top'
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: 'excel' }}>
                              <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                           </OverlayTrigger>
                        </Row>
                     </Form.Label>
                     <Form.Control
                        type='file'
                        disabled={isPending ? true : false}
                        style={{ display: 'none' }}
                        onChange={(e) => handleUploadChecklist(e, entityId, 'invoicepayments')}
                     />
                  </Form.Group>
               </Col> */}
                  </Row>

                  <Row className='justify-content-center align-items-center'>
                     <Col className='py-3 ps-0' style={{ paddingRight: '5.5rem', textAlign: isPending ? 'center' : 'left' }}>
                        {isPending && (
                           <Spinner
                              animation='border'
                              role='status'
                              style={{ display: 'flex', margin: '0 auto' }}></Spinner>
                        )}
                        {sbShow}
                     </Col>
                  </Row>
               </Col>
            </Row>
         )}

         <Tabs
            activeKey={subActiveKey}
            className='mb-3 mt-3'
            onSelect={(k) => {
               setSubActiveKey(k);
               setSbShow(<></>);
               setErrors([]);
            }}>
            <Tab eventKey='entities' title={translate('common.list', 'Elenco')}>
               <div className='mt-4 px-3'>
                  {errors && errors.length > 0 && errorsTable()}

                  {subActiveKey === 'entities' && contractsTable()}
               </div>
            </Tab>

            {userRole < 50 && (
               <Tab eventKey='log-entities' title={translate('common.loglist', 'Log Elenco')}>
                  <div className='mt-4'>
                     {errors && errors.length > 0 && errorsTable()}

                     {subActiveKey === 'log-entities' && contractsStagingTable()}
                  </div>
               </Tab>
            )}

            {/* <Tab eventKey='log-checklists' title={translate('common.logchecklist', 'Log Checklist')}>
               <div className='mt-4'>
                  {errors && errors.length > 0 && errorsTable()}

                  {subActiveKey === 'log-checklists' && checklistsStagingTable()}
               </div>
            </Tab> */}

            {userRole < 50 && (
               <Tab eventKey='log-documents' title={translate('common.documentlog', 'Log Documenti')}>
                  <div className='mt-4 px-3'>
                     {errors && errors.length > 0 && errorsTable()}

                     {subActiveKey === 'log-documents' && documentsStagingTable()}
                  </div>
               </Tab>
            )}
         </Tabs>
      </>
   );
};

export default MassiveUpload;
