import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Accordion,
   Spinner
} from "react-bootstrap";
import Select from "react-select";
import { IcoEdit, IcoCanc } from "@icons";
import { getOEChecklistQuestionsByEntity, getQuestionSet, editQuestionSet, deleteQuestionSet } from "@lib/api";
import OeApiResponseRenderer from "components/OeApiResponseRenderer";
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const ActionBtnRendererQuestionSets = ({ data, optionsLegacyType, translateEntityType, setRefresh }) => {
   const id = data.id;
   const [allQuestions, setAllQuestions] = useState([]);
   const [questionsSet, setQuestionsSet] = useState({});
   const [sections, setSections] = useState([]);
   const [entities, setEntities] = useState([]);
   const [selectedLegacyType, setSelectedLegacyType] = useState([]);
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isUsed, setIsUsed] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   //console.log('questionsSet', questionsSet);

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setRefresh(true);
      setSelectedLegacyType([]);
      setSections([]);
      setEntities([]);
      setShowModal(false);
   };
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const fetchData = useCallback(async () => {
      if (id && showModalEdit) {
         getQuestionSet(id).then((retrievedQuestionsSet) => {
            if (retrievedQuestionsSet.success) {
               setQuestionsSet(retrievedQuestionsSet.data);
            }
         });
      }

      setIsUsed(data.used)
   }, [data.used, id, showModalEdit]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      if (showModalEdit) {
         setSelectedLegacyType(optionsLegacyType.filter(opt => opt.value === questionsSet.legacyType));

         getOEChecklistQuestionsByEntity(questionsSet.legacyType).then((retrievedQuestions) => {
            if (retrievedQuestions.success) {
               let tempQuestions = [...retrievedQuestions.data];
               setAllQuestions(tempQuestions);

               const tempEntities = tempQuestions.filter((value, index, self) => index === self.findIndex((t) => (t.entityType === value.entityType))).map(el => el.entityType)
               setEntities(tempEntities);

               const tempSections = tempQuestions.filter((value, index, self) => index === self.findIndex((t) => (t.sectionName === value.sectionName)))
               setSections(tempSections);
            }
         });
      }
   }, [optionsLegacyType, questionsSet.legacyType, showModalEdit]);

   const onChange = (e, fieldName, codeOrSectionName, question, setSelect) => {
      //console.log('e', e);

      let tempQuestionsSet = { ...questionsSet };
      let value;

      switch (fieldName) {
         case 'name':
         case 'description':
            value = e.target.value;

            tempQuestionsSet[fieldName] = value;
            break;
         case 'checklistQuestions':
            value = e.target.checked;

            if (value) {
               tempQuestionsSet[fieldName].push(question);
            } else {
               tempQuestionsSet[fieldName] = tempQuestionsSet[fieldName].filter(el => el.code !== codeOrSectionName);
            }
            break;
         case 'section':
            value = e.target.checked;

            if (value) {
               tempQuestionsSet.checklistQuestions = tempQuestionsSet.checklistQuestions.filter(el => el.sectionName !== codeOrSectionName);

               allQuestions.forEach(quest => {
                  if (quest.sectionName === codeOrSectionName) {
                     tempQuestionsSet.checklistQuestions.push(quest);
                  }
               });
            } else {
               tempQuestionsSet.checklistQuestions = tempQuestionsSet.checklistQuestions.filter(el => el.sectionName !== codeOrSectionName);
            }
            break;
         case 'legacyType':
            value = e.value ?? '';

            tempQuestionsSet[fieldName] = value;
            tempQuestionsSet.checklistQuestions = [];

            setSelect(e);
            break;
         default:
            break;
      }

      setQuestionsSet(tempQuestionsSet);
   };

   const handleEdit = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (!questionsSet.name) {
         handleStatusBar({ success: false, message: translate('common.error.requiredFields', 'Compila i campi obbligatori') });
         return;
      }

      let isOnePerEntityNotices = { contracts: false, invoices: false, invoicepayments: false, timesheets: false };
      let isOnePerEntityTenders = { tenders: false, frameworkContracts: false };
      let isOnePerEntity;

      if (questionsSet.legacyType === 'tenders') isOnePerEntity = isOnePerEntityTenders;
      if (questionsSet.legacyType === 'notices') isOnePerEntity = isOnePerEntityNotices;

      questionsSet.checklistQuestions.forEach(el => {
         isOnePerEntity[el.entityType] = true;
      });

      if (questionsSet.checklistQuestions.length > 0 && !Object.values(isOnePerEntity).every(el => el === true)) {
         handleStatusBar({
            success: false,
            message: translate('common.error.requiredEntities', 'Un set di domande deve avere almeno una domanda selezionata per ogni entità'),
         });
         return;
      }

      const { createdAt, createdBy, updatedAt, updatedBy, checklistQuestions, ...restQuestionsSet } = questionsSet;
      const result = await editQuestionSet(questionsSet.id, restQuestionsSet);
      //console.log('result', result);

      if (result.success) {
         let checklistQuestionsResult;

         if (!isUsed) {
            const checklistQuestionsCodes = checklistQuestions.map(quest => quest.code);
            checklistQuestionsResult = await editQuestionSet(result.data.id, { questionCodes: checklistQuestionsCodes }, true);

            if (checklistQuestionsResult.success) {
               document.getElementById("required-field").scrollIntoView({ behavior: 'smooth' });

               handleStatusBar({
                  success: true,
                  message: translate('common.message.edited', 'Elemento modificato con successo'),
               });

               setQuestionsSet(checklistQuestionsResult.data);
            } else {
               handleStatusBar(checklistQuestionsResult);
            }
         } else {
            document.getElementById("required-field").scrollIntoView({ behavior: 'smooth' });

            handleStatusBar({
               success: true,
               message: translate('common.message.edited', 'Elemento modificato con successo'),
            });
         }
      } else {
         handleShowModal(result);
      }
   };

   const handleDelete = async () => {
      setIsLoading(true);

      const deleteResult = await deleteQuestionSet(id);

      if (deleteResult.success) {
         handleStatusBar("success", "Elemento eliminato correttamente", true);
         setIsLoading(false);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar(
            "error",
            deleteResult.message ??
            "Si è verificato un errore durante l'eliminazione delle informazioni.",
            false
         );

         setIsLoading(false);
      }
   };

   const editTooltip = (props) => <Tooltip {...props}>Modifica</Tooltip>;
   const deleteTooltip = (props) => <Tooltip {...props}>{isUsed ? "Set in uso" : "Elimina"}</Tooltip>;

   return (
      <div>
         <OverlayTrigger placement='top' overlay={editTooltip}>
            <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className='second-color ico-small edit' />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <span>
               <Button
                  variant="link"
                  className="me-0"
                  onClick={() => handleShowModal(setShowModalDelete)}
                  disabled={isUsed}
               >
                  <IcoCanc className="second-color ico-small cancel" />
               </Button>
            </span>
         </OverlayTrigger>

         {showModalEdit && (
            <Modal
               size='xl'
               className="modal-with-nav"
               show={showModalEdit}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered
               scrollable
            >
               <Modal.Header closeButton>
                  <Modal.Title><OETrans code='common.questionsets.editset' fallback={'Modifica Set Domande'} /></Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Form onSubmit={handleEdit} id='form-new-setquestion'>
                     <Row className='mb-3' style={{ marginTop: '2rem' }}>
                        <Col className="">
                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='questionsets.name' fallback={'Nome Set'} />&nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='name'
                                 value={questionsSet.name ?? ''}
                                 onChange={(e) => onChange(e, 'name')}
                              />
                           </Form.Group>
                        </Col>

                        <Col className="">
                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='questionsets.legacyType' fallback={'Entità'} />&nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Select
                                 components={{
                                    IndicatorSeparator: () => null,
                                 }}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 name="legacyType"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedLegacyType}
                                 options={optionsLegacyType}
                                 onChange={(e) =>
                                    onChange(e, "legacyType", undefined, undefined, setSelectedLegacyType)
                                 }
                                 isDisabled={isUsed}
                              />
                           </Form.Group>
                        </Col>
                     </Row>

                     <Row className='mb-3' style={{ marginTop: '2rem' }}>
                        <Col className="">
                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='questionsets.description' fallback={'Descrizione'} />
                              </Form.Label>
                              <Form.Control
                                 as='textarea'
                                 rows={2}
                                 name='description'
                                 value={questionsSet.description ?? ''}
                                 onChange={(e) => onChange(e, 'description')}
                              />
                           </Form.Group>
                        </Col>
                     </Row>

                     <Row className='mb-3 pb-3' style={{ marginTop: '2rem' }}>
                        <Form.Label>
                           <OETrans code='common.questionsets.questions' fallback={'Domande'} />&nbsp;<span className='required-field'>*</span>
                        </Form.Label>

                        {entities.map(entity => {
                           let sectionsByEntity = sections.filter(sect => sect.entityType === entity);

                           return <Fragment key={entity}>
                              <span className='my-2' style={{ fontWeight: '600' }}>{translateEntityType(entity).translate}</span>

                              {sectionsByEntity.map((section, index) => {
                                 return <Fragment key={section.sectionName}>
                                    <Accordion defaultActiveKey="0">
                                       <Accordion.Item eventKey={index}>
                                          <Accordion.Header>
                                             {section.sectionName}&nbsp;
                                             ({allQuestions.filter(quest => {
                                                return questionsSet.checklistQuestions?.some(selectedQuest => {
                                                   return selectedQuest.code === quest.code && quest.sectionName === section.sectionName;
                                                });
                                             }).length}/{allQuestions.filter(quest => quest.sectionName === section.sectionName).length})
                                          </Accordion.Header>
                                          <Accordion.Body>
                                             <Row>
                                                <Col className="col-12 my-2">
                                                   <Form.Group>
                                                      <Form.Check
                                                         inline
                                                         type='checkbox'
                                                         id={`${section.sectionName}`}
                                                         name={`${section.sectionName}`}
                                                         label={<span style={{ borderBottom: '1px solid #b3b3b3' }}><OETrans code='common.questionsets.selectall' fallback={'Seleziona tutto'} /></span>}
                                                         checked={allQuestions.filter(quest => {
                                                            return questionsSet.checklistQuestions?.some(selectedQuest => {
                                                               return selectedQuest.code === quest.code && quest.sectionName === section.sectionName;
                                                            });
                                                         }).length === allQuestions.filter(quest => quest.sectionName === section.sectionName).length}
                                                         onChange={(e) => onChange(e, 'section', section.sectionName)}
                                                         disabled={isUsed}
                                                      />
                                                   </Form.Group>
                                                </Col>

                                                {questionsSet.checklistQuestions && allQuestions.filter(quest => quest.sectionName === section.sectionName)
                                                   .sort((valueA, valueB) => valueA.number - valueB.number).map(quest =>
                                                      <Col key={quest.code} className="col-6 my-2">
                                                         <Form.Group>
                                                            <Form.Check
                                                               inline
                                                               type='checkbox'
                                                               id={`${quest.code}`}
                                                               name={`${section.sectionName}`}
                                                               label={quest.questions}
                                                               checked={questionsSet.checklistQuestions.some(el => el.code === quest.code)}
                                                               onChange={(e) => onChange(e, 'checklistQuestions', quest.code, quest)}
                                                               disabled={isUsed}
                                                            />
                                                         </Form.Group>
                                                      </Col>
                                                   )}
                                             </Row>
                                          </Accordion.Body>
                                       </Accordion.Item>
                                    </Accordion>
                                 </Fragment>
                              })}
                           </Fragment>
                        })}
                     </Row>
                  </Form>
                  {sbShow}

                  <Row id='required-field' className='mt-5 mb-2 w-100'>
                     <span className='ms-2'>
                        <span className='required-field'>*</span>
                        &nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'} />
                     </span>
                  </Row>
               </Modal.Body>

               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => handleCloseModal(setShowModalEdit)}
                  >
                     <OETrans code='common.cancel' fallback={'Annulla'} />
                  </Button>
                  <Button
                     variant="primary"
                     onClick={(e) => handleEdit(e)}
                  >
                     <OETrans code='common.edit' fallback={'Modifica'} />
                  </Button>
               </Modal.Footer>
            </Modal>
         )}

         <Modal
            show={showModalDelete}
            onHide={() => handleCloseModal(setShowModalDelete)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="my-3">
                  <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => handleCloseModal(setShowModalDelete)}
               >
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant="danger" onClick={() => handleDelete()}>
                  <OETrans code='common.delete' fallback={'Elimina'} />
                  {isLoading &&
                     <Spinner
                        style={{ width: '15px', height: '15px' }}
                        className="align-middle"
                        size='sm'
                        animation='border'
                        role='status'
                     >
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                     </Spinner>}
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ActionBtnRendererQuestionSets;
