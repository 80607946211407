import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Spinner,
   Accordion,
} from 'react-bootstrap';
import { getEntity } from '@lib/api';
import { IcoEdit } from '@icons';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { formatToDecimals, dateFormatter } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import { OETrans } from 'components/translation/OETrans';

const ProjectContractModal = ({
   data,
   modalIsVisible,
   setModalIsVisible,
   isSummary,
   spinnerVisible,
   setModalData,
   setRefresh,
}) => {
   const { /* isOe, */ userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [details, setDetails] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [isEdited, setIsEdited] = useState(false);
   const [isOpenDetails, setIsOpenDetails] = useState(false);
   //console.log('funddetails', details);

   useEffect(() => {
      if ((modalIsVisible || (modalIsVisible === undefined && showModalEdit)) && projectId && data.id) {
         getEntity('contracts', projectId, data.id).then((retrievedContract) => {
            if (retrievedContract.success) {
               setDetails(retrievedContract.data);
            }
         });
      }
   }, [data, showModalEdit, projectId, modalIsVisible]);

   const handleCloseModal = (setter) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      if (isSummary) {
         setModalData([]);
         setModalIsVisible(false);
      } else {
         setter(false);
      }
   };

   const handleShowModal = (setter) => {
      if (isSummary) {
         setModalIsVisible(true);
      } else {
         setter(true);
      }
   };

   // Determine the modal visibility based on external or internal state
   const whichStateToUse = isSummary ? modalIsVisible : showModalEdit;

   const editTooltip = (props) => <Tooltip {...props}>Dettaglio</Tooltip>;

   return (
      <div>
         {!isSummary && (
            <OverlayTrigger placement='top' overlay={editTooltip}>
               <Button variant='link' onClick={() => handleShowModal(setShowModalEdit)}>
                  <IcoEdit className='second-color path ico-small edit' />
               </Button>
            </OverlayTrigger>
         )}

         {whichStateToUse && (
            <Modal
               fullscreen
               className='modal-with-nav'
               show={whichStateToUse}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton />
               <Modal.Body className='overflow-hidden'>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (details.id &&
                     <>
                        <div className='workpanel-container'>
                           <Accordion className='workpanel-top' id="detail-modal-accordion" flush>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                 <Accordion.Header className='expanded-accordion title-fixed'>
                                    <h4 className='mb-0 me-2'>Documento {Number(userRole) === 1 || Number(userRole) <= 30 ? 'n. ' + details?.id : ''}</h4>
                                    <span 
                                       className="fake-link-detail ms-2"
                                       {...(isOpenDetails ? { style:{ color: 'white', fontWeight: 600} } : {})}
                                    >
                                       dettagli
                                    </span>
                                 </Accordion.Header>
                                 <Accordion.Body /* className="border-bottom" */
                                    onEnter={() => setIsOpenDetails(true)}
                                    onExit={() =>  setIsOpenDetails(false)}
                                 >  
                                    <Row className='mb-2rem mt-3'>
                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Nome Fornitore</Form.Label>
                                          <Form.Text as='p'>{details.supplierName ?? 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Codice Fornitore</Form.Label>
                                          <Form.Text as='p'>{details.supplierCode ?? 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Partita Iva</Form.Label>
                                          <Form.Text as='p'>{details.vatNumber ?? 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Nr. Lettera D'Incarico</Form.Label>
                                          <Form.Text as='p'>{details?.po === false ? details?.number : 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Nr. Attingimento</Form.Label>
                                          <Form.Text as='p'>{details?.po === true ? details?.number : 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Data Documento</Form.Label>
                                          <Form.Text as='p'>
                                             {details?.date ? dateFormatter(details?.date) : 'N.D.'}
                                          </Form.Text>
                                       </Form.Group>
                                    </Row>

                                    <Row className='mb-2rem'>
                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>CUP</Form.Label>
                                          <Form.Text as='p'>{details?.project?.cup ?? 'N.D.'}</Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Nr. Procedura</Form.Label>
                                          <Form.Text as='p'>
                                             {details?.frameworkContract?.tender?.tenderNumber ?? 'N.D.'}
                                          </Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>CIG</Form.Label>
                                          <Form.Text as='p'>
                                             {details.cigs?.map((cig) => (
                                                <Fragment key={cig.id}>
                                                   {cig.cig}
                                                   <br />
                                                </Fragment>
                                             )) ?? 'N.D.'}
                                          </Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Nr. Contratto Quadro</Form.Label>
                                          <Form.Text as='p'>
                                             {details?.po === true && details?.frameworkContract
                                                ? details?.frameworkContract.number
                                                : 'N.D.'}
                                          </Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Imponibile Consuntivato (€)</Form.Label>
                                          <Form.Text as='p'>
                                             {details?.baseAmount ? formatToDecimals(details.baseAmount) : 'N.D.'}
                                          </Form.Text>
                                       </Form.Group>

                                       <Form.Group as={Col} className='px-4'>
                                          <Form.Label className='mb-3'>Avvio Progetto</Form.Label>
                                          <Form.Text as='p'>{details?.project?.startDate ? dateFormatter(details?.project?.startDate) : 'N.D.'}</Form.Text>
                                       </Form.Group>
                                    </Row>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>

                           <div className='workpanel-left border-tab right'>
                              <UploadDocument entity='contracts' entityId={details.id} disableEdit={Number(userRole) > 40 && isSummary} externalBorder={true} />
                           </div>

                           <div className='workpanel-right pe-0'>
                              <CheckList
                                 entityType='contracts'
                                 entityId={details.id}
                                 disabled={details?.reported || Number(userRole) > 40}
                              />
                           </div>
                        </div>
                     </>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     Chiudi
                  </Button>
                  {!isSummary || /* isOe || */ userRole < 50 ? (
                     <Button
                        disabled={details?.reported}
                        type='submit'
                        form='checklist-contracts'
                        onClick={() => setIsEdited(true)}
                        variant='primary'>
                        <OETrans code='common.edit' fallback={'Modifica'}/>
                     </Button>
                  ) : null}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};

export default ProjectContractModal;
