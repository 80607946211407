import React, { useState } from "react";
import { Form, Row, Col, Modal, Button, Tabs, Tab } from "react-bootstrap";
import { storeRisk, updateRisk } from "@lib/api";
import UploadDocument from "@components/form/upload/uploadDocuments";
import { OETrans } from "components/translation/OETrans";

const RiskForm = ({
  riskData,
  setRiskData,
  showFormModal,
  setShowFormModal,
  isLoading,
  setIsLoading,
  projectId,
  setShowAlert,
  canEdit,
}) => {
  const [requiredFields, setRequiredFields] = useState({});

  const alwaysRequiredFields = [
    "number",
   /* "description",
    "wp",
    "mitigation",
    "referencePeriod",
    "effectiveDate",
    "applied",
    "happened",*/
  ];

  const handleChanges = (e) => {
    const { name, value } = e.target;

    setRiskData({
      ...riskData,
      [name]: value,
    });

    if (alwaysRequiredFields.includes(name)) {
      if (value.trim() === "" || value === null || value === undefined) {
        setRequiredFields({ ...requiredFields, [name]: true });
      } else {
        const newFields = { ...requiredFields };
        delete newFields[name];
        setRequiredFields(newFields);
      }
    }
  };

  const handleSubmit = (type) => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);
    const newRequiredFields = {};
    alwaysRequiredFields.forEach((field) => {
      if (field === "applied" || field === "happened") {
        if (riskData[field] === undefined || riskData[field] === "") {
          newRequiredFields[field] = true;
        }
      } else {
        if (!riskData[field]) {
          newRequiredFields[field] = true;
        }
      }
    });

    if (Object.keys(newRequiredFields).length > 0) {
      setRequiredFields(newRequiredFields);
      setIsLoading(false);
      return;
    }

    if (type === "store") {
      // store risk
      storeRisk(projectId, riskData)
        .then((res) => {
          if (res.success) {
            setShowFormModal(false);
            setRiskData({});
            setIsLoading(false);
            setShowAlert({
              show: true,
              message: "Salvataggio avvenuto con successo",
              type: "success",
            });
          } else {
            // Get all keys in data and join messages
            Object.keys(res.data).map((key) => {
              // Se key è un required field, set requiredFields
              if (alwaysRequiredFields.includes(key)) {
                setRequiredFields({ ...requiredFields, [key]: true });
              }
              return res.data[key].join(" ");
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setShowAlert({
            show: true,
            message: "Errore durante il salvataggio",
            type: "danger",
          });
          setIsLoading(false);
        });
    } else {
      updateRisk(projectId, riskData)
        .then((res) => {
          if (res.success) {
            setShowFormModal(false);
            setRiskData({});
            setIsLoading(false);
            setShowAlert({
              show: true,
              message: "Modifica avvenuta con successo",
              type: "success",
            });
          } else {
            // Get all keys in data and join messages
            Object.keys(res.data).map((key) => {
              // Se key è un required field, set requiredFields
              if (alwaysRequiredFields.includes(key)) {
                setRequiredFields({ ...requiredFields, [key]: true });
              }
              return res.data[key].join(" ");
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setShowAlert({
            show: true,
            message: "Errore durante la modifica",
            type: "danger",
          });
          setIsLoading(false);
        });
    }
  };

  const handleModalClose = () => {
    setRiskData({});
    setShowFormModal(false);
    setRequiredFields({});
    setIsLoading(false);
  };

  return (
    <Modal
      size="lg"
      show={showFormModal}
      onHide={() => handleModalClose()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="pt-0">
          {riskData.id ? (canEdit ? <OETrans code='common.edit' fallback={'Modifica'}/> : "Dettagli") : <OETrans code='common.add' fallback={'Aggiungi'}/>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs className="mb-4" defaultActiveKey="detail" fill>
          <Tab eventKey="detail" title={"Dettagli"}>
            <Form>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="number">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Numero
                      {alwaysRequiredFields.includes("number") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="number"
                          value={riskData.number || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!riskData.number && requiredFields.number
                            ? "Campo obbligatorio"
                            : "Numero già esistente"}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{riskData.number}</Form.Text>}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="description">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Descrizione
                      {alwaysRequiredFields.includes("description") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="description"
                          value={riskData.description || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{riskData.description}</Form.Text>}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="wp">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      WP
                      {alwaysRequiredFields.includes("wp") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="wp"
                          value={riskData.wp || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.wp}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{riskData.wp}</Form.Text>}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="mitigation">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Misura di mitigazione
                      {alwaysRequiredFields.includes("mitigation") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="mitigation"
                          value={riskData.mitigation || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.mitigation}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{riskData.mitigation}</Form.Text>}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="referencePeriod">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Periodo di riferimento
                      {alwaysRequiredFields.includes("referencePeriod") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="text"
                          name="referencePeriod"
                          value={riskData.referencePeriod || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.referencePeriod}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>{riskData.referencePeriod}</Form.Text>
                  )}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="effectiveDate">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Data effettiva
                      {alwaysRequiredFields.includes("effectiveDate") &&
                        canEdit && <span className="required-field">*</span>}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          type="date"
                          name="effectiveDate"
                          value={riskData.effectiveDate || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.effectiveDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {riskData.effectiveDate &&
                        riskData.effectiveDate.split("-").reverse().join("-")}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-6">
                  <Form.Group controlId="applied">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      La misura è stata applicata?
                      {alwaysRequiredFields.includes("applied") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Select
                          name="applied"
                          // Check if exist and convert to string
                          value={riskData.applied || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.applied}
                        >
                          <option value="">Seleziona</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {riskData.applied === "1" ? "SI" : "NO"}
                    </Form.Text>
                  )}
                </Col>
                <Col className="col-6">
                  <Form.Group controlId="happened">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Il rischio si è concretizzato?
                      {alwaysRequiredFields.includes("happened") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Select
                          name="happened"
                          value={riskData.happened || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.happened}
                        >
                          <option value="">Seleziona</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && (
                    <Form.Text>
                      {riskData.happened === "1" ? "SI" : "NO"}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "40px" }}>
                <Col className="col-12">
                  <Form.Group controlId="note">
                    <Form.Label
                      {...(!canEdit && { style: { fontWeight: "bold" } })}
                    >
                      Note
                      {alwaysRequiredFields.includes("note") && canEdit && (
                        <span className="required-field">*</span>
                      )}
                    </Form.Label>
                    {canEdit && (
                      <>
                        <Form.Control
                          as="textarea"
                          name="note"
                          style={{ resize: "none" }}
                          value={riskData.note || ""}
                          onChange={(e) => handleChanges(e)}
                          isInvalid={requiredFields.note}
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo obbligatorio
                        </Form.Control.Feedback>
                      </>
                    )}
                  </Form.Group>
                  {!canEdit && <Form.Text>{riskData.note}</Form.Text>}
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="documents" title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!riskData.id}>
            <UploadDocument
              entity="projectRisks"
              entityId={riskData.id || ""}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {canEdit ? (
          <>
            <Button variant="secondary" onClick={() => handleModalClose()}>
               <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            <Button
              disabled={isLoading || Object.keys(requiredFields).length > 0}
              type="submit"
              onClick={() =>
                riskData.id ? handleSubmit("update") : handleSubmit("store")
              }
              variant="primary"
            >
              {riskData.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.add' fallback={'Aggiungi'}/>}
            </Button>
          </>
        ) : (
          <Button variant="secondary" onClick={() => handleModalClose()}>
            Chiudi
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RiskForm;
