import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
   ButtonToolbar,
   Spinner,
   Button,
   Modal,
   Row,
   Col,
   Form,
   Tabs,
   Tab,
   OverlayTrigger,
   Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import { IcoPlus, IcoMinusCircled, TooltipInfoIcon } from '@icons';
import {
   getCompanies,
   getGroups,
   getCompanySizes,
   getRegions,
   createCompany,
   editCompany,
   createCompanyBusinessLine,
   createCompanyBusinessArea,
   editCompanyBusinessArea,
   editCompanyBusinessLine,
   deleteCompanyBusinessArea,
   deleteCompanyBusinessLine,
   getCompanyBusinessAreas,
   getCompanyBusinessLines,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererCompanies from 'components/actionBtnRenderer/ActionBtnRendererCompanies';
import UploadDocument from 'components/form/upload/uploadDocuments';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import CustomPaginationMui from '@components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from '@components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   code: '', //obbli
   name: '', //obbli
   vat: '', //obbli
   address: '',
   sizeCode: '',
   pec: '',
   legalRepresentative: '',
   powerOfAttorney: false,
   attorney: '',
   groupids: [], //non su dbdiagram
};

const CompaniesList = ({ setupGroups }) => {
   const [companies, setCompanies] = useState(null);
   const [companiesMeta, setCompaniesMeta] = useState({});
   const [newCompany, setNewCompany] = useState(initialState);
   const [businessLines, setBusinessLines] = useState([]);
   const [clusters, setClusters] = useState([]);
   const [originBusinessLines, setOriginBusinessLines] = useState([]);
   const [originClusters, setOriginClusters] = useState([]);
   const [optionsSizes, setOptionsSizes] = useState([]);
   const [optionsGroups, setOptionsGroups] = useState([]);
   const [optionsRegions, setOptionsRegions] = useState([]);
   const [selectedSize, setSelectedSize] = useState([]);
   const [selectedGroups, setSelectedGroups] = useState([]);
   const [activeKey, setActiveKey] = useState('registry');
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [refresh, setRefresh] = useState(false);
   const [pecIsInvalid, setPecIsInvalid] = useState(false);
   const [codeIsInvalid, setCodeIsInvalid] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const pecRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
   const codeRegex = /^[A-Z0-9]{4,10}$/; // eslint-disable-line
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   //console.log('newCompany', newCompany);

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setActiveKey('registry');
      setRefresh(true);
      setShowModal(false);
      setNewCompany(initialState);
      setSelectedSize([]);
      setSelectedGroups([]);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   useEffect(() => {
      getCompanies(undefined, 'companies').then((retrievedCompanies) => {
         if (retrievedCompanies.success) {
            setCompanies(retrievedCompanies.data);
            const { data, success, ...restResp } = retrievedCompanies;
            setCompaniesMeta(restResp);
         }
      });
   }, []);

   useEffect(() => {
      getGroups().then(retrievedGroups => {
         setOptionsGroups((setupGroups ?? retrievedGroups.data).map(group => ({
            value: group.id,
            label: group.name,
         })));
      });

      getCompanySizes().then(retrievedSizes => {
         setOptionsSizes(retrievedSizes.data.map(size => ({
            value: size.code,
            label: size.name,
         })));
      });

      getRegions().then(retrievedRegions => {
         setOptionsRegions(retrievedRegions.data.map(docs => ({
            value: docs.codRegione,
            label: docs.nomeRegione,
         })));
      });
   }, [setupGroups]);

   useEffect(() => {
      setSelectedSize(optionsSizes.filter(option => option.value === newCompany.sizeCode)
         .map(filteredOpt => ({
            value: filteredOpt.value,
            label: filteredOpt.label,
         }))
      );

      setSelectedGroups(optionsGroups.filter(optionGroup => {
         return newCompany.groupids.some(group => {
            return Number(optionGroup.value) === Number(group.id || group.value);
         });
      })
      );
   }, [newCompany, optionsGroups, optionsSizes]);

   useEffect(() => {
      if (refresh) {
         getCompanies(undefined, 'companies').then((retrievedCompanies) => {
            if (retrievedCompanies.success) {
               setCompanies(retrievedCompanies.data);
               const { data, success, ...restResp } = retrievedCompanies;
               setCompaniesMeta(restResp);
            }
         });

         setRefresh(false);
      }
   }, [refresh]);

   const onGridReady = useCallback(() => {
      getCompanies(undefined, 'companies').then((retrievedCompanies) => {
         if (retrievedCompanies.success) {
            setCompanies(retrievedCompanies.data);
            const { data, success, ...restResp } = retrievedCompanies;
            setCompaniesMeta(restResp);
         }
      });
   }, []);

   const defaultColDef = {
      resizable: false,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('companies.name', 'Società'),
            field: 'name',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('companies.vat', 'Partita IVA'),
            field: 'vat',
            filter: 'agTextColumnFilter',
            maxWidth: 300,
         },
         {
            headerName: translate('companies.groups', 'Gruppo'),
            field: 'groups',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.groups.map(group => group.name);
            },
         },
         {
            headerName: '',
            minWidth: 130,
            maxWidth: 150,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererCompanies,
            cellRendererParams: {
               setRefresh: setRefresh,
               optionsGroups: optionsGroups,
               optionsSizes: optionsSizes,
               optionsRegions: optionsRegions,
            },
         },
      ]);
   }, [optionsSizes, optionsRegions, optionsGroups]);

   const onChange = (e, fieldName, setSelect, elIndex, list) => {
      //console.log('e:', e);

      let tempNewCompany = { ...newCompany };
      let value;

      switch (fieldName) {
         case 'code':
         case 'name':
         case 'vat':
         case 'pec':
         case 'address':
         case 'legalRepresentative':
         case 'attorney':
            value = fieldName === 'code' ? e.target.value.toUpperCase() : e.target.value;

            tempNewCompany[fieldName] = value;
            break;
         case 'groupids':
         case 'sizeCode':
            if (fieldName === 'groupids') {
               value = e ?? [];
            } else {
               value = e.value ?? [];
            }

            tempNewCompany[fieldName] = value;

            setSelect(e);
            break;
         case 'businessLines':
         case 'clusters':
            let tempList = [...list];

            let tempElement = {
               ...tempList[elIndex],
               [e.target ? e.target.name : 'regions']: e.target ? e.target.value : (e.map(e => e.value) ?? []),
            };

            tempList[elIndex] = tempElement;

            setSelect(tempList); //as setList
            break;
         case 'powerOfAttorney':
            value = e.target.checked;

            tempNewCompany[fieldName] = value;

            if (e.target.checked === false) {
               tempNewCompany.attorney = '';
            }
            break;
         case 'docs':

            break;
         default:
            value = e.target.value;
            tempNewCompany[fieldName] = value;
      }

      setNewCompany(tempNewCompany);
   };

   const onBlur = (value, regex, setState) => {
      if (!value || (value && value.match(regex))) {
         setState(false);
      } else {
         setState(true);
      }
   };

   const handleNewCompany = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (!newCompany.code || !newCompany.name || !newCompany.vat || selectedGroups.length === 0 ||
         (newCompany.powerOfAttorney && !newCompany.attorney)) {
         handleStatusBar({
            success: false,
            message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
         });
         return;
      }

      if (newCompany.code && !newCompany.code.match(codeRegex)) {
         handleStatusBar({
            success: false,
            message: translate('common.companies.validcode', 'Inserisci un codice società valido'),
         });
         return;
      }

      if (newCompany.pec && !newCompany.pec.match(pecRegex)) {
         handleStatusBar({
            success: false,
            message: translate('common.companies.validpec', 'Inserisci un indirizzo pec valido'),
         });
         return;
      }

      if (newCompany.groupids.length > 0) {
         newCompany.groupids = newCompany.groupids.map(group => group.value || group.id);
      }

      const { createdAt, createdBy, updatedAt, updatedBy, ...restNewCompany } = newCompany;
      const { code, ...restCompany } = restNewCompany;

      let result;

      result = newCompany.id ? await editCompany(restCompany) : await createCompany(restNewCompany);

      if (result.success) {
         let resultOfActions = [true];
         let resultOfMessages = [];
         let businessLinesResult;
         let clustersResult;

         if (businessLines.length > 0) {
            [businessLinesResult] = await Promise.all([
               handleItemList('businessLines', originBusinessLines, businessLines, deleteCompanyBusinessLine, editCompanyBusinessLine, createCompanyBusinessLine, result.data.id),
            ]);
         }

         if (clusters.length > 0) {
            [clustersResult] = await Promise.all([
               handleItemList('clusters', originClusters, clusters, deleteCompanyBusinessArea, editCompanyBusinessArea, createCompanyBusinessArea, result.data.id),
            ]);
         }

         const resultOfAll = resultOfActions.concat(businessLinesResult ? businessLinesResult.resultOfActionsTemp : [], clustersResult ? clustersResult.resultOfActionsTemp : []);
         const resultOfAllMessages = resultOfMessages.concat(businessLinesResult ? businessLinesResult.messageResult : [], clustersResult ? clustersResult.messageResult : []);
         //console.log('resultOfAll', resultOfAll);
         //console.log('resultOfAllMessages', resultOfAllMessages);

         if (resultOfAll.length === 0 || (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))) {
            setTimeout(() => {
               getCompanyBusinessLines(result.data.id).then(retrievedBusinessLines => {
                  setBusinessLines(retrievedBusinessLines.data);
                  setOriginBusinessLines(retrievedBusinessLines.data);
               });

               getCompanyBusinessAreas(result.data.id).then(retrievedClusters => {
                  let tempClusters = retrievedClusters.data.map(cluster => ({
                     id: cluster.id,
                     name: cluster.name,
                     regions: cluster.regions.map(region => region.codRegione),
                  }));

                  setClusters(tempClusters);
                  setOriginClusters(tempClusters);
               });
            }, 500);

            handleStatusBar({
               success: true,
               message: translate('common.message.edited', 'Elemento modificato con successo'),
            });
         } else {
            handleStatusBar(resultOfAllMessages);
         }

         const { groups, ...restResult } = result.data;
         const newResult = { groupids: groups, ...restResult };

         setNewCompany(newResult);
      } else {
         handleStatusBar(result);
      }
   };

   const handleItemList = async (entityType, originItemList, itemList, deleteAPI, editAPI, createAPI, entityId) => {
      let resultOfActionsTemp = [];

      const oldItemsDeleted = originItemList.filter(object1 => {
         return !itemList.some(object2 => {
            return object1.id === object2.id;
         });
      });

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async item => {
            const deleteResult = await deleteAPI(entityId, item.id);
            resultOfActionsTemp.push(deleteResult.success);
         });
      }

      let validationFields = [];

      switch (entityType) {
         case 'businessLines':
            validationFields = ['name'];
            break;
         default:
            validationFields = [];
      }

      let result;
      let messageResult;

      itemList.forEach(async item => {

         if (validationFields.every(field => item[field] || (Array.isArray(item[field]) && item[field].every(el => el)))) {

            if (item.id) {
               const { createdAt, createdBy, updatedAt, updatedBy, notice, ...restItem } = item;
               result = await editAPI(entityId, restItem);
               //console.log('result:', result);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            } else {
               result = await createAPI(entityId, item);
               //console.log('result:', result);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            }
         } else {
            //console.log('non riempiti');
            messageResult = { success: false, data: { validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`] } };
            resultOfActionsTemp.push(false);
            return;
         }
      });

      //console.log('messageResult', messageResult);
      return { resultOfActionsTemp, messageResult };
   };

   const addElement = (section, typeArray, setTypeArray) => {
      let tempTypeArray = [...typeArray];

      let objTypeArray = {};

      switch (section) {
         case 'businessLines':
            objTypeArray = { name: '' }
            break;
         case 'cluster':
            objTypeArray = { name: '', regions: [] }
            break;
         default:
            objTypeArray = {};
      }

      tempTypeArray.push(objTypeArray);

      setTypeArray(tempTypeArray);
   };

   const removeElement = (typeArray, setTypeArray, index) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.splice(index, 1);

      setTypeArray(tempTypeArray);
   };

   const businessLinesRows = (businessLines, setBusinessLines) => {
      let rows = [];

      if (businessLines) {
         if (businessLines.length === 0) {
            businessLines = [{ name: '' }];
         }

         //console.log('businessLines', businessLines);
         let lastIndex = businessLines.length - 1;

         rows = businessLines.map((el, i) => {
            const isThisRowFilled = el.name ? true : false;

            return (
               <Row className='align-items-center mb-3' key={'item-' + i}>
                  <Col className='col-9'>
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.businesslines', 'Business lines')}
                        value={el.name ?? ''}
                        onChange={e => onChange(e, 'businessLines', setBusinessLines, i, businessLines)}
                     />
                  </Col>
                  <Col className="ps-1">
                     {businessLines.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-1'
                           onClick={() => removeElement(businessLines, setBusinessLines, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}
                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('businessLines', businessLines, setBusinessLines)}
                           disabled={!isThisRowFilled}
                        >
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   const clusterRows = (clusters, setClusters, options) => {
      let rows = [];

      if (clusters) {
         if (clusters.length === 0) {
            clusters = [{ name: '', regions: [] }];
         }

         //console.log('clusters', clusters);
         let lastIndex = clusters.length - 1;

         rows = clusters.map((el, i) => {
            const isThisRowFilled = el.name && el.regions?.length > 0 ? true : false;

            return (
               <Row className='align-items-center mb-3' key={i}>
                  <Col className="col-5 align-self-start">
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.denomination', 'Denominazione')}
                        value={el.name ?? ''}
                        onChange={e => onChange(e, 'clusters', setClusters, i, clusters)}
                     />
                  </Col>
                  <Col className=''>
                     <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="regions"
                        placeholder={translate('common.placeholder.selectregions', 'Seleziona regioni') + '...'}
                        value={options.filter(opt => {
                           return el.regions?.some(region => {
                              return opt.value === region;
                           });
                        }) ?? []}
                        options={options}
                        isMulti
                        isClearable
                        onChange={e => onChange(e, 'clusters', setClusters, i, clusters)}
                     />
                  </Col>
                  <Col className='col-auto p-0' style={{ width: '5rem' }}>
                     {clusters.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-1'
                           onClick={() => removeElement(clusters, setClusters, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}

                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('clusters', clusters, setClusters)}
                           disabled={!isThisRowFilled}
                        >
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   return (
      <>
         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  <h5 className='title-simple-box'><OETrans code='common.companies.companylist' fallback={'Lista società'}/></h5>
               </div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'><OETrans code='common.companies.addcompany' fallback={'Nuova società'}/></span>
                     </Button>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={companiesMeta}
                        setData={setCompanies}
                        setDataMeta={setCompaniesMeta}
                        dataLength={companies?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='companies'
                     />
                  </ButtonToolbar>
               </div>
            </div>

            <div className='row'>
               {Array.isArray(companies) ? (
                  <div className='col-12' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={companies}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           //onFirstDataRendered={onFirstDataRendered}
                           //onModelUpdated={onModelUpdated}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription="ag-grid-table"
                           suppressCellFocus={true}
                        />
                        {Object.keys(companiesMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={companiesMeta} 
                              setData={setCompanies} 
                              setDataMeta={setCompaniesMeta} 
                              rowsPerPageOptions={[10, 100, 200, 500]}
                              refTable='companies'
                              setIsFirstResearch={setIsFirstResearch}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-5'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>

         <Modal show={showModalNew} dialogClassName="modal-75w" className="modal-with-nav" size='xl' onHide={() => handleCloseModal(setShowModalNew)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.companies.createcompany' fallback={'Crea società'}/>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} fill>
                  {/* Anagrafica */}
                  <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')} className='px-5'>
                     <Form onSubmit={handleNewCompany} id='form-new-company'>
                        <Row className='mb-3' style={{ marginTop: '2rem' }}>
                           <Form.Group as={Col} className='pe-2' style={{ maxWidth: '10rem' }}>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.code' fallback={'Codice'}/>
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={
                                       <Tooltip id='button-tooltip'>
                                          <OETrans code='common.codemustcontain' fallback={'Il codice deve rispettare la seguente forma'} />:   
                                          <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
                                             <li><OETrans code='common.minimumcharacters' fallback={'un minimo di 4 ed un massimo di 10 caratteri'}/>,</li>
                                             <li><OETrans code='common.nospace' fallback={'nessuno spazio'}/>,</li>
                                             <li><OETrans code='common.nospecialcharacters' fallback={'nessun carattere speciale'}/>.</li>
                                          </ul>
                                       </Tooltip>
                                    }>
                                    <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='code'
                                 maxLength={10}
                                 minLength={4}
                                 value={newCompany.code ?? ''}
                                 onChange={e => onChange(e, 'code')}
                                 onBlur={() => onBlur(newCompany.code, codeRegex, setCodeIsInvalid)}
                                 isInvalid={codeIsInvalid}
                                 disabled={newCompany.id}
                              />
                              <Form.Control.Feedback type='invalid' className='ps-1 position-absolute'>
                                 {translate('common.error.code', 'codice non conforme').toLowerCase()}
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.name' fallback={'Nome'}/>
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='name'
                                 value={newCompany.name ?? ''}
                                 onChange={e => onChange(e, 'name')}
                              />
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.vat' fallback={'Partita IVA'}/>
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='vat'
                                 value={newCompany.vat ?? ''}
                                 onChange={e => onChange(e, 'vat')}
                              />
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.groupids' fallback={'Gruppi'}/>
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              <Select
                                 components={{
                                    IndicatorSeparator: () => null,
                                 }}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 closeMenuOnSelect={false}
                                 name="groupids"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedGroups}
                                 options={optionsGroups}
                                 isMulti
                                 isClearable
                                 onChange={e => onChange(e, 'groupids', setSelectedGroups)}
                              />
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2' style={{ maxWidth: '16.5rem' }}>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.sizeCode' fallback={'Dimensione'}/>
                              </Form.Label>
                              <Select
                                 components={{
                                    IndicatorSeparator: () => null,
                                 }}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 name="sizeCode"
                                 placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                 value={selectedSize}
                                 options={optionsSizes}
                                 onChange={e => onChange(e, 'sizeCode', setSelectedSize)}
                              />
                           </Form.Group>
                        </Row>

                        <Row className='mt-5 mb-3'>
                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.pec' fallback={'Pec'}/>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='pec'
                                 value={newCompany.pec ?? ''}
                                 onChange={e => onChange(e, 'pec')}
                                 onBlur={(e) => onBlur(e.target.value, pecRegex, setPecIsInvalid)}
                                 isInvalid={pecIsInvalid}
                              />
                              <Form.Control.Feedback type="invalid" className="ps-1 position-absolute">
                                 {translate('common.error.pec', 'pec non valida').toLowerCase()}
                              </Form.Control.Feedback>
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.address' fallback={'Sede legale'}/>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='address'
                                 value={newCompany.address ?? ''}
                                 onChange={e => onChange(e, 'address')}
                              />
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.legalRepresentative' fallback={'Rappresentante legale'}/>
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='legalRepresentative'
                                 placeholder="nome cognome"
                                 value={newCompany.legalRepresentative ?? ''}
                                 onChange={e => onChange(e, 'legalRepresentative')}
                              />
                           </Form.Group>

                           <Form.Group as={Col} className='pe-2'>
                              <Form.Label className='mb-3'>
                                 <OETrans code='companies.powerOfAttorney' fallback={'Procuratore'}/>
                                 <Form.Check
                                    inline
                                    style={{ marginTop: '0.2rem' }}
                                    className='ms-3'
                                    type='checkbox'
                                    name='powerOfAttorney'
                                    defaultChecked={newCompany.powerOfAttorney}
                                    onClick={e => onChange(e, 'powerOfAttorney')}
                                 />
                              </Form.Label>
                              <Form.Control
                                 type='text'
                                 name='attorney'
                                 placeholder={newCompany.powerOfAttorney ? translate('common.namesurname', 'Nome Cognome') : ''}
                                 value={newCompany.attorney ?? ''}
                                 onChange={e => onChange(e, 'attorney')}
                                 disabled={!newCompany.powerOfAttorney}
                              />
                           </Form.Group>
                        </Row>

                        <Row>
                           <Form.Group as={Col} className='px-3 col-4 border-tab right'>
                              <Form.Label className='mt-4 mb-3 me-1'>
                                 <OETrans code='companies.businessLines' fallback={'Business lines'}/>
                              </Form.Label>
                              {businessLinesRows(businessLines, setBusinessLines)}
                           </Form.Group>

                           <Form.Group as={Col} className='px-3 col-8'>
                              <Form.Label className='mt-4 mb-3 me-1'>
                                 <OETrans code='companies.geographicClusters' fallback={'Cluster geografici'}/>
                              </Form.Label>
                              {clusterRows(clusters, setClusters, optionsRegions)}
                           </Form.Group>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Documenti */}
                  <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />} className='px-5' disabled={!newCompany.id}>
                     <UploadDocument entity='companies' entityId={newCompany.id} />
                     {sbShow}
                  </Tab>
               </Tabs>

               <Row className='mt-5 mb-2'>
                  <span className='mx-5'>
                     <span className='required-field'>*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                  </span>
               </Row>
            </Modal.Body>

            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant='primary' onClick={e => handleNewCompany(e)} disabled={activeKey === 'docs'}>
                  {newCompany.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.create' fallback={'Crea'}/>}
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default CompaniesList;