export const months = () => {
   return [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
   ];
};

export const optionsForMonths = () => {
   return [
      {
         value: 1,
         label: 'Gennaio',
      },
      {
         value: 2,
         label: 'Febbraio',
      },
      {
         value: 3,
         label: 'Marzo',
      },
      {
         value: 4,
         label: 'Aprile',
      },
      {
         value: 5,
         label: 'Maggio',
      },
      {
         value: 6,
         label: 'Giugno',
      },
      {
         value: 7,
         label: 'Luglio',
      },
      {
         value: 8,
         label: 'Agosto',
      },
      {
         value: 9,
         label: 'Settembre',
      },
      {
         value: 10,
         label: 'Ottobre',
      },
      {
         value: 11,
         label: 'Novembre',
      },
      {
         value: 12,
         label: 'Dicembre',
      },
   ];
};