import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from 'react-bootstrap';
import { IcoUtenteBox, WorldIcon } from "@icons";

const AdministrationNavHorizontal = () => {
   const urlSearch = window.location.search;
   const navigation = [
      {
         name: "Utenti",
         link: "/administration?users",
         search: "?users",
         icon: <IcoUtenteBox style={{ width: "35px", height: "35px" }} />,
      },
      {
         name: "Traduzioni",
         link: "/administration?translations",
         search: "?translations",
         icon: <WorldIcon />,
      },
   ];

   return (
      <>
         <Navbar className='sub-navbar'>
            <Nav activeKey={urlSearch} className="justify-content-evenly">
               <Nav.Item>
                  <Nav.Link as={Link} to='/administration?users' eventKey='?users'>
                     Utenti
                  </Nav.Link>
               </Nav.Item>

               <Nav.Item>
                  <Nav.Link as={Link} to='/administration?translations' eventKey='?translations'>
                     Traduzioni
                  </Nav.Link>
               </Nav.Item>
            </Nav>
         </Navbar>

         <ul className="d-none menu-container side-nav">
            {navigation.map((item, index) => {
               // if urlSearch is equal to item.link, then the item is active or if urlSearch is empty and the item is the first one
               const active =
                  urlSearch === item.search
                     ? "active"
                     : urlSearch === "" && index === 0
                        ? "active"
                        : "";

               return (
                  <li className="menu-item" key={index}>
                     <Link
                        to={item.link}
                        className="text-decoration-none"
                        title={item.name}
                     >
                        <span className={`menu-link ${active}`}>
                           <div className="nav-ico">{item.icon}</div>
                           <div className="nav-text">{item.name}</div>
                        </span>
                     </Link>
                  </li>
               );
            })}
         </ul>
      </>
   );
};

export default AdministrationNavHorizontal;
