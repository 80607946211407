import React, { useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import moment from 'moment';
import { Button, ButtonToolbar, Spinner, Badge  } from 'react-bootstrap';
import { IcoPlus } from '@icons';
import { getUsers, getRoles, getCompanies, getGroups, getProjects } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererUsers from '@components/actionBtnRenderer/ActionBtnRendererUsers';
import UserCreation from '@components/usersManagement/UserCreation';
import { OETrans } from 'components/translation/OETrans';
import { translate } from 'components/translation/translation';
import { LevelContext } from '@components/contexts/LevelContext';
import Unauthorized from 'components/Unauthorized';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';

const UsersList = ({ isOEUserManagement }) => {
   const { isOe } = useContext(LevelContext);
   const [users, setUsers] = useState(null);
   const [usersMeta, setUsersMeta] = useState({});
   const [optionsRoles, setOptionsRoles] = useState([]);
   const [optionsCompanies, setOptionsCompanies] = useState([]);
   const [optionsPrivilegeGroups, setOptionsPrivilegeGroups] = useState([]);
   const [optionsPrivilegeCompanies, setOptionsPrivilegeCompanies] = useState([]);
   const [optionsPrivilegeProjects, setOptionsPrivilegeProjects] = useState([]);
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const refTable = useMemo(() => isOEUserManagement ? 'usersOE' : 'users', [isOEUserManagement]);
   const localeText = AG_GRID_LOCALE_IT;
   const gridRef = useRef();
   //console.log('trans', trans);

   const handleShowModal = (setShowModal) => setShowModal(true);

   useEffect(() => {
      getUsers(`?isOe=${isOEUserManagement ? 1 : 0}`, refTable).then((retrievedUsers) => {
         if (retrievedUsers.success) {
            setUsers(retrievedUsers.data);
            const { data, success, ...restResp } = retrievedUsers;
            setUsersMeta(restResp);
         }
      });

      getRoles().then((retrievedRoles) => {
         setOptionsRoles(
            retrievedRoles.data.map((role) => ({
               value: role.id,
               label: role.name,
            }))
         );
      });

      if (!isOEUserManagement) {
         getCompanies('?perPage=false').then((retrievedCompanies) => {
            let tempOptionsCompanies = retrievedCompanies.data.map((company) => ({
               value: company.id,
               label: company.name,
            }));

            setOptionsCompanies(tempOptionsCompanies);
            setOptionsPrivilegeCompanies(tempOptionsCompanies);
         });

         getGroups('?perPage=false').then((retrievedGroups) => {
            setOptionsPrivilegeGroups(
               retrievedGroups.data.map((group) => ({
                  value: group.id,
                  label: group.name,
               }))
            );
         });

         getProjects('?perPage=false').then((retrievedProjects) => {
            setOptionsPrivilegeProjects(
               retrievedProjects.data.map((project) => ({
                  value: project.id,
                  label: project.name,
               }))
            );
         });
      }
   }, [isOEUserManagement, refTable]);

   useEffect(() => {
      if (refresh) {
         getUsers(`?isOe=${isOEUserManagement ? 1 : 0}`, refTable).then((retrievedUsers) => {
            if (retrievedUsers.success) {
               setUsers(retrievedUsers.data);
               const { data, success, ...restResp } = retrievedUsers;
               setUsersMeta(restResp);
            }
         });

         setRefresh(false);
      }
   }, [isOEUserManagement, refTable, refresh]);

   const onGridReady = useCallback(() => {
      getUsers(`?isOe=${isOEUserManagement ? 1 : 0}`, refTable).then((retrievedUsers) => {
         if (retrievedUsers.success) {
            setUsers(retrievedUsers.data);
            const { data, success, ...restResp } = retrievedUsers;
            setUsersMeta(restResp);
         }
      });
   }, [isOEUserManagement, refTable]);

   const defaultColDef = {
      resizable: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('users.firstName', 'Nome'),
            field: 'firstName',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('users.lastName', 'Cognome'),
            field: 'lastName',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('users.email', 'Email'),
            field: 'email',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('users.lastLogin', 'Ultimo login'),
            field: 'lastLogin',
            maxWidth: 250,
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.lastLogin
                  ? moment(params.data.lastLogin).format('DD/MM/YYYY H:mm:ss')
                  : '';
            },
         },
         {
            headerName: 'Stato',
            field: 'accountStatus',
            maxWidth: 150,
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               const status = params.data.accountStatus;
               switch (status) {
                  case 'ACTIVE':
                     return 'Attivo';
                  case 'LOCKED':
                     return 'Bloccato';
                  case 'SUSPENDED':
                     return 'Sospeso';
                  default:
                     return '';
               }
            },
            cellRenderer: (params) => {
               const status = params.data.accountStatus;
               switch (status) {
                  case 'ACTIVE':
                     return <Badge bg='success'>Attivo</Badge>;
                  case 'LOCKED':
                     return <Badge bg='warning'>Bloccato</Badge>;
                  case 'SUSPENDED':
                     return <Badge bg='danger'>Sospeso</Badge>;
                  default:
                     return '';
               }
            },

         },
         {
            headerName: '',
            minWidth: 130,
            maxWidth: 150,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererUsers,
            cellRendererParams: {
               setRefresh: setRefresh,
               isOEUserManagement: isOEUserManagement,
               optionsRoles: optionsRoles,
               optionsCompanies: optionsCompanies,
               optionsPrivilegeGroups: optionsPrivilegeGroups,
               optionsPrivilegeCompanies: optionsPrivilegeCompanies,
               optionsPrivilegeProjects: optionsPrivilegeProjects,
            },
         },
      ]);
   }, [
      isOEUserManagement,
      optionsCompanies,
      optionsPrivilegeGroups,
      optionsPrivilegeCompanies,
      optionsPrivilegeProjects,
      optionsRoles
   ]);

   if (!isOe) return <Unauthorized />;

   return (
      <>
         {/* {isOEUserManagement && (
            <h5 className='px-4 mt-4 mb-4'>
               <OETrans code="common.menu.administration" fallback={'Amministrazione OE'}/>
            </h5>
         )} */}

         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  <h5 className='title-simple-box'>
                     {isOEUserManagement ? (
                        translate('common.oeusers', 'Utenti OpenEconomics')
                     ) : (
                        translate('common.userlist', 'Lista utenti')
                     )}
                  </h5>
               </div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <Button
                        variant='success'
                        onClick={() => handleShowModal(setShowModalNew)}
                        className=''>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'>
                           {isOEUserManagement ? (
                              <>
                                 <OETrans code='common.newuser' fallback={'Nuovo utente'}/>&nbsp;OE
                              </>
                           ) : (
                              <OETrans code='common.newuser' fallback={'Nuovo utente'}/>
                           )}
                        </span>
                     </Button>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={usersMeta}
                        setData={setUsers}
                        dataLength={users?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable={refTable}
                     />
                  </ButtonToolbar>
               </div>
            </div>
            <div className='row'>
               {Array.isArray(users) ? (
                  <div className='col-12' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={users}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                        />
                        {Object.keys(usersMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={usersMeta} 
                              setData={setUsers} 
                              setDataMeta={setUsersMeta}
                              refTable={refTable}
                              setIsFirstResearch={setIsFirstResearch}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>

         <UserCreation
            isOEUserManagement={isOEUserManagement}
            showModalNew={showModalNew}
            setShowModalNew={setShowModalNew}
            setRefresh={setRefresh}
            optionsCompanies={optionsCompanies}
            optionsPrivilegeGroups={optionsPrivilegeGroups}
            optionsPrivilegeCompanies={optionsPrivilegeCompanies}
            optionsPrivilegeProjects={optionsPrivilegeProjects}
         />
      </>
   );
};
export default UsersList;
