import { useEffect, useRef, useState } from "react";

const useDebounce = (callback, delay = 500, dependencies = []) => {
  const [executed, setExecuted] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (!executed) {
      const handle = () => {
        timeoutRef.current = setTimeout(() => {
          callback();
          setExecuted(true);
        }, delay);
      };

      handle();

      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
  }, [callback, delay, executed, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps
};
export default useDebounce;
