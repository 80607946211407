import { useEffect, useRef } from 'react';

const useInterval = (callback, timing, status) => {
  const referencedCallback = useRef();
  useEffect(() => {
    referencedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function fetchData() {
      referencedCallback.current();
    }
    if (timing !== null && status) {
      const id = setInterval(fetchData, timing);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, timing, status]);
};

export default useInterval;
