import React, { useState } from 'react';
import {
   Spinner,
   OverlayTrigger,
   Tooltip,
   Button,
} from 'react-bootstrap';
import { ExcelIcon } from '@icons';
import OeApiResponseRenderer from '@components/OeApiResponseRenderer';
import { OETrans } from 'components/translation/OETrans';

const DownloadExcelButton = ({ apiDownload, apiProps }) => {
   const [isDownloading, setIsDownloading] = useState(false);
   const [sbShow, setSbShow] = useState(false);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const handleFileDownload = async () => {
      setSbShow(<></>);
      setIsDownloading(true);

      const result = await apiDownload(apiProps);
      //console.log('result', result);

      if (!result.success) {
         handleStatusBar(result);
      }

      setIsDownloading(false);
   };

   return (
      <>
         {sbShow && <span className='me-3'>{sbShow}</span>}
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.downloadexcel' fallback={'Scarica Excel'}/></Tooltip>}>
            <Button
               className='me-2'
               style={{ padding: '0.3rem' }}
               onClick={() => handleFileDownload()}>
               {isDownloading ? 
                  <Spinner
                     style={{ width: '24px', height: '24px' }}
                     className="align-middle"
                     animation='border'
                     size='md'
                     role='status'>
                     <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                  </Spinner> :
                  <ExcelIcon style={{ width: '24px', height: '24px' }} />
               }
            </Button>
         </OverlayTrigger>
      </>
   )
};
export default DownloadExcelButton;