import React from 'react';
import OpportunityLayout from '@components/opportunity/OpportunityLayout';

const OpportunityPage = () => {

   return (
      <>
         <OpportunityLayout />
      </>
   );
};
export default OpportunityPage;
