import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const Unauthorized = ({ drilled = false }) => {
  return (
    <div className={`${!drilled ? 'simple-box' : ''} text-center not-found error`}>
      <Row>
        <Col>
          <h2>L'utente loggato non dispone dei diritti necessari per visualizzare la pagina.</h2>

          <Link to='/projects'>Ritorna ai progetti</Link>
        </Col>
      </Row>
    </div>
  );
};

export default Unauthorized;
