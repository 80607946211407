import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatToDecimals } from '@lib/helpers/formatting';
import { IcoHexagon } from '@icons';

// const colors = [
//   '#C2C923',
//   '#08A2AA',
//   '#CB1B4A',
//   '#FFB317',
//   '#053D58',
//   '#378288',
//   '#5BCFD4',
//   '#D4AFCD',
//   '#F5D0C5',
//   '#D69F7E',
// ];

const LegendBullet = ({ label, color, className }) => {
   return (
      <Col className='d-flex justify-content-start align-items-center'>
         <span className={className + ' bullet-span'}>
            <IcoHexagon className={'hexagon'} style={{ fill: color }} />
            <span className='ms-2 text-start align-middle'>{label}</span>
         </span>
      </Col>
   );
};

// IF COLOR SELECTION SHOULD BE TAKEN BY COLORS VARIABLE put it into the data.map
/* if (d.propertyName !== totalName && d.propertyValue !== 0) {
  backgroundColor = colors[nextColorIndex];
  nextColorIndex++; // Passa all'indice successivo dei colori
} else if (d.propertyValue === 0) {
  backgroundColor = 'rgba(192, 192, 192, .5)';
  nextColorIndex++;
} */

const NewLegend = ({ data, scope, className, totalName, totalValue, showTotal = false }) => {

   return (
      <>
         {data.length > 0 &&
            data.map((d, index) => {
               let backgroundColor = d.color;

               return (
                  <Row
                     className='row-legend'
                     key={`${className}_legend_${index}`}
                  >
                     <Col style={{ flexBasis: 'max-content' }} className='p-0 col-auto'>
                        <LegendBullet
                           className={`span-legend ${className}`}
                           label={d.translation}
                           color={backgroundColor}
                           scope={scope}
                           property={d.propertyName}
                           totalName={totalName}
                        />
                     </Col>

                     <Col style={{ flexBasis: 'max-content' }} className='text-end p-0'>
                        <span className={d.realValue === '' ? 'invisible' : ''}>{formatToDecimals(d.realValue)}</span>
                     </Col>
                  </Row>
               );
            })
         }
      </>
   );
};
export default NewLegend;
