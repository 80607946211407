import React, { useState, useEffect } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
} from "react-bootstrap";
import { IcoEdit } from "@icons";
import { editFund } from "@lib/api";
import UploadDocument from "components/form/upload/uploadDocuments";
import OeApiResponseRenderer from "components/OeApiResponseRenderer";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const ActionBtnRendererFunds = ({ data, setRefresh }) => {
  const id = data.id;
  const [fund, setFund] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  //console.log('fund', fund);

  useEffect(() => {
    setFund(data);
  }, [data]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setFund(data);
    setActiveKey("registry");
    setRefresh(true);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  const handleShowModalTab = (setShowModal, tab) => {
    setShowModal(true);
    setActiveKey(tab);
  };

  const onChange = (e, fieldName) => {
    //console.log('e', e);

    let tempFund = { ...fund };
    let value;

    switch (fieldName) {
      case "descripition":
        value = e.target.value;
        break;
      default:
        value = e.target.value;
    }

    tempFund[fieldName] = value;

    setFund(tempFund);
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restFund } = fund;
    //console.log('restClass', restClass);

    if (!fund.name) {
      handleStatusBar({
        success: false,
        message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
      });
      return;
    }

    const editResult = await editFund(id, restFund);

    if (editResult.success) {
      handleStatusBar({
        success: true,
        message: translate('common.message.edited', 'Elemento modificato con successo'),
      });

      /* setTimeout(() => {
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000); */
    } else {
      handleStatusBar(editResult);
    }
  };

  const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;

  return (
    <div>
      <Button
        variant="outline-info"
        className="py-1 me-4"
        onClick={() => handleShowModalTab(setShowModalEdit, "docs")}
      >
        <OETrans code='common.documents' fallback={'Documenti'} />
      </Button>

      <OverlayTrigger placement="top" overlay={editTooltip}>
        <Button
          variant="link"
          className="me-0"
          onClick={() => handleShowModal(setShowModalEdit)}
        >
          <IcoEdit className="second-color ico-small edit" />
        </Button>
      </OverlayTrigger>

      {showModalEdit && (
        <Modal
          size="lg"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><OETrans code='common.editfund' fallback={'Modifica fondo'} /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              variant='pills'
              fill
            >
              {/* Anagrafica */}
              <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
                <Form onSubmit={handleEdit} id="form-edit-fund">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-12">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='funds.name' fallback={'Nome'} />
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={fund.name}
                          //onChange={(e) => onChange(e, 'name')}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-12">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='funds.description' fallback={'Descrizione'} /></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          name="description"
                          value={fund.description}
                          onChange={(e) => onChange(e, "description")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Documenti */}
              <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument entity="funds" entityId={fund.id} />
                {sbShow}
              </Tab>
            </Tabs>

            <Row>
              <Col className="col-12">
                <span className="label"><span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/></span>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            <Button
              variant="primary"
              onClick={(e) => handleEdit(e)}
              disabled={activeKey === "docs"}
            >
              <OETrans code='common.edit' fallback={'Modifica'}/>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ActionBtnRendererFunds;
