import { useCallback, useRef, useMemo } from "react";
import {
   InputGroup,
   FormControl,
   ButtonToolbar,
   Spinner,
   Col,
   Row,
} from "react-bootstrap";
import { Lens } from "@icons";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import CustomTooltip from "@components/ag-grid/CustomTooltip";
import { OETrans } from "components/translation/OETrans";
import { translate } from "components/translation/translation";

const OESimpleTable = ({
   columnDefs,
   rowData,
   buttonToolbarVisible = false,
   reloadData,
   buttonChildren,
   showSearchbox = true,
   sortable = false,
   pinnedBottomRow = false,
   bottomColumns = [],
   fullWidth = true,
   dataForSum = [],
}) => {
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;

   const defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: {
         padding: "0 1rem",
         textOverflow: "ellipsis",
         whiteSpace: "nowrap",
         overflow: "hidden",
      },
      sortable: sortable,
      tooltipComponent: CustomTooltip,
   };

   const onFilterTextBoxChanged = useCallback((e) => {
      gridRef.current.api.setQuickFilter(e.target.value);
   }, []);

   /*  const onGridReady = useCallback(
    (params, gridRef) => {
      if (reloadData) reloadData();

      params.api.sizeColumnsToFit();

      window.addEventListener("resize", function () {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        });
      });

      if (window.screen.width >= 1600) {
        //&& window.screen.height >= 768
        // Resolution is 1600 //x768 or above
        gridRef.current.api.sizeColumnsToFit();
      } else {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
      }
    },
    [reloadData]
  ); */

   const createData = useCallback(
      (columns) => {
         let result = {};
         let data = dataForSum.length > 0 ? dataForSum : rowData;

         if (Array.isArray(data)) {
            columns.forEach((col) => {
               //SE LA PROPRIETA' ESISTE NEI DATA ALLORA FA LA SOMMA, SE NO AGGIUNGE LA STRINGA ALLA COLONNA SCELTA
               if (typeof col === "string" || col instanceof String) {
                  result[col] = data.reduce((prev, curr) => prev + curr[col], 0);
               } else {
                  result[Object.keys(col).pop()] = Object.values(col).pop();
               }
            });
         }

         return [result];
      },
      [rowData, dataForSum]
   );

   const pinnedBottomRowDataFunction = useMemo(() => {
      return createData(bottomColumns);
   }, [bottomColumns, createData]);

   const getRowStyle = useCallback((params) => {
      if (params.node.rowPinned === "bottom") {
         return { fontWeight: "600" };
      }
   }, []);

   return (
      <Row className="justify-content-center px-0">
         {buttonToolbarVisible && (
            <Col className={`mb-3 px-0 py-1 d-flex justify-content-end text-right ${fullWidth ? 'col-12' : 'col-xxl-10'}`}>
               <ButtonToolbar className="justify-content-end">
                  {buttonChildren}
                  {showSearchbox &&
                     <><InputGroup className="search-table align-items-center mt-3 mt-md-0">
                        <FormControl
                           type="text"
                           className="filter-text-box"
                           id="filter-text-box-tenders"
                           placeholder={translate('common.search', 'Cerca') + '...'}
                           onInput={(e) => onFilterTextBoxChanged(e)}
                        />
                        <Lens />
                     </InputGroup>
                     </>
                  }
               </ButtonToolbar>
            </Col>
         )}

         {Array.isArray(rowData) ? (
            <div className={`px-0 ${fullWidth ? 'col-12' : 'col-xxl-10'}`} {...(fullWidth && { id: 'grid-wrapper' })}>
               <div className="ag-theme-alpine "> {/* list-table */}
                  <AgGridReact
                     ref={gridRef}
                     columnDefs={columnDefs}
                     rowData={rowData}
                     defaultColDef={defaultColDef}
                     domLayout="autoHeight"
                     tooltipShowDelay={0}
                     tooltipHideDelay={2000}
                     tooltipMouseTrack={true}
                     //onGridReady={(e) => onGridReady(e, gridRef)}
                     headerHeight={50}
                     rowHeight={50}
                     localeText={localeText}
                     ariaDescription="ag-grid-table"
                     suppressCellFocus={true}
                     autoSizeStrategy={{ type: "fitCellContents" }}
                     animateRows={true}
                     {...(pinnedBottomRow && {
                        pinnedBottomRowData: pinnedBottomRowDataFunction,
                        getRowStyle: getRowStyle,
                        accentedSort: true,
                     })}
                  />
               </div>
            </div>
         ) : (
            <div className="text-center my-4">
               <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
               </Spinner>
            </div>
         )}
      </Row>
   );
};

export default OESimpleTable;
