import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { getFinancialProgresses } from "@lib/api/index";
import FinancialTotalsTab from "@components/advancement/FinancialTotalsTab";

const Financial = ({ projectId }) => {
  const [financialAmounts, setFinancialAmounts] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);

  useEffect(() => {
    if (projectId) {
      getFinancialProgresses(projectId).then((financialProgress) => {
        setFinancialAmounts(financialProgress.data);
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (financialAmounts.length) {
      setUniqueYears(() => getUniqueItems(financialAmounts));
    }
  }, [financialAmounts]);

  const getUniqueItems = (data, inputYear) => {
    const uniqueItems = new Set();

    if (inputYear) {
      data
        .filter((el) => el.year === inputYear)
        .sort((a, b) => a.posizioneVoceDiSpesa - b.posizioneVoceDiSpesa)
        .forEach((item) => {
          uniqueItems.add(item.voceDiSpesa);
        });
    } else {
      data
        .sort((a, b) => a.year - b.year)
        .forEach((item) => {
          uniqueItems.add(item.year);
        });
    }

    const uniqueItemsArray = Array.from(uniqueItems);

    return uniqueItemsArray;
  };

  return (
    <>
      {uniqueYears.length ? (
        <Row>
          <FinancialTotalsTab financialAmounts={financialAmounts} />
        </Row>
      ) : (
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            Nessun elemento disponibile
          </Col>
        </Row>
      )}
    </>
  );
};
export default Financial;
