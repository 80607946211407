import React, { useCallback, useEffect, useState } from 'react';
import StatusBar from './StatusBar';
import { Accordion, ListGroup } from 'react-bootstrap';

const OeApiResponseRenderer = ({ dataToHandle }) => {
  const [dataToDisplay, setDataToDisplay] = useState({});

  //Cosa devo aspettarmi?
  // 1. dataToHandle puo essere sia un Array (che contiene più response) sia un Oggetto che contiene una singola response
  // 2. in caso di array con più response, posso avere tutti success===true, tutti success==false ed un mix di entrambi
  // 3. in caso di tutti success===true oppure singolo oggetto ===true ritorna messaggio di ok
  // 4. in caso di più errori nell'array voglio condensare i messaggi come titolo della statusbar ed avere una lista dei singoli errori nell'accordion quindi la lista sarà key-messaggio.
  // 5. potrebbero esserci più errori con la stessa key. Vanno numerati altrimenti si sostituiranno.
  // 6. potenzialmente, potrebbero NON essere key-values ma array di stringhe, in quel caso? Numero soltanto e chiamo error-messaggio

  const mergeErrors = (data) => {
    const mergedErrors = data.reduce(
      (acc, current) => {
        if (current.success === false) {
          const { message, data } = current;
          acc.message = acc.message ? `${acc.message}, ${message}` : message;

          if (Array.isArray(data)) {
            data.forEach((error, index) => {
              acc.data[`error${index + 1}`] = [error];
            });
          } else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key) => {
              let newKey = key;
              let count = 2;
              while (acc.data[newKey]) {
                newKey = `${key}${count}`;
                count++;
              }
              acc.data[newKey] = data[key];
            });
          }
        }
        return acc;
      },
      { message: '', data: {} }
    );

    return mergedErrors;
  };

  useEffect(() => {
    // 1)
    if (Array.isArray(dataToHandle)) {
      // 3)
      const hasAllSuccess = dataToHandle.every((item) => item.success === true);

      if (hasAllSuccess) {
        setDataToDisplay({ success: true, message: 'Operazione eseguita con successo' });
      } else {
        // 2) 4)
        // const mergedErrors = dataToHandle.reduce(
        //   (acc, current) => {
        //     if (current.success === false) {
        //       const { message, data } = current;
        //       acc.message = acc.message ? `${acc.message}, ${message}` : message;
        //       // 6)
        //       if (Array.isArray(data)) {
        //         data.forEach((error, index) => {
        //           acc.data[`error${index + 1}`] = [error];
        //         });
        //       } else if (typeof data === 'object' && data !== null) {
        //         // 5)
        //         Object.keys(data).forEach((key) => {
        //           let newKey = key;
        //           let count = 2;
        //           while (acc.data[newKey]) {
        //             newKey = `${key}${count}`;
        //             count++;
        //           }
        //           acc.data[newKey] = data[key];
        //         });
        //       }
        //     }
        //     return acc;
        //   },
        //   { message: '', data: {} }
        // );
        const mergedErrors = mergeErrors(dataToHandle);
        setDataToDisplay({ success: false, ...mergedErrors });
      }
    } else if (dataToHandle && dataToHandle.success === false) {
      // Singolo Errore
      setDataToDisplay({
        success: false,
        message: dataToHandle.message || 'ERROR',
        data: dataToHandle.data || {},
      });
    } else if (dataToHandle && dataToHandle.success === true) {
      setDataToDisplay({
        success: true,
        message:
          !dataToHandle.message || dataToHandle.message === 'ok'
            ? 'Operazione eseguita con successo'
            : dataToHandle.message,
      });
    }
  }, [dataToHandle]);

  const renderCorrectMessage = useCallback((state) => {
    const { success, message } = state;

    if (success === true) {
      return <StatusBar status='success' message={message} timeout={true} />;
    } else if (success === false) {
      const { data, message } = state;
      let errorMessage;
      if (Array.isArray(data)) {
        // Qui genero gli errori in caso di array di stringhe
        errorMessage = data.map((element, index) => (
          <ListGroup.Item key={`arrayError_${index}`}>{element}</ListGroup.Item>
        ));
      } else {
        // Qui genero gli errori in caso di oggetti key-value
        const keys = Object.keys(data);
        //console.log('CHIAVI', keys);
        errorMessage = keys.map((key, index) => {
          if (data[key]?.[0]?.colonna && data[key]?.[0]?.errore) {
            return (
              <ListGroup.Item key={`objectError_${index}`}>
                <strong>{data[key]?.[0]?.colonna}</strong> - {data[key]?.[0]?.errore}
              </ListGroup.Item>
            );
          } else {
            return (
              <ListGroup.Item key={`objectError_${index}`}>
                <strong>{key}</strong> - {data[key]?.[0]}
              </ListGroup.Item>
            );
          }
        });
      }
      return (
        <StatusBar
          status='errors'
          message={message || 'ERROR'}
          timeout={false}
          children={
            errorMessage.length > 0 ? (
              <Accordion id='errorAccordion'>
                <Accordion.Item className='accordionBorder' eventKey='0'>
                  <Accordion.Header className='expanded-accordion '>
                    <h5 className='d-inline me-2 mb-0'>{message || 'ERROR'}</h5>
                  </Accordion.Header>
                  <Accordion.Body>{errorMessage}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              <div className='simple-box error d-inline'>
                <h5 className='d-inline me-2'>ERROR</h5> {message ? <span>{message}</span> : null}
              </div>
            )
          }
        />
      );
    }
  }, []);
  return <>{Object.keys(dataToDisplay).length !== 0 && renderCorrectMessage(dataToDisplay)}</>;
};

export default OeApiResponseRenderer;
