import React, { useState } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { Alert } from "components/atomic/atoms";
import { impersonate } from "@lib/api";

const Impersonate = ({ user, showFormModal, setShowFormModal }) => {
  const [requiredFields, setRequiredFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [ImpersonateData, setImpersonateData] = useState({
    userId: user.id,
    password: "",
    passwordConfirmation: "",
  });

  const alwaysRequiredFields = ["password", "passwordConfirmation"];

  const handleChanges = (e) => {
    const { name, value } = e.target;

    setImpersonateData({
      ...ImpersonateData,
      [name]: value,
    });

    if (alwaysRequiredFields.includes(name)) {
      if (value.trim() === "" || value === null || value === undefined) {
        setRequiredFields({ ...requiredFields, [name]: true });
      } else {
        const newFields = { ...requiredFields };
        delete newFields[name];
        setRequiredFields(newFields);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);
    const newRequiredFields = {};

    alwaysRequiredFields.forEach((field) => {
      if (!ImpersonateData[field]) {
        newRequiredFields[field] = true;
      }
    });

    if (ImpersonateData.password !== ImpersonateData.passwordConfirmation) {
      newRequiredFields.passwordConfirmation = true;
    }

    if (Object.keys(newRequiredFields).length > 0) {
      setRequiredFields(newRequiredFields);
      setIsLoading(false);
      return;
    }

    impersonate(ImpersonateData).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setShowAlert({
          show: true,
          message: "Utente impersonato con successo",
          type: "success",
        });
        localStorage.setItem('impersonate', 'true');
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('userEmail', res.data.email);
        localStorage.setItem('userFirstname', res.data.firstName);
        localStorage.setItem('userLastname', res.data.lastName);
        localStorage.setItem('error', '');
        if (!localStorage.getItem('userLanguage')) {
          localStorage.setItem('userLanguage', 'IT');
        }
        setTimeout(() => {
          // Remove impersonate key
          localStorage.removeItem('impersonate');
          // Redirect to dashboard
          window.location.href = '/projects';
          handleModalClose();
        }, 2000);
      } else {
        setIsLoading(false);
        setShowAlert({
          show: true,
          message: "Errore durante l'impersonazione dell'utente",
          type: "danger",
        });
      }
    });
  };

  const handleModalClose = () => {
    setShowFormModal(false);
    setRequiredFields({});
    setImpersonateData({});
    setIsLoading(false);
  };

  return (
    <Modal
      size="lg"
      show={showFormModal}
      onHide={() => handleModalClose()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="pt-0">
          Impersona utente {user.firstName + " " + user.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {showAlert.show && (
            <Row className="pt-3">
              <Col className="col-12 p-0">
                <Alert
                  show={showAlert.show}
                  message={showAlert.message}
                  variant={showAlert.type}
                  delay={5000}
                />
              </Col>
            </Row>
          )}

          <Row style={{ marginBottom: "40px" }}>
            <Col className="col-12">
              <Form.Text>
                <p>
                  <strong>Attenzione:</strong> Impersonando un utente, verrai
                  reindirizzato alla dashboard dell'utente selezionato. Per
                  tornare alla tua dashboard personale, dovrai effettuare
                  nuovamente il login.
                </p>
              </Form.Text>
            </Col>
          </Row>
          <Row style={{ marginBottom: "40px" }}>
            <Col className="col-6">
              <Form.Group controlId="password">
                <Form.Label>
                  Inserisci la tua password
                  {alwaysRequiredFields.includes("password") && (
                    <span className="required-field">*</span>
                  )}
                </Form.Label>

                <Form.Control
                  type="password"
                  name="password"
                  style={{ resize: "none" }}
                  defaultValue={""}
                  onChange={(e) => handleChanges(e)}
                  isInvalid={requiredFields.password}
                />
                <Form.Control.Feedback type="invalid">
                  Campo obbligatorio
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group controlId="passwordConfirmation">
                <Form.Label>
                  Conferma Password
                  {alwaysRequiredFields.includes("passwordConfirmation") && (
                    <span className="required-field">*</span>
                  )}
                </Form.Label>

                <Form.Control
                  type="password"
                  name="passwordConfirmation"
                  style={{ resize: "none" }}
                  defaultValue={""}
                  onChange={(e) => handleChanges(e)}
                  isInvalid={requiredFields.passwordConfirmation}
                />
                <Form.Control.Feedback type="invalid">
                  {ImpersonateData.password !== ImpersonateData.passwordConfirmation && "Le password non corrispondono"}
                  {requiredFields.passwordConfirmation && "Campo obbligatorio"}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleModalClose()}>
          Annulla
        </Button>
        <Button
          disabled={isLoading || Object.keys(requiredFields).length > 0}
          type="submit"
          onClick={(e) => handleFormSubmit(e)}
          variant="primary"
        >
          {isLoading ? "Caricamento..." : "Impersona"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Impersonate;
