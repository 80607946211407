import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import {
   Form,
   Row,
   Col,
   Spinner,
   OverlayTrigger,
   Tooltip,
   Button,
   Tabs,
   Tab,
   ButtonToolbar,
   Modal,
} from 'react-bootstrap';
//import StatusBar from '@components/StatusBar';
import { IcoUploadFile, IcoDownloadFile } from '@icons';
import {
   getStagingTable,
   getDocumentsStaging,
   //getLastBatch,
   uploadTendersList,
   getBatchesByInfo,
   getChecklistStagingTable,
   tendersListUploadChecklist,
   uploadDocumentsTenders,
   downloadDocument,
   downloadEntityTemplate,
   getFrameworkContractsStagingAll,
   uploadFrameworkContracts,
   deleteDocumentsStaging,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { IcoDanger, IcoRefresh, TooltipInfoIcon } from '@icons';
import useInterval from '@lib/hooks/useInterval';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import { columnCentered, columnToRight } from '@lib/helpers/agGridHelpers';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { OETrans } from 'components/translation/OETrans';
import { translate } from 'components/translation/translation';
//import OEModal from 'components/oemodal/OEModal';

/* const entities = {
   'tenders': 'gare',
   'frameworkcontracts': 'contratti aperti',
} */

const MassiveUploadTendersList = () => {
   const [isPending, setIsPending] = useState(false);
   const [isError, setIsError] = useState(false); // eslint-disable-line no-unused-vars
   const [isComplete, setIsComplete] = useState(false); // eslint-disable-line no-unused-vars
   const [tendersStaging, setTendersStaging] = useState(null);
   const [tendersStagingMeta, setTendersStagingMeta] = useState({});
   const [contractStaging, setContractStaging] = useState(null);
   const [contractStagingMeta, setContractStagingMeta] = useState({});
   const [checklistsTendersStaging, setChecklistsTendersStaging] = useState(null);
   const [checklistsTendersStagingMeta, setChecklistsTendersStagingMeta] = useState({});
   const [checklistsFrameworkContractsStaging, setChecklistsFrameworkContractsStaging] = useState(null);
   const [checklistsFrameworkContractsStagingMeta, setChecklistsFrameworkContractsStagingMeta] =
      useState({});
   const [documentsStaging, setDocumentsStaging] = useState(null);
   const [documentsStagingMeta, setDocumentsStagingMeta] = useState({});
   const [selectedDocumentsStaging, setSelectedDocumentsStaging] = useState([]);
   const [errors, setErrors] = useState(null);
   const [sbShow, setSbShow] = useState(<></>);
   const [optionsPagination, setOptionsPagination] = useState([]);
   const [columnDefsTendersStaging, setColumnDefsTendersStaging] = useState([]);
   const [columnDefsContractStaging, setColumnDefsContractStaging] = useState([]);
   const [columnDefsStagingTendersCL, setColumnDefsStagingTendersCL] = useState([]);
   const [columnDefsStagingFrameworkContractsCL, setColumnDefsStagingFrameworkContractsCL] =
      useState([]);
   const [columnDefsDocuments, setColumnDefsDocuments] = useState([]);
   const [columnDefsErrors, setColumnDefsErrors] = useState([]);
   //const [activeKey, setActiveKey] = useState('cl-tenders');
   const [subActiveKey, setSubActiveKey] = useState('log-tenders');
   const [batchPollingEnabled, setBatchPollingEnabled] = useState(true);
   const [dangerModalShow, setDangerModalShow] = useState(false);
   const [isFirstResearch3, setIsFirstResearch3] = useState(true);
   const [isFirstResearch4, setIsFirstResearch4] = useState(true);
   const localeText = AG_GRID_LOCALE_IT;
   const gridRefStaging = useRef();
   const gridRefStaging1 = useRef();
   const gridRefStaging2 = useRef();
   const gridRefStaging3 = useRef();
   const gridRefStaging4 = useRef();
   const gridRefErrors = useRef();
   const fileInputRef = useRef(null);

   const handleCloseModal = (setState) => {
      setState(false);
   };

   useEffect(() => {
      setOptionsPagination([
         <Fragment key='options'>
            <option defaultValue='10' key='10'>
               10
            </option>
            <option value='50' key='50'>
               50
            </option>
            <option value='100' key='20'>
               100
            </option>
         </Fragment>,
      ]);
   }, []);

   const pollDataBatch = useCallback(() => {
      let taskEntity;

      switch (subActiveKey) {
         case 'log-tenders':
            getStagingTable('tenders','tendersStaging').then((stagings) => {
               setTendersStaging(stagings.data);
               const { data, success, ...restResp } = stagings;
               setTendersStagingMeta(restResp);
            });
            break;
         case 'log-cq':
            getFrameworkContractsStagingAll('frameworkcontractsStaging').then((stagingContracts) => {

               setContractStaging(stagingContracts.data);
               const { data, success, ...restResp } = stagingContracts;
               setContractStagingMeta(restResp);

               /* fetchForPaginationWithQueryParams(
                 stagingContracts.meta.path,
                 `?taskType=${'XLSX'}`
               ).then((resultData) => {
                 console.log('resultData:', resultData);
                 const { data, success, ...restResp } = resultData;
                 setContractStagingMeta(restResp);
               }); */
            });

            break;
         case 'log-cl-tenders':
            taskEntity = 'tenders';
            getChecklistStagingTable(taskEntity, 'tendersCLStaging').then((stagingChecklists) => {
               setChecklistsTendersStaging(stagingChecklists.data);

               const { data, success, ...restResp } = stagingChecklists;
               setChecklistsTendersStagingMeta(restResp);
            });
            break;
         case 'log-cl-frameworkcontracts':
            taskEntity = 'frameworkContracts';
            getChecklistStagingTable(taskEntity, 'frameworkcontractsCLStaging').then((stagingChecklists) => {
               setChecklistsFrameworkContractsStaging(stagingChecklists.data);

               const { data, success, ...restResp } = stagingChecklists;
               setChecklistsFrameworkContractsStagingMeta(restResp);
            });
            break;
         case 'log-documents':
            let docEntityType = 'tenders';
            let docTaskEntity = 'tenders';

            /* getLastBatch(docEntityType, docTaskEntity, 'ZIP').then(lastBatch => {
               console.log('lastBatch', lastBatch);

               if (lastBatch.success && lastBatch.data.length > 0) {
                  console.log('lastBatch.data[0].id', lastBatch.data[0].id);
                  let lastBatchId = lastBatch.data[0].id;

                  getDocumentsStaging(docEntityType, undefined, lastBatchId, 'tendersDocStaging').then((retrievedDocs) => {
                     setDocumentsStaging(retrievedDocs.data);
                     const { data, success, ...restResp } = retrievedDocs;
                     setDocumentsStagingMeta(restResp);
                  });
               }
            }); */

            getDocumentsStaging(docEntityType, docTaskEntity, undefined, 'tendersDocStaging').then((retrievedDocs) => {
               setDocumentsStaging(retrievedDocs.data);
               const { data, success, ...restResp } = retrievedDocs;
               setDocumentsStagingMeta(restResp);
            });

            setSelectedDocumentsStaging([]);
            break;
         default:
      }

      getBatchesByInfo('tenders').then((batches) => {
         let filteredBatches;

         if (batches.success) {
            if (batches.data.length > 0) {
               filteredBatches = batches.data.filter(
                  (el) => /* el.taskEntity === entityType && */ el.entityType === 'tenders'
               );

               const lastBatch = filteredBatches[filteredBatches.length - 1];
               const isDone =
                  !lastBatch?.status === 'DONE' && !lastBatch?.status === 'DONE WITH ERRORS';

               setBatchPollingEnabled(isDone);
            } else {
               setBatchPollingEnabled(false);
            }
         }
      });
   }, [subActiveKey]);

   useEffect(() => {
      pollDataBatch();
   }, [subActiveKey, pollDataBatch]);

   useInterval(pollDataBatch, 2000, batchPollingEnabled);

   const defaultColDef = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      suppressSizeToFit: false,
      cellStyle: { padding: '0 1rem' },
   };

   const defaultColDefLogs = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      //flex: 1,
      suppressMovable: true,
      suppressSizeToFit: false,
      cellStyle: { padding: '3px 1rem', fontStyle: 'italic' },
      headerClass: 'fst-italic',
      //suppressColumnVirtualisation=true
   };

   const isValidJSON = (jsonString) => {
      try {
         JSON.parse(jsonString);
         return true;
      } catch (error) {
         return false;
      }
   };

   const LogsCellRenderer = useCallback((params) => {
      if (params.value) {
         const isValid = isValidJSON(params.value);
         if (isValid) {
            const JSONerrors = JSON.parse(params.value);
            const arrayErrors = Object.values(JSONerrors);
            //console.log('arrayErrors', arrayErrors);

            return arrayErrors.map((error, index) => (
               <p key={index} style={{ color: 'red' }}>
                  {Object.values(error)[0]}
               </p>
            ));
         } else {
            return <span>{params.value}</span>;
         }
      } else {
         return null;
      }
   }, []);

   const LogCellRenderer = useCallback((params) => {
      const red = '#C94723';
      // const yellow = '#D4BE31';
      const green = '#689689';

      const legendStyles = {
         bullet: {
            display: 'inline-block',
            width: '1.2em',
            height: '1.2em',
            borderRadius: '2em',
            //top: '0.30em',
            //position: 'relative',
            // boxShadow: '1px 1px 3px 0px #666666',
         },
         label: {
            display: 'inline-block',
            width: '50%',
            lineHeight: '2.5em',
         },
      };

      if (params.value) {
         return (
            <i
               style={{
                  ...legendStyles.bullet,
                  backgroundColor: params.value === 'OK' ? green : red,
                  textAlign: 'left',
                  verticalAlign: 'sub',
               }}
            />
         );
      }
   }, []);

   const handleDownloadDocument = useCallback((params) => {
      const handleDownload = async (e) => {
         setSbShow(<></>);
         setIsPending(true);
         setIsError(false);
         setIsComplete(false);

         const result = await downloadDocument(params.data);


         if (result.success) {
            // File upload successful
            setIsComplete(true);
            setIsPending(false);
         } else {
            /* const message = result.data.file.join(',')
                    handleStatusBar('error', message, false); */
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
         }
      };

      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.download' fallback={'Scarica'}/></Tooltip>}>
            <Button variant='link' className='' onClick={(e) => handleDownload(e)}>
               <IcoDownloadFile className='second-color ico-small massive-upload align-bottom' />
            </Button>
         </OverlayTrigger>
      );
   }, []);

   useEffect(() => {
      setColumnDefsStagingTendersCL([
         {
            headerName: translate('checklistsstaging.entityNumber.tenders', 'Nr. Gara'),
            field: 'entityNumber',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('checklistsstaging.status', 'Stato'),
            field: 'status',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('checklistsstaging.log', 'Errori'),
            field: 'log',
            cellRenderer: LogsCellRenderer,
            filter: 'agTextColumnFilter',
            //minWidth: 460,
            resizable: true,
         },
         {
            headerName: translate('checklistsstaging.answeredQuestion', 'Nr. Risposte'),
            field: 'answeredQuestion',
            filter: 'agTextColumnFilter',
            //type: 'rightAligned',
            //minWidth: 250,
            ...columnToRight('fst-italic'),
         },
         {
            headerName: translate('checklistsstaging.numberOfQuestions', 'Nr. Domande'),
            field: 'numberOfQuestions',
            filter: 'agTextColumnFilter',
            //type: 'rightAligned',
            ...columnToRight('fst-italic'),
         },
      ]);

      setColumnDefsStagingFrameworkContractsCL([
         {
            headerName: translate('checklistsstaging.entityNumber.frameworkcontracts', 'Nr. Contratto Quadro'),
            field: 'entityNumber',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('checklistsstaging.log', 'Errori'),
            field: 'log',
            cellRenderer: LogsCellRenderer,
            filter: 'agTextColumnFilter',
            //minWidth: 460,
            resizable: true,
         },
         {
            headerName: translate('checklistsstaging.answeredQuestion', 'Nr. Risposte'),
            field: 'answeredQuestion',
            filter: 'agTextColumnFilter',
            //type: 'rightAligned',
            //minWidth: 250,
            ...columnToRight('fst-italic'),
         },
         {
            headerName: translate('checklistsstaging.numberOfQuestions', 'Nr. Domande'),
            field: 'numberOfQuestions',
            filter: 'agTextColumnFilter',
            //type: 'rightAligned',
            ...columnToRight('fst-italic'),
         },
      ]);

      setColumnDefsTendersStaging([
         {
            headerName: translate('tendersstaging.tenderNumber', 'Nr. gara'),
            field: 'tenderNumber',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('tendersstaging.log', 'Errori'),
            field: 'log',
            cellRenderer: LogsCellRenderer,
            filter: 'agTextColumnFilter',
            //minWidth: 460,
            resizable: true,
         },
         {
            headerName: translate('tendersstaging.companyName', 'Società'),
            field: 'companyName',
            filter: 'agTextColumnFilter',
         },
         /* {
              headerName: 'RDO',
              field: 'rdo',
              filter: 'agTextColumnFilter',
            }, */
         /* {
              headerName: translate('tendersstaging.cigs', 'Cigs'),
              field: 'cigs',
              filter: 'agTextColumnFilter',
            }, */
         {
            headerName: translate('tendersstaging.tenderClassification', 'Classificazione'),
            field: 'tenderClassification',
            filter: 'agTextColumnFilter',
         },
      ]);
      setColumnDefsContractStaging([
         {
            headerName: translate('frameworkcontractsstaging.contractNumber', 'ID Contratto'),
            field: 'contractNumber',
            filter: 'agTextColumnFilter',
            maxWidth: 200,
         },
         {
            headerName: translate('frameworkcontractsstaging.tenderNumber', 'Nr. Gara'),
            field: 'tenderNumber',
            filter: 'agTextColumnFilter',
            maxWidth: 200,
         },
         {
            headerName: translate('frameworkcontractsstaging.log', 'Messaggi'),
            field: 'log',
            cellRenderer: LogsCellRenderer,
            filter: 'agTextColumnFilter',
            //minWidth: 460,
            resizable: true,
         },
         {
            headerName: translate('frameworkcontractsstaging.tenderCig', 'Cig'),
            field: 'tenderCig',
            filter: 'agTextColumnFilter',
         },
      ]);


      setColumnDefsDocuments([
         {
            headerName: '',
            field: 'status',
            cellRenderer: LogCellRenderer,
            filter: 'agTextColumnFilter',
            maxWidth: 100,
            minWidth: 100,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            ...columnCentered(),
         },
         {
            headerName: translate('documentsstaging.fileName', 'Nome file'),
            field: 'fileName',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('documentsstaging.log', 'Messaggi'),
            field: 'log',
            filter: 'agTextColumnFilter',
            minWidth: 500,
            cellStyle: { color: 'red' },
         },
         {
            headerName: translate('documentsstaging.missing', 'Mancante'),
            field: 'missing',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.missing ? translate('common.yes', 'Sì') : translate('common.no', 'No');
            },
         },
         {
            headerName: translate('documentsstaging.documentCode', 'Codice documento'),
            field: 'documentCode',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: '',
            pinned: 'right',
            minWidth: 90,
            maxWidth: 90,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: handleDownloadDocument,
         },
      ]);

      setColumnDefsErrors([
         {
            headerName: translate('common.column', 'Colonna'),
            field: 'colonna',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('common.error', 'Errore'),
            field: 'errore',
            filter: 'agTextColumnFilter',
            minWidth: 600,
            cellStyle: { color: 'red' },
         },
      ]);
   }, [LogCellRenderer, LogsCellRenderer, handleDownloadDocument]);

   const onPageSizeChanged = useCallback((type, ref) => {
      let value = document.getElementById('page-size-' + type).value;
      ref?.current?.api.paginationSetPageSize(Number(value));
   }, []);

   const onGridReady = useCallback(
      (params, gridRef) => {
         pollDataBatch();

         params.api.sizeColumnsToFit();

         window.addEventListener('resize', function () {
            setTimeout(function () {
               params.api.sizeColumnsToFit();
            });
         });

         //gridRef.current.api.sizeColumnsToFit();
         if (window.screen.width >= 1600) {
            //&& window.screen.height >= 768
            // Resolution is 1600 //x768 or above
            gridRef.current.api.sizeColumnsToFit();
         } else {
            const allColumnIds = [];
            gridRef.current.columnApi.getAllColumns().forEach((column) => {
               allColumnIds.push(column.getId());
            });
            gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
         }
      }, [subActiveKey]); // eslint-disable-line react-hooks/exhaustive-deps

   /* const onFirstDataRendered = useCallback((gridRef) => {
        if (window.screen.width >= 1600) {
           //&& window.screen.height >= 768
           // Resolution is 1600 //x768 or above
           gridRef.current.api.sizeColumnsToFit();
        } else {
           const allColumnIds = [];
           gridRef.current.columnApi.getAllColumns().forEach((column) => {
              allColumnIds.push(column.getId());
           });
           gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
        }
     }, []); */

   /* const onModelUpdated = useCallback((gridRef) => {
        if (window.screen.width >= 1600) {
           //&& window.screen.height >= 768
           // Resolution is 1600 //x768 or above
           gridRef.current.api.sizeColumnsToFit();
        } else {
           const allColumnIds = [];
           gridRef.current.columnApi.getAllColumns().forEach((column) => {
              allColumnIds.push(column.getId());
           });
           gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
        }
     }, []); */

   const refreshData = () => {
      pollDataBatch();

      setErrors([]);
      setIsComplete(true); //useless
      setIsPending(false);

      setSbShow(<></>);
   };

   const cleanData = async () => {
      setSbShow(<></>);

      const data = {
         docIds: selectedDocumentsStaging,
      };

      const result = await deleteDocumentsStaging(data, 'tenders', 'tenders');

      if (result.success) {
         handleStatusBar({ success: true, message: translate('common.message.logsdeleted', 'Log eliminati con successo') });

         pollDataBatch();
      } else {
         handleStatusBar({ success: false, message: result.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni') });
      }
   };

   //   const handleStatusBar = (status, message, timeout) => {
   //     setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   //   };
   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   /* const handleClickUpload = () => {
        inputRef.current?.click();
     }; */

   const handleUpload = async (entityType, event) => {
      event?.preventDefault();

      setSbShow(<></>);
      const file = event.target.files[0];
      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await uploadTendersList(entityType, file);


      if (result.success) {
         handleStatusBar({
            success: true,
            message: translate('common.massiveupload.uploadedtenders', 'Gare caricate con successo. I dati saranno disponibili a breve nella sezione "Gare"'),
         });
         setBatchPollingEnabled(true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         if (result.data?.file) {
            //   const message = result.data.file.join(',');
            //   handleStatusBar('error', message, false);
            handleStatusBar(result);
            setIsPending(false);
         } else if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         }  else {
            handleStatusBar({
               success: false,
               message: translate('common.massiveupload.erroruploadtenders', 'Le gare non sono state caricate, controlla la tabella degli errori'),
            });
       
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
            const dataArray = [];
            for (const key in result.data) {
               const element = result.data[key][0];
               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else { //string
                  colonna = 'N.A.';
                  errore = element;
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }
            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setSubActiveKey('log-tenders');
   };

   const handleUploadCQ = async (entityType, event) => {
      event?.preventDefault();
      setDangerModalShow(false);
      setSbShow(<></>);
      const file = event.target.files[0];
      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await uploadFrameworkContracts(entityType, file);


      if (result.success) {
         handleStatusBar({
            success: true,
            message: translate('common.massiveupload.uploadedframeworkcontracts', 'Contratti aperti caricati con successo. I dati saranno disponibili a breve nelle rispettive gare'),
         });
         setBatchPollingEnabled(true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         handleStatusBar(result);
         setIsError(true);
         setIsPending(false);
         setIsComplete(true);
         const dataArray = [];
         for (const key in result.data) {
            const element = result.data[key][0];

            let colonna;
            let errore;

            if (typeof element === 'object') {
               colonna = element.colonna;
               errore = element.errore[0];
            } else {
               return;
               /* const startIndex = element.indexOf("'") + 1;
                    const endIndex = element.indexOf("'", startIndex);
                    colonna = element.slice(startIndex, endIndex);
                    errore = element.slice(endIndex + 1).trim(); */
            }

            //console.log('colonna:', colonna);
            //console.log('errore:', errore);
            dataArray.push({ colonna, errore });
         }
         setErrors(dataArray);
      }

      event.target.value = '';

      setSubActiveKey('log-cq');
   };

   const handleUploadChecklist = async (entityType, event) => {
      event?.preventDefault();

      setSbShow(<></>);
      const file = event.target.files[0];
      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await tendersListUploadChecklist(entityType.toLowerCase(), file);


      if (result.success) {
         // File upload successful
         handleStatusBar({
            success: true,
            message: translate('common.message.uploadedsuccesfully', 'Documento caricato con successo. I dati saranno disponibili a breve'),
         });
         setBatchPollingEnabled(true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         if (result.data?.file) {
            handleStatusBar(result);
            setIsPending(false);

         } else if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         } else {
            handleStatusBar({
               success: false,
               message: translate('common.error.uploadederror', 'Il documento non è stato caricato, controlla la tabella degli errori'),
            });
         
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);

            const dataArray = [];
            if (result.data) {
               for (const key in result.data) {
                  const element = result.data[key][0];

                  let colonna;
                  let errore;

                  if (typeof element === 'object') {
                     colonna = element.colonna;
                     errore = element.errore[0];
                  } else { //string
                     colonna = 'N.A.';
                     errore = element;
                  }

                  //console.log('colonna:', colonna);
                  //console.log('errore:', errore);
                  dataArray.push({ colonna, errore });
               }
            }

            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setSubActiveKey('log-cl-' + entityType);
   };

   const handleUploadDocuments = async (entityType, event) => {
      event?.preventDefault();
      setSbShow(<></>);
      const file = event.target.files[0];

      //se il file è più grande di 100MB (in byte)
      if (file.size > 104857600) {
         handleStatusBar('error', translate('common.error.filecannotexceed', 'Il file non può superare i 100MB'), true);
         return;
      }

      setIsPending(true);
      setIsError(false);
      setIsComplete(false);

      // UPLOAD
      const result = await uploadDocumentsTenders(entityType, file);

      if (result.success) {
         // File upload successful
         // handleStatusBar('success', 'Documenti caricati con successo', true);
         handleStatusBar({ success: true, message: translate('common.message.documentsinprocess', 'Documenti in elaborazione') });
         setBatchPollingEnabled(true);
         setIsComplete(true);
         setIsPending(false);
         setErrors([]);
      } else {
         if (!result.data) {
            handleStatusBar('error', result.message, false);
            setIsPending(false);

         } else {
            // const message = result.data.file.join(',');
            // handleStatusBar('error', message, false);
            handleStatusBar(result);
            setIsError(true);
            setIsPending(false);
            setIsComplete(true);
            const dataArray = [];
            for (const key in result.data) {
               const element = result.data[key][0];

               let colonna;
               let errore;

               if (typeof element === 'object') {
                  colonna = element.colonna;
                  errore = element.errore[0];
               } else {
                  return;
                  /* const startIndex = element.indexOf("'") + 1;
                     const endIndex = element.indexOf("'", startIndex);
                     colonna = element.slice(startIndex, endIndex);
                     errore = element.slice(endIndex + 1).trim(); */
               }

               //console.log('colonna:', colonna);
               //console.log('errore:', errore);
               dataArray.push({ colonna, errore });
            }
            setErrors(dataArray);
         }
      }

      event.target.value = '';

      setSubActiveKey('log-documents');
   };

   const handleDownloadTemplate = async (entityType) => {
      setSbShow(<></>);
      setIsPending(true);

      const result = await downloadEntityTemplate(entityType);

      if (result) {
         setIsPending(false);
      }
   };

   const reloadButton = () => {
      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.reloaddata' fallback={'Ricarica i dati'}/></Tooltip>}>
            <Button onClick={() => refreshData()} style={{ padding: '0.2rem' }}>
               <IcoRefresh />
            </Button>
         </OverlayTrigger>
      );
   };

   const cleanButton = () => {
      return (
         <Button variant='danger' className="me-2" onClick={() => cleanData()}>
            <OETrans code='common.cleanlog' fallback={'Pulisci Log'}/>
         </Button>
      );
   };

   const entitiesStagingTable = () => {

      return (
         <>
            <Row>
               <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                  {Array.isArray(tendersStaging) ? (
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRefStaging}
                           columnDefs={columnDefsTendersStaging}
                           rowData={tendersStaging}
                           defaultColDef={defaultColDefLogs}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRefStaging)}
                           //onFirstDataRendered={() => onFirstDataRendered(gridRefStaging)}
                           //onModelUpdated={() => onModelUpdated(gridRefStaging)}
                           //pagination={true}
                           //paginationPageSize={10}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        {Object.keys(tendersStagingMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={tendersStagingMeta} 
                              setData={setTendersStaging} 
                              setDataMeta={setTendersStagingMeta}
                              refTable='tendersStaging'
                           />
                        )}
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </>
      );
   };
   const entitiesCQStagingTable = () => {

      return (
         <>
            <Row>
               <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                  {Array.isArray(contractStaging) ? (
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRefStaging4}
                           columnDefs={columnDefsContractStaging}
                           rowData={contractStaging}
                           defaultColDef={defaultColDefLogs}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRefStaging4)}
                           //onFirstDataRendered={() => onFirstDataRendered(gridRefStaging)}
                           //onModelUpdated={() => onModelUpdated(gridRefStaging)}
                           //pagination={true}
                           //paginationPageSize={10}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        {Object.keys(contractStagingMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={contractStagingMeta} 
                              setData={setContractStaging} 
                              setDataMeta={setContractStagingMeta}
                              refTable='frameworkcontractsStaging'
                           />
                        )}
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                          <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </>
      );
   };

   const checklistsStagingTable = (
      entityType,
      columnDefs,
      entityList,
      setEntityList,
      entityListMeta,
      setEntityListMeta,
      isFirstResearch,
      setIsFirstResearch,
   ) => {
      const gridRef = entityType === 'tenders' ? gridRefStaging1 : gridRefStaging2;
      const refTable = entityType === 'tenders' ? 'tendersCLStaging' : 'frameworkcontractsCLStaging';

      return (
         <>
            <Row className='align-items-center mb-3'>
               <Col className='col-4'></Col>
               <Col className='col-8 text-right py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     {reloadButton()}
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={entityListMeta}
                        setData={setEntityList}
                        dataLength={entityList?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable={refTable}
                     />
                  </ButtonToolbar>
               </Col>
            </Row>

            <Row>
               {Array.isArray(entityList) ? (
                  <Col className='col-12'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={entityList}
                           defaultColDef={defaultColDefLogs}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRef)}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                           autoSizeStrategy={{ type: "fitCellContents" }}
                        />
                        {Object.keys(entityListMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={entityListMeta} 
                              setData={setEntityList} 
                              setDataMeta={setEntityListMeta}
                              refTable={refTable}
                              setIsFirstResearch={setIsFirstResearch3}
                           />
                        )}
                     </div>
                  </Col>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                       <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </Row>
         </>
      );
   };

   const documentsStagingTable = () => {

      const onSelectionChanged = (e) => {
         //Prende solo le righe selezionate e filtrate
         let rowData = [];
         e.api.forEachNode((node) => (node.isSelected() ? rowData.push(node.data) : null));

         const selectedData = rowData.map((node) => node);

         setSelectedDocumentsStaging(selectedData.map(el => el.id));
      };

      return (
         <>
            {documentsStagingMeta.meta?.total > 0 ? (
               <>
                  <Row className='align-items-center mb-3 ps-4'>
                     <Col className='col-4' />
                     <Col className='col-8 text-right py-1'>
                        <ButtonToolbar className='justify-content-end'>
                           {selectedDocumentsStaging.length > 0 && cleanButton()}
                           {reloadButton()}
                           <CustomQuickFilterBox
                              tableRef={gridRefStaging3}
                              dataMeta={documentsStagingMeta}
                              setData={setDocumentsStaging}
                              dataLength={documentsStaging?.length}
                              isFirstResearch={isFirstResearch4} 
                              setIsFirstResearch={setIsFirstResearch4}
                              refTable='tendersDocStaging'
                           />
                        </ButtonToolbar>
                     </Col>
                  </Row>

                  <Row>
                     <Col className='col-12'>
                        {Array.isArray(documentsStaging) ? (
                           <div className='ag-theme-alpine list-table' id='grid-wrapper'>
                              <AgGridReact
                                 ref={gridRefStaging3}
                                 columnDefs={columnDefsDocuments}
                                 rowData={documentsStaging}
                                 defaultColDef={defaultColDefLogs}
                                 domLayout='autoHeight'
                                 tooltipShowDelay={0}
                                 tooltipHideDelay={2000}
                                 tooltipMouseTrack={true}
                                 onGridReady={(e) => onGridReady(e, gridRefStaging3)}
                                 //onFirstDataRendered={(e) => onFirstDataRendered(gridRefStaging3)}
                                 //onModelUpdated={(e) => onModelUpdated(gridRefStaging3)}
                                 //pagination={true}
                                 //paginationPageSize={10}
                                 headerHeight={50}
                                 rowHeight={50}
                                 ariaDescription='ag-grid-table'
                                 suppressCellFocus={true}
                                 animateRows={true}
                                 localeText={localeText}
                                 rowSelection={'multiple'}
                                 onSelectionChanged={onSelectionChanged}
                                 suppressRowClickSelection={true}
                                 rowMultiSelectWithClick={true}
                              />
                              {Object.keys(documentsStagingMeta).length > 0 ? (
                                 <CustomPaginationMui
                                    dataMeta={documentsStagingMeta} 
                                    setData={setDocumentsStaging} 
                                    setDataMeta={setDocumentsStagingMeta}
                                    refTable='tendersDocStaging'
                                    setIsFirstResearch={setIsFirstResearch4}
                                 />
                              ) : (
                                 <div className='page-size-table'>
                                    <OETrans code='common.itemsperpage' fallback={'Elementi per pagina'} />:&nbsp;
                                    <select
                                       onChange={() => onPageSizeChanged('log-documents', gridRefStaging3)}
                                       className='page-size'
                                       id='page-size-log-documents'>
                                       {optionsPagination}
                                    </select>
                                 </div>
                              )}
                           </div>
                        ) : (
                           <div className='text-center my-4'>
                              <Spinner animation='border' role='status'>
                                <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                              </Spinner>
                           </div>
                        )}
                     </Col>
                  </Row>
               </>
            ) : (
               <Row className='text-center my-5'>
                  <span><OETrans code='common.nologs' fallback={'Non sono presenti log'}/></span>
               </Row>
            )}
         </>
      );
   };

   const errorsTable = () => {
      return (
         <Fragment>
            <Row className='title-simple-box justify-content-center align-items-center mt-4 mb-3'>
               <Col className='col-auto p-0'>
                  <IcoDanger className='me-1 ico-red' />
               </Col>
               <Col className='col-auto p-1'>
                  <h5 className='m-0'><OETrans code='common.errorlist' fallback={'Lista Errori'}/></h5>
               </Col>
            </Row>

            <Row>
               <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                  {Array.isArray(errors) ? (
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRefErrors}
                           columnDefs={columnDefsErrors}
                           rowData={errors}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={(e) => onGridReady(e, gridRefErrors)}
                           //onFirstDataRendered={() => onFirstDataRendered(gridRefErrors)}
                           //onModelUpdated={() => onModelUpdated(gridRefErrors)}
                           pagination={true}
                           paginationPageSize={5}
                           headerHeight={50}
                           rowHeight={50}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                           animateRows={true}
                           localeText={localeText}
                        />
                        <div className='page-size-table'>
                           <OETrans code='common.errorsperpage' fallback={'Errori per pagina'}/>:&nbsp;
                           <select
                              onChange={() => onPageSizeChanged('errors', gridRefErrors)}
                              className='page-size'
                              id='page-size-errors'>
                              {optionsPagination}
                           </select>
                        </div>
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                          <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                        </Spinner>
                     </div>
                  )}
               </Col>
            </Row>
         </Fragment>
      );
   };

   const renderTooltip = (props) => {
      let type = '';

      if (props.popper.state) {
         type = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id='button-tooltip' {...props}>
            <OETrans code='common.filesallowed' fallback={'Ammessi solo file'}/>:
            <ul style={{ textAlign: 'left', paddingLeft: '20px', margin: 'auto' }}>
               {type === 'excel' ? (
                  <>
                     <li><OETrans code='common.extensionxls' fallback={'con estensione .xls, .xlsx'}/></li>
                  </>
               ) : (
                  <>
                     <li><OETrans code='common.extensionzip' fallback={'con estensione .zip'}/></li>
                     <li><OETrans code='common.noexceed' fallback={'che non superino i 100MB'}/></li>
                  </>
               )}
            </ul>
         </Tooltip>
      );
   };

   return (
      <>
         <Row className='mt-5 mb-5'>
            <Col>
               <Row className='align-items-center mb-5'>
                  <Col className='text-center'>
                     <Form.Group className='px-3' controlId={`tender_1`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoUploadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span>
                                 <OETrans code='common.importdatatenders' fallback={'Importa dati Gare'}/> 
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUpload('tenders', e)}
                        />
                     </Form.Group>
                  </Col>

                  <Col className='text-center'>
                     <Form.Group className='px-3' controlId={`checklist_tenders_3`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoUploadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span>
                                 <OETrans code='common.uploadtenderchecklist' fallback={'Carica checklist gare'}/>                          
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUploadChecklist('tenders', e)}
                        />
                     </Form.Group>
                  </Col>
                  <Col className='text-center'>
                     <Form.Group as={Col} className='px-3' controlId={`tender_documents_2`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoUploadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span>
                                 <OETrans code='common.uploadtenderdocuments' fallback={'Carica documenti gare'}/>
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'zip' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>
                              </span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUploadDocuments('tenders', e)}
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Row className='align-items-center'>
                  <Col className='text-center'>
                     <span style={{ cursor: 'pointer' }} onClick={() => setDangerModalShow(true)}>
                        <Row className="justify-content-center">
                           <IcoUploadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                           <span style={{ fontWeight: '500' }}>
                              <OETrans code='common.importframeworkcontract' fallback={'Importa dati CQ'}/>
                              <OverlayTrigger
                                 placement='top'
                                 delay={{ show: 200, hide: 400 }}
                                 overlay={renderTooltip}
                                 popperConfig={{ testObj: 'excel' }}>
                                 <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                              </OverlayTrigger>
                           </span>
                        </Row>
                     </span>

                     {/* <Form.Group className='px-3' controlId={`cq_1`}>
                <Form.Label style={{ cursor: 'pointer' }}> 
                  <IcoUploadFile className={`ico-small download-upload me-2 align-bottom ${isPending ? 'disabled' : 'second-color'}`} />
                  <span>Importa dati CQ</span>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 200, hide: 400 }}
                    overlay={renderTooltip}
                    popperConfig={{ testObj: 'excel' }}>
                    <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type='file'
                  disabled={isPending ? true : false}
                  style={{ display: 'none' }}
                  onChange={(e) => handleUploadCQ('frameworkcontracts', e)}
                />
              </Form.Group> */}
                  </Col>
                  <Col className='text-center'>
                     <Form.Group as={Col} className='px-3' controlId={`framework_contracts_2`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoUploadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span>
                                 <OETrans code='common.uploadframeworkcontractscl' fallback={'Carica checklist CQ'}/>
                                 <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 200, hide: 400 }}
                                    overlay={renderTooltip}
                                    popperConfig={{ testObj: 'excel' }}>
                                    <TooltipInfoIcon className='tooltip-ico position-absolute' style={{ width: '1rem' }} />
                                 </OverlayTrigger>    
                              </span> 
                           </Row>                     
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onChange={(e) => handleUploadChecklist('frameworkcontracts', e)}
                        />
                     </Form.Group>
                  </Col>

                  <Col className="text-center">
                     <Form.Group as={Col} className='px-3' controlId={`export_template`}>
                        <Form.Label style={{ cursor: 'pointer' }}>
                           <Row className="justify-content-center">
                              <IcoDownloadFile className={`ico-small download-upload me-2 align-bottom p-0 ${isPending ? 'disabled' : 'second-color'}`} />
                              <span><OETrans code='common.uploadtemplate' fallback={'Scarica template'}/></span>
                           </Row>
                        </Form.Label>
                        <Form.Control
                           type='file'
                           disabled={isPending ? true : false}
                           style={{ display: 'none' }}
                           onClick={() => handleDownloadTemplate('tenders')}
                        />
                     </Form.Group>
                  </Col>
               </Row>
               {sbShow}
            </Col>
         </Row>

         {isPending && <Row className='justify-content-center align-items-center'>
            <Col className='text-center py-3 ps-0' style={{ paddingRight: '5.5rem' }}>
               <Spinner
                  animation='border'
                  role='status'
                  style={{ display: 'flex', margin: '0 auto' }}
               />
               {/* {sbShow} */}
            </Col>
         </Row>}

         <Tabs
            activeKey={subActiveKey}
            className='mb-3 mt-3'
            onSelect={(k) => {
               setSubActiveKey(k);
               setSbShow(<></>);
            }}>
            <Tab eventKey='log-tenders' title={translate('common.logtenders', 'Log Gare')}>
               {errors && errorsTable()}

               {subActiveKey === 'log-tenders' && entitiesStagingTable()}
            </Tab>
            <Tab eventKey='log-cq' title={translate('common.logframeworkcontracts', 'Log Contratti aperti')}>
               {errors && errorsTable()}

               {subActiveKey === 'log-cq' && entitiesCQStagingTable()}
            </Tab>

            <Tab eventKey='log-cl-tenders' title={translate('common.logtenderschecklist', 'Log Checklist Gare')}>
               <div className='mt-5'>
                  {errors && errorsTable()}

                  {subActiveKey === 'log-cl-tenders' &&
                     checklistsStagingTable(
                        'tenders',
                        columnDefsStagingTendersCL,
                        checklistsTendersStaging,
                        setChecklistsTendersStaging,
                        checklistsTendersStagingMeta,
                        setChecklistsTendersStagingMeta,
                        isFirstResearch3, 
                        setIsFirstResearch3,
                     )}
               </div>
            </Tab>

            <Tab eventKey='log-cl-frameworkcontracts' title={translate('', 'Log Checklist Contratti aperti')}>
               <div className='mt-5'>
                  {errors && errorsTable()}

                  {subActiveKey === 'log-cl-frameworkcontracts' &&
                     checklistsStagingTable(
                        'frameworkContracts',
                        columnDefsStagingFrameworkContractsCL,
                        checklistsFrameworkContractsStaging,
                        setChecklistsFrameworkContractsStaging,
                        checklistsFrameworkContractsStagingMeta,
                        setChecklistsFrameworkContractsStagingMeta,
                        isFirstResearch4, 
                        setIsFirstResearch4,
                     )}
               </div>
            </Tab>

            <Tab eventKey='log-documents' title={translate('common.documentlog', 'Log Documenti')}>
               <div className='mt-5 px-3'>
                  {errors && errorsTable()}

                  {subActiveKey === 'log-documents' && documentsStagingTable()}
               </div>
            </Tab>
         </Tabs>

         <Modal show={dangerModalShow} onHide={() => handleCloseModal(setDangerModalShow)} centered>
            <Modal.Header closeButton>
               <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>
                     <OETrans code='common.warning.uploadframeworkcontracts' fallback={'Caricando i contratti aperti in modo massivo, verranno soltanto aggiunti i contratti nuovi e/o modificati quelli esistenti. Desideri continuare?'} />
                  </span>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setDangerModalShow)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Form.Group controlId={`cq_1`}>
                  <Form.Label className='mb-0'>
                     <Button onClick={() => fileInputRef.current.click()}><OETrans code='common.continue' fallback={'Continua'}/></Button>
                  </Form.Label>

                  <Form.Control
                     ref={fileInputRef}
                     type='file'
                     disabled={isPending ? true : false}
                     style={{ display: 'none' }}
                     onChange={(e) => handleUploadCQ('frameworkcontracts', e)}
                  />
               </Form.Group>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default MassiveUploadTendersList;
