import React, { useState, useEffect } from 'react';
import { Row, Col, /* Form */ } from 'react-bootstrap';
import moment from 'moment';
//import MultilineChart from '@components/charts/flourishCharts/MultilineChart';
import LinesColumnsChart from '@components/charts/flourishCharts/LinesColumnsChart';

const ReportBudgetCashflow = ({ dataStats }) => {
   const [/* budgetData */, setBudgetData] = useState([]);
   const [cashflowData, setCashflowData] = useState([]);
   //const [isShowBudget, setIsisShowBudget] = useState(false);
   //console.log('dataStats:', dataStats);

   useEffect(() => {
      if (Object.values(dataStats).length > 0) {
         //"Previsto" -> accruedAmount
         //"Cumulato" -> accruedFinalAmount

         //"Cumulato" -> linea: accruedCashedIn - accruedFinalAmount  
         //"Avanzamento" -> barra giu: finalAmount
         //"Incassato" -> barra su: cashedIn

         let tempBudgetData = [["Period", "Previsto", "Cumulato"]];

         dataStats.cashFlow.forEach((d) => tempBudgetData.push(
            [moment(new Date(d.year, d.month - 1)).format(`MM/YYYY`), Number(d.accruedAmount), Number(d.accruedFinalAmount)]
         ));

         setBudgetData(tempBudgetData);
         ////////////////////////////////
         let tempCashflowData = [["Period", "Cumulato", "Avanzamento", "Incassato"]];

         const d = new Date();
         const currentYear = d.getFullYear();
         const currentMonth = d.getMonth(); //index

         let tempDataStatsCashFlow = dataStats.cashFlow.filter(el => el.year <= currentYear);
         let tempDataStatsCashFlowFinal = [];

         tempDataStatsCashFlow.forEach(el => {
            if (el.year < currentYear || (el.year === currentYear && el.month <= currentMonth + 1)) {
               tempDataStatsCashFlowFinal.push(el);
            }
         });

         tempDataStatsCashFlowFinal.forEach((d) => tempCashflowData.push(
            [moment(new Date(d.year, d.month - 1)).format(`MM/YYYY`), Number(d.accruedCashedIn) - Number(d.accruedFinalAmount), Number(-d.finalAmount), Number(d.cashedIn)]
         ));

         setCashflowData(tempCashflowData);
      }
   }, [dataStats]);

   return (
      <>
         <div className='simple-box scenaritab' style={{minHeight: '43rem', maxHeight: '43rem'}}>
            <div className='d-flex'>
               <div className='col-auto me-auto'>
                  <h5 className='title-simple-box margin-title-box text-uppercase'>Cash-flow</h5>
               </div>

               {/* <div className='col-auto'>
                  <span className="me-2">Actual</span>
                  <Form.Check
                     type='switch'
                     id="toggle-switch"
                     inline
                     name='group1'
                     label="Budget"
                     value='isShowBudget'
                     defaultChecked={isShowBudget}
                     onClick={(e) => setIsisShowBudget(e.target.checked)}
                  />
               </div> */}
            </div>

            <Row>
               <Col>
                  <div style={{maxWidth: '58rem'}}>
                     {/* {isShowBudget ?
                        (budgetData.length > 0 && <MultilineChart data={budgetData} />) :
                        (cashflowData.length > 0 && <LinesColumnsChart data={cashflowData} />)
                     } */}
                     {cashflowData.length > 0 && <LinesColumnsChart data={cashflowData} />}
                  </div>
               </Col>
            </Row>

         </div>
      </>
   );
};
export default ReportBudgetCashflow;
