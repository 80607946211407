import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

//import AnimatedDonutChartDash from './AnimatedDonutChartDash';
import { formatToDecimals } from '@lib/helpers/formatting';
import NewLegend from './chartLegend';
import Histogram from './histogram';

const AnimatedHistogramChartCard = ({
   chartId,
   data,
   cardData,
   labelOnHover = true,
   totalName = 'totalAmount',
   title,
}) => {
   const [totalValue, setTotalValue] = useState(0);

   const dataPoints = [
      { label: 'Rendicontato', value: data.reportedAmount},
      { label: 'Incassato', value: data.cashedIn },
      { label: 'Da incassare', value: data.reportedAmount < data.cashedIn ? 0 : data.reportedAmount - data.cashedIn },
   ];

   useEffect(() => {
      setTotalValue(Number(cardData.filter(data => data.propertyName === totalName).map(data => data.propertyValue).join()));
   }, [cardData, totalName]);

   return (
      <>
         <Row className="flex-column align-items-center justify-content-between" style={{ minHeight: '24rem' }}>
            <Col className="col-auto">
               <Row className='flex-column justify-content-center align-items-center w-100 m-auto'>
                  <h5 className='text-center title-box'>{title}</h5>
                  <Col className='col-12 d-flex justify-content-center align-items-center'>
                     <Histogram data={dataPoints} />
                  </Col>
               </Row>
            </Col>

            <Col>
               <Row className='flex-column justify-content-center align-items-center w-100 m-auto'>
                  {cardData.length > 0 && (
                     <NewLegend
                        data={cardData}
                        scope={title}
                        totalName={totalName}
                        className={chartId}
                        propertyValue={true}
                     />
                  )}
               </Row>
            </Col>
         </Row>

         <Row>
            <Col className="invisible mt-auto">
               <Row className="totalDiv">
                  <Col className="total-label">Totale</Col>
                  <Col className="total-value text-end">{formatToDecimals(totalValue)}</Col>
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default AnimatedHistogramChartCard;
