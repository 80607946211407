import moment from 'moment';
import { /* locale, */ addLocale } from 'primereact/api';

const localLang = localStorage?.getItem('userLanguage')
   ? `${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem('userLanguage')}`
   : 'it-IT';

export const formatToDecimals = (value, minFraction = 2, maxFraction = 2) => {
   // const locale = localLang ? localLang : 'it-IT';
   if ((value !== null || value !== undefined) && typeof value === 'number') {
      return Number(value).toLocaleString(localLang, {
         minimumFractionDigits: minFraction,
         maximumFractionDigits: maxFraction,
      });
   } else {
      return '';
   }
};

export const dateFormatter = (date, format = 'DD-MM-YYYY') => {
   if (!date) return null;
   return moment(date).format(format);
};

export const dateFormatterInput = (date, format = 'YYYY-MM-DD') => {
   if (!date) return null;
   return moment(date).format(format);
};

//error in calendar with locale
export const localeCalendar = (lang = 'it', firstDayOfWeek = 1) => {
   let dayNames = [];
   let dayNamesShort = [];
   let dayNamesMin = [];
   let monthNames = [];
   let monthNamesShort = [];
   let today = '';
   let clear = '';

   switch (lang) {
      case 'it':
         dayNames = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];
         dayNamesShort = ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'];
         dayNamesMin = ['D', 'L', 'M', 'M', 'G', 'V', 'S'];
         monthNames = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
         monthNamesShort = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'];
         today = 'Oggi';
         clear = 'Cancella';
         break;
      case 'en':
         dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday ', 'friday', 'saturday'];
         dayNamesShort = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
         dayNamesMin = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
         monthNames = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
         monthNamesShort = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
         today = 'Today';
         clear = 'Clear';
         break;
      case 'es':
         dayNames = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
         dayNamesShort = ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'];
         dayNamesMin = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
         monthNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
         monthNamesShort = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
         today = 'Hoy';
         clear = 'Limpiar';
         break;
      default:
         break;
   };


   addLocale(lang, {
      firstDayOfWeek: firstDayOfWeek,
      dayNames: dayNames,
      dayNamesShort: dayNamesShort,
      dayNamesMin: dayNamesMin,
      monthNames: monthNames,
      monthNamesShort: monthNamesShort,
      today: today,
      clear: clear,
   });

   //locale(lang);
};