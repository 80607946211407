import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const MarkdownEditorComponent = ({ onChange, name, type, existingContent = '',
   toolbarOptions = ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'image', 'history'] }) => {
   const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(
         ContentState.createFromBlockArray(convertFromHTML(existingContent))
      )
   );
   const [convertedContent, setConvertedContent] = useState(null);

   useEffect(() => {
      let html = convertToHTML(editorState.getCurrentContent());

      setConvertedContent(html);
   }, [editorState]);

   useEffect(() => {
      onChange(convertedContent, name, type);
   }, [convertedContent, name, type]); //eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div>
         <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName='wrapper-class'
            editorClassName='editor-class'
            toolbarClassName='toolbar-class'
            toolbar={{
               //options: ['inline', 'blockType', 'list', 'link', 'embedded', 'image'],
               options: toolbarOptions,
               /* inline: {
                 inDropdown: false,
                 className: undefined,
                 component: undefined,
                 dropdownClassName: undefined,
                 options: ['bold', 'italic', 'underline'],
               }, */
               inline: {
                  inDropdown: true,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', /* 'strikethrough', 'superscript', 'subscript' */],
                  //bold: { icon: bold, className: undefined },
                  //italic: { icon: italic, className: undefined },
                  //underline: { icon: underline, className: undefined },
                  //strikethrough: { icon: strikethrough, className: undefined },
                  //superscript: { icon: superscript, className: undefined },
                  //subscript: { icon: subscript, className: undefined },
               },
               blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
               },
               fontSize: {
                  //icon: fontSize,
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
               },
               list: {
                  inDropdown: true,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['unordered', 'ordered', 'indent', 'outdent'],
                  //unordered: { icon: unordered, className: undefined },
                  //ordered: { icon: ordered, className: undefined },
                  //indent: { icon: indent, className: undefined },
                  //outdent: { icon: outdent, className: undefined },
               },
               textAlign: {
                  inDropdown: true,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right', 'justify'],
                  //left: { icon: left, className: undefined },
                  //center: { icon: center, className: undefined },
                  //right: { icon: right, className: undefined },
                  //justify: { icon: justify, className: undefined },
               },
               link: {
                  inDropdown: true,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  dropdownClassName: undefined,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: '_self',
                  options: ['link', 'unlink'],
                  //link: { icon: link, className: undefined },
                  //unlink: { icon: unlink, className: undefined },
                  linkCallback: undefined
               },
               image: {
                  //icon: image,
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: (file) => {
                     const test = new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                           const dataURL = reader.result;
                           const truncatedDataURL = dataURL.substring(10, 30) + "..."; // set the maximum length of the truncated string
                           resolve({ data: { link: dataURL }, link: { url: truncatedDataURL } });
                          
                           //onChange(dataURL, 'images');
                        };
                        reader.onerror = (error) => {
                           reject(error);
                        };
                     });

                     onChange(test, 'images');
                   
                     return test;
                  },
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                     height: 'auto',
                     width: 'auto',
                  },
               },
               history: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['undo', 'redo'],
                  undo: {/*  icon: undo, */ className: undefined },
                  redo: { /* icon: redo, */ className: undefined },
               },
            }}
         />
      </div>
   );
};
export default MarkdownEditorComponent;