import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Spinner, Form, Col, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import OESimpleTable from 'components/oetable/OESimpleTable';
import StatusBar from '@components/StatusBar';
import { IcoPlus } from '@icons';
import { columnCentered, columnCenteredWithFilter } from '@lib/helpers/agGridHelpers';
import { dateFormatter, dateFormatterInput } from '@lib/helpers/formatting';
import {
   getMilestones,
   createMilestone,
   editMilestone,
} from '@lib/api/index';
import UploadDocument from "@components/form/upload/uploadDocuments";
import ActionBtnRendererMilestones from 'components/actionBtnRenderer/ActionBtnRendererMilestones';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   number: '', //obbl
   description: '',
   wp: '',
   probativeDocumentation: '',
   forecastDate: null,
   effectiveDate: null,
   completed: false,
   note: '',
};

const Milestones = ({ projectId, canEdit }) => {
   const [newMilestone, setNewMilestone] = useState(initialState);
   const [showModalNew, setShowModalNew] = useState(false);
   const [columnDefs, setColumnDefs] = useState([]);
   const [milestones, setMilestones] = useState(null);
   const optionsCompleted = useMemo(() => [{ value: true, label: translate('common.yes', 'Sì') }, { value: false, label: translate('common.no', 'No') }], []);
   const [selectedCompleted, setSelectedCompleted] = useState({ value: false, label: "No" });
   const [refresh, setRefresh] = useState(false);
   const [showSpinner, setShowSpinner] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [isLoading, setIsLoading] = useState(false);
   const [activeKey, setActiveKey] = useState("registry");
   //console.log('newMilestone', newMilestone);

   const handleReset = () => {
      setSbShow(<></>);
      setActiveKey("registry");
      setSelectedCompleted({ value: false, label: "No" });
      setNewMilestone(initialState);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleCloseModal = (setShowModal) => {
      handleReset();
      setShowModal(false);
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const fetchData = useCallback(async () => {
      getMilestones(projectId).then((retrievedMilestones) => {
         setMilestones(retrievedMilestones.data);
      });
   }, [projectId]);

   useEffect(() => {
      if (projectId) {
         fetchData();
      }
   }, [fetchData, projectId]);

   useEffect(() => {
      setSelectedCompleted(optionsCompleted.filter(option => option.value === newMilestone.completed)
         .map(filteredOpt => ({
            value: filteredOpt.value,
            label: filteredOpt.label,
         }))
      );
   }, [newMilestone.completed, optionsCompleted]);

   useEffect(() => {
      if (refresh && projectId) {
         fetchData();
         setRefresh(false);
      }
   }, [refresh, projectId, fetchData]);

   useEffect(() => {
      setColumnDefs([
         {
            headerName: 'N.',
            field: 'number',
            filter: 'agTextColumnFilter',
            minWidth: 90,
            maxWidth: 90,
         },
         {
            headerName: 'Descrizione',
            field: 'description',
            filter: 'agTextColumnFilter',
            minWidth: 150,
            tooltipField: 'description',
            wrapText: false,
            autoHeight: false,
         },
         {
            headerName: 'WP n.',
            field: 'wp',
            filter: 'agTextColumnFilter',
            minWidth: 100,
         },
         {
            headerName: 'Doc. probatoria',
            field: 'probativeDocumentation',
            filter: 'agTextColumnFilter',
            tooltipField: 'probativeDocumentation',
            wrapText: false,
            autoHeight: false,
            minWidth: 100,
         },
         {
            headerName: 'Data prevista',
            field: 'forecastDate',
            //filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               if (!params.data.forecastDate) return '';
               return dateFormatter(params.data.forecastDate, 'MM-YYYY');
            },
            ...columnCentered(),
            minWidth: 150,
            maxWidth: 170,
         },
         {
            headerName: 'Data effettiva',
            field: 'effectiveDate',
            //filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               if (!params.data.effectiveDate) return '';
               return dateFormatter(params.data.effectiveDate);
            },
            ...columnCentered(),
            minWidth: 150,
            maxWidth: 170,
         },
         {
            headerName: 'Completata',
            field: 'completed',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               const value = params.data.completed ? 'SÌ' : 'NO';
               return value;
            },
            filterValueGetter: (params) => {
               const value = params.data.completed ? 'SÌ' : 'NO';
               return value;
            },
            minWidth: 150,
            maxWidth: 180,
            ...columnCenteredWithFilter,
         },
         {
            headerName: 'Note',
            field: 'note',
            filter: 'agTextColumnFilter',
            tooltipField: 'note',
            wrapText: false,
            autoHeight: false,
            minWidth: 160,
         },
         {
            headerName: '',
            minWidth: 100,
            maxWidth: 100,
            cellRenderer: ActionBtnRendererMilestones,
            cellRendererParams: {
               setRefresh: setRefresh,
               optionsCompleted: optionsCompleted,
               canEdit: canEdit,
            },
            resizable: false,
            cellStyle: { textAlign: 'center', padding: 0 },
         },
      ]);
   }, [optionsCompleted, canEdit]);

   const onChange = (e, fieldName, setSelect) => {
      //console.log('e', e);

      let tempNewMilestone = { ...newMilestone };
      let value;

      switch (fieldName) {
         case 'number':
         case 'description':
         case 'wp':
         case 'probativeDocumentation':
         case 'note':
         case 'forecastDate':
         case 'effectiveDate':
            value = e.target.value;
            tempNewMilestone[fieldName] = value;
            break;
         case 'completed':
            value = e.value;
            tempNewMilestone[fieldName] = value;

            setSelect(e);
            break;
         default:
            break;
      }

      setNewMilestone(tempNewMilestone);
   };

   const handleSave = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const {
         createdAt,
         createdBy,
         updatedAt,
         updatedBy,
         ...restMilestone
      } = newMilestone;

      if (!newMilestone.number) {
         handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      if (restMilestone.forecastDate) {
         restMilestone.forecastDate = restMilestone.forecastDate.length === 10 ? restMilestone.forecastDate : restMilestone.forecastDate + '-01';
      }

      setIsLoading(true);
      setShowSpinner(true);

      let result = restMilestone.id ? await editMilestone(restMilestone.projectId, restMilestone.id, restMilestone) : await createMilestone(projectId, restMilestone);
      //console.log('result', result);

      if (result.success) {
         handleStatusBar('success', translate('common.message.created', 'Elemento creato con successo'), true);

         setTimeout(() => {
            setNewMilestone(result.data);
            setRefresh(true);
         }, 2500);
      } else {
         handleStatusBar(
            'error',
            result.success === false
               ? Object.values(result.data).join()
               : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }

      setIsLoading(false);
      setShowSpinner(false);
   };

   return (
      <>
         <OESimpleTable
            columnDefs={columnDefs}
            rowData={milestones}
            buttonToolbarVisible={true}
            buttonChildren={
               canEdit && (
                  <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                     <IcoPlus className='light-color ico-small plus new-button' />
                     <span className='d-md-inline'>Nuova Milestone</span>
                  </Button>
               )
            }
            buttonTitle={'Nuova Milestone'}
            showModal={handleShowModal}
            setShowModal={setShowModalNew}
            pageSizeId={'milestones'}
            fullWidth={true}
         />

         <Modal
            show={showModalNew}
            className='modal-with-nav'
            size='lg'
            onHide={() => handleCloseModal(setShowModalNew)}
            centered>
            <Modal.Header closeButton>
               <Modal.Title>Nuova Milestone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Tabs
                  className="mb-4"
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                  fill
               >
                  <Tab eventKey="registry" title={"Dettagli"}>
                     <Form id='form-new-milestone'>
                        <Row className='mb-3' style={{ marginTop: '2rem' }}>
                           <Col className='col-4'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    Numero<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='number'
                                    value={newMilestone.number ?? ''}
                                    onChange={e => onChange(e, 'number')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-8'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    Descrizione
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='description'
                                    value={newMilestone.description ?? ''}
                                    onChange={e => onChange(e, 'description')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row className='mb-3' style={{ marginTop: '2rem' }}>
                           <Col className='col-4'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    WP n.
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='wp'
                                    value={newMilestone.wp ?? ''}
                                    onChange={e => onChange(e, 'wp')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-8'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>
                                    Doc. probatoria
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='probativeDocumentation'
                                    value={newMilestone.probativeDocumentation ?? ''}
                                    onChange={e => onChange(e, 'probativeDocumentation')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row className='mb-3' style={{ marginTop: '2rem' }}>
                           <Col className='col-4'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label>
                                    Completata
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="completed"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedCompleted}
                                    options={optionsCompleted}
                                    onChange={e => onChange(e, 'completed', setSelectedCompleted)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-4'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>Data prevista</Form.Label>
                                 <Form.Control
                                    type='month'
                                    name='forecastDate'
                                    value={dateFormatterInput(newMilestone?.forecastDate, 'yyyy-MM') || ''}
                                    onChange={(e) => onChange(e, 'forecastDate')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-4'>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label className='mb-3'>Data effettiva</Form.Label>
                                 <Form.Control
                                    type='date'
                                    name='effectiveDate'
                                    value={dateFormatterInput(newMilestone?.effectiveDate) || ''}
                                    onChange={(e) => onChange(e, 'effectiveDate')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row className='mb-3' style={{ marginTop: '2rem' }}>
                           <Col>
                              <Form.Group as={Col} className='pe-2'>
                                 <Form.Label>Note</Form.Label>
                                 <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="note"
                                    value={newMilestone.note ?? ''}
                                    onChange={(e) => onChange(e, "note")}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                     </Form>
                     {sbShow}

                     <Row>
                        <Col className="col-12">
                           <span className="label">
                              <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                           </span>
                        </Col>
                     </Row>
                  </Tab>

                  <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />} disabled={!newMilestone.id}>
                     <UploadDocument
                        entity="projectmilestones"
                        entityId={newMilestone.id}
                     />
                     {sbShow}
                  </Tab>
               </Tabs>
            </Modal.Body>

            <Modal.Footer>
               <Button
                  disabled={isLoading}
                  variant='secondary'
                  onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button disabled={activeKey === "docs" || isLoading} variant='primary' onClick={(e) => handleSave(e)}>
                  {newMilestone.id ? <OETrans code='common.save' fallback={'Salva'}/> : <OETrans code='common.create' fallback={'Crea'}/>}

                  {showSpinner && (
                     <Spinner
                        as='span'
                        className='ms-2'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                     />
                  )}
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default Milestones;
