import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Dropdown, Form } from 'react-bootstrap';
import Select from 'react-select';
import { getProjects, getNotices, getBusinessLines } from '@lib/api';
import { IcoChevronDown } from '@icons';
import { translate } from 'components/translation/translation';

const CustomMenu = ({ optionsItems, selectedItems, setSelectedItems, type,
   onChange }) => {

   return (<>
      <div ref={useRef()} style={{ minWidth: '30rem' }}>
         <Row className='mt-2 justify-content-center'>
            <Col>
               <Form.Group as={Col}>
                  <Form.Label className='mb-0'>Filtra per {type}</Form.Label>
                  <Select
                     components={{
                        IndicatorSeparator: () => null,
                     }}
                     className="react-select-container"
                     classNamePrefix="react-select"
                     placeholder={translate('common.placeholder.select', 'Seleziona...')}
                     value={selectedItems}
                     options={optionsItems}
                     onChange={(e) => onChange(e, setSelectedItems)}
                     isMulti
                     closeMenuOnSelect={false}
                  />
               </Form.Group>
            </Col>
         </Row>
      </div>
   </>);
};

const ReportFilters = ({
   selectedProjects,
   selectedNotices,
   selectedBusinessArea,
   setSelectedProjects,
   setSelectedNotices,
   setSelectedBusinessArea
}) => {
   const [optionsProjects, setOptionsProjects] = useState([]);
   const [optionsNotices, setOptionsNotices] = useState([]);
   const [optionsBusinessArea, setOptionsBusinessArea] = useState([]);
   //console.log('optionsBusinessArea', optionsBusinessArea);

   useEffect(() => {
      getProjects().then((retrievedProjects) => {
         if (retrievedProjects.success) {
            setOptionsProjects(retrievedProjects.data.map(proj => ({
               value: proj.id,
               label: proj.name,
               queryParam: 'project_id',
            })));
         }
      });

      getNotices().then((retrievedNotices) => {
         if (retrievedNotices.success) {
            setOptionsNotices(retrievedNotices.data.map(notice => ({
               value: notice.id,
               label: notice.call,
               queryParam: 'notice_id',
            })));
         }
      });

      getBusinessLines().then(retrievedClusters => {
         if (retrievedClusters.success) {

            setOptionsBusinessArea(retrievedClusters.data.map(cluster => ({
               value: cluster.id,
               label: cluster.name,
               queryParam: 'businessarea_id',
            })));
         }
      });
   }, []);

   const onChange = (e, setSelect) => {
      setSelect(e);
   };

   const CustomToggleClusters = React.forwardRef(({ children, onClick }, ref) => (
      <>
         <Button
            style={{ padding: '0.37rem 0.65rem', paddingRight: '0.35rem', fontSize: '0.86rem', color: '#79889A', textTransform: 'capitalize' }}
            variant={selectedBusinessArea.length > 0 ? 'outline-info' : 'light'}
            onClick={(e) => { onClick(e); }}
         >
            Cluster geografico {selectedBusinessArea.length > 0 ? selectedBusinessArea.length : null}<IcoChevronDown className="chevron-filter" />
         </Button>
      </>
   ));

   const CustomToggleNotices = React.forwardRef(({ children, onClick }, ref) => (
      <>
         <Button
            style={{ padding: '0.37rem 0.65rem', paddingRight: '0.35rem', fontSize: '0.86rem', color: '#79889A', textTransform: 'capitalize' }}
            variant={selectedNotices.length > 0 ? 'outline-info' : 'light'}
            onClick={(e) => { onClick(e); }}
         >
            Bando {selectedNotices.length > 0 ? selectedNotices.length : null}<IcoChevronDown className="chevron-filter" />
         </Button>
      </>
   ));

   const CustomToggleProjects = React.forwardRef(({ children, onClick }, ref) => (
      <>
         <Button
            style={{ padding: '0.37rem 0.65rem', paddingRight: '0.35rem', fontSize: '0.86rem', color: '#79889A', textTransform: 'capitalize' }}
            variant={selectedProjects.length > 0 ? 'outline-info' : 'light'}
            onClick={(e) => { onClick(e); }}
         >
            Progetto {selectedProjects.length > 0 ? selectedProjects.length : null}<IcoChevronDown className="chevron-filter" />
         </Button>
      </>
   ));

   return (
      <div className='d-flex justify-content-end'>
         <Dropdown autoClose="outside" className="me-3 text-end" /* onToggle={(e) => console.log(e)} */>
            <Dropdown.Toggle as={CustomToggleClusters} id="dropdown-custom-components">
               {/* children */}
            </Dropdown.Toggle>

            <Dropdown.Menu /* as={CustomMenu} */ className="filter-table to-left-report">
               <CustomMenu
                  optionsItems={optionsBusinessArea}
                  selectedItems={selectedBusinessArea}
                  setSelectedItems={setSelectedBusinessArea}
                  onChange={onChange}
                  type={'cluster geografico'}
               />
            </Dropdown.Menu>
         </Dropdown>

         <Dropdown autoClose="outside" className="me-3 text-end" /* onToggle={(e) => console.log(e)} */>
            <Dropdown.Toggle as={CustomToggleNotices} id="dropdown-custom-components">
               {/* children */}
            </Dropdown.Toggle>

            <Dropdown.Menu /* as={CustomMenu} */ className="filter-table to-left-report">
               <CustomMenu
                  optionsItems={optionsNotices}
                  selectedItems={selectedNotices}
                  setSelectedItems={setSelectedNotices}
                  onChange={onChange}
                  type={'bando'}
               />
            </Dropdown.Menu>
         </Dropdown>

         <Dropdown autoClose="outside" className="text-end" /* onToggle={(e) => console.log(e)} */>
            <Dropdown.Toggle as={CustomToggleProjects} id="dropdown-custom-components">
               {/* children */}
            </Dropdown.Toggle>

            <Dropdown.Menu /* as={CustomMenu} */ className="filter-table to-left-report">
               <CustomMenu
                  optionsItems={optionsProjects}
                  selectedItems={selectedProjects}
                  setSelectedItems={setSelectedProjects}
                  onChange={onChange}
                  type={'progetto'}
               />
            </Dropdown.Menu>
         </Dropdown>
      </div>
   );
};
export default ReportFilters;
