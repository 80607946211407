import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import AnimatedDonutChartDash from './AnimatedDonutChartDash';
import { formatToDecimals } from '@lib/helpers/formatting';
import NewLegend from './chartLegend';

// const colors = [
//   '#C2C923',
//   '#08A2AA',
//   '#CB1B4A',
//   '#FFB317',
//   '#053D58',
//   '#378288',
//   '#5BCFD4',
//   '#D4AFCD',
//   '#F5D0C5',
//   '#D69F7E',
// ];

const AnimatedDonutChartCard = ({
   chartId,
   donutData,
   cardData,
   labelOnHover = true,
   totalName,
   title,
   code,
   valueInCircle,
}) => {
   const [totalValue, setTotalValue] = useState(0);

   useEffect(() => {
      setTotalValue(Number(donutData.filter(data => data.propertyName === totalName).map(data => data.propertyValue).join()));
   }, [donutData, totalName]);

   return (
      <>
         <Row className="flex-column align-items-center justify-content-between" style={{minHeight: '24rem'}}>
            <Col className="col-auto">
               <Row className='flex-column justify-content-center align-items-center w-100 m-auto'>
                  <h5 className='text-center title-box'>{title}</h5>
                  <Col className='dashChartBox donutCharts' style={{minHeight: '13rem'}}>
                     <AnimatedDonutChartDash
                        chartId={chartId}
                        data={donutData}
                        labelOnHover={labelOnHover}
                        totalName={totalName}
                        code={code}
                        valueInCircle={valueInCircle}
                     />
                  </Col>
               </Row>
            </Col>

            <Col>
               <Row className='flex-column justify-content-center align-items-center w-100 m-auto'>
                  {/* {cardData.length && cardData.map((el, i) => {
                     let backgroundColor = 'transparent';
                     if (el.propertyName !== totalName && el.propertyValue !== 0) {
                        backgroundColor = colors[nextColorIndex];
                        nextColorIndex++; // Passa all'indice successivo dei colori
                     } else if (el.propertyValue === 0) {
                        backgroundColor = 'rgba(192, 192, 192, .5)';
                        nextColorIndex++;
                     }
                     console.log('BACK', backgroundColor);
                     return (
                        <Row
                        className='d-flex justify-content-center col-8 p-2 my-1'
                        key={`${chartId}_${el.propertyName}`}
                        style={{
                           backgroundColor: `${
                              i === 0 ? 'rgba(194, 201, 35, .1)' : 'rgba(245, 245, 245, .5)'
                           }`,
                           borderRadius: '10px',
                        }}>
                        <Col
                           className='col-4 color-legend'
                           style={{
                              backgroundColor: backgroundColor,
                           }}></Col>
                        <Col className='text-end'>
                           <span className='fw-bold' style={{ fontSize: '.8rem' }}>
                              {el.translation}
                           </span>
                           <p className='mb-0'>{formatToDecimals(el.realValue)} €</p>
                        </Col>
                        </Row>
                     );
                  })} */}

                  {cardData.length > 0 && (
                     <NewLegend data={cardData} scope={title} totalName={totalName} className={chartId} totalValue={totalValue} showTotal={true} />
                  )}
               </Row>
            </Col>
         </Row>

         <Row>
            <Col className="mt-auto">
               <Row className="totalDiv">
                  <Col className="col-auto total-label f-600">Totale</Col>
                  <Col className="total-value text-end f-600">{formatToDecimals(totalValue)}</Col>
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default AnimatedDonutChartCard;
