import React from 'react';
import ClientPage from '@components/administration/clientPage';

const Clients = () => {
  return (
   <div id="clients" className="megaContent"> 
      <ClientPage />
   </div>
  );
};

export default Clients;
