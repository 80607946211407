import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
//import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';
import { IcoEdit } from '@icons';
import MarkdownEditorComponent from './MarkdownEditorComponent';
/* import DOMPurify from 'dompurify';
import CustomModal from 'components/form/CustomModal/CustomModal';
import ResizableDiv from 'components/form/ResizableDiv/ResizableDiv';
 */
const MarkdownEditComponent = ({
  titleCode,
  fallback,
  customStyle,
  placeholder,
  onChange,
  name,
  type = 'description',
  existingContent,
  editState,
  setEditState,
  toolbarOptions,
  mandatory,
}) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
    setEditState((prevState) => !prevState);
  };

  /* function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  } */
  return (
    <Form.Group as={Col} controlId={name} id='markdown-edit'>
      <Form.Label className='d-flex align-items-center'>
        <OETrans code={titleCode} fallback={fallback} />{mandatory ? <>&nbsp;<span className="required-field">*</span></> : null}
        <Button
          className='d-none d-flex align-items-center justify-content-center ml-2'
          variant='link'
          disabled={!editMode && editState}
          onClick={(e) => {
            e.preventDefault();
            toggleEditMode();
          }}>
          <IcoEdit className='ms-2 second-color ico-small edit' />
          {/* <span className='ml-1'>{<Trans code='F00055' />}</span> */}
          {/* {!editMode ? (
            <IcoEdit className='second-color ico-small edit' />
          ) : (
            <IcoSave className='second-color' />
          )}
          <span className='ml-1'>{!editMode ? 'EDIT' : 'SAVE'}</span> */}
        </Button>
      </Form.Label>
      {/* <ResizableDiv resize='vertical' customStyle={customStyle}>
        {!existingContent || existingContent === '<p></p>' ? (
          <div className='previewContainerMdEditor'>{placeholder}</div>
        ) : (
          <div
            className='previewContainerMdEditor'
            dangerouslySetInnerHTML={createMarkup(existingContent)}></div>
        )}
      </ResizableDiv> */}

      <MarkdownEditorComponent
         onChange={onChange}
         name={name}
         type={type}
         existingContent={existingContent}
         toolbarOptions={toolbarOptions}
      />

      {/* {editMode && (
        <CustomModal
          show={editMode}
          handleClose={toggleEditMode}
          title={translate(titleCode, fallback)}
          content={
            <MarkdownEditorComponent
              onChange={onChange}
              name={name}
              type={type}
              existingContent={existingContent}
              toolbarOptions={toolbarOptions}
            />
          }
          modalSize='lg'
          buttonText={translate('common.close', 'Chiudi')}
        />
      )} */}
    </Form.Group>
  );
};

export default MarkdownEditComponent;